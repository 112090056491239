import { writable, get } from "$utils/shim";
import { derived } from "svelte/store";

export const FRAMES_PER_SECOND = 30;
export const SECONDS_PER_FRAME = 1.0 / FRAMES_PER_SECOND;
export const FIRST_FRAME_TIME = 1 / FRAMES_PER_SECOND / 2;

export function createPlaybackStore() {
  const eventBus = document.createComment("video-event-bus");
  const source = writable(null);
  const isLive = writable(false); // TODO: Rename to liveModeEnabled
  const canCaptureCurrent = writable(true);
  const audioLevel = writable(30);
  let previousAudioLevel = 30;

  // Managed state by the video player
  const isPlaying = writable(false);
  const totalDuration = writable(1);
  const maxPlayedTime = writable(0);
  const videoIsLive = writable(false);
  const currentTime = writable(0);

  const sourceUrl = derived(source, ($sources) =>
    typeof $sources === "string" ? $sources : $sources?.[0]?.src || null,
  );

  function send(eventName, data = null) {
    eventBus.dispatchEvent(new CustomEvent(eventName, { detail: data }));
  }

  return {
    listen(eventName, callback) {
      eventBus.addEventListener(eventName, (event) => callback(event.detail));
      return () => eventBus.removeEventListener(eventName, callback);
    },

    currentTime: { subscribe: currentTime.subscribe },
    canCaptureCurrent: { subscribe: canCaptureCurrent.subscribe },
    maxPlayedTime: { subscribe: maxPlayedTime.subscribe },
    totalDuration: { subscribe: totalDuration.subscribe },
    isPlaying: { subscribe: isPlaying.subscribe },
    isLive: { subscribe: isLive.subscribe },
    source: { subscribe: source.subscribe },
    audioLevel: { subscribe: audioLevel.subscribe },
    sourceUrl,

    // Internal API
    setCurrentTime(time) {
      currentTime.set(time);
      maxPlayedTime.set(Math.max(time, get(maxPlayedTime)));
    },

    setTotalDuration(duration) {
      totalDuration.set(Math.max(duration, 1));
    },

    setTotalDurationFromEvents(duration) {
      totalDuration.update((currentDuration) => Math.max(currentDuration, duration));
    },

    setVideoIsLive(isLive) {
      videoIsLive.set(isLive);
    },

    setIsPlaying(playing) {
      isPlaying.set(playing);
    },

    onPlayerReady() {
      // Called when the player is ready (might be after source is set)
      send("source", { videoSrc: get(source), autoplay: false });
      send("currenttime", { time: get(currentTime) });
      send(get(isPlaying) ? "play" : "pause");
    },

    // External API
    setSource(src, shouldAutoplay = false) {
      const previousSrc = get(source);
      if (src !== previousSrc) {
        maxPlayedTime.set(0);
        source.set(src); // TODO: remove, get it from the player
        isPlaying.set(false);
        send("source", { videoSrc: src, autoplay: shouldAutoplay });
      }
    },

    togglePlay() {
      if (get(isPlaying)) {
        send("pause");
      } else {
        send("play");
      }
    },

    play() {
      send("play");
    },

    pause() {
      send("pause");
    },

    rewind() {
      send("currenttime", { time: FIRST_FRAME_TIME });
    },

    forward() {
      send("currenttime", { time: get(totalDuration) });
    },

    stepForward(stepSize = 1) {
      if (stepSize > 0) {
        const newValue = get(currentTime) + stepSize / FRAMES_PER_SECOND;
        const maxDuration = get(totalDuration);
        const newTime = Math.min(newValue, maxDuration);
        send("currenttime", { time: newTime });
      }
    },

    stepBackward(stepSize = 1) {
      if (stepSize > 0) {
        const newValue = get(currentTime) - stepSize / FRAMES_PER_SECOND;
        const newTime = Math.max(newValue, FIRST_FRAME_TIME);
        send("currenttime", { time: newTime });
      }
    },

    goToTime(time) {
      send("currenttime", { time });
    },

    goToPercentage(percentage) {
      const time = (get(totalDuration) * percentage) / 100;
      send("currenttime", { time });
    },

    setAudioLevel(level) {
      const newLevel = Math.max(0, Math.min(100, level));
      audioLevel.set(newLevel);
      send("audiolevel", { level: newLevel });
    },

    toggleMute() {
      const currentLevel = get(audioLevel);
      if (currentLevel > 0) {
        previousAudioLevel = currentLevel;
        this.setAudioLevel(0);
      } else {
        this.setAudioLevel(previousAudioLevel);
      }
    },

    increaseVolume(amount = 10) {
      const currentLevel = get(audioLevel);
      this.setAudioLevel(currentLevel + amount);
    },

    decreaseVolume(amount = 10) {
      const currentLevel = get(audioLevel);
      this.setAudioLevel(currentLevel - amount);
    },

    toggleLiveMode() {
      isLive.update((value) => !value);
    },

    enableLiveMode(value = true) {
      isLive.set(value);
    },

    captureCurrentScreen() {
      send("capture");
    },

    reset() {
      canCaptureCurrent.set(true);
      maxPlayedTime.set(0);
      currentTime.set(0);
      totalDuration.set(1);
      isPlaying.set(false);
      isLive.set(false);
      videoIsLive.set(false);
      source.set(null);
      audioLevel.set(30);
      previousAudioLevel = 30;
      eventBus.cloneNode(true);
    },
  };
}
