// TODO: Keep track when scrolling

export function positionElement(refElement, targetElement, align = "bottom-right") {
  const rect = refElement.getBoundingClientRect();
  const offsetTop = 8;

  targetElement.style.position = "fixed";

  switch (align) {
    case "bottom-left":
      targetElement.style.left = `${rect.left + window.scrollX}px`;
      targetElement.style.top = `${rect.bottom + window.scrollY + offsetTop}px`;
      break;
    case "bottom-right":
      targetElement.style.left = `${rect.right + window.scrollX - targetElement.offsetWidth}px`;
      targetElement.style.top = `${rect.bottom + window.scrollY + offsetTop}px`;
      break;
    default:
      break;
  }
}
