<script>
  export let live;

  let files;
  let errorByFile = {};
  let progressByFile = {};

  function onUploadReady(key) {
    console.log("onUploadReady", key);
  }

  function upload() {
    if (!files) return;

    files = Array.from(files).map((file) => {
      file.meta = () => {
        return { foo: "bar" };
      };
      return file;
    });

    live.upload("assets", files);

    const refIds = new Set(Array.from(files).map((file) => file._phxRef));

    function removeFile(ref) {
      files = Array.from(files).filter((file) => file._phxRef != ref);
    }

    function onProgress(params) {
      if (!params) return;
      const { meta: _, ref, progress } = params;
      progressByFile[ref] = progress;
    }

    function onError(params) {
      if (!params) return;
      const { meta: _, ref, error } = params;
      refIds.delete(ref);
      removeFile(ref);
      errorByFile[ref] = error;
    }

    function onUploadComplete(params) {
      if (!params) return;
      const { meta: _, key, ref } = params;
      progressByFile[ref] = 100;

      if (refIds.delete(ref)) {
        removeFile(ref);
        onUploadReady(key);
      } else {
        console.warn(`File with ref ${ref} not in list:`, refIds);
      }

      if (refIds.size === 0) {
        live.removeHandleEvent(onUploadComplete);
        live.removeHandleEvent(onProgress);
        live.removeHandleEvent(onError);
      }
    }

    live.handleEvent("upload_progress", onProgress);
    live.handleEvent("upload_error", onError);
    live.handleEvent("upload_complete", onUploadComplete);
  }
</script>

<div class="max-w-md rounded overflow-hidden shadow-lg m-4">
  <div class="px-6 py-4">
    <label for="file_upload" class="font-bold">Upload multiple files:</label>
    <input class="my-4" bind:files multiple type="file" />

    {#if Object.values(errorByFile).length > 0}
      <div class="bg-red-500 text-white font-semibold p-1 text-left text-sm">
        <pre>{JSON.stringify(errorByFile, null, 2)}</pre>
      </div>
    {/if}

    {#if files}
      <h2 class="font-semibold text-gray-700 mb-4">Selected files:</h2>
      <ul class="mb-4">
        {#each Array.from(files) as file}
          <li class="text-xs">{file.name} ({file.size} bytes)</li>
        {/each}
      </ul>
    {/if}

    <button
      class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded"
      on:click={upload}>Upload</button
    >

    <pre>{JSON.stringify(progressByFile, null, 2)}</pre>
  </div>
</div>
