import { Editor } from "@tiptap/core";
import StarterKit from "@tiptap/starter-kit";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import Link from "@tiptap/extension-link";

const TextEditor = {
  editor: null,
  content: null,
  buttonSetup: {
    bold: {
      run: (editor) => editor.chain().focus().toggleBold().run(),
      check: (editor) => editor.isActive("bold"),
    },
    italic: {
      run: (editor) => editor.chain().focus().toggleItalic().run(),
      check: (editor) => editor.isActive("italic"),
    },
    h1: {
      run: (editor) => editor.chain().focus().toggleHeading({ level: 1 }).run(),
      check: (editor) => editor.isActive("heading", { level: 1 }),
    },
    strikethrough: {
      run: (editor) => editor.chain().focus().toggleStrike().run(),
      check: (editor) => editor.isActive("strike"),
    },
    link: {
      run: (editor) => {
        const previousUrl = editor.getAttributes("link").href || "";
        const url = window.prompt("URL", previousUrl);

        if (url === null) {
          // cancelled
          return;
        }

        if (url === "") {
          editor.chain().focus().extendMarkRange("link").unsetLink().run();
          return;
        }

        try {
          editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
        } catch (e) {
          alert(e.message);
        }
      },
      check: (editor) => editor.isActive("link"),
    },
    bulletList: {
      run: (editor) => editor.chain().focus().toggleBulletList().run(),
      check: (editor) => editor.isActive("bulletList"),
    },
    orderedList: {
      run: (editor) => editor.chain().focus().toggleOrderedList().run(),
      check: (editor) => editor.isActive("orderedList"),
    },
    blockquote: {
      run: (editor) => editor.chain().focus().toggleBlockquote().run(),
      check: (editor) => editor.isActive("blockquote"),
    },
    codeBlock: {
      run: (editor) => editor.chain().focus().toggleCodeBlock().run(),
      check: (editor) => editor.isActive("codeBlock"),
    },
    code: {
      run: (editor) => editor.chain().focus().toggleCode().run(),
      check: (editor) => editor.isActive("code"),
    },
    clearMarks: {
      run: (editor) => editor.chain().focus().unsetAllMarks().run(),
      check: () => false,
    },
    clearNodes: {
      run: (editor) => editor.chain().focus().clearNodes().run(),
      check: () => false,
    },
    paragraph: {
      run: (editor) => editor.chain().focus().setParagraph().run(),
      check: (editor) => editor.isActive("paragraph"),
    },
    h2: {
      run: (editor) => editor.chain().focus().toggleHeading({ level: 2 }).run(),
      check: (editor) => editor.isActive("heading", { level: 2 }),
    },
    h3: {
      run: (editor) => editor.chain().focus().toggleHeading({ level: 3 }).run(),
      check: (editor) => editor.isActive("heading", { level: 3 }),
    },
    h4: {
      run: (editor) => editor.chain().focus().toggleHeading({ level: 4 }).run(),
      check: (editor) => editor.isActive("heading", { level: 4 }),
    },
    h5: {
      run: (editor) => editor.chain().focus().toggleHeading({ level: 5 }).run(),
      check: (editor) => editor.isActive("heading", { level: 5 }),
    },
    h6: {
      run: (editor) => editor.chain().focus().toggleHeading({ level: 6 }).run(),
      check: (editor) => editor.isActive("heading", { level: 6 }),
    },
    horizontalRule: {
      run: (editor) => editor.chain().focus().setHorizontalRule().run(),
      check: () => false,
    },
    hardBreak: {
      run: (editor) => editor.chain().focus().setHardBreak().run(),
      check: () => false,
    },
    undo: {
      run: (editor) => editor.chain().focus().undo().run(),
      check: (editor) => !editor.can().chain().focus().undo().run(),
    },
    redo: {
      run: (editor) => editor.chain().focus().redo().run(),
      check: (editor) => !editor.can().chain().focus().redo().run(),
    },
    fullScreen: {
      run: (editor) => {
        const editorContainer = editor.options.element.parentElement.parentElement;
        const classList = ["fullscreen-editor", "fixed", "inset-2", "z-100", "bg-white"];
        if (editorContainer.classList.contains("fullscreen-editor")) {
          // Exit full screen
          document.body.classList.remove("overflow-hidden");
          classList.forEach((className) => {
            editorContainer.classList.remove(className);
          });
        } else {
          // Enter full screen
          document.body.classList.add("overflow-hidden");
          classList.forEach((className) => {
            editorContainer.classList.add(className);
          });
        }
      },
      check: () => false,
    },
  },

  initialValue() {
    return this.el.dataset.value || "";
  },

  classes() {
    return this.el.dataset.class || "";
  },

  updated() {
    this.handleResetEditor(this.el.dataset.value || "");
  },

  mounted() {
    const element = this.el.querySelector("[data-editor]");
    const hidden = this.el.querySelector("[data-editor-hidden]");
    const controlButtons = this.el.closest("form").querySelectorAll("[data-editor-control]");

    this.handleEvent("reset-editor", (data) => {
      this.handleResetEditor(data.content || "");
    });

    controlButtons.forEach((btn) => {
      btn.addEventListener("click", (e) => {
        const button = e.target.closest("[data-editor-control]");
        const config = this.buttonSetup[button.dataset.editorControl];
        if (config && typeof config.run === "function") {
          config.run(this.editor);
        }
      });
    });

    this.editor = new Editor({
      element,
      extensions: [
        // Color.configure({ types: [TextStyle.name, ListItem.name] }),
        TextStyle.configure({ types: [ListItem.name] }),
        StarterKit,
        Link.configure({
          openOnClick: false,
          autolink: true,
          defaultProtocol: "https",
          protocols: ["http", "https"],
          validate: (url) => /^https?:\/\//.test(url),
        }),
      ],
      onUpdate: ({ editor }) => {
        this.content = editor.getHTML();
        hidden.value = this.content;
        hidden.dispatchEvent(new Event("input", { bubbles: true }));
      },
      content: this.initialValue(),
      editorProps: {
        attributes: {
          class: this.classes(),
        },
      },
      onTransaction: ({ editor: _editor }) => {
        controlButtons.forEach((btn) => {
          const button = btn.closest("[data-editor-control]");
          const config = this.buttonSetup[button.dataset.editorControl];
          if (config && typeof config.check === "function") {
            if (config.check(this.editor)) {
              btn.classList.add("bg-gray-50");
            } else {
              btn.classList.remove("bg-gray-50");
            }
          }
        });
      },
    });
  },

  handleResetEditor(content) {
    if (this.editor) {
      this.editor.commands.setContent(content);

      const hidden = this.el.querySelector("[data-editor-hidden]");
      if (hidden) {
        hidden.value = content;
        hidden.dispatchEvent(new Event("input", { bubbles: true }));
      }
    }
  },

  destroyed() {
    this.editor.destroy();
  },
};

export { TextEditor };
