<script>
  import { Icon, CodeBracket } from "svelte-hero-icons";
  import { tooltip } from "$lib/utils/tooltip.js";

  export let checked = false;
  export let onChange = () => {};
  export let description = "";
</script>

<div class="flex items-center justify-center m-0 p-0 rounded">
  <button
    type="button"
    class="relative inline-flex h-8 w-16 flex-shrink-0 cursor-pointer rounded-md border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
    class:bg-indigo-600={checked}
    class:bg-gray-200={!checked}
    role="switch"
    aria-label={description}
    use:tooltip
    aria-checked={checked}
    on:click={onChange}
  >
    <span class="sr-only">Use setting</span>
    <span
      aria-hidden="true"
      class="pointer-events-none h-7 w-7 transform rounded-md bg-white shadow ring-0 transition duration-200 ease-in-out flex items-center justify-center"
      class:translate-x-8={checked}
      class:translate-x-0={!checked}
    >
      <Icon src={CodeBracket} mini class="w-3 h-3" />
    </span>
  </button>
</div>
