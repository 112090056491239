import ChartJS from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import colorLib from "@kurkle/color";

import { parseJsonOrDefault } from "../utils/parseJsonOrDefault";

ChartJS.register(ChartDataLabels);

function transparentize(value, opacity) {
  var alpha = opacity === undefined ? 0.5 : 1 - opacity;
  return colorLib(value).alpha(alpha).rgbString();
}

export const BetterCharts = {
  updated() {
    const rawData = parseJsonOrDefault(this.el.dataset.data, []);
    const labels = parseJsonOrDefault(this.el.dataset.labels, []);

    const data = {
      labels: labels,
      datasets: Object.entries(rawData).map(([label, data]) => ({
        label,
        data: data.data,
        backgroundColor: transparentize(data.color, 0.5),
        borderColor: data.color,
        borderWidth: 2,
        borderSkipped: false,
        borderRadius: 5,
      })),
    };

    this.el.chart.data.datasets.forEach((dataset) => {
      const newData = data.datasets.find((d) => d.label === dataset.label);
      dataset.data = newData.data;
    });
    this.el.chart.update();
  },
  mounted() {
    const id = this.el.id;
    const $chart = this.el.querySelector("canvas");
    const rawData = parseJsonOrDefault(this.el.dataset.data, []);
    const labels = parseJsonOrDefault(this.el.dataset.labels, []);
    const title = this.el.dataset.title;
    const legend = this.el.dataset.legend;

    const data = {
      labels: labels,
      datasets: Object.entries(rawData).map(([label, data]) => ({
        label,
        data: data.data,
        backgroundColor: transparentize(data.color, 0.5),
        borderColor: data.color,
        borderWidth: 2,
        borderSkipped: false,
        borderRadius: 5,
      })),
    };

    const config = {
      type: "bar",
      data: data,
      plugins: [ChartDataLabels],
      options: {
        maintainAspectRatio: false,
        // animation: false,
        animation: {
          duration: 800,
          easing: "easeOutExpo",
        },
        transitions: {
          active: 100,
          resize: 100,
        },
        onClick: (event, opts, chart) => {
          const elements = chart.getElementsAtEventForMode(
            event,
            "nearest",
            { intersect: true },
            true,
          );
          const element = elements[0];

          const raw = element?.element?.$context?.raw;

          if (raw) {
            this.pushEvent("chart-clicked", {
              id,
              index: element.index,
              dataset_index: element.datasetIndex,
              ...raw,
            });
          }
        },
        responsive: true,
        interaction: {
          mode: "index",
        },
        plugins: {
          legend: {
            position: legend,
          },
          title: {
            display: true,
            text: title,
          },
          datalabels: {
            anchor: "end",
            align: "end",
            offset: 4,
            clamp: true,
            // Show ms label
            formatter: function (value, _context) {
              if (typeof value === "object" && typeof value.label === "string") {
                return value.label;
              }

              let y;
              if (typeof value === "number") {
                y = value;
              } else {
                y = value.y;
              }

              return typeof y === "number" ? Math.round(y) + "ms" : "";
            },
          },
        },
      },
    };

    const chart = new ChartJS($chart, config);

    this.el.chart = chart;
  },

  destroyed() {},
};
