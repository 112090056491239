<script>
  export let annotation;
  const { is_assert, is_active } = annotation;

  function labelText({ name, ui_type }) {
    const label = `${ui_type.charAt(0).toUpperCase() + ui_type.slice(1)} (${name})`;
    if (is_assert) {
      if (is_active) return `✓ Assert - ${label}`;
      return `✗ Assert - ${label}`;
    }
    return label;
  }
  const style = is_assert
    ? is_active
      ? "bg-green-400"
      : "text-white font-bold bg-red-400"
    : "bg-white border-white";
</script>

<div
  class="pointer-events-none select-none px-1 m-0 absolute top-[-2px] left-[-2px] -translate-y-full whitespace-nowrap border rounded-t-sm {style}"
>
  {labelText(annotation)}
</div>
