import { writable } from "$utils/shim";
import { addRequestHandler } from "$utils/request";
import { createWorkflowExecutionStore } from "./execution";
import { createWorkflowStore } from "./workflow";
import { createFeatureStore } from "./features";
import { createFiletreeStore } from "./filetree";
import { createDefinitionStore } from "./definition";
import { createAssetGroupStore } from "./assetGroups";
import { createJobsStore } from "./jobs";

const initialToolbar = {
  show_code: false,
  show_table: false,
  show_prop_pane: true,
  show_code_pane: true,
  show_debug: false,
  show_toolbox: true,
  hide_props: false,
};

export const liveStore = writable(null);

export const features = createFeatureStore();

export const toolbar = writable(initialToolbar);

export const assetGroupStore = createAssetGroupStore(liveStore);

export const execution = createWorkflowExecutionStore(liveStore, features);

export const definitions = createDefinitionStore();

export const workflow = createWorkflowStore(liveStore, definitions);

export const luaCode = writable("");

export const filetree = createFiletreeStore(liveStore, workflow, execution);

export const currentStep = execution.currentStep;

export const exportedState = workflow;

export const jobs = createJobsStore();

export const user = writable({});

export function resetStores() {
  // Svelte stores persist throughout live view patch navigation.
  // All stores must be reset to avoid issues when navigating away.
  features.reset();
  execution.reset();
  workflow.reset();
  definitions.reset();
  filetree.reset();
  assetGroupStore.reset();
  luaCode.set("");
  liveStore.set(null);
  toolbar.set(initialToolbar);
  jobs.reset();
  user.set({});
}

export function initializeStore(
  live,
  {
    toolbox,
    workflow: workflow_data,
    root_definition,
    user_roles = [],
    user_nickname,
    is_readonly,
    job,
    tree_items = [],
  },
) {
  resetStores();
  liveStore.set(addRequestHandler(live));
  execution.initialize();
  definitions.initialize(toolbox, root_definition, user_roles);
  features.initialize(user_roles, is_readonly);
  workflow.initialize(workflow_data);
  filetree.initialize(tree_items);
  assetGroupStore.initialize();
  jobs.initialize(job);
  user.set({ nickname: user_nickname });
}
