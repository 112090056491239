<script>
  import { Icon, ArrowLeftEndOnRectangle } from "svelte-hero-icons";
  import { tooltip } from "$lib/utils/tooltip";
  import { uploadScreenshot } from "$lib/Debugger/store";

  export let src;
  export let alt;
  export let crossorigin = "anonymous";
  export let sessionId = null;

  function importImage() {
    if (sessionId === null) {
      console.error("sessionId is required to import image");
      return;
    }

    const img = new Image();
    img.crossOrigin = crossorigin;
    img.src = src;
    img.onload = () => {
      let canvas = document.createElement("canvas");
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      const mime = "image/jpeg";
      canvas.toBlob(
        (blob) => uploadScreenshot([new File([blob], "screenshot.jpg", { type: mime })], sessionId),
        mime,
        0.7,
      );
    };
  }
</script>

<div class="group">
  <div class="relative">
    <button
      class="absolute top-0 right-0 m-2 w-4 h-4 z-30"
      use:tooltip={{ placement: "bottom" }}
      aria-label="Import this image to assets"
      on:click|stopPropagation={importImage}
    >
      <Icon
        src={ArrowLeftEndOnRectangle}
        class="absolute top-0 right-0 w-4 h-4 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out"
      />
    </button>
  </div>
  <img {src} {alt} {crossorigin} class="max-w-full h-auto rounded-lg {$$props.class || ''}" />
</div>
