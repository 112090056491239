<script>
  import { Icon, ChevronRight, ChevronDown } from "svelte-hero-icons";
  export let node;
  export let depth = 0;

  let isExpanded = true;

  function toggleExpand() {
    isExpanded = !isExpanded;
  }

  $: hasChildren = node.sequence || (node.branches && Object.keys(node.branches).length > 0);
</script>

<div
  class="text-xs font-mono bg-gray-100 p-2 rounded flex items-start"
  style="padding-left: {depth * 0.5}rem"
>
  {#if hasChildren}
    <button on:click={toggleExpand} class="mr-1">
      <Icon src={isExpanded ? ChevronDown : ChevronRight} class="w-3 h-3 text-gray-800" />
    </button>
  {:else}
    <div class="w-3 h-3 mr-1"></div>
  {/if}
  <div class="flex-grow">
    <span class="font-semibold">{node.name || node.type || "Root"}</span>
    {#if node.componentType}
      <span class="ml-1 text-gray-500 text-xs">({node.componentType})</span>
    {/if}
    {#if node.properties && Object.keys(node.properties).length > 0}
      <div class="ml-4 text-xs text-gray-600">{JSON.stringify(node.properties)}</div>
    {/if}

    {#if isExpanded}
      {#if node.sequence}
        {#each node.sequence as childNode (childNode.id)}
          <svelte:self node={childNode} depth={depth + 1} />
        {/each}
      {/if}
      {#if node.branches}
        {#each Object.entries(node.branches) as [branchName, branchSequence] (branchName)}
          <div class="ml-4">
            <span class="font-semibold text-blue-600">{branchName}:</span>
            {#each branchSequence as childNode (childNode.id)}
              <svelte:self node={childNode} depth={depth + 2} />
            {/each}
          </div>
        {/each}
      {/if}
    {/if}
  </div>
</div>
