<script>
  // svelte-ignore unused-export-let
  export let props;
  // svelte-ignore unused-export-let
  export let update;
  // svelte-ignore unused-export-let
  export let isReadonly = false;
</script>

<div class="text-sm text-gray-500 mt-2">No additional properties for Image matcher.</div>
