import { writable, derived, get } from "$utils/shim";
import { generateUUID } from "$utils/uuid";

export function createAssetStore(liveStore, uploader = "assets") {
  const assets = writable([]);
  const selectedIndex = writable(0);
  const errors = writable({});
  const uploadingFiles = writable([]);
  const { subscribe, update } = assets;

  const selected = derived(
    [assets, selectedIndex],
    ([$assets, $selectedIndex]) => $assets[$selectedIndex],
  );

  function getImageDimensionsAndPreview(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () =>
          resolve({
            tempSrc: e.target.result,
            width: img.width,
            height: img.height,
          });
        img.onerror = reject;
        img.src = e.target.result;
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  function removeInProgressFile(ref) {
    uploadingFiles.update((files) => files.filter((file) => file.phxRef !== ref));
  }

  return {
    subscribe,

    initialize(initialAssets = []) {
      initialAssets.forEach((asset) => this.add(asset));
    },

    async upload(files, associated_element = null, metadata = {}, callback = null) {
      if (!files || files.length === 0) return;

      const preProcessFile = async (file) => {
        try {
          const { tempSrc, width, height } = await getImageDimensionsAndPreview(file);

          // Set meta data on file that will be send to sauron
          file.meta = () => ({ ...metadata, width, height, associated_element });

          return { success: true, tempSrc, width, height, file };
        } catch (error) {
          return { success: false, error };
        }
      };

      const result = await Promise.all(Array.from(files).map(preProcessFile));

      get(liveStore).upload(uploader, files);

      result.map(({ success, tempSrc, width, height, file, error }) => {
        const phxRef = file._phxRef; // set by get(liveStore).upload()
        if (success) {
          const newItem = { tempSrc, width, height, progress: 0, phxRef };
          uploadingFiles.update((currentFiles) => [...currentFiles, newItem]);
        } else {
          console.error("Error processing file:", error);
          errors.update((prev) => ({ ...prev, [phxRef]: "Error processing file" }));
        }
      });

      this.setupEventHandlers(callback);

      return result;
    },

    setupEventHandlers(callback) {
      const handleProgress = (params) => {
        if (!params) return;
        const { ref, progress } = params;
        uploadingFiles.update((files) =>
          files.map((file) => (file.phxRef === ref ? { ...file, progress } : file)),
        );
      };

      const handleError = (params) => {
        if (!params) return;
        const { ref, message } = params;
        removeInProgressFile(ref);
        errors.update((prev) => ({ ...prev, [ref]: message }));
      };

      const handleComplete = (params) => {
        if (!params) return;
        const { id, url, ref } = params;

        const completedFile = get(uploadingFiles).find((file) => file.phxRef === ref);
        if (completedFile) {
          update((currentAssets) => {
            const updatedAssets = [
              ...currentAssets,
              { id, url, width: completedFile.width, height: completedFile.height },
            ];
            if (callback) callback(updatedAssets);
            return updatedAssets;
          });
        }

        removeInProgressFile(ref);

        if (get(uploadingFiles).length === 0) {
          get(liveStore).removeHandleEvent(handleComplete);
          get(liveStore).removeHandleEvent(handleProgress);
          get(liveStore).removeHandleEvent(handleError);
        }
      };

      get(liveStore).handleEvent("upload_progress", handleProgress);
      get(liveStore).handleEvent("upload_error", handleError);
      get(liveStore).handleEvent("upload_complete", handleComplete);
    },

    add(image) {
      const { id = generateUUID(), url, width, height } = image;
      update((images) => [...images, { id, url, width, height }]);
    },

    remove(imageIndex) {
      update((images) => {
        if (!images[imageIndex]) {
          console.log("Can not delete image. Invalid index:", imageIndex, images);
          return;
        }

        const assetId = images[imageIndex].id;
        const newImages = images.filter((_, index) => index !== imageIndex);
        const currentSelectedIndex = get(selectedIndex);
        if (currentSelectedIndex >= newImages.length) {
          this.select(Math.max(0, newImages.length - 1));
        } else if (currentSelectedIndex > imageIndex) {
          this.select(currentSelectedIndex - 1);
        }

        // Remove from DB as well
        get(liveStore).pushEvent("delete_asset", { id: assetId });

        return newImages;
      });
    },

    // Set it to the closest valid index
    select(index) {
      const images = get(this);
      selectedIndex.set(Math.max(0, Math.min(index, images.length - 1)));
    },

    clearErrors() {
      errors.set({});
    },

    get selectedIndex() {
      return selectedIndex;
    },

    get selected() {
      return selected;
    },

    get errors() {
      return errors;
    },

    get uploadingFiles() {
      return uploadingFiles;
    },

    reset() {
      assets.set([]);
      errors.set({});
      selectedIndex.set(0);
      uploadingFiles.set([]);
    },
  };
}
