<script>
  import DataGrid from "../DataGrid/DataGrid.svelte";

  export let event;

  let data = [
    {
      id: "status",
      label: "Status",
      value: event.is_active,
      type: "status",
    },
    {
      id: "event_time",
      label: "Event Time",
      value: event.start,
      type: "time",
    },
    {
      id: "region",
      label: "Region",
      value: event.region,
      type: "region",
    },
  ];

  for (let index = 0; index < event.state.detected.length; index++) {
    const detected = event.state.detected[index];
    data.push({
      id: `detected_${index}`,
      label: `Matching`,
      value: detected.matched,
      type: "status",
      props: {
        statusLabel: {
          true: "matching",
          false: "not matching",
        },
      },
    });
    data.push({
      id: `image_${index}`,
      label: `Image`,
      value: detected.image,
      type: "image",
      alt: `Detected text #${index + 1} with bounding box`,
    });
    data.push({
      id: `confidence_${index}`,
      label: `Confidence`,
      value: detected.confidence,
      type: "numeric",
    });

    if (detected.distance) {
      data.push({
        id: `similarity_${index}`,
        label: `Similarity`,
        value: detected.distance,
        type: "numeric",
      });
    }
    data.push({
      id: `text_${index}`,
      label: `Detected`,
      value: detected.text || "N/A",
      type: "text",
    });
    data.push({
      id: `bounding_box_${index}`,
      label: `Bounding Box`,
      value: {
        left: detected.left,
        top: detected.top,
        width: detected.width,
        height: detected.height,
      },
      type: "region",
    });
    if (detected.accepted !== undefined) {
      data.push({
        id: `text_${index}_accepted`,
        label: "Accepted?",
        value: detected.accepted,
        type: "text",
      });
    }
    if (detected.matched !== undefined) {
      data.push({
        id: `text_${index}_matched`,
        label: "Matched?",
        value: detected.matched,
        type: "text",
      });
    }
    if (detected.matched_text) {
      for (let j = 0; j < detected.matched_text.length; j++) {
        data.push({
          id: `text_${index}_matched_text_${j}`,
          label: `Matched [${j + 1}]`,
          value: detected.matched_text[j],
          type: "text",
        });
      }
    }
  }
</script>

<DataGrid {data} />
