<script>
  import CheckboxInput from "$lib/Inputs/CheckboxInput.svelte";
  import SelectInput from "$lib/Inputs/SelectInput.svelte";
  import NumericInput from "$lib/Inputs/NumericInput.svelte";
  import PropHelp from "../PropHelp.svelte";

  export let props;
  export let update;
  export let isReadonly = false;
  export let showAdditionalHelp = true;

  const indicatorPositionOptions = [
    { value: "none", label: "None" },
    { value: "above", label: "Above" },

    // TODO: Implement this feature
    //   allow users to specify a region for the target seek time
    //   for example, for Netflix, the target seek time is in shown
    //   before the progress bar at a fixed position
    // { value: "fixed_position", label: "In region..." },
  ];
</script>

<div class="flex flex-col mt-2">
  <CheckboxInput
    value={props.detect_indicator || false}
    on:input={(e) => update(e, "detect_indicator")}
    label="Detect indicators?"
    {isReadonly}
  />

  <PropHelp {showAdditionalHelp}>
    When checked, the progress bar will try to detect seek indicators like fast forward (1x, 2x,
    3x), fast rewind (1x, 2x, 3x), jump back 10 seconds and jump forward 30 seconds and target seek
    time label.
  </PropHelp>
</div>

<div class="flex flex-col mt-2">
  <SelectInput
    value={props.indicator_position || "none"}
    options={indicatorPositionOptions}
    on:input={(e) => update(e, "indicator_position")}
    label="Indicator Position"
    variant="wide"
    {isReadonly}
  />

  <PropHelp {showAdditionalHelp}>
    Choose where the target seek indicators are shown.<br /><br />

    Currently, this matcher only detects their existance and does not check which kind of seek
    indicator is shown.
  </PropHelp>
</div>

<div class="flex flex-col mt-2">
  <NumericInput
    value={props.indicator_required || 0}
    on:input={(e) => update(e, "indicator_required")}
    label="# Indicators Required"
    variant="ultrawide"
    {isReadonly}
  />

  <PropHelp {showAdditionalHelp}>
    Define how many seek indicators are required to be detected. For example, if you set this to 2,
    the matcher will only match if exactly 2 seek indicators are detected.<br /><br />

    Set this value to 0 if no seek indicators are required.
  </PropHelp>
</div>
