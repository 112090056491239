import { createAssetStore } from "$lib/shared_stores";

export function createAssetGroupStore(liveStore) {
  const assetStoreMap = {};

  function requestStore(stepId, inputName) {
    const storeKey = `${stepId}/${inputName}`;

    if (!(storeKey in assetStoreMap)) {
      assetStoreMap[storeKey] = createAssetStore(liveStore);
    }

    // We don't delete asset stores here when blocks are removed. This should be
    // fine since they'll only exist until the next page reload.

    return assetStoreMap[storeKey];
  }

  return {
    initialize() {},
    reset() {
      Object.keys(assetStoreMap).forEach((key) => {
        assetStoreMap[key].reset();
        delete assetStoreMap[key];
      });
    },
    requestStore,
  };
}
