<script>
  import { createEventDispatcher, onMount } from "svelte";

  export let value = "";
  export let startEditing = false; // New prop to determine if editing should start immediately
  export let isReadonly = false;
  export let doubleClick = true;

  let isEditing = false;
  let inputValue = value;
  let element;
  const dispatch = createEventDispatcher();

  $: if (value !== inputValue && !isEditing) {
    inputValue = value;
  }

  onMount(() => {
    if (startEditing && !isReadonly) {
      isEditing = true;
      setTimeout(() => element?.focus(), 0);
    }
  });

  function handleClick() {
    if (!isReadonly) {
      isEditing = true;
      setTimeout(() => element?.focus(), 0);
    }
  }

  function handleInput(e) {
    inputValue = e.target.value;
    dispatch("input", inputValue);
  }

  function handleBlur() {
    finishEditing();
  }

  function handleKeyDown(event) {
    if (event.key === "Enter") {
      finishEditing();
    } else if (event.key === "Escape") {
      cancelEditing();
    }
  }

  function finishEditing() {
    isEditing = false;
    if (inputValue !== value) {
      dispatch("change", inputValue);
    }
  }

  function cancelEditing() {
    isEditing = false;
    inputValue = value;
  }
</script>

{#if isEditing}
  <input
    type="text"
    bind:this={element}
    value={inputValue}
    on:input={handleInput}
    on:blur={handleBlur}
    on:keydown={handleKeyDown}
    class={`${$$props.class || ""} px-[2px] py-0 bg-white`}
    data-1p-ignore
  />
{:else}
  <button
    on:click={!doubleClick ? handleClick : undefined}
    on:dblclick={doubleClick ? handleClick : undefined}
    class={`${$$props.class || ""} cursor-pointer`}
    aria-label="Edit text"
  >
    {value}
  </button>
{/if}
