<script>
  import { onMount } from "svelte";

  import Overlays from "../Overlay/OverlayLabels.svelte";
  import Debug from "$lib/Elements/Debug.svelte";
  import { UrlAvailabilityChecker } from "$utils/url";
  import "@mux/mux-player";

  import { toolbar, timeline, playback, uploadScreenshot } from "./store";

  let videoElement;
  let refElementId = "videoPlayer";
  let isLoading = false;
  let loadingMessage = "";
  let lastSource;

  const TIME_UPDATE_INTERVAL = 30;
  const baseWidth = 1920;
  const baseHeight = 1080;
  const { activeTimelineEvents } = timeline;
  const isLive = playback.isLive;
  // const log = console.log;
  const log = () => {};

  function secondsToMMSS(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    const millseconds = Math.floor((seconds % 1) * 1000);
    return `${minutes.toString().padStart(2, "0")}-${remainingSeconds.toString().padStart(2, "0")}-${millseconds.toString().padStart(3, "0")}`;
  }

  function handleAnnotationClick({ detail }) {
    if (detail) {
      timeline.select(detail.ui_id);
    } else {
      timeline.select(null);
    }
  }

  function handleWindowResize() {
    if (videoElement) {
      videoElement.width = videoElement.offsetWidth;
      videoElement.height = videoElement.offsetHeight;
    }
  }

  function captureVideoFrame(
    saveToDisk = false,
    mime = "image/jpeg",
    quality = 0.7,
    filename = "screenshot.jpg",
  ) {
    if (!videoElement || !lastSource) return;

    try {
      let canvas = document.createElement("canvas");
      const muxRoot = videoElement.shadowRoot;
      const muxVideo = muxRoot.querySelector("mux-video");
      const realVideoElement = muxVideo.shadowRoot.querySelector("video");
      canvas.width = realVideoElement.videoWidth;
      canvas.height = realVideoElement.videoHeight;
      const ctx = canvas.getContext("2d");

      ctx.drawImage(realVideoElement, 0, 0, canvas.width, canvas.height);
      canvas.toBlob(
        (blob) => {
          if (saveToDisk) {
            if (videoElement && videoElement.currentTime) {
              filename = `screenshot-${secondsToMMSS(videoElement.currentTime)}.jpg`;
            }
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = filename;
            a.click();
            URL.revokeObjectURL(url);
          } else {
            uploadScreenshot([new File([blob], filename, { type: mime })], lastSource[0].src);
          }
        },
        mime,
        quality,
      );
    } catch (error) {
      console.error("Error capturing video frame:", error);
    }
  }

  function timeOberserver(callback) {
    let animationFrameId;
    let lastUpdateTime = 0;
    window.videoElement = videoElement;

    function animationFrameLoop(timestamp) {
      if (!videoElement) return;

      if (timestamp - lastUpdateTime >= TIME_UPDATE_INTERVAL) {
        callback(videoElement.currentTime);
        lastUpdateTime = timestamp;
      }

      animationFrameId = requestAnimationFrame(animationFrameLoop);
    }

    animationFrameId = requestAnimationFrame(animationFrameLoop);

    return function cancel() {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }

  onMount(() => {
    // FIXME: Implement aync import of mux-player.

    // import("@mux/mux-player").then(() => {
    //   // setSource might be called before the player is ready
    //   if (lastSource) {
    //     videoElement.src = lastSource[0].src;
    //   }
    // });

    const url_availability_checker = UrlAvailabilityChecker({
      onProgress: (retries, maxRetries) => {
        loadingMessage = `Waiting for video to become available... (${retries + 1}/${maxRetries})`;
      },
    });

    const cancel_time_observer = timeOberserver(() => {
      playback.setCurrentTime(videoElement.currentTime);
    });

    // Synchronize playback store with Mux player
    function handlePlay() {
      playback.setIsPlaying(true);
    }

    function handlePause() {
      playback.setIsPlaying(false);
    }

    function handleDurationChange() {
      if (videoElement.duration !== Infinity && videoElement.duration > 0) {
        playback.setTotalDuration(videoElement.duration);
        playback.setVideoIsLive(false);
      } else {
        playback.setVideoIsLive(true);
      }
    }

    function handleError(event) {
      console.warn("Error loading video:", event.detail);
      // Mux player will show its own error message
      isLoading = false;
    }

    videoElement.addEventListener("playing", handlePlay);
    videoElement.addEventListener("pause", handlePause);
    videoElement.addEventListener("durationchange", handleDurationChange);
    videoElement.addEventListener("error", handleError);

    const unsubscribe_currenttime = playback.listen("currenttime", ({ time }) => {
      log("📼 currenttime", time);
      if (videoElement) {
        videoElement.currentTime = time;
      }
    });

    const unsubscribe_play = playback.listen("play", async () => {
      log("📼 play", videoElement);
      if (!videoElement) return;
      if (videoElement.paused) {
        await videoElement.play();
      }
    });

    const unsubscribe_pause = playback.listen("pause", async () => {
      log("📼 pause");
      if (!videoElement) return;
      if (!videoElement.paused) {
        await videoElement.pause();
      }
    });

    const unsubscribe_capture = playback.listen("capture", () => {
      captureVideoFrame();
    });
    const unsubscribe_download_screenshot = playback.listen("downloadScreenshot", () => {
      captureVideoFrame(true);
    });

    const unsubscribe_audiolevel = playback.listen("audiolevel", ({ level }) => {
      log("📼 audiolevel", level);
      if (videoElement) {
        videoElement.volume = level / 100;
      }
    });

    const unsubscribe_source = playback.listen("source", async ({ videoSrc, autoplay }) => {
      log("📼 source", videoSrc, autoplay);

      if (!videoSrc) return;

      if (!videoElement) return;

      if (!(videoSrc instanceof Object)) {
        videoSrc = [{ src: videoSrc, type: "application/x-mpegURL" }];
      }

      isLoading = true;
      loadingMessage = "Checking video availability...";
      videoElement.src = null;
      lastSource = videoSrc;

      const isAvailable = await url_availability_checker.check(videoSrc[0].src);

      if (isAvailable) {
        videoElement.src = videoSrc[0].src;
        isLoading = false;

        setTimeout(() => (autoplay ? videoElement.play() : videoElement.pause()), 100);
      } else {
        loadingMessage = "Failed to load video. Please try again later.";
      }
    });

    window.addEventListener("resize", handleWindowResize);

    playback.onPlayerReady();

    return () => {
      cancel_time_observer();
      unsubscribe_currenttime();
      unsubscribe_source();
      unsubscribe_play();
      unsubscribe_pause();
      unsubscribe_audiolevel();
      unsubscribe_capture();
      unsubscribe_download_screenshot();
      url_availability_checker.cancel();
      videoElement.removeEventListener("playing", handlePlay);
      videoElement.removeEventListener("pause", handlePause);
      videoElement.removeEventListener("durationchange", handleDurationChange);
      videoElement.removeEventListener("error", handleError);
      window.removeEventListener("resize", handleWindowResize);
    };
  });
</script>

<div class="flex flex-col p-4 pt-0">
  <div
    id={refElementId}
    class="w-full h-0 pb-[56.25%] relative bg-gray-900 drop-shadow-[0_0_10px_#000000AA]"
  >
    <mux-player
      bind:this={videoElement}
      class="absolute inset-0 w-full h-full"
      stream-type={$isLive ? "live" : "on-demand"}
      style="--controls: none"
      playback-id=""
      muted
    />
    {#if isLoading}
      <div
        class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white"
      >
        <div class="text-center">
          <p class="text-xl font-bold mb-2">Waiting for Live Stream</p>
          <p>{loadingMessage}</p>
        </div>
      </div>
    {/if}
  </div>
</div>

{#if $toolbar.show_overlay}
  <Overlays
    annotations={$activeTimelineEvents}
    {refElementId}
    {baseWidth}
    {baseHeight}
    on:select={handleAnnotationClick}
  />
{/if}

{#if $toolbar.show_debug}
  <Debug {...playback} />
{/if}
