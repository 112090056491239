<script>
  import { afterUpdate } from "svelte";

  import "node_modules/jsondiffpatch/lib/formatters/styles/html.css";
  import "node_modules/jsondiffpatch/lib/formatters/styles/annotated.css";
  import * as jsondiffpatch from "jsondiffpatch";
  import * as htmlFormatter from "jsondiffpatch/formatters/html";

  import { workflow as workflowStore } from "../store";

  const persistedWorkflow = workflowStore.persistedWorkflow;
  const serverWorkflow = workflowStore.serverWorkflow;

  let delta = null;
  let showServerClientDiff = false;
  let showUnchanged = false;

  function toggleServerWorkflow() {
    showServerClientDiff = !showServerClientDiff;
  }

  function toggleShowUnchanged() {
    if (showUnchanged) {
      htmlFormatter.showUnchanged();
    } else {
      htmlFormatter.hideUnchanged();
    }
    showUnchanged = !showUnchanged;
  }

  afterUpdate(() => {
    if (delta) {
      document.getElementById("visual").innerHTML = htmlFormatter.format(delta, $workflowStore);
    }
  });

  $: {
    const workflow_local = $workflowStore;
    const workflow_persisted = $persistedWorkflow;
    const workflow_server = $serverWorkflow;

    delta = jsondiffpatch.diff(
      showServerClientDiff ? workflow_server : workflow_persisted,
      workflow_local,
    );
  }
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-noninteractive-element-to-interactive-role -->
<h2
  class="text-sm font-bold mb-2 hover:underline cursor-pointer"
  on:click={toggleServerWorkflow}
  role="button"
>
  {showServerClientDiff ? "Server vs. Client Workflow" : "Saved vs. Unsaved Workflow"}
</h2>

<div class="flex gap-2 mb-2">
  <button class="text-xs p-1 bg-gray-100 hover:bg-gray-200 rounded" on:click={toggleShowUnchanged}>
    {showUnchanged ? "Full diff" : "Delta only"}
  </button>
</div>

<div id="visual" class="w-full h-full p-2 border"></div>
