export function createPositionTracker(refElementId) {
  let refElement;
  let resizeObserver;
  let subscribers = [];

  function updatePosition() {
    if (refElement) {
      const rect = refElement.getBoundingClientRect();

      subscribers.forEach((callback) =>
        callback({
          top: rect.top,
          left: rect.left,
          width: rect.width,
          height: rect.height,
        }),
      );
    }
  }

  function init() {
    refElement = document.getElementById(refElementId);
    if (refElement) {
      resizeObserver = new ResizeObserver(updatePosition);
      resizeObserver.observe(refElement);
      window.addEventListener("resize", updatePosition);
      updatePosition();
    }
  }

  function destroy() {
    if (resizeObserver) {
      resizeObserver.disconnect();
    }
    window.removeEventListener("resize", updatePosition);
    subscribers = [];
  }

  function subscribe(callback) {
    subscribers.push(callback);
    if (subscribers.length === 1) {
      init();
    }
    return () => {
      subscribers = subscribers.filter((cb) => cb !== callback);
      if (subscribers.length === 0) {
        destroy();
      }
    };
  }

  return { subscribe, destroy };
}
