<script>
  import { tweened } from "svelte/motion";
  import { cubicOut } from "svelte/easing";

  export let label = "";
  export let value = 0;
  export let max = 100;
  export let unit = "%";
  export let color = "bg-blue-500";
  export let fixed = 1;
  export let showValue = false;

  const progress = tweened(value / max, {
    duration: 400,
    easing: cubicOut,
  });

  $: progress.set(value / max);
</script>

<div class="flex items-center space-x-1 text-xs">
  {#if label}
    <span class="w-10 font-medium">{label}</span>
  {/if}
  <div class="w-full bg-gray-200 rounded-full h-2 overflow-hidden">
    <div class="h-full rounded-full {color}" style="width: {$progress * 100}%;"></div>
  </div>
  {#if showValue}
    <span class="w-14 text-right">{value.toFixed(fixed)}{unit}</span>
  {/if}
</div>
