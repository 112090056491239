<script>
  import { get } from "$utils/shim";
  import { ArrowPath, Icon } from "svelte-hero-icons";
  import ColorHistogram from "./ColorHistogram.svelte";
  import { getColorHistogram, getColorsInRegion } from "./utils";
  import { currentAssets } from "../store";

  export let props;

  let color_results = null;
  let is_loading = false;
  let error = null;

  async function onAnalyzeColors() {
    is_loading = true;

    const selectedImage = get($currentAssets.selected);
    if (selectedImage) {
      const { left: x, top: y, width, height } = props;
      const colors = await getColorsInRegion(selectedImage.url, x, y, width, height);
      const histogram = getColorHistogram(colors, 5, "hex");

      color_results = { x, y, width, height, histogram };
    } else {
      error = "You must select a screen before analyzing the colors in this region.";
    }
    is_loading = false;
  }
</script>

<div class="border-t border-gray-200 mt-2 py-2">
  <button
    class="px-4 py-2 flex rounded bg-gray-700 text-white hover:bg-gray-500"
    on:click={onAnalyzeColors}
  >
    Analyze colors
    {#if is_loading}
      <Icon src={ArrowPath} mini class="ml-1 h-4 w-4 animate-spin" />
    {/if}
  </button>

  {#if error}
    <p class="mt-2 text-red-500 text-xs">{error}</p>
  {/if}

  {#if color_results && !is_loading}
    <ColorHistogram on:color {...color_results} />
  {/if}
</div>
