<script>
  export let fixedWidth = false;
</script>

<div
  aria-label="Pane"
  class="max-h-full pt-5 bg-white border border-gray-200 rounded-xl text-xs overflow-auto"
  class:w-64={fixedWidth}
  class:flex-shrink-0={fixedWidth}
>
  <slot />
</div>
