export function throttle(func, limit) {
  let wait = false;

  return (...args) => {
    if (!wait) {
      requestAnimationFrame(() => func(...args));
      wait = true;
      setTimeout(() => {
        wait = false;
      }, limit);
    }
  };
}
