<script>
  import { Icon, Cube } from "svelte-hero-icons";
  import { timeline } from "../store";
  import Tooltip from "./Tooltip.svelte";
  import { uiStateConfig } from "$utils/ui-state-config";

  export let event;
  export let width;
  export let x;
  export let selected;

  let { color, icon } = uiStateConfig[event.ui_type] || {
    color: "bg-yellow-200 text-yellow-600 border-yellow-600",
    icon: Cube,
  };
  if (event.ui_type !== "screen" && !event.is_active) {
    color = "bg-red-100 text-red-400 border-red-400";
  }
</script>

<div class="absolute h-6 overflow-hidden flex items-center" style="left: {x}px;">
  {#if event.ui_type === "screen"}
    <Tooltip message={event.preview} class="h-6" isPreview={true}>
      <button
        on:click={(e) => timeline.navigate(e, event, "designer")}
        class="max-w-[22px] hover:max-w-[150px] transition-all duration-300 ease-in-out h-full rounded z-10 hover:z-50 border {color} font-semibold flex items-center px-1 shadow-sm whitespace-nowrap {selected
          ? 'outline outline-2 -outline-offset-2 outline-blue-500'
          : ''}"
      >
        <Icon src={icon} class="w-3 h-3 mr-1 flex-shrink-0" />
        <span class="truncate">{event.label}</span>
      </button>
    </Tooltip>
  {:else}
    <Tooltip message={event.name} class="h-6">
      <button
        on:click={(e) => timeline.navigate(e, event, "designer")}
        class="h-full rounded z-10 hover:z-50 border {color} font-semibold flex items-center px-1 shadow-sm whitespace-nowrap"
        style="width: {width}px;"
      >
        <Icon src={icon} class="w-3 h-3 mr-1 flex-shrink-0" />
      </button>
    </Tooltip>
  {/if}
</div>
