// eslint-disable-next-line no-unused-vars
import uPlot from "uplot";
import { parseJsonOrDefault } from "../../utils/parseJsonOrDefault";

// Observer changes to data and preset
export function dataSync($chart) {
  let observer = null;

  function handleDataUpdate(uplot) {
    const data = parseJsonOrDefault($chart.dataset.data, []);
    uplot.setData(data, true);
    uplot.redraw();
  }

  function handleUnitUpdate(uplot) {
    const unit = $chart.dataset.unit;
    uplot.hooks.setUnit.forEach((fn) => fn(uplot, unit));
  }

  function init(uplot) {
    observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        switch (mutation.attributeName) {
          case "data-data":
            return handleDataUpdate(uplot);
          case "data-unit":
            return handleUnitUpdate(uplot);
        }
      });
    });

    observer.observe($chart, {
      attributes: true,
      attributeFilter: ["data-data", "data-unit"],
    });
  }

  function destroy() {
    if (observer) {
      observer.disconnect();
    }
  }

  return {
    hooks: {
      init,
      destroy,
    },
  };
}
