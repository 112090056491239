<script>
  import { createEventDispatcher } from "svelte";
  import { Icon, XMark } from "svelte-hero-icons";

  export let value = "";
  export let label;
  export let placeholder = "";
  export let variant = "default";
  export let optional = false;
  export let isReadonly = false;

  const id = String(Math.random());
  const dispatch = createEventDispatcher();

  function handleInput(event) {
    if (!isReadonly) {
      value = event.target.value;
      dispatch("input", value);
    }
  }

  function clearValue() {
    if (!isReadonly) {
      value = "";
      dispatch("input", value);
    }
  }
</script>

<div class="relative w-full flex items-center {$$props.class || ''}">
  <input
    {id}
    type="text"
    class="rounded p-2 w-full text-xs bg-gray-100 border-0"
    class:pl-8={variant == "narrow"}
    class:pl-12={variant == "default"}
    class:pl-32={variant == "wide"}
    class:pl-40={variant == "ultrawide"}
    class:cursor-not-allowed={isReadonly}
    class:opacity-60={isReadonly}
    {value}
    {placeholder}
    on:input={handleInput}
    readonly={isReadonly}
    data-1p-ignore
  />
  <label for={id} class="absolute left-2 text-gray-500 {isReadonly ? 'cursor-not-allowed' : ''}"
    >{label}</label
  >
  {#if optional && !isReadonly}
    <button
      type="button"
      class="ml-2 text-gray-500 hover:text-gray-700 focus:outline-none"
      on:click={clearValue}
    >
      <Icon src={XMark} class="w-4 h-4" />
    </button>
  {/if}
  <slot name="dynamic" />
</div>

{#if false}
  <safelist class="pl-8" />
  <safelist class="pl-12" />
  <safelist class="pl-32" />
{/if}
