<script>
  import { debounce } from "$utils/debounce";
  import CodeInput from "./Inputs/CodeInput.svelte";
  import CheckboxInput from "./Inputs/CheckboxInput.svelte";

  export let live;
  export let content;
  export let on_change;

  let isSynced = false;

  const pushDebounced = debounce((code) => {
    live.pushEvent(on_change, { content: code });
  }, 500);

  function onCodeInput(event) {
    pushDebounced(event.detail);
  }
</script>

<div class="border border-red-500 m-4 p-4">
  <CodeInput value={content} on:input={onCodeInput} {isSynced} />
  <div class="mt-4">
    <CheckboxInput bind:value={isSynced} label="Is synced?" />
  </div>
</div>
