// eslint-disable-next-line no-unused-vars
import uPlot from "uplot";

export function debug() {
  function createPreElement(existingElement, jsonData) {
    const preElement = document.createElement("pre");

    preElement.textContent = JSON.stringify(jsonData, null, 2);
    preElement.classList.add("u-debug-output");

    if (existingElement.parentNode) {
      existingElement.parentNode.appendChild(preElement);
    }
  }

  return {
    opts: (u, opts) => {
      setTimeout(() => createPreElement(u.root, opts), 0);
    },
  };
}
