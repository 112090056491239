<script>
  import {
    Icon,
    ArrowPath,
    ChevronDoubleDown,
    ChevronDoubleUp,
    ArrowDown,
  } from "svelte-hero-icons";
  import { msToHumanReadableTime } from "$lib/utils/time";
  import { jobs, execution, toolbar } from "../store";
  import { get } from "$utils/shim";
  import ClampText from "$lib/Elements/ClampText.svelte";
  import ProgressBar from "$lib/Elements/ProgressBar.svelte";
  import ImagePreview from "$lib/Elements/ImagePreview.svelte";
  import { imageManager } from "$lib/shared_stores";

  export let state = {
    inputs: {},
    outputs: {},
    status: "",
    validations: {},
    duration: 0,
    progress: 0,
    errors: [],
  };

  $: hasSucceeded = state.status === "succeeded";
  $: hasFailed = state.status === "failed";
  $: isCompleted = hasSucceeded || hasFailed;
  $: progressColor = hasSucceeded ? "bg-green-500" : hasFailed ? "bg-red-500" : "bg-blue-500";
  $: progressPercentage = isCompleted ? "100%" : `${state.progress}%`;
  $: inputs = resolveOutputReferences(state.inputs);

  function formatValue(key, value) {
    if (key === "execDuration" && typeof value === "number") {
      return msToHumanReadableTime(value);
    }
    if ((key === "execStartTime" || key === "execEndTime") && typeof value === "number") {
      return new Date(value).toLocaleTimeString();
    }
    if (value instanceof Object) {
      return JSON.stringify(value);
    }
    return value;
  }

  function isImage(value) {
    return (
      value &&
      value instanceof Object &&
      value.image &&
      typeof value.image === "string" &&
      value.image.startsWith("img:")
    );
  }

  function resolveOutputReferences(inputs) {
    const resolved = {};

    Object.entries(inputs).forEach(([key, value]) => {
      resolved[key] = isOutputReference(value) ? getOutputReferenceValue(value) : value;
    });

    return resolved;
  }

  function isOutputReference(value) {
    return typeof value === "string" && /w:get_output\("([^"]*)", "([^"]*)"\)/.test(value);
  }

  function getOutputReferenceValue(outputReference) {
    const match = outputReference.match(/"([^"]*)"/g).map((m) => m.replace(/"/g, ""));
    const stepId = match[0];
    const outputKey = match[1];
    const stepState = get(execution.stepState(stepId));

    if (outputKey === "default") {
      return stepState.result;
    }

    return stepState.outputs[outputKey];
  }

  function sortEntries(entries) {
    const order = [
      "result",
      "default",
      "captureStart",
      "captureEnd",
      "execStartTime",
      "execEndTime",
      "execDuration",
    ];
    return entries.sort((a, b) => {
      const indexA = order.indexOf(a[0]);
      const indexB = order.indexOf(b[0]);
      return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB);
    });
  }

  function toggleHideProps() {
    toolbar.update((state) => ({ ...state, hide_props: !state.hide_props }));
  }
</script>

<div class="bg-gray-900 text-white font-mono text-xs rounded-xl pb-2">
  <button
    on:click={toggleHideProps}
    class="w-full flex items-center justify-center p-1 mb-4 rounded-t-xl hover:bg-gray-700"
  >
    <Icon src={$toolbar.hide_props ? ChevronDoubleDown : ChevronDoubleUp} class="w-3 h-3" />
  </button>

  <div class="mb-2 px-4">
    <div class="flex justify-between items-center mb-1">
      <span class="font-bold">Status: {state.status}</span>
      <span class="text-xs">{progressPercentage}</span>
    </div>
    <ProgressBar value={isCompleted ? 100 : state.progress} color={progressColor} />
  </div>

  <div class="mb-2 px-4">
    <h4 class="font-bold mb-1">Inputs:</h4>
    {#if Object.keys(inputs).length > 0}
      {#each Object.entries(inputs) as [key, value]}
        <div class="ml-2">
          {#if isImage(value)}
            <span class="text-purple-400">{key}:</span>
            <div class="my-1">
              <ImagePreview
                src={imageManager.src(value.image)}
                alt={key}
                class="max-w-full h-auto rounded-lg"
                sessionId={$jobs.m3u8}
              />
            </div>
          {:else}
            <ClampText>
              <span class="text-purple-400">{key}:</span>
              <span class="text-gray-300 whitespace-pre-wrap">{formatValue(key, value)}</span>
            </ClampText>
          {/if}
        </div>
      {/each}
    {:else}
      <div class="ml-2 text-gray-500">No inputs</div>
    {/if}
  </div>

  <div class="flex justify-center my-2">
    <Icon src={ArrowDown} class="w-5 h-5 text-gray-500" />
  </div>

  <div class="mb-2 px-4">
    <h4 class="font-bold mb-1">Outputs:</h4>
    {#if state.result || Object.keys(state.outputs).length > 0}
      {#each sortEntries(Object.entries( { ...state.outputs, result: state.result }, )) as [key, value]}
        <div class="ml-2">
          {#if isImage(value)}
            <span class="text-purple-400">{key}:</span>
            <div class="my-1">
              <ImagePreview
                src={imageManager.src(value.image)}
                alt={key}
                class="max-w-full h-auto rounded-lg"
                sessionId={$jobs.m3u8}
              />
            </div>
          {:else}
            <ClampText>
              <span class="text-purple-400">{key}:</span>
              <span class="text-gray-300 whitespace-pre-wrap">{formatValue(key, value)}</span>
            </ClampText>
          {/if}
        </div>
      {/each}
    {:else if isCompleted}
      <div class="ml-2 text-gray-500">No outputs</div>
    {:else}
      <div class="ml-2 flex flex-row text-gray-500">
        <Icon src={ArrowPath} mini class="h-4 w-4 animate-spin" />
        <span class="ml-2">{state.status === "in_progress" ? "Running" : "Pending"}...</span>
      </div>
    {/if}
  </div>

  {#if state.errors.length > 0}
    <div class="mt-4 px-4">
      <h4 class="font-bold mb-1 text-red-500">Errors:</h4>
      {#each state.errors as error}
        <div>
          <pre
            class="bg-transparent ml-2 text-red-400 text-xs border-none whitespace-normal break-words focus:outline-none resize-none"
            readonly>{error}</pre>
        </div>
      {/each}
    </div>
  {/if}
</div>
