/**
 * draggable.ts
 *
 * A utility for making a list reorderable by drag and drop.
 * Children components must implement the draggable="true" attribute.
 *
 * `reorder` event is dispatched on the parent element with the `from` and `to` indices.
 *
 * Usage:
 *
 * <ul use:draggable>
 *   <li draggable="true">Item 1</li>
 *   <li draggable="true">Item 2</li>
 *   <li draggable="true">Item 3</li>
 * </ul>
 */

let dragSourceElement: HTMLElement | null = null;
let dragTargetElement: HTMLElement | null = null;

type DraggableOptions = {
  hoverClasses: string[];
};

export function draggable(
  node: HTMLUListElement,
  options: DraggableOptions = { hoverClasses: ["border", "border-blue-500"] },
) {
  function handleDragStart(e: DragEvent) {
    const target = e.currentTarget as HTMLElement;
    target.style.opacity = "0.4";

    dragSourceElement = target;
  }

  function handleDragEnd(e: DragEvent) {
    const target = e.currentTarget as HTMLElement;
    target.style.opacity = "1";

    Array.from(node.children).forEach((item) => {
      removeClasses(item, options.hoverClasses);
    });

    dragTargetElement = null;
  }

  function handleDragOver(e: DragEvent) {
    e.preventDefault();

    const target = e.target as HTMLElement;
    const draggableTarget = target.closest('[draggable="true"]') as HTMLElement;

    if (draggableTarget && draggableTarget !== dragTargetElement) {
      if (dragTargetElement) {
        removeClasses(dragTargetElement, options.hoverClasses);
      }

      applyClasses(draggableTarget, options.hoverClasses);

      dragTargetElement = draggableTarget;
    }

    return false;
  }

  function handleDrop(e: DragEvent) {
    e.stopPropagation(); // stops the browser from redirecting.

    const target = e.currentTarget as HTMLElement;

    if (dragSourceElement !== target) {
      const allItems = Array.from(node.children);
      const fromIndex = allItems.indexOf(dragSourceElement);
      const toIndex = allItems.indexOf(target);

      node.dispatchEvent(
        new CustomEvent("reorder", {
          detail: { from: fromIndex, to: toIndex },
          bubbles: true,
        }),
      );
    }

    if (dragTargetElement) {
      removeClasses(dragTargetElement, options.hoverClasses);
    }

    return false;
  }

  function applyClasses(target: Element, classes: string[]) {
    classes.forEach((cls) => {
      target.classList.add(cls);
    });
  }

  function removeClasses(target: Element, classes: string[]) {
    classes.forEach((cls) => {
      target.classList.remove(cls);
    });
  }

  Array.from(node.children).forEach((child) => {
    child.addEventListener("dragstart", handleDragStart);
    child.addEventListener("dragover", handleDragOver);
    child.addEventListener("dragend", handleDragEnd);
    child.addEventListener("drop", handleDrop);
  });

  return {
    destroy() {
      Array.from(node.children).forEach((child) => {
        child.removeEventListener("dragstart", handleDragStart);
        child.removeEventListener("dragover", handleDragOver);
        child.removeEventListener("dragend", handleDragEnd);
        child.removeEventListener("drop", handleDrop);
      });
    },
  };
}
