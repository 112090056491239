import { get, writable, derived } from "$utils/shim";
import { addRequestHandler } from "$utils/request";
import { structuredDataToState } from "./data";
import { createScreenStores } from "./screens";
import { createFeatureStore } from "./features";

const initialToolbar = {
  show_code: false,
  show_treeview_pane: true,
  show_prop_pane: true,
  show_overlay: true,
  show_label: false,
  show_debug: false,
  draw_mode: false,
};

export const liveStore = writable(null);

export const features = createFeatureStore();

export const toolbar = writable(initialToolbar);

export const appAttributes = writable({});

export const moveableRef = writable(null);

export const screenStores = createScreenStores(liveStore);

export const selectedScreenId = screenStores.selectedScreenId;

export const selectedElements = screenStores.selectedElements;

export const selectedScreenRegions = screenStores.selectedScreenRegions;

export const currentAssets = screenStores.currentAssets;

export const screenStoresAsList = screenStores.screenStoresAsList;

export const activeRegions = screenStores.activeRegions;

export const exportedState = derived(
  [screenStoresAsList, appAttributes],
  ([$screenStoresAsList, _$appAttributes], set) => {
    let innerUnsubscribes = [];

    const update = () => {
      const result = $screenStoresAsList.map((screen, index) => ({
        ...screen,
        matchers: innerUnsubscribes[index] ? get($screenStoresAsList[index].matchers) : [],
        assets: get($screenStoresAsList[index].assets),
      }));
      set(result);
    };

    innerUnsubscribes = $screenStoresAsList.map((screen) => {
      const matcherUnsub = screen.matchers.subscribe(() => update());
      const assetUnsub = screen.assets.subscribe(() => update());
      return () => {
        matcherUnsub();
        assetUnsub();
      };
    });

    update();

    return () => {
      innerUnsubscribes.forEach((unsub) => unsub());
    };
  },
);

export function selectScreen(id) {
  screenStores.selectScreen(id);
}

export function selectRegion(screenId = null, regionId = null) {
  if (!regionId) {
    const regions = get(activeRegions);
    if (regions.length > 0) {
      regionId = regions[0]._id;
    } else {
      return;
    }
  }

  if (!screenId) {
    screenId = get(selectedScreenId);
  }

  // Disable code editor when a region is selected to avoid visible bounding boxes
  toolbar.update((currentToolbar) => ({
    ...currentToolbar,
    show_code: false,
  }));

  screenStores.changeSelection([regionId]);
  screenStores.selectScreen(screenId);
}

export function resetStores() {
  // Svelte stores persist throughout live view patch navigation.
  // All stores must be reset to avoid issues when navigating away.
  features.reset();
  screenStores.reset();
  appAttributes.set({});
  liveStore.set(null);
  toolbar.set(initialToolbar);
}

export function loadState(structuredData, assets = []) {
  const convertedData = structuredDataToState(structuredData, assets);

  appAttributes.set(convertedData.appAttributes);

  screenStores.initialize(convertedData.screens);

  if (convertedData.screens.length > 0) {
    screenStores.selectScreen(convertedData.screens[0]._id);
  }
}

export function initializeStore(live, { structuredData, assets, user_roles, is_readonly }) {
  resetStores();

  liveStore.set(addRequestHandler(live));

  features.initialize(user_roles, is_readonly);

  loadState(structuredData, assets);
}
