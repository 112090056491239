<script>
  import NumericInput from "$lib/Inputs/NumericInput.svelte";

  export let props;
  export let update;
  export let isReadonly = false;
</script>

<div class="flex space-x-2 py-2">
  <NumericInput
    class="w-1/2"
    value={props.left}
    on:input={(e) => update(e, "left")}
    label="X"
    variant="narrow"
    allowDecimals={false}
    {isReadonly}
  />
  <NumericInput
    class="w-1/2"
    value={props.top}
    on:input={(e) => update(e, "top")}
    label="Y"
    variant="narrow"
    allowDecimals={false}
    {isReadonly}
  />
</div>

<div class="flex space-x-2 py-2 pt-0">
  <NumericInput
    class="w-1/2"
    value={props.width}
    on:input={(e) => update(e, "width")}
    label="W"
    variant="narrow"
    allowDecimals={false}
    {isReadonly}
  />
  <NumericInput
    class="w-1/2"
    value={props.height}
    on:input={(e) => update(e, "height")}
    label="H"
    variant="narrow"
    allowDecimals={false}
    {isReadonly}
  />
</div>
