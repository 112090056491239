<script>
  import { generateUUID } from "$utils/uuid";
  import { createEventDispatcher } from "svelte";
  import { Icon, Plus, Trash } from "svelte-hero-icons";

  const dispatch = createEventDispatcher();

  export let value = [{ id: generateUUID("c"), name: "Condition 1", condition: "" }];
  export let label = "Branches";
  export let isReadonly = false;

  let branches = [...value];

  function addBranch() {
    if (isReadonly) return;
    branches = [
      ...branches,
      {
        id: generateUUID("c"),
        name: `Condition ${branches.length + 1}`,
        condition: "",
      },
    ];
    dispatch("input", branches);
  }

  function deleteBranch(id) {
    if (isReadonly) return;
    if (branches.length > 1) {
      branches = branches.filter((branch) => branch.id !== id);
      dispatch("input", branches);
    }
  }

  function updateBranch(id, field, value) {
    if (isReadonly) return;
    branches = branches.map((branch) =>
      branch.id === id ? { ...branch, [field]: value } : branch,
    );
    dispatch("input", branches);
  }
</script>

<div class="border rounded-lg p-3 max-w-2xl">
  <div class="flex justify-between items-center mb-2">
    <h3 class="font-semibold text-sm">{label}</h3>
    <button
      on:click={addBranch}
      class="flex items-center text-blue-500 hover:text-blue-600 text-xs font-medium transition-colors duration-200"
    >
      <Icon src={Plus} class="w-4 h-4 mr-1" />
      Add branch
    </button>
    <slot name="dynamic" />
  </div>

  <div class="pr-2 -mr-2">
    {#each branches as branch (branch.id)}
      <div class="mb-2 p-2 border border-gray-200 rounded-md bg-gray-50">
        <div class="flex items-center mb-1">
          <input
            type="text"
            value={branch.name}
            on:change={(e) => updateBranch(branch.id, "name", e.target.value)}
            class="flex-grow text-xs font-medium px-1 py-0.5 rounded-sm border-none bg-transparent focus:ring-0 hover:bg-gray-200 focus:bg-gray-200"
            placeholder="Branch name"
            data-1p-ignore
          />
          <button
            on:click={() => deleteBranch(branch.id)}
            disabled={branches.length == 1}
            class="text-gray-400 hover:text-red-500 disabled:opacity-25 transition-colors duration-200"
          >
            <Icon src={Trash} class="w-4 h-4" />
          </button>
        </div>
        <input
          type="text"
          value={branch.condition}
          on:change={(e) => updateBranch(branch.id, "condition", e.target.value)}
          class="w-full text-xs px-1 py-0.5 border font-mono border-gray-300 rounded focus:ring-1 focus:ring-blue-500"
          placeholder="Enter condition"
          data-1p-ignore
        />
      </div>
    {/each}
  </div>
  <p class="text-xs p-2 mt-2 text-gray-300">"default" will match everything else.</p>
</div>
