<script>
  import KeyEvent from "../Timeline/KeyEvent.svelte";
  import { timeline } from "../store";

  export let track;
  export let trackHeaderSize;
  export let trackEventsLeft;

  const { scaledTimelineWidth, visibleRemoteElements } = timeline;
</script>

<div
  class="flex border-b border-gray-200 bg-white h-8"
  style="min-width: 100%; width: {$scaledTimelineWidth + trackEventsLeft + trackHeaderSize}px;"
>
  <!-- Track Header -->
  <div
    data-el-tracker-header
    class="w-36 flex-shrink-0 px-2 flex items-center justify-between sticky left-0 z-[1000] bg-white border-r border-gray-200"
  >
    <span class="font-medium">{track.name}</span>
  </div>
  <!-- Track Events -->
  <div class="flex items-center relative" style="left: {trackEventsLeft}px;">
    {#each $visibleRemoteElements as event}
      <KeyEvent
        {event}
        x={timeline.calcPxStartTime(event)}
        width={timeline.calcPxDuration(event)}
      />
    {/each}
  </div>
</div>
