import { writable } from "$utils/shim";

export function signalStore() {
  const store = writable(0);

  return {
    trigger() {
      store.update((value) => value + 1);
    },
    subscribe: store.subscribe,
  };
}
