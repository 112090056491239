<script>
  import {
    Icon,
    Cog6Tooth,
    ArrowsRightLeft,
    Camera,
    ClipboardDocumentList,
    Clock,
    Equals,
    Fire,
    MagnifyingGlass,
    PencilSquare,
    Printer,
    Sparkles,
    Tv,
    ViewfinderCircle,
    XMark,
  } from "svelte-hero-icons";
  import { timeline } from "../store";

  export let visibleWorkflowElements;
  export let index;
  export let width;
  export let x;
  export let selected;

  let event = null;
  let color = "gray";
  let icon = Cog6Tooth;
  let name = "Unknown";

  const workflowStepTypeMap = {
    assert_equal: { icon: Equals, name: "Assert Equal" },
    assert_region: { icon: Equals, name: "Assert Region" },
    capture_screen: { icon: Camera, name: "Capture Screen" },
    close_app: { icon: XMark, name: "Close App" },
    compare_image: { icon: ArrowsRightLeft, name: "Compare Image" },
    detect_color: { icon: MagnifyingGlass, name: "Detect Color" },
    detect_image: { icon: MagnifyingGlass, name: "Detect Image" },
    detect_on_screen: { icon: MagnifyingGlass, name: "Detect On Screen" },
    detect_playback: { icon: MagnifyingGlass, name: "Detect Playback" },
    detect_text: { icon: MagnifyingGlass, name: "Detect Text" },
    enter_text: { icon: PencilSquare, name: "Enter Text" },
    open_app: { icon: Tv, name: "Open App" },
    press_key: { icon: undefined, name: "Press Key" },
    print: { icon: Printer, name: "Print" },
    prompt_ai: { icon: Sparkles, name: "Prompt AI" },
    record_metric: { icon: ClipboardDocumentList, name: "Record Metric" },
    set_keyboard: { icon: undefined, name: "Set Keyboard" },
    wait_for_motion: { icon: ViewfinderCircle, name: "Wait Motion" },
    wait_for_region: { icon: ViewfinderCircle, name: "Wait Region" },
    wait_for_screen: { icon: ViewfinderCircle, name: "Wait Screen" },
    wait_for_text: { icon: ViewfinderCircle, name: "Wait Text" },
    wait: { icon: Clock, name: "Wait" },
    warmup: { icon: Fire, name: "Warmup" },
  };

  const colorMap = {
    running: "bg-gray-100 border-gray-400 text-gray-400",
    succeeded: "bg-green-100 border-green-400 text-green-400",
    failed: "bg-red-100 border-red-400 text-red-400",
  };

  visibleWorkflowElements.subscribe((value) => {
    if (index >= value.length) return;
    event = value[index];
    color =
      event.id !== "warmup" && event.duration === event.execDuration
        ? colorMap[event.status]
        : "bg-gray-100 border-gray-400 text-gray-400";
    const pair = workflowStepTypeMap[event.subtype] || { icon: Cog6Tooth, name: event.label };
    icon = pair.icon || Cog6Tooth;
    name = event.label || pair.name;
  });
</script>

<div class="absolute h-6 overflow-hidden flex items-center" style="left: {x}px;">
  <button
    on:click={(e) => timeline.navigate(e, event, "automate")}
    class="h-full rounded z-10 hover:z-50 border {color} font-medium flex items-center px-1 shadow-sm whitespace-nowrap {selected
      ? 'outline outline-2 -outline-offset-2 outline-blue-500'
      : ''}"
    style="width: {width}px;"
  >
    <Icon src={icon} class="w-3 h-3 mr-1 flex-shrink-0" />
    <span class="truncate">{name}</span>
  </button>
</div>
