export const availableTagOptions = [
  { value: "region", label: "None" },
  { value: "text", label: "Text" },
  { value: "color", label: "Color" },
  { value: "image", label: "Image" },
  { value: "brightness", label: "Brightness" },
];

export const experimentalTagOptions = [
  { value: "playback-progress-bar", label: "Playback Progress Bar" },
];

export const developerTagOptions = [
  { value: "button", label: "Button [Dev Only]" },
  { value: "component", label: "Component [Dev Only]" },
  { value: "form", label: "Form [Dev Only]" },
  { value: "input", label: "Input [Dev Only]" },
];

export const allTagNames = [
  ...availableTagOptions,
  ...experimentalTagOptions,
  ...developerTagOptions,
].map((tag) => tag.label);

export const defaultOptions = ["name", "required", "left", "top", "width", "height"];

export const tagPropsMap = {
  text: [...defaultOptions, "match", "engine", "mode", "threshold", "similarity", "case_sensitive"],
  color: [...defaultOptions, "color", "tolerance", "percentage", "lower", "upper"],
  image: [...defaultOptions, "assets", "mode", "threshold", "metrics"],
  brightness: [...defaultOptions, "above", "below"],
  "playback-progress-bar": [
    ...defaultOptions,
    "detect_indicator",
    "indicator_position",
    "indicator_required",
  ],
  button: [...defaultOptions, "type", "row", "col"],
  component: [...defaultOptions],
  form: [...defaultOptions],
  region: [...defaultOptions],
  input: [...defaultOptions, "row", "col"],
};

export function tagRequireble(tagName) {
  switch (tagName.toLowerCase()) {
    case "region":
      return false;
    default:
      return true;
  }
}

// this only applies to available tags + experimental tags
export function tagIsExperimental(tagName) {
  switch (tagName.toLowerCase()) {
    case "playback-progress-bar":
      return true;
    default:
      return false;
  }
}

export function tagColor(tagName) {
  switch (tagName.toLowerCase()) {
    case "text":
      return "border-amber-500 bg-amber-500";
    case "color":
      return "border-green-500 bg-green-500";
    case "image":
      return "border-blue-500 bg-blue-500";
    case "brightness":
      return "border-zinc-500 bg-zinc-500";
    case "playback-progress-bar":
      return "border-emerald-500 bg-emerald-500";
    case "button":
      return "border-red-500 bg-red-500";
    case "component":
      return "border-teal-500 bg-teal-500";
    case "form":
      return "border-fuchsia-500 bg-fuchsia-500";
    case "input":
      return "border-rose-500 bg-rose-500";
    default:
      return "border-gray-500 bg-gray-500";
  }
}
