import { Tv, Sun, EyeDropper, Newspaper, Photo, PlayCircle } from "svelte-hero-icons";

export const uiStateConfig = {
  screen: { color: "bg-yellow-200 border-yellow-500 text-yellow-600", icon: Tv },
  brightness: { color: "bg-gray-200 border-gray-500 text-gray-600", icon: Sun },
  color: { color: "bg-violet-200 border-violet-600 text-violet-600", icon: EyeDropper },
  text: { color: "bg-blue-200 border-blue-600 text-blue-600", icon: Newspaper },
  image: { color: "bg-fuchsia-200 border-fuchsia-600 text-fuchsia-600", icon: Photo },
  playback_progress_bar: {
    color: "bg-emerald-200 border-emerald-600 text-emerald-600",
    icon: PlayCircle,
  },
};
