import { columnHighlight } from "../plugins/columnHighlight";
import { tooltip } from "../plugins/tooltip";
import { boxWhisker } from "../plugins/boxWhisker";

export function whisker(chart) {
  let libs = chart.libs.slice(0, 30);
  const chartName = "Whisker";

  return {
    width: 800,
    height: 400,
    title: chartName,
    plugins: [columnHighlight({ isBarChart: true }), tooltip(), boxWhisker()],
    axes: [
      {
        rotate: -90,
        space: 10,
        size: 100,
        grid: { show: false },
        values: (self, values) => values.map((v) => libs[v]),
      },
    ],
    scales: {
      x: {
        distr: 2,
        time: false,
      },
    },
    series: [
      { label: "Lib", value: (self, i) => libs[i] },
      { label: "Median" },
      { label: "q1" },
      { label: "q3" },
      { label: "min" },
      { label: "max" },
    ],
  };
}
