<script>
  import { Icon, Clipboard, CheckCircle } from "svelte-hero-icons";
  import { tooltip } from "$utils/tooltip";

  export let text = "";
  export let showTooltip = false;

  let copied = false;

  const TIMEOUT_CHECKMARK = 3000;

  function copyToClipboard() {
    navigator.clipboard.writeText(text).then(() => {
      copied = true;
      setTimeout(() => {
        copied = false;
      }, TIMEOUT_CHECKMARK);
    });
  }
</script>

<div class="inline-flex items-center space-x-2">
  {#if !showTooltip}
    <pre class={$$props.class || "font-mono text-sm font-semibold"}>{text}</pre>
  {/if}
  <button
    use:tooltip
    aria-label={showTooltip ? text : null}
    on:click={copyToClipboard}
    class="p-0 hover:opacity-70 focus:outline-none relative"
  >
    {#if copied}
      <Icon src={CheckCircle} class="w-4 h-4 -ml-1 text-green-500" />
    {:else}
      <Icon src={Clipboard} class="w-4 h-4 -ml-1 text-gray-500" />
    {/if}
  </button>
</div>
