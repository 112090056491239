<script>
  import { createEventDispatcher } from "svelte";
  import StepSelector from "$lib/Inputs/StepSelector.svelte";
  import OutputSelector from "$lib/Inputs/OutputSelector.svelte";
  import { Icon, Variable } from "svelte-hero-icons";
  import { clickOutside } from "$utils/clickOutside";
  import Portal from "$lib/Elements/Portal.svelte";
  import { positionElement } from "$utils/position";

  const dispatch = createEventDispatcher();

  export let currentStepId = null;
  export let isReadonly = false;

  let selectedStep = null;
  let selectedOutput = null;
  let id = `var-name-${Math.random().toString(36).slice(2)}`;
  let showDropdown = false;
  let buttonEl;
  let dropdownEl;

  $: if (!selectedStep) selectedOutput = null;

  function handleSelect(outputName) {
    const stepId = selectedStep.id;
    const varName = `$step["${stepId}"].${outputName}`;
    dispatch("input", varName);
    showDropdown = false;
  }

  function emitNull() {
    dispatch("input", null);
  }

  function handleClickOutside() {
    showDropdown = false;
  }

  function toggleDropdown() {
    showDropdown = !showDropdown;
  }

  $: if (showDropdown && buttonEl && dropdownEl) positionElement(buttonEl, dropdownEl);
</script>

<div {id} class="relative flex items-center" role="group">
  <button
    bind:this={buttonEl}
    class="p-1 rounded hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
    on:click={() => toggleDropdown()}
    disabled={isReadonly}
  >
    <Icon src={Variable} class="w-4 h-4 text-gray-600" />
  </button>

  {#if showDropdown}
    <Portal target="body">
      <div
        class="fixed min-w-96 z-50 bg-white border rounded-lg shadow-lg"
        use:clickOutside={[buttonEl]}
        on:clickoutside={handleClickOutside}
        bind:this={dropdownEl}
      >
        <div class="p-2 flex flex-col text-xs">
          <div class="flex">
            <StepSelector {currentStepId} bind:selectedStep includeGlobalContext={false} />

            <OutputSelector
              {selectedStep}
              {selectedOutput}
              on:select={(e) => handleSelect(e.detail)}
            />
          </div>

          <button
            class="self-end mt-2 px-2 py-1 bg-gray-100 hover:bg-gray-200 rounded-md text-xs transition-colors"
            on:click={emitNull}
            disabled={isReadonly}
          >
            Clear selection
          </button>
        </div>
      </div>
    </Portal>
  {/if}
</div>
