<script>
  import { Icon } from "svelte-hero-icons";
  import {
    AlignLeftIcon,
    AlignRightIcon,
    AlignTopIcon,
    AlignBottomIcon,
    AlignVerticalCenterIcon,
    AlignHorizontalCenterIcon,
    DistributeHorizontalIcon,
    DistributeVerticalIcon,
    EqualSizeIcon,
    EqualHeightIcon,
    EqualWidthIcon,
  } from "$lib/Elements/Icons";
  import { tooltip } from "$lib/utils/tooltip";

  import { moveableRef, selectedScreenRegions } from "../store";

  $: regions = $selectedScreenRegions ? $selectedScreenRegions.matchers : {};

  function alignLeft() {
    const moveables = $moveableRef.getMoveables();
    if (moveables.length <= 1) return;

    // Find leftmost position
    const leftmost = Math.min(...moveables.map((m) => m.state.left));

    moveables.forEach((moveable) => {
      moveable.request("draggable", { x: leftmost }, true);
    });
    $moveableRef?.updateRect();
  }

  function alignRight() {
    const moveables = $moveableRef.getMoveables();
    if (moveables.length <= 1) return;

    // Find rightmost position
    const rightmost = Math.max(
      ...moveables.map((m) => {
        const rect = m.getRect();
        return rect.left + rect.width;
      }),
    );

    moveables.forEach((moveable) => {
      const rect = moveable.getRect();
      moveable.request(
        "draggable",
        {
          x: rightmost - rect.width,
        },
        true,
      );
    });
    $moveableRef?.updateRect();
  }

  function alignTop() {
    const moveables = $moveableRef.getMoveables();
    if (moveables.length <= 1) return;

    // Find topmost position
    const topmost = Math.min(...moveables.map((m) => m.state.top));

    moveables.forEach((moveable) => {
      moveable.request("draggable", { y: topmost }, true);
    });
    $moveableRef?.updateRect();
  }

  function alignBottom() {
    const moveables = $moveableRef.getMoveables();
    if (moveables.length <= 1) return;

    // Find bottommost position
    const bottommost = Math.max(
      ...moveables.map((m) => {
        const rect = m.getRect();
        return rect.top + rect.height;
      }),
    );

    moveables.forEach((moveable) => {
      const rect = moveable.getRect();
      moveable.request(
        "draggable",
        {
          y: bottommost - rect.height,
        },
        true,
      );
    });
    $moveableRef?.updateRect();
  }

  function alignCenterVertical() {
    const moveables = $moveableRef.getMoveables();
    if (moveables.length <= 1) return;

    // Calculate center based on the bounding box of all selected elements
    const allRects = moveables.map((m) => m.getRect());
    const top = Math.min(...allRects.map((r) => r.top));
    const bottom = Math.max(...allRects.map((r) => r.top + r.height));
    const centerY = top + (bottom - top) / 2;

    moveables.forEach((moveable) => {
      const rect = moveable.getRect();
      moveable.request(
        "draggable",
        {
          y: centerY - rect.height / 2,
        },
        true,
      );
    });
    $moveableRef?.updateRect();
  }

  function alignCenterHorizontal() {
    const moveables = $moveableRef.getMoveables();
    if (moveables.length <= 1) return;

    // Calculate center based on the bounding box of all selected elements
    const allRects = moveables.map((m) => m.getRect());
    const left = Math.min(...allRects.map((r) => r.left));
    const right = Math.max(...allRects.map((r) => r.left + r.width));
    const centerX = left + (right - left) / 2;

    moveables.forEach((moveable) => {
      const rect = moveable.getRect();
      moveable.request(
        "draggable",
        {
          x: centerX - rect.width / 2,
        },
        true,
      );
    });
    $moveableRef?.updateRect();
  }

  function distributeHorizontal() {
    const moveables = $moveableRef.getMoveables();
    if (moveables.length <= 2) return;

    // Sort by center position rather than left edge
    const sorted = [...moveables].sort((a, b) => {
      const rectA = a.getRect();
      const rectB = b.getRect();
      const centerA = rectA.left + rectA.width / 2;
      const centerB = rectB.left + rectB.width / 2;
      return centerA - centerB;
    });

    // Get first and last element centers
    const firstRect = sorted[0].getRect();
    const lastRect = sorted[sorted.length - 1].getRect();
    const firstCenter = firstRect.left + firstRect.width / 2;
    const lastCenter = lastRect.left + lastRect.width / 2;

    // Calculate equal spacing between centers
    const totalSpace = lastCenter - firstCenter;
    const spacing = totalSpace / (moveables.length - 1);

    // Position each element by its center
    sorted.forEach((moveable, index) => {
      if (index === 0 || index === sorted.length - 1) return;
      const rect = moveable.getRect();
      const targetCenter = firstCenter + spacing * index;
      moveable.request(
        "draggable",
        {
          x: targetCenter - rect.width / 2,
        },
        true,
      );
    });
    $moveableRef?.updateRect();
  }

  function distributeVertical() {
    const moveables = $moveableRef.getMoveables();
    if (moveables.length <= 2) return;

    // Sort by center position rather than top edge
    const sorted = [...moveables].sort((a, b) => {
      const rectA = a.getRect();
      const rectB = b.getRect();
      const centerA = rectA.top + rectA.height / 2;
      const centerB = rectB.top + rectB.height / 2;
      return centerA - centerB;
    });

    // Get first and last element centers
    const firstRect = sorted[0].getRect();
    const lastRect = sorted[sorted.length - 1].getRect();
    const firstCenter = firstRect.top + firstRect.height / 2;
    const lastCenter = lastRect.top + lastRect.height / 2;

    // Calculate equal spacing between centers
    const totalSpace = lastCenter - firstCenter;
    const spacing = totalSpace / (moveables.length - 1);

    // Position each element by its center
    sorted.forEach((moveable, index) => {
      if (index === 0 || index === sorted.length - 1) return;
      const rect = moveable.getRect();
      const targetCenter = firstCenter + spacing * index;
      moveable.request(
        "draggable",
        {
          y: targetCenter - rect.height / 2,
        },
        true,
      );
    });
    $moveableRef?.updateRect();
  }

  function makeSameWidth() {
    const moveables = getMoveablesSortedBySelectionOrder();
    if (moveables.length <= 1) return;

    // Use the last selected element's width as reference
    const referenceWidth = moveables[moveables.length - 1].getRect().offsetWidth;

    moveables.forEach((moveable) => {
      // Update the visual representation
      moveable.request(
        "resizable",
        {
          offsetWidth: referenceWidth,
          keepRatio: false,
        },
        true,
      );

      // Update the region data to persist the change
      const id = moveable.getDragElement().dataset.id;
      regions.updatePosition(id, {
        width: referenceWidth,
      });
    });
    $moveableRef?.updateRect();
  }

  function makeSameHeight() {
    const moveables = getMoveablesSortedBySelectionOrder();
    if (moveables.length <= 1) return;

    // Use the last selected element's height as reference
    const referenceHeight = moveables[moveables.length - 1].getRect().offsetHeight;

    moveables.forEach((moveable) => {
      // Update the visual representation
      moveable.request(
        "resizable",
        {
          offsetHeight: referenceHeight,
          keepRatio: false,
        },
        true,
      );

      // Update the region data to persist the change
      const id = moveable.getDragElement().dataset.id;
      regions.updatePosition(id, {
        height: referenceHeight,
      });
    });
    $moveableRef?.updateRect();
  }

  function makeSameSize() {
    const moveables = getMoveablesSortedBySelectionOrder();
    if (moveables.length <= 1) return;

    // Use the last selected element's dimensions as reference
    const reference = moveables[moveables.length - 1].getRect();

    moveables.forEach((moveable) => {
      // Update the visual representation
      moveable.request(
        "resizable",
        {
          offsetWidth: reference.offsetWidth,
          offsetHeight: reference.offsetHeight,
          keepRatio: false,
        },
        true,
      );

      // Update the region data to persist the change
      const id = moveable.getDragElement().dataset.id;
      regions.updatePosition(id, {
        width: reference.offsetWidth,
        height: reference.offsetHeight,
      });
    });
    $moveableRef?.updateRect();
  }

  function getMoveablesSortedBySelectionOrder() {
    const moveables = $moveableRef.getMoveables();
    return moveables.sort((a, b) => {
      const aIndex = a.getDragElement().dataset.selectionIndex;
      const bIndex = b.getDragElement().dataset.selectionIndex;
      return (aIndex ? Number(aIndex) : Infinity) - (bIndex ? Number(bIndex) : Infinity);
    });
  }
</script>

<div class="px-3">
  <h2 class="text-sm font-medium text-gray-500">Alignment</h2>

  <div class="flex gap-1 py-2 mb-1 flex-wrap w-24">
    <button
      class="p-1 hover:bg-gray-100 rounded border"
      on:click={alignLeft}
      use:tooltip
      aria-label="Align Left"
    >
      <Icon src={AlignLeftIcon} class="w-4 h-4" />
    </button>

    <button
      class="p-1 hover:bg-gray-100 rounded border"
      on:click={alignCenterHorizontal}
      use:tooltip
      aria-label="Align Center Horizontal"
    >
      <Icon src={AlignHorizontalCenterIcon} class="w-4 h-4" />
    </button>

    <button
      class="p-1 hover:bg-gray-100 rounded border"
      on:click={alignRight}
      use:tooltip
      aria-label="Align Right"
    >
      <Icon src={AlignRightIcon} class="w-4 h-4" />
    </button>

    <button
      class="p-1 hover:bg-gray-100 rounded border"
      on:click={alignTop}
      use:tooltip
      aria-label="Align Top"
    >
      <Icon src={AlignTopIcon} class="w-4 h-4" />
    </button>

    <button
      class="p-1 hover:bg-gray-100 rounded border"
      on:click={alignCenterVertical}
      use:tooltip
      aria-label="Align Center Vertical"
    >
      <Icon src={AlignVerticalCenterIcon} class="w-4 h-4" />
    </button>

    <button
      class="p-1 hover:bg-gray-100 rounded border"
      on:click={alignBottom}
      use:tooltip
      aria-label="Align Bottom"
    >
      <Icon src={AlignBottomIcon} class="w-4 h-4" />
    </button>

    <button
      class="p-1 hover:bg-gray-100 rounded border"
      on:click={distributeHorizontal}
      use:tooltip
      aria-label="Distribute Horizontal"
    >
      <Icon src={DistributeHorizontalIcon} class="w-4 h-4" />
    </button>

    <button
      class="p-1 hover:bg-gray-100 rounded border"
      on:click={distributeVertical}
      use:tooltip
      aria-label="Distribute Vertical"
    >
      <Icon src={DistributeVerticalIcon} class="w-4 h-4" />
    </button>
  </div>
</div>

<div class="px-3">
  <h2 class="text-sm font-medium text-gray-500">Size</h2>

  <div class="flex gap-1 py-2 mb-1 flex-wrap w-24">
    <button
      class="p-1 hover:bg-gray-100 rounded border"
      on:click={makeSameWidth}
      use:tooltip
      aria-label="Same width as last selected"
    >
      <Icon src={EqualWidthIcon} class="w-4 h-4" />
    </button>

    <button
      class="p-1 hover:bg-gray-100 rounded border"
      on:click={makeSameHeight}
      use:tooltip
      aria-label="Same height as last selected"
    >
      <Icon src={EqualHeightIcon} class="w-4 h-4" />
    </button>

    <button
      class="p-1 hover:bg-gray-100 rounded border"
      on:click={makeSameSize}
      use:tooltip
      aria-label="Same size as last selected"
    >
      <Icon src={EqualSizeIcon} class="w-4 h-4" />
    </button>
  </div>
</div>
