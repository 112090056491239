/**
### UUID clash probability

Possible combinations: `36^6 = 2,176,782,336`

For 1000 items:

```
P(no clash) ≈ e^(-(1000 * 999) / (2 * 2,176,782,336))
            ≈ e^(-0.000229) ≈ 0.999771

P(clash) = 1 - P(no clash) ≈ 0.000229
```

Chance of clash: `~0.0229%`
*/

export function generateUUID(prefix = "r") {
  return `${prefix}.${Math.random().toString(36).substring(2, 8)}`;
}
