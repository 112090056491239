export function addClipboard() {
  window.addEventListener("phx:clipcopy", (event) => {
    const target = event.target;
    const clipboardIcon = target.querySelector(".clipboard-icon");
    const checkmarkIcon = target.querySelector(".checkmark-icon");

    if ("clipboard" in navigator) {
      const text = event.detail.text;
      navigator.clipboard
        .writeText(text)
        .then(() => {
          clipboardIcon.classList.add("hidden");
          checkmarkIcon.classList.remove("hidden");

          setTimeout(() => {
            clipboardIcon.classList.remove("hidden");
            checkmarkIcon.classList.add("hidden");
          }, 3000);
        })
        .catch((error) => {
          console.error("Error copying text: ", error);
        });
    } else {
      alert("Sorry, your browser does not support clipboard copy.");
    }
  });
}
