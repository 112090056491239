<script>
  import { formatSeconds } from "$lib/utils/time";
  import { Icon, MagnifyingGlass } from "svelte-hero-icons";
  import { playback } from "../../store";

  export let value;

  function handleClick() {
    playback.goToTime(value);
  }
</script>

<div class="font-mono">
  {formatSeconds(value)}
  <button on:click={handleClick}><Icon src={MagnifyingGlass} class="w-4 h-4 inline-block" /></button
  >
</div>
