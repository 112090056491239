<svelte:options accessors={true} />

<script>
  import { tooltip } from "$lib/utils/tooltip";
  import { fade } from "svelte/transition";
  export let x = 0;
  export let y = 0;
  export let canvasWidth = 1920;
  export let canvasHeight = 1080;

  let showNormalized = true;
  let showCopied = false;

  function toggleFormat() {
    showNormalized = !showNormalized;
  }

  export function onCopy() {
    copyToClipboard();
  }

  function copyToClipboard() {
    navigator.clipboard.writeText(`${displayX},${displayY}`);
    showCopied = true;
    setTimeout(() => {
      showCopied = false;
    }, 400);
  }

  $: displayX = showNormalized ? (x / canvasWidth).toFixed(3) : Math.round(x);
  $: displayY = showNormalized ? (y / canvasHeight).toFixed(3) : Math.round(y);
</script>

<div
  use:tooltip={{
    placement: "top",
    content: showNormalized ? "Switch to absolute coordinates" : "Switch to normalized coordinates",
  }}
  class="px-2 py-1 text-xs font-mono rounded cursor-pointer flex items-center gap-1"
  on:click={toggleFormat}
  on:keydown={toggleFormat}
  role="button"
  tabindex="0"
>
  <span class="text-gray-600">x:</span>
  {displayX}{showNormalized ? "" : "px"}
  <span class="text-gray-600">y:</span>
  {displayY}{showNormalized ? "" : "px"}
  {#if showCopied}
    <div
      out:fade={{ duration: 300 }}
      class="ml-1 bg-gray-700 text-white text-xs px-1 py-0.5 rounded"
    >
      Copied
    </div>
  {/if}
</div>
