<script>
  export let value;

  function formatColor(color) {
    if (color === undefined || color === null || color.length !== 3) {
      return "#000000";
    }
    return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
  }
</script>

<div style="width: 100%; height: 100%; background: {formatColor(value)}"></div>
