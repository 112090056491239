import { writable, get } from "$utils/shim";
import MockLiveSocketUI from "./MockLiveSocketUI.svelte";

export function createMockLive(mockServer) {
  const events = writable([]);
  const uploads = writable([]);
  const eventHandlers = new Map();
  const props = writable(mockServer?.initialState() ?? {});

  function triggerEvent(event, payload) {
    const handler = eventHandlers.get(event);
    if (handler) {
      handler({ event: payload });
      events.update((e) => [...e, { type: "triggered", event, payload, timestamp: new Date() }]);
    }
  }

  function updateProps(newProps) {
    props.update((props) => {
      const updatedProps = { ...props };
      for (const [key, value] of Object.entries(newProps)) {
        if (value === undefined) {
          delete updatedProps[key];
        } else {
          updatedProps[key] = value;
        }
      }
      return updatedProps;
    });
  }

  const live_socket = {
    props,
    triggerEvent,
    updateProps,
    pushEvent: (event, payload, replyFn) => {
      events.update((e) => [...e, { type: "push", event, payload, timestamp: new Date() }]);
      mockServer.handleEvent(event, payload, { ...live_socket, replyFn });
    },
    // pushEventTo: (selectorOrTarget, event, payload, cb) => this.pushEvent(event, cb),
    handleEvent: (event, handler) => {
      eventHandlers.set(event, handler);
      events.update((e) => [...e, { type: "handle", event, timestamp: new Date() }]);
    },
    removeHandleEvent: (handler) => {
      for (let [key, value] of eventHandlers.entries()) {
        if (value === handler) {
          eventHandlers.delete(key);
          events.update((e) => [
            ...e,
            { type: "removeHandler", event: key, timestamp: new Date() },
          ]);
          break;
        }
      }
    },
    upload: (channel, files) => {
      uploads.update((u) => [...u, { channel, files, timestamp: new Date() }]);

      Array.from(files).forEach((file, index) => {
        const ref = `mock-ref-${index}`;
        file._phxRef = ref;

        let progress = 0;
        const progressInterval = setInterval(() => {
          progress += 20;
          if (progress <= 100) {
            triggerEvent("upload_progress", { ref, progress, meta: file.meta?.() });
          } else {
            clearInterval(progressInterval);
            triggerEvent("upload_complete", {
              id: `mock-id-${index}`,
              url: `https://mock-url.com/${file.name}`,
              ref,
              meta: file.meta?.(),
            });
          }
        }, 500);
      });
    },
    // uploadTo: (selectorOrTarget, event, files) => this.uploadTo(event, files),
    getEvents: () => events,
    getUploads: () => uploads,
    clearEvents: () => events.set([]),
    clearUploads: () => uploads.set([]),
  };

  return {
    get props() {
      return {
        ...get(props),
        live: live_socket,
        mock_env: true,
      };
    },

    configure(app, routes = [], target = document.body) {
      props.subscribe((newProps) => {
        app.$set({ ...newProps });
      });

      new MockLiveSocketUI({
        target,
        props: { live: live_socket, routes },
      });
    },
  };
}
