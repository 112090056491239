<script>
  import { readable } from "$utils/shim";
  import { persisted } from "$lib/utils/persisted-stores";

  import Default from "./Elements/Default.svelte";
  import Position from "./Elements/Position.svelte";
  import TextMatcher from "./Elements/Text.svelte";
  import ColorMatcher from "./Elements/Color.svelte";
  import ImageMatcher from "./Elements/Image.svelte";
  import BrightnessMatcher from "./Elements/Brightness.svelte";
  import PlaybackProgressBarMatcher from "./Elements/PlaybackProgressBar.svelte";
  import ButtonMatcher from "./Elements/Button.svelte";
  import ComponentMatcher from "./Elements/Component.svelte";
  import FormMatcher from "./Elements/Form.svelte";
  import InputMatcher from "./Elements/Input.svelte";

  import SelectInput from "$lib/Inputs/SelectInput.svelte";

  import {
    availableTagOptions,
    experimentalTagOptions,
    developerTagOptions,
    tagIsExperimental,
  } from "../elements";
  import { selectedScreenRegions, toolbar, features } from "../store";

  const developerMode = features.developerMode;
  const isReadonly = features.readonlyMode;
  const additionalHelp = persisted("show_additional_help", false);

  export let selected;

  $: regions = $selectedScreenRegions ? $selectedScreenRegions.matchers : readable([]);
  $: showAdditionalHelp = $additionalHelp;

  let props;
  $: {
    // TODO: We can still end up with props == undefined here during transitions
    props = $regions.filter(({ _id }) => _id == selected)[0];
  }

  $: tagOptions = $developerMode
    ? [...availableTagOptions, ...experimentalTagOptions, ...developerTagOptions]
    : [...availableTagOptions, ...experimentalTagOptions];

  function onRepaintRegions() {
    const event = new CustomEvent("repaintRegions");
    window.dispatchEvent(event);
  }

  function update(e, propName) {
    regions.updateProp(selected, propName, e.detail);
    onRepaintRegions();
  }
</script>

{#if props}
  <div class="border-b border-gray-200 px-3 pb-4 mb-4">
    <h4 class="font-semibold">Properties</h4>
    <Default {props} {update} isReadonly={$isReadonly} />
  </div>

  <div class="border-b border-gray-200 px-3 pb-4 mb-4">
    <h4 class="font-semibold">Layout</h4>
    <Position {props} {update} isReadonly={$isReadonly} />
  </div>

  <div class="px-3 pb-2">
    <h4 class="font-semibold">Matcher</h4>
    <div class="flex flex-col mt-2">
      <SelectInput
        value={props.tagName}
        options={tagOptions}
        on:change={(e) => update(e, "tagName")}
        label="Type"
        isReadonly={$isReadonly}
      />
      {#if tagIsExperimental(props.tagName)}
        <div class="flex flex-col mt-2">
          <span class="text-xs text-red-500 ml-2"
            >This is an experimental matcher, its functionality and parameters subject to changes
            and it may not work in your case.</span
          >
        </div>
      {/if}
    </div>

    {#if props.tagName === "text"}
      <TextMatcher {props} {update} isReadonly={$isReadonly} {showAdditionalHelp} />
    {:else if props.tagName === "color"}
      <ColorMatcher {props} {update} isReadonly={$isReadonly} {showAdditionalHelp} />
    {:else if props.tagName === "image"}
      <ImageMatcher {props} {update} isReadonly={$isReadonly} {showAdditionalHelp} />
    {:else if props.tagName === "brightness"}
      <BrightnessMatcher {props} {update} isReadonly={$isReadonly} {showAdditionalHelp} />
    {:else if props.tagName === "button"}
      <ButtonMatcher {props} {update} isReadonly={$isReadonly} {showAdditionalHelp} />
    {:else if props.tagName === "component"}
      <ComponentMatcher {props} {update} isReadonly={$isReadonly} {showAdditionalHelp} />
    {:else if props.tagName === "form"}
      <FormMatcher {props} {update} isReadonly={$isReadonly} {showAdditionalHelp} />
    {:else if props.tagName === "input"}
      <InputMatcher {props} {update} isReadonly={$isReadonly} {showAdditionalHelp} />
    {:else if props.tagName === "playback-progress-bar"}
      <PlaybackProgressBarMatcher {props} {update} isReadonly={$isReadonly} {showAdditionalHelp} />
    {/if}
  </div>

  {#if $toolbar.show_debug}
    <div class="border-t border-gray-200 px-3 pt-4">
      <h4 class="font-semibold mb-2">Debug</h4>
      <button
        class="px-4 py-2 rounded-sm bg-gray-700 text-white hover:bg-gray-500"
        on:click={onRepaintRegions}>repaint</button
      >

      <div class="w-full h-32 overflow-auto border p-2 mt-4">
        <pre class="text-xs">{JSON.stringify(props, null, 2)}</pre>
      </div>
    </div>
  {/if}
{/if}
