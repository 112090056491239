<script>
  import Pane from "$lib/Elements/Pane.svelte";
  import { definitions } from "../store";
  import InputWrapper from "./InputWrapper.svelte";

  let workflow;
  export let context;
  export { workflow as definition };
  export let isReadonly;

  let properties = workflow.properties;

  function onPropertyChanged(property, value) {
    properties[property] = value;
    context.notifyPropertiesChanged();
  }

  function shouldDisplayProperty(property) {
    return property !== "global_context";
  }
</script>

<Pane>
  <h3 class="font-semibold px-4 text-sm text-gray-400 pb-5">Workflow Settings</h3>

  <div class="px-3 mb-4">
    <h4 class="font-semibold mb-4">Properties</h4>
    {#each Object.entries(definitions.getRootDefinitions()) as [property, propertyType]}
      {#if shouldDisplayProperty(property)}
        <InputWrapper
          {properties}
          {property}
          {propertyType}
          {isReadonly}
          step={{ id: null }}
          on:change={(e) => onPropertyChanged(property, e.detail)}
        />
      {/if}
    {/each}
  </div>
</Pane>
