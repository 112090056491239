import { writable, derived, get } from "$utils/shim";

function flattenItems(nodes) {
  return nodes.reduce((acc, node) => {
    if (node.variant !== "folder") {
      acc.push({ ...node, children: null });
    }
    if (node.children && node.children.length > 0) {
      acc.push(...flattenItems(node.children));
    }
    return acc;
  }, []);
}

export function createFiletreeStore(liveStore, workflow, execution) {
  const selectedNode = writable(null);
  const selectedWorkflow = writable(null);
  const items = writable([]);
  const itemsAsFlatList = derived(items, ($items) => flattenItems($items || []));
  const isMoving = writable(false);
  const isCopying = writable(false);

  function initialize(initialItems) {
    items.set(initialItems || []);
    expandAll();
  }

  function addNewItem(type, parentId = null) {
    const live = get(liveStore);
    if (!live) return;

    live.pushEvent("add_node", { type, parent_id: parentId });
  }

  function deleteItem(nodeId) {
    const live = get(liveStore);
    if (!live) return;

    live.pushEvent("delete_node", { id: nodeId });
    selectedNode.set(null);
    selectedWorkflow.set(null);
  }

  function updateItemLabel(nodeId, newLabel) {
    const live = get(liveStore);
    if (!live) return;

    live.pushEvent("rename_node", { node_id: nodeId, name: newLabel });
  }

  function startMove() {
    if (!get(selectedNode)) return;
    isMoving.set(true);
  }

  function startCopy() {
    if (!get(selectedNode)) return;
    isCopying.set(true);
  }

  function cancelMoveOrCopy() {
    isMoving.set(false);
    isCopying.set(false);
  }

  function moveItem(nodeId, newParentId) {
    const live = get(liveStore);
    if (!live) return;

    live.pushEvent("move_node", { id: nodeId, new_parent_id: newParentId });
    isMoving.set(false);
  }

  function copyItem(nodeId, newParentId) {
    const live = get(liveStore);
    if (!live) return;

    live.pushEvent("copy_node", { id: nodeId, new_parent_id: newParentId });
    isCopying.set(false);
  }

  function toggleType(nodeId) {
    const live = get(liveStore);
    if (!live) return;

    live.pushEvent("toggle_type", { id: nodeId });
  }

  function selectNode(node) {
    selectedNode.set(node);

    if (node.is_folder) {
      toggleExpand([node], true);
    } else {
      selectedWorkflow.set(node);
      execution.setCurrentStep(null);
      workflow.switchWorkflow(node.id);
      // We could clear events from execution store here, but it's not necessary
      // because all events have unique identifiers
    }
  }

  function toggleExpand(nodes, expand) {
    return nodes.map((node) => ({
      ...node,
      expanded: node.is_folder ? expand : node.expanded,
      children: node.children ? toggleExpand(node.children, expand) : [],
    }));
  }

  function expandAll() {
    items.update((currentItems) => toggleExpand(currentItems, true));
  }

  function collapseAll() {
    items.update((currentItems) => toggleExpand(currentItems, false));
  }

  return {
    initialize,
    selectedNode,
    selectedWorkflow,
    items: {
      subscribe: items.subscribe,
    },
    itemsAsFlatList,
    selectNode,
    expandAll,
    collapseAll,
    addNewItem,
    deleteItem,
    updateItemLabel,
    moveItem,
    copyItem,
    toggleType,
    isMoving: { subscribe: isMoving.subscribe },
    isCopying: { subscribe: isCopying.subscribe },
    startMove,
    startCopy,
    cancelMoveOrCopy,
    reset() {
      selectedNode.set(null);
      selectedWorkflow.set(null);
      items.set([]);
      isMoving.set(false);
      isCopying.set(false);
    },
  };
}
