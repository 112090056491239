<script>
  import TreeItem from "./TreeItem.svelte";
  import Toolbar from "./Toolbar.svelte";
  import Debug from "$lib/Elements/Debug.svelte";
  import { filetree, toolbar } from "../store";

  $: items = filetree.items;
  $: selectedNode = filetree.selectedNode;
</script>

<Toolbar />

<div role="tree" aria-orientation="vertical" class="w-full font-medium mt-2">
  {#each $items as item (item.id)}
    <TreeItem {item} />
  {/each}
</div>

{#if $toolbar.show_debug}
  <Debug items={$items} selectedNode={$selectedNode} />
{/if}
