<script>
  export let value = null;
  export let precision = 2;
  export let isPercentage = false;
</script>

{#if value === null}
  <div class="font-mono">Not available</div>
{:else}
  <div class="font-mono">{value.toFixed(precision)}{isPercentage ? "%" : ""}</div>
{/if}
