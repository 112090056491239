import { tagPropsMap } from "../elements";
import { generateUUID } from "$utils/uuid";

function mapAllowedProps(props, tagName) {
  const allowedProps = tagPropsMap[tagName] || [];
  return allowedProps.reduce((acc, key) => {
    if (key in props) {
      acc[key] = props[key];
    }
    return acc;
  }, {});
}

function parseMatcher(child) {
  const tagName = (child.tag || "").toLowerCase();
  return {
    _id: child.attributes._id || generateUUID("r"),
    tagName,
    name: child.attributes.name || "",
    required: child.attributes.required || false,
    ...mapAllowedProps(child.attributes, tagName),
  };
}

function assignAssets(assets, screenId) {
  return assets
    .filter((asset) => asset.associated_element === screenId)
    .map(({ id, url, width, height }) => ({ id, url, width, height }));
}

export function structuredDataToState(data, assets = []) {
  if (!data || !data.children) {
    return { screens: [], appAttributes: {} };
  }

  const screens = data.children
    .filter((child) => child.tag === "screen")
    .map((screen) => {
      const screenId = screen.attributes._id || generateUUID("s");

      return {
        _id: screenId,
        name: screen.attributes.name,
        matchers: screen.children.map(parseMatcher),
        assets: assignAssets(assets, screenId),
      };
    });

  return { screens, appAttributes: data.attributes };

  // TODO: Also return unmatched assets, so we know which ones we can delete
}

function serializeMatcher(matcher) {
  return {
    tag: matcher.tagName,
    attributes: {
      _id: matcher._id,
      ...mapAllowedProps(matcher, matcher.tagName),
    },
  };
}

export function stateToStructuredData(input, appAttributes = {}) {
  return {
    tag: "app",
    attributes: appAttributes,
    children: input.map((screen) => ({
      tag: "screen",
      attributes: { _id: screen._id, name: screen.name },
      children: [...screen.matchers.map(serializeMatcher)],
    })),
  };
}
