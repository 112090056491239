<script>
  import { Icon, Check, Trash } from "svelte-hero-icons";
  import { createEventDispatcher } from "svelte";
  import { user } from "../store";

  export let text;
  export let author;
  export let resolved = false;
  export let resolver = "";
  export let index;
  export let isReadonly = false;

  let currentUserNickname = $user.nickname;
  const dispatch = createEventDispatcher();

  let isHidden = true;

  function deleteComment() {
    if (!isReadonly) {
      if (confirm("Are you sure you want to delete this comment?")) {
        dispatch("deleteComment", { index });
      }
    }
  }

  function resolveComment() {
    if (!isReadonly) {
      dispatch("resolveComment", { index, resolver: currentUserNickname });
    }
  }

  function toggleResolvedComment() {
    isHidden = !isHidden;
  }
</script>

<div class="relative w-full">
  <div class="bg-yellow-100 p-2 mb-2 text-xs shadow-md {resolved ? 'opacity-60' : ''}">
    {#if resolved}
      <button
        class="text-xs text-gray-500 {resolved ? 'cursor-pointer' : ''} hover:underline"
        on:click={() => resolved && toggleResolvedComment()}
      >
        Resolved by {resolver}
      </button>
      {#if !isHidden}
        <p class="font-bold mb-1 m-0 break-words">{author}</p>
        <p class="m-0 break-words whitespace-pre-wrap">{text}</p>
      {/if}
    {:else}
      <p class="font-bold mb-1 m-0 break-words">{author}</p>
      <p class="m-0 break-words whitespace-pre-wrap">{text}</p>
    {/if}
  </div>
  {#if !isReadonly}
    <div class="absolute top-1 right-1 flex">
      {#if !resolved}
        <button on:click={resolveComment} class="text-slate-400 hover:text-green-500">
          <Icon src={Check} mini class="w-4 h-4" />
        </button>
      {/if}
      <button on:click={deleteComment} class="text-slate-400 hover:text-red-500">
        <Icon src={Trash} mini class="w-4 h-4" />
      </button>
    </div>
  {/if}
</div>
