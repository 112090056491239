<script>
  import NumericInput from "$lib/Inputs/NumericInput.svelte";
  import PropHelp from "../PropHelp.svelte";

  export let props;
  export let update;
  export let isReadonly = false;
  export let showAdditionalHelp = true;
</script>

<PropHelp {showAdditionalHelp}>
  Brightness value ranges from 0 to 100. A value of 0 means completely black, and as the value
  increases, the brightness increases.
</PropHelp>

<div class="flex mt-2">
  <NumericInput
    value={props.above}
    on:input={(e) => update(e, "above")}
    label="Above"
    {isReadonly}
  />
</div>

<div class="flex mt-2">
  <NumericInput
    value={props.below}
    on:input={(e) => update(e, "below")}
    label="Below"
    {isReadonly}
  />
</div>
