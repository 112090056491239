<script>
  import { createEventDispatcher } from "svelte";
  import { Icon, EyeSlash, Eye } from "svelte-hero-icons";

  export let element;
  export let showInOverlay = false;

  const dispatch = createEventDispatcher();

  function toggleActive() {
    showInOverlay = !showInOverlay;
    dispatch("toggle", { id: element.ui_id, active: showInOverlay });
  }
</script>

<button
  class="flex items-center justify-between w-full p-1 hover:bg-gray-100 rounded text-xs"
  on:click={toggleActive}
>
  <div class="flex items-center space-x-2 text-left">
    <Icon
      src={showInOverlay ? Eye : EyeSlash}
      mini
      class="w-4 h-4 {showInOverlay ? 'text-blue-500' : 'text-gray-400'}"
    />
    <span>{element.name}</span>
  </div>
  {#if element.executionTime}
    <span class="text-gray-500">{element.executionTime}ms</span>
  {/if}
</button>
