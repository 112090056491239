<script>
  import Modal from "$lib/Elements/Modal.svelte";

  export let isOpen = false;
  export let maxWidth = "64rem";
  export let keyboardShortcuts = {};

  function toggle() {
    isOpen = !isOpen;
  }

  let os = "";
  if (navigator.appVersion.indexOf("Win") != -1) os = "windows";
  if (navigator.appVersion.indexOf("Mac") != -1) os = "macos";

  function mappedMetaKey(key) {
    if (key === "meta") {
      if (os === "windows") {
        return "Win";
      } else if (os === "macos") {
        return "Cmd";
      }
      return "Meta";
    } else if (key === "ctrl_or_meta") {
      if (os === "windows") {
        return "Ctrl";
      } else if (os === "macos") {
        return "Cmd";
      }
      return "Meta";
    }
    return key;
  }
</script>

<Modal open={isOpen} title="Help" on:close={toggle} {maxWidth}>
  <slot></slot>
  {#if Object.keys(keyboardShortcuts).length !== 0}
    <div class="text-sm w-full">
      <h2 class="font-bold mb-4 mt-2">Keyboard Shortcuts</h2>
      {#each Object.entries(keyboardShortcuts) as [group, shortcuts]}
        <h2 class="font-semibold text-xs mb-4 mt-3">{group}</h2>
        {#each Object.entries(shortcuts) as [key, value]}
          <div class="flex items-center w-full border-t border-gray-200">
            <div class="flex flex-grow justify-start text-xs mt-1 mb-1">{key}</div>
            {#if typeof value === "string"}
              <kbd class="flex justify-end text-xs rounded bg-blue-100 px-1"
                >{mappedMetaKey(value)}</kbd
              >
            {:else if value instanceof Array}
              <div class="flex justify-end">
                {#each value as v}
                  <kbd class="text-xs rounded bg-blue-100 px-1 ml-1">{mappedMetaKey(v)}</kbd>
                {/each}
              </div>
            {/if}
          </div>
        {/each}
      {/each}
    </div>
  {/if}
</Modal>
