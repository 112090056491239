export const Faq = {
  mounted() {
    this.handleClick = (info) => {
      const { target } = info;
      this.toggleTargetItem(target);
    };

    this.el.addEventListener("click", this.handleClick);
  },

  toggleTargetItem(target) {
    while (target && target != this.el) {
      const index = target.dataset.i;
      if (index) {
        target.dataset.open = toggle(target.dataset.open);
      }
      target = target.parentElement;
    }

    return null;
  },

  destroyed() {
    this.el.removeEventListener("click", this.handleClick);
  },
};

function toggle(value) {
  switch (value) {
    case "true":
      return "false";
    case "false":
      return "true";
  }
}
