<script>
  import AudioLevelChart from "../Timeline/AudioLevelChart.svelte";
  import { timeline } from "../store";

  export let track;
  export let trackHeaderSize;
  export let trackEventsLeft;

  const { scaledTimelineWidth, visibleAudioElements } = timeline;

  $: elements = visibleAudioElements;
</script>

<div
  class="flex border-b border-gray-200 bg-white h-8"
  style="min-width: 100%; width: {$scaledTimelineWidth + trackEventsLeft + trackHeaderSize}px;"
>
  <!-- Track Header -->
  <div
    data-el-tracker-header
    class="w-36 flex-shrink-0 px-2 flex items-center justify-between sticky left-0 z-[1000] bg-white border-r border-gray-200"
  >
    <span class="font-medium">{track.name}</span>
  </div>
  <!-- Track Events -->
  <div class="flex items-center relative" style="left: {trackEventsLeft}px;">
    {#each $elements as event, i}
      {@const hoverWidth =
        i > 0 ? timeline.durationToPx(event.start - $elements[i - 1].start, 1) : 0}
      <AudioLevelChart
        {event}
        {hoverWidth}
        x={timeline.calcPxStartTime(event)}
        width={timeline.calcPxDuration(event)}
      />
    {/each}
  </div>
</div>
