export function UrlAvailabilityChecker(options = {}) {
  let prevAbortController;

  const maxRetries = options.maxRetries || 30;
  const retryInterval = options.retryInterval || 1000;
  const onProgressCallback = options.onProgress || (() => {});

  async function check(url) {
    if (prevAbortController) {
      prevAbortController.abort();
    }

    let abortController = new AbortController();
    prevAbortController = abortController;

    let retries = 0;

    while (retries < maxRetries - 1) {
      if (abortController.signal.aborted) {
        console.warn("URL check aborted");
        return false;
      }

      try {
        const response = await fetch(url, { method: "HEAD", signal: abortController.signal });

        if (response.ok) {
          return true;
        }
      } catch (error) {
        if (error.name === "AbortError") {
          console.warn("URL check aborted");
          return false;
        }
        console.warn("Error checking URL:", error);
      }

      onProgressCallback(retries + 1, maxRetries);

      if (abortController.signal.aborted) {
        console.warn("Timeout aborted");
        return false;
      }

      await new Promise((resolve) => setTimeout(resolve, retryInterval));
      retries++;
    }

    console.error("Max retries reached. Recording is not available:", url);
    return false;
  }

  return { check, cancel: () => prevAbortController && prevAbortController.abort() };
}
