<script>
  import { createEventDispatcher } from "svelte";
  import { Icon, XMark, Clipboard } from "svelte-hero-icons";
  import { tooltip } from "$utils/tooltip";

  export let value = null;
  export let label;
  export let variant = "default";
  export let optional = false;
  export let signed = true;
  export let isReadonly = false;
  export let allowDecimals = true;

  const id_x = `${String(Math.random())}-x`;
  const id_y = `${String(Math.random())}-y`;
  const dispatch = createEventDispatcher();

  function handleInput(event, axis) {
    if (!isReadonly) {
      const inputValue = event.target.value;
      const parseInput = allowDecimals ? parseFloat : parseInt;
      const axisValue = inputValue !== "" ? parseInput(inputValue, 10) : null;
      if (axis == "x") {
        value = value === null ? { x: axisValue, y: null } : value;
      } else {
        value = value === null ? { x: null, y: axisValue } : value;
      }
      dispatch("input", value);
    }
  }

  function handleKeyPress(event) {
    if (!allowDecimals && (event.charCode < 48 || event.charCode > 57)) {
      event.preventDefault();
    }
  }

  function clearValue() {
    if (!isReadonly) {
      value = null;
      dispatch("input", value);
    }
  }

  function pasteCoordinates() {
    navigator.clipboard.readText().then((text) => {
      if (!text.includes(",")) {
        return;
      }

      const [x, y] = text.split(",");
      value = { x: parseFloat(x), y: parseFloat(y) };
      dispatch("input", value);
    });
  }
</script>

<div class="left-2 text-gray-500 mb-2 flex flex-row items-left">
  <div class="flex">{label}</div>
  <button
    use:tooltip
    aria-label="Paste coordinates"
    on:click={pasteCoordinates}
    class="flex pl-2 hover:opacity-70 focus:outline-none relative"
  >
    <Icon src={Clipboard} class="w-4 h-4 -ml-1 text-gray-500" />
  </button>
</div>
<div class="relative flex flex-row gap-2 items-center {$$props.class || ''}">
  <div class="flex flex-6">
    <label
      for={id_x}
      class="absolute left-2 pt-2 text-gray-500 {isReadonly ? 'cursor-not-allowed' : ''}">X</label
    >
    <input
      {id_x}
      step={allowDecimals ? "any" : $$props.step || 1}
      type="number"
      class="rounded p-2 w-full text-xs bg-gray-100 border-0"
      class:pl-8={variant == "wide"}
      class:pl-12={variant == "ultrawide"}
      class:cursor-not-allowed={isReadonly}
      class:opacity-60={isReadonly}
      value={value?.x}
      min={signed ? null : 0}
      on:input={(e) => handleInput(e, "x")}
      on:keypress={handleKeyPress}
      readonly={isReadonly}
    />
  </div>
  <div class="flex flex-6">
    <label
      for={id_y}
      class="absolute left-36 pt-2 text-gray-500 {isReadonly ? 'cursor-not-allowed' : ''}"
      class:left-32={optional && !isReadonly}
      class:left-36={!optional || isReadonly}>Y</label
    >
    <input
      {id_y}
      step={allowDecimals ? "any" : $$props.step || 1}
      type="number"
      class="rounded p-2 w-full text-xs bg-gray-100 border-0"
      class:pl-8={variant == "wide"}
      class:pl-12={variant == "ultrawide"}
      class:cursor-not-allowed={isReadonly}
      class:opacity-60={isReadonly}
      value={value?.y}
      min={signed ? null : 0}
      on:input={(e) => handleInput(e, "y")}
      on:keypress={handleKeyPress}
      readonly={isReadonly}
    />
  </div>

  {#if optional && !isReadonly}
    <div class="flex flex-1">
      <button
        type="button"
        class="text-gray-500 hover:text-gray-700 focus:outline-none"
        on:click={clearValue}
      >
        <Icon src={XMark} class="w-4 h-4" />
      </button>
    </div>
  {/if}
  <slot name="dynamic" />
</div>
