<script>
  import CheckboxInput from "$lib/Inputs/CheckboxInput.svelte";
  import TextInput from "$lib/Inputs/TextInput.svelte";
  import SelectInput from "$lib/Inputs/SelectInput.svelte";
  import NumericInput from "$lib/Inputs/NumericInput.svelte";
  import PropHelp from "../PropHelp.svelte";

  export let props;
  export let update;
  export let isReadonly = false;
  export let showAdditionalHelp = true;

  const engineOptions = [
    { value: "detect", label: "Detect" },
    { value: "recognize", label: "Recognize" },
  ];

  if (!props.mode) {
    props.mode = "plaintext";
  }
  if (props.case_sensitive !== true || props.case_sensitive !== false) {
    props.case_sensitive = false;
  }
  let caseSensitive = props.case_sensitive;

  // 0.7 confidence score for text detection
  let threshold = finiteValueFrom("threshold", props.threshold, 70, 0, 100);
  // 70% similarity threshold for text recognition when using plain text mode
  let similarity = finiteValueFrom("similarity", props.similarity, 70, 0, 100);

  function finiteValueFrom(key, value, defaultValue, min, max) {
    if (!isFinite(value) || isNaN(value)) {
      updateDetail({ detail: defaultValue }, key);
      return defaultValue;
    }
    value = Math.round(value * 100);
    if (value < min || value > max) {
      updateDetail({ detail: defaultValue }, key);
      return defaultValue;
    }
    return value;
  }

  const modeOptions = [
    { value: "plaintext", label: "Plain Text" },
    { value: "regex", label: "Regex" },
  ];

  function updateDetail(e, key) {
    const value = e.detail === null ? null : e.detail / 100.0;
    update({ detail: value }, key);
  }

  function updateBoolean(e, key) {
    update({ detail: e.detail }, key);
  }
</script>

<div class="flex flex-col mt-2">
  <TextInput value={props.match} on:input={(e) => update(e, "match")} label="Text" {isReadonly} />

  <PropHelp {showAdditionalHelp}>
    Text provided will match on any subset of the detected text, case-insensitive. A regular
    expression can be provided as well.
  </PropHelp>
</div>

<div class="flex flex-col mt-2">
  <SelectInput
    value={props.mode}
    on:change={(e) => update(e, "mode")}
    label="Mode"
    options={modeOptions}
    {isReadonly}
  />

  <PropHelp {showAdditionalHelp}>
    Plain text mode will not interpret symbols like <code>?</code>, <code>*</code>, <code>.</code> as
    regular expression syntax, so they can be matched as is. Regex mode will interpret the text as a
    regular expression.
  </PropHelp>
</div>

<div class="flex flex-col mt-2 relative">
  <CheckboxInput
    value={caseSensitive}
    on:input={(e) => updateBoolean(e, "case_sensitive")}
    label="Case Sensitive?"
    variant="wide"
    {isReadonly}
  />

  <PropHelp {showAdditionalHelp}>
    In "Regex" mode, case sensitive mode can cause the regular expression to mismatch if the case
    does not match.<br /><br />

    In "Plain Text" mode, detected text and the provided text will be converted to lower case before
    calculating similarity.
  </PropHelp>
</div>

<div class="flex flex-col mt-2 relative">
  <NumericInput
    value={threshold}
    on:input={(e) => updateDetail(e, "threshold")}
    label="Threshold (%)"
    variant="wide"
    {isReadonly}
  />

  <PropHelp {showAdditionalHelp}>
    In both "Regex" and "Plain Text" mode, the threshold will be used to reject detected text with a
    lower confidence score than this threshold.<br /><br />

    Rejected ones will still show up in Debugger.
  </PropHelp>
</div>

<div class="flex flex-col mt-2 relative">
  <NumericInput
    value={similarity}
    on:input={(e) => updateDetail(e, "similarity")}
    label="Similarity (%)"
    variant="wide"
    {isReadonly}
  />

  <PropHelp {showAdditionalHelp}>
    Text is considered matched if the similarity is above this threshold. A value of 100 means the
    text must be an exact match while a value of 0 means the text can be completely different.<br
    /><br />

    This value is only used when the "Plain Text" mode is selected.
  </PropHelp>
</div>

<div class="flex flex-col mt-2">
  <SelectInput
    optional
    value={props.engine}
    on:change={(e) => update(e, "engine")}
    label="Engine"
    options={engineOptions}
    {isReadonly}
  />

  <PropHelp {showAdditionalHelp}>
    Recognize extracts the text from the entire region. Detect finds bounding boxes inside the
    region and then extracts text. Use recognize for faster results; detect for more accurate.
  </PropHelp>
</div>
