<g>
  <path class="sqd-validation-error" d="M 0 -11 l 11 22 l -22 0 Z" transform="translate(11, 11)">
  </path>
  <g transform="translate(5, 7.5)">
    <path
      d="M24 42q-1.45 0-2.475-1.025Q20.5 39.95 20.5 38.5q0-1.45 1.025-2.475Q22.55 35 24 35q1.45 0 2.475 1.025Q27.5 37.05 27.5 38.5q0 1.45-1.025 2.475Q25.45 42 24 42Zm-3.5-12V6h7v24Z"
      transform="scale(0.25)"
    >
    </path>
  </g>
</g>
