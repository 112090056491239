<script>
  import { createEventDispatcher } from "svelte";
  import { Icon, Swatch, ArrowLongUp, ArrowLongDown } from "svelte-hero-icons";
  import { tooltip } from "$utils/tooltip";

  export let x;
  export let y;
  export let width;
  export let height;
  export let histogram;

  let colorTarget = "color";

  const dispatch = createEventDispatcher();

  $: totalCount = histogram.reduce((sum, [_, count]) => sum + count, 0);
</script>

<div class="mt-2">
  <p class="text-gray-700 text-xs mb-1">
    Detected {histogram.length} dominant colors in region<br />
    {`(x: ${x}, y: ${y}, w: ${width}, h: ${height})`}
  </p>
  <div class="flex flex-col items-left">
    <div
      class="flex gap-1 items-center px-2 py-1 my-2 bg-gray-100 border-0 rounded border-gray-700"
    >
      <span class="text-gray-500 justify-self-start pr-8">Target</span>
      <div class="flex gap-1 items-center justify-center w-full">
        <div use:tooltip aria-label="Set Color">
          <input type="radio" bind:group={colorTarget} id="color" value="color" class="hidden" />
          <label
            class:text-gray-400={colorTarget !== "color"}
            class:text-gray-700={colorTarget === "color"}
            class="transition-colors duration-200 cursor-pointer"
            for="color"><Icon src={Swatch} class="w-6 h-6" /></label
          >
        </div>
        <div use:tooltip aria-label="Set Upper Color">
          <input type="radio" bind:group={colorTarget} id="upper" value="upper" class="hidden" />
          <label
            class:text-gray-400={colorTarget !== "upper"}
            class:text-gray-700={colorTarget === "upper"}
            class="transition-colors duration-200 cursor-pointer"
            for="upper"><Icon src={ArrowLongUp} class="w-6 h-6" /></label
          >
        </div>
        <div use:tooltip aria-label="Set Lower Color">
          <input type="radio" bind:group={colorTarget} id="lower" value="lower" class="hidden" />
          <label
            class:text-gray-400={colorTarget !== "lower"}
            class:text-gray-700={colorTarget === "lower"}
            class="transition-colors duration-200 cursor-pointer"
            for="lower"><Icon src={ArrowLongDown} class="w-6 h-6" /></label
          >
        </div>
      </div>
    </div>

    {#each histogram as [color, count]}
      <div class="flex flex-row items-center mt-1 justify-between">
        <button
          class="h-6 border border-gray-300 rounded mr-2 hover:!w-full transition-all duration-200"
          style="background-color: {color}; width: max({((count / totalCount) * 100).toFixed(
            2,
          )}%, 5%);"
          on:click={(_e) => dispatch("color", { [colorTarget]: color })}
          use:tooltip={{ placement: "left" }}
          aria-label={`${color}, ${((count / totalCount) * 100).toFixed(2)}%`}
        />
      </div>
    {/each}
  </div>
</div>
