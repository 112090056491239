<script>
  import GridRow from "./GridRow.svelte";

  export let data = [];
  export let columns = {
    label: "Property",
    value: "Value",
  };
  export let split = "wide";

  let key_span = "col-span-2";
  let value_span = "col-span-4";
  if (split === "equal") {
    key_span = value_span = "col-span-3";
  }
</script>

<div class="w-full grid grid-cols-6 gap-0 overflow-hidden">
  <!-- Header -->
  <div class="grid grid-cols-subgrid col-span-6 border-b border-gray-200 bg-gray-100">
    <div class="{key_span} bg-gray-100 text-left p-1 font-semibold">{columns.label}</div>
    <div class="{value_span} bg-gray-100 text-left p-1 font-semibold">{columns.value}</div>
  </div>

  {#each data as item, index (item.id)}
    <GridRow {item} isEven={index % 2 === 0} {key_span} {value_span} />
  {/each}
</div>
