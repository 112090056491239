<script>
  import { workflow, definitions } from "../Automator/store";

  export let currentStepId = null;
  export let selectedStep = null;
  export let includeGlobalContext = false;

  function flattenWorkflow(sequence, parentId = "") {
    return sequence.flatMap((step) => {
      const flatStep = { ...step, flatId: parentId ? `${parentId}-${step.id}` : `${step.id}` };
      if (step.branches) {
        Object.entries(step.branches).forEach(([key, branchSequence]) => {
          flatStep[key] = flattenWorkflow(branchSequence, flatStep.flatId);
        });
      }
      return [
        flatStep,
        ...(step.branches
          ? Object.values(step.branches).flatMap((branch) =>
              flattenWorkflow(branch, flatStep.flatId),
            )
          : []),
      ];
    });
  }

  $: flatSteps = flattenWorkflow($workflow.sequence);
  $: intermediateSteps = currentStepId
    ? flatSteps.slice(
        0,
        flatSteps.findIndex((step) => step.id === currentStepId),
      )
    : flatSteps;
  $: visibleSteps = [
    ...(includeGlobalContext
      ? [
          {
            componentType: "task",
            id: "global_context",
            name: "context",
            type: "global_context",
            flatId: "context",
          },
        ]
      : []),
    ...intermediateSteps,
  ];

  function selectStep(step) {
    selectedStep = step;
  }
</script>

<div class="w-1/2 pr-2 border-r">
  <h3 class="font-semibold mb-1">Steps</h3>
  <ul class="space-y-0.5 max-h-48 overflow-y-auto">
    {#if visibleSteps.length === 0}
      <p class="text-gray-500 italic">No prior steps found</p>
    {/if}
    {#each visibleSteps as step (step.flatId)}
      <li>
        <button
          class="w-full text-left flex items-center px-1.5 py-0.5 rounded-sm hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
          class:bg-gray-200={selectedStep === step}
          on:click={() => selectStep(step)}
          style="padding-left: {(step.flatId.split('-').length - 1) * 0.5}rem"
        >
          <img
            src={definitions.iconUrlProvider(step.componentType, step.type)}
            alt={step.type}
            class="w-3 h-3 mr-1 flex-shrink-0"
          />
          <span class="truncate">{step.name || step.type}</span>
        </button>
      </li>
    {/each}
  </ul>
</div>
