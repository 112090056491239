<script>
  import { createEventDispatcher } from "svelte";
  import { Icon, Plus, Trash } from "svelte-hero-icons";

  export let value = [""];
  export let label = "Multi-line Input";
  export let placeholder = "Enter text";
  // svelte-ignore unused-export-let
  export let variant = "default";
  export let isReadonly = false;

  const dispatch = createEventDispatcher();

  function addLine() {
    if (!isReadonly) {
      value = [...value, ""];
      dispatch("input", value);
    }
  }

  function removeLine(index) {
    if (!isReadonly) {
      value = value.filter((_, i) => i !== index);
      dispatch("input", value);
    }
  }

  function updateLine(index, newValue) {
    if (!isReadonly) {
      value[index] = newValue;
      value = [...value];
      dispatch("input", value);
    }
  }
</script>

<div class="border rounded-lg p-3 max-w-2xl {isReadonly ? 'bg-gray-100' : ''}">
  <div class="flex justify-between items-center mb-2">
    <h3 class="font-semibold text-xs">{label}</h3>
    <div>
      {#if !isReadonly}
        <button
          on:click={addLine}
          class="flex items-center text-blue-500 hover:text-blue-600 text-xs font-medium transition-colors duration-200"
        >
          <Icon src={Plus} class="w-4 h-4 mr-1" />
          Add line
        </button>
      {/if}
      <slot name="dynamic" />
    </div>
  </div>

  <div class="pr-2 -mr-2">
    {#each value as line, index (index)}
      <div class="mb-1 border border-gray-200 rounded-md bg-gray-50 flex items-center">
        <input
          type="text"
          value={line}
          on:input={(e) => updateLine(index, e.target.value)}
          class="flex-grow text-xs px-2 py-1 rounded-md border-none bg-transparent focus:ring-0 hover:bg-gray-100 focus:bg-gray-100"
          class:cursor-not-allowed={isReadonly}
          {placeholder}
          disabled={isReadonly}
          data-1p-ignore
        />
        {#if !isReadonly}
          <button
            on:click={() => removeLine(index)}
            class="text-gray-400 hover:text-red-500 transition-colors duration-200 mr-1"
          >
            <Icon src={Trash} class="w-4 h-4" />
          </button>
        {/if}
      </div>
    {/each}
  </div>
</div>
