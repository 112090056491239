export const HLSVideo = {
  videoURL() {
    return this.el.dataset.url;
  },

  mounted() {
    this.initPlayer();
  },

  updated() {
    console.log(`Updated HLS video: ${this.videoURL()}`);
    this.player.src = this.videoURL();
  },

  async initPlayer() {
    await import("@mux/mux-player");

    const url = this.videoURL();
    console.log(`Initializing Mux video: ${url}`);

    this.player = this.el.querySelector("mux-player");

    // TODO: Enable for live streams
    // this.player.setAttribute("stream-type", "live");

    this.player.muted = true;
    this.player.src = url;

    let firstError = true;

    this.player.addEventListener("error", async (event) => {
      const error = event.detail;
      console.log("Mux Player Error:", error);

      // Skip segment if it fails to decode
      // TODO: Remove this in 90 days as this has been fixed at source.
      if (firstError && error.name === "MediaError") {
        console.warn("Skipping bad .ts segment due to missing keyframe");
        // We need to replace the entire player as this.player.load()
        // will not clear out the error overlay
        const newPlayer = this.player.cloneNode(true); // Clone all attributes

        // .ts segments are 3s currently. We are safe 4s in either direction
        newPlayer.startTime -= 4;

        this.player.replaceWith(newPlayer); // Replace old player with the new instance
        this.player = newPlayer;

        firstError = false;
      }
    });
  },
};
