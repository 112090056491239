import { writable, get } from "$utils/shim";

export function createFeatureStore() {
  const developerMode = writable(false);

  return {
    initialize(user_roles) {
      developerMode.set(user_roles.includes("root"));
    },
    developerMode,
    get isDeveloperMode() {
      return get(developerMode);
    },
    reset() {
      developerMode.set(false);
    },
  };
}
