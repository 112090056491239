<script>
  import { createEventDispatcher, onMount } from "svelte";
  import { Icon, Plus, Trash } from "svelte-hero-icons";

  export let label = "Image Upload";
  export let value;
  // svelte-ignore unused-export-let
  export let variant = "default";
  export let isReadonly = false;
  export let multiple = false;
  export let store = null;
  export let associatedElement = null;

  onMount(() => {
    if (value instanceof Object && value.assets) {
      store.reset();
      store.initialize(value.assets);
    }
    if (value instanceof Object && value.selected) {
      store.select(value.selected);
    }
  });

  if (!store) {
    console.error("No asset store provided.");
  }

  $: assets = store;
  $: uploadingFiles = assets?.uploadingFiles ?? [];
  $: selectedIndex = assets?.selectedIndex ?? 0;
  $: errors = assets?.errors ?? [];

  const dispatch = createEventDispatcher();

  async function upload(files) {
    if (!files) return;
    await assets.upload(files, associatedElement, {}, (new_assets) => {
      emitChanges(new_assets);
    });
  }

  async function handleFileChange(event) {
    const files = event.target.files;
    await upload(files);
  }

  function handleDragOver(event) {
    event.preventDefault();
  }

  function handleDragLeave() {}

  async function handleDrop(event) {
    event.preventDefault();
    const files = event.dataTransfer.files;
    await upload(files);
  }

  function removeImage(index) {
    assets.remove(index);
    emitChanges($assets);
  }

  function selectImage(index) {
    assets.select(index);
    emitChanges($assets);
  }

  function emitChanges(new_assets) {
    if (!new_assets) return;

    if (multiple) {
      dispatch("input", { assets: new_assets });
    } else {
      dispatch("input", { assets: new_assets, selected: $selectedIndex });
    }
  }

  function toOrdinal(number) {
    const suffixes = ["th", "st", "nd", "rd"];
    const mod100 = number % 100;
    const suffix = suffixes[(mod100 - 20) % 10] || suffixes[mod100] || suffixes[0];
    return number + suffix;
  }
</script>

<div
  class="border rounded"
  on:dragover={handleDragOver}
  on:dragleave={handleDragLeave}
  on:drop={handleDrop}
  role="list"
>
  <div class="flex justify-between items-center p-4 pb-0">
    <h3 class="font-semibold text-xs">
      {label}
      {#if !multiple && $assets.length > 0}
        <span class="font-normal">({toOrdinal($selectedIndex + 1)} selected)</span>
      {/if}
    </h3>
    <slot name="dynamic" />
  </div>
  <div class="overflow-x-auto w-full">
    <div role="region" aria-label="Image Gallery" class="flex space-x-2 w-fit p-4">
      {#each $assets as asset, index (asset.id)}
        <div class="relative w-20 h-20 group">
          <button
            disabled={isReadonly}
            class="relative w-full h-full p-0 border-0 bg-transparent cursor-pointer"
            on:click={() => selectImage(index)}
          >
            <img
              src={asset.url}
              crossorigin="anonymous"
              draggable="false"
              alt="Thumbnail of asset"
              class="w-full h-full object-cover {!multiple && $selectedIndex === index
                ? 'outline outline-2 outline-offset-1 outline-indigo-400'
                : ''}"
            />
          </button>
          <button
            class="absolute top-1 right-1 bg-red-500 hover:bg-red-600 text-white p-1 text-xs rounded-xs opacity-0 group-hover:opacity-100 transition-opacity duration-100"
            on:click={() => removeImage(index)}
            disabled={isReadonly}
          >
            <Icon src={Trash} mini class="w-3 h-3" />
          </button>
        </div>
      {/each}

      {#each $uploadingFiles as { tempSrc, progress, phxRef } (phxRef)}
        <div class="relative w-20 h-20 group">
          <img
            src={tempSrc}
            crossorigin="anonymous"
            draggable="false"
            alt=""
            class="w-full h-20 object-cover"
          />
          <div class="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div class="text-white font-bold">
              {Math.round(progress)}%
            </div>
          </div>
        </div>
      {/each}

      {#if !isReadonly}
        <label
          class="w-20 h-20 border-2 border-dashed flex items-center justify-center transition select-none duration-100 ease-in-out border-gray-300 text-gray-300 cursor-pointer hover:bg-gray-200 hover:text-gray-400"
        >
          <Icon src={Plus} mini class="w-8 h-8" />
          <input
            type="file"
            accept="image/*"
            on:change={handleFileChange}
            class="hidden"
            multiple
          />
        </label>
      {/if}
    </div>
  </div>
</div>

{#if Object.values($errors).length > 0}
  <div class="bg-red-500 text-white font-semibold p-1 text-left text-sm mt-4">
    <pre>{JSON.stringify($errors, null, 2)}</pre>
  </div>
{/if}
