import TextInput from "../../Inputs/TextInput.svelte";
import MultilineInput from "../../Inputs/MultilineInput.svelte";
import NumericInput from "../../Inputs/NumericInput.svelte";
import DurationInput from "../../Inputs/DurationInput.svelte";
import CoordinateInput from "../../Inputs/CoordinateInput.svelte";
import ColorInput from "../../Inputs/ColorInput.svelte";
import ImageInput from "../../Inputs/ImageInput.svelte";
import RegionSelector from "../../Inputs/RegionSelector.svelte";
import TextareaInput from "../../Inputs/TextareaInput.svelte";
import RemoteSequenceInput from "../../Inputs/RemoteSequenceInput.svelte";
import ScreenSelector from "../../Inputs/ScreenSelector.svelte";
import CheckboxInput from "../../Inputs/CheckboxInput.svelte";
import SelectInput from "../../Inputs/SelectInput.svelte";
import BranchesInput from "../../Inputs/BranchesInput.svelte";
import NullInput from "../../Inputs/NullInput.svelte";
import CodeInput from "../../Inputs/CodeInput.svelte";
import WorkflowInput from "../../Inputs/WorkflowInput.svelte";

import { assetGroupStore } from "../store";
import { screenStoresAsList } from "$lib/shared_stores";

export function getInputComponent(propertyName, nameOrTypeDefinition, step) {
  let typeName = nameOrTypeDefinition;
  let optional = false;
  let multiple = false;
  let dynamic = false;
  let signed = true;
  let multipleRegion = false;
  let multipleScreen = false;
  let enumOptions = [];
  let label = propertyName;
  let docs;

  if (nameOrTypeDefinition instanceof Object) {
    typeName = nameOrTypeDefinition["type"];
    optional = nameOrTypeDefinition["optional"];
    enumOptions = nameOrTypeDefinition["options"];
    multipleRegion = multiple = nameOrTypeDefinition["multiple"];
    multipleScreen = nameOrTypeDefinition["multipleScreen"];
    label = nameOrTypeDefinition["human"] || propertyName;
    docs = nameOrTypeDefinition["doc"];
    dynamic = nameOrTypeDefinition["dynamic"];
    signed = nameOrTypeDefinition["signed"];
  }

  const defaultVariant = label.length > 14 ? "ultrawide" : "wide";
  switch (typeName) {
    case "string":
      if (multiple) {
        return [MultilineInput, { variant: defaultVariant, label, docs }];
      } else {
        return [TextInput, { variant: defaultVariant, optional, label, docs, dynamic }];
      }
    case "text":
      return [TextareaInput, { label, optional, docs, dynamic }];
    case "workflow":
      return [WorkflowInput, { label, optional, docs, dynamic }];
    case "color":
      return [ColorInput, { variant: defaultVariant, optional, label, docs, dynamic }];
    case "number":
      return [NumericInput, { variant: defaultVariant, optional, label, docs, dynamic, signed }];
    case "coordinate":
      return [CoordinateInput, { variant: defaultVariant, optional, label, docs, dynamic }];
    case "duration":
      return [DurationInput, { variant: defaultVariant, optional, label, docs, dynamic, signed }];
    case "boolean":
      return [CheckboxInput, { label, docs, dynamic }];
    case "code":
      return [CodeInput, { label, optional, docs, dynamic, theme: "light" }];
    case "enum":
      return [
        SelectInput,
        { variant: defaultVariant, optional, label, docs, dynamic, options: enumOptions },
      ];
    case "image":
      return [
        ImageInput,
        {
          multiple,
          label,
          docs,
          dynamic,
          store: assetGroupStore.requestStore(step.id, propertyName),
          associatedElement: propertyName,
        },
      ];
    case "key":
      return [RemoteSequenceInput, { label, docs, dynamic }];
    case "branches":
      return [BranchesInput, { label, docs, dynamic: false }];
    case "region":
      return [
        RegionSelector,
        {
          label,
          docs,
          dynamic,
          optional,
          multipleRegion,
          multipleScreen,
          store: screenStoresAsList,
        },
      ];
    case "screen":
      return [
        ScreenSelector,
        { optional, multiple, label, docs, dynamic, store: screenStoresAsList },
      ];
    case "null":
      return [NullInput, { label, docs, dynamic }];
    default:
      return [TextareaInput, { label, optional, docs, dynamic }];
  }
}
