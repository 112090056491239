function cloneDeep(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export const ProcessorController = {
  // ==========================================
  // Lifecycle Methods
  // ==========================================
  async mounted() {
    // Initialize camera parameters
    this.homographyParams = cloneDeep(
      this.homographyPresets.Identity || {
        h00: 1.0,
        h01: 0.0,
        h02: 0.0,
        h10: 0.0,
        h11: 1.0,
        h12: 0.0,
        h20: 0.0,
        h21: 0.0,
        h22: 1.0,
      },
    );

    this.fisheyeParams = cloneDeep(
      this.fisheyePresets.Equidistant || {
        fx: 1050,
        fy: 1050,
        cx: 960,
        cy: 540,
        k1: 0.0,
        k2: 0.0,
        k3: 0.0,
        k4: 0.0,
        lens_model: "equidistant",
      },
    );

    // Try to get existing remoteControl if it's already been created
    this.remoteControl = window.sauronRemoteControl || null;

    // Listen for the remoteControl to become available
    this.handleRemoteControlReady = (event) => {
      this.remoteControl = event.detail.remoteControl;
      console.log("ProcessorController: remoteControl channel connected");
    };

    window.addEventListener("remoteControlReady", this.handleRemoteControlReady);

    this.setupAllControls();

    // Initialize UI with default values
    this.updateUIFromParams(this.homographyParams, ".homography-value", (_, value) =>
      value.toFixed(4),
    );
    this.updateUIFromParams(this.fisheyeParams, ".fisheye-value", (key, value) => {
      if (key === "fx" || key === "fy" || key === "cx" || key === "cy") {
        return Math.round(value);
      } else {
        return value.toFixed(4);
      }
    });

    // Set default lens model
    const lensModelSelect = this.el.querySelector('select[phx-value-param="lens_model"]');
    if (lensModelSelect && this.fisheyeParams.lens_model) {
      lensModelSelect.value = this.fisheyeParams.lens_model;
    }

    // Initialize vignetting parameters
    this.vignetteParams = {
      cx: 960,
      cy: 540,
      factors: [1.0, 0.0, 0.0, 0.0],
    };

    console.log("ProcessorController: mounted and initialized");
  },

  destroyed() {
    // Clean up event listener when the hook is destroyed
    window.removeEventListener("remoteControlReady", this.handleRemoteControlReady);
  },

  // ==========================================
  // Setup Methods
  // ==========================================
  setupAllControls() {
    this.setupEventListeners([
      // Homography sliders
      {
        selector: 'input[phx-value-param^="h"]',
        event: "input",
        handler: this.handleHomographyChange,
      },

      // Fisheye sliders
      {
        selector:
          'input[phx-value-param^="f"], input[phx-value-param^="c"], input[phx-value-param^="k"]',
        event: "input",
        handler: this.handleFisheyeChange,
      },

      // Preset buttons
      {
        selector: "button[data-homography-preset]",
        event: "click",
        handler: this.applyHomographyPreset,
      },
      { selector: "button[data-fisheye-preset]", event: "click", handler: this.applyFisheyePreset },

      // Replace dropdown handlers with button handlers
      {
        selector: "button[data-lens-model]",
        event: "click",
        handler: (event) => {
          this.fisheyeParams.lens_model = event.target.getAttribute("data-lens-model");
          this.sendFisheyeUpdate();
        },
      },
      {
        selector: "button[data-grid]",
        event: "click",
        handler: (event) => this.sendGridUpdate(event.target.getAttribute("data-grid") === "true"),
      },
      {
        selector: "button[data-interpolation]",
        event: "click",
        handler: (event) =>
          this.sendInterpolationUpdate(event.target.getAttribute("data-interpolation")),
      },

      // Vignetting preset buttons
      {
        selector: "button[phx-value-preset]",
        event: "click",
        handler: this.handleVignettingPreset,
      },

      // Controls with hooks
      {
        selector: 'input[phx-hook="VignettingControl"]',
        event: "input",
        handler: (event) =>
          this.handleValueWithDisplay(event, ".vignetting-value", 2, this.sendVignettingUpdate),
      },

      // Add handlers for vignetting parameters
      {
        selector: 'input[phx-value-param="vignette_cx"]',
        event: "input",
        handler: this.handleVignetteCxChange.bind(this),
      },
      {
        selector: 'input[phx-value-param="vignette_cy"]',
        event: "input",
        handler: this.handleVignetteCyChange.bind(this),
      },
      {
        selector: 'input[phx-value-param="vignette_f1"]',
        event: "input",
        handler: this.handleVignetteF1Change.bind(this),
      },
      {
        selector: 'input[phx-value-param="vignette_f2"]',
        event: "input",
        handler: this.handleVignetteF2Change.bind(this),
      },
      {
        selector: 'input[phx-value-param="vignette_f3"]',
        event: "input",
        handler: this.handleVignetteF3Change.bind(this),
      },
      {
        selector: 'input[phx-value-param="vignette_f4"]',
        event: "input",
        handler: this.handleVignetteF4Change.bind(this),
      },
    ]);
  },

  setupEventListeners(configs) {
    configs.forEach((config) => {
      // Find elements within this hook's element
      const elements = this.el.querySelectorAll(config.selector);

      elements.forEach((element) => {
        // Store a reference to the previous handler if it exists
        if (!element._eventHandlers) {
          element._eventHandlers = {};
        }

        // Remove previous handler for this event if it exists
        if (element._eventHandlers[config.event]) {
          element.removeEventListener(config.event, element._eventHandlers[config.event]);
        }

        // Create a bound version of the handler
        const boundHandler = config.handler.bind(this);

        // Store the new handler
        element._eventHandlers[config.event] = boundHandler;

        // Add the new event listener
        element.addEventListener(config.event, boundHandler);
      });
    });
  },

  // ==========================================
  // Event Handlers
  // ==========================================
  handleValueWithDisplay(event, displaySelector, precision, updateCallback) {
    const value = parseFloat(event.target.value);
    const valueDisplay = document.querySelector(displaySelector);
    if (valueDisplay) {
      valueDisplay.textContent = value.toFixed(precision);
    }
    updateCallback.call(this, value);
  },

  handleParamChange(event, params, displaySelector, formatValue, updateCallback) {
    const param = event.target.getAttribute("phx-value-param");
    const value = parseFloat(event.target.value);

    // Update the display value
    const valueDisplay = document.querySelector(`${displaySelector}-${param}`);
    if (valueDisplay) {
      valueDisplay.textContent = formatValue(param, value);
    }

    // Update our local state
    params[param] = value;

    // Send the update
    updateCallback.call(this);
  },

  handleHomographyChange(event) {
    this.handleParamChange(
      event,
      this.homographyParams,
      ".homography-value",
      (_, value) => value.toFixed(4),
      this.sendHomographyUpdate,
    );
  },

  handleFisheyeChange(event) {
    this.handleParamChange(
      event,
      this.fisheyeParams,
      ".fisheye-value",
      (param, value) => {
        if (param === "fx" || param === "fy" || param === "cx" || param === "cy") {
          return Math.round(value);
        } else {
          return value.toFixed(4);
        }
      },
      this.sendFisheyeUpdate,
    );
  },

  handleVignettingPreset(event) {
    const preset = event.target.getAttribute("phx-value-preset");
    if (!preset) return;

    // Find the preset (case-insensitive)
    const presetKey = Object.keys(this.vignettePresets).find(
      (key) => key.toLowerCase() === preset.toLowerCase(),
    );

    if (presetKey) {
      // Update our local state
      if (presetKey === "None") {
        this.vignetteParams = null;
        this.sendRemoteControlMessage({ vignetting_correction: "none" });
        return;
      }

      // Get the preset values
      const presetValues = this.vignettePresets[presetKey];

      // Update our local state
      this.vignetteParams = cloneDeep(presetValues);

      // Update the UI sliders
      this.updateVignetteSliders(presetValues);

      // Send the update
      this.sendVignettingUpdate(this.vignetteParams);
    }
  },

  updateVignetteSliders(values) {
    // Update cx/cy sliders
    const cxSlider = this.el.querySelector('input[phx-value-param="vignette_cx"]');
    const cySlider = this.el.querySelector('input[phx-value-param="vignette_cy"]');
    const f1Slider = this.el.querySelector('input[phx-value-param="vignette_f1"]');
    const f2Slider = this.el.querySelector('input[phx-value-param="vignette_f2"]');
    const f3Slider = this.el.querySelector('input[phx-value-param="vignette_f3"]');
    const f4Slider = this.el.querySelector('input[phx-value-param="vignette_f4"]');

    // Update the sliders if they exist
    if (cxSlider && values.cx) {
      cxSlider.value = values.cx;
      this.updateVignetteValueDisplay("vignette_cx", values.cx);
    }

    if (cySlider && values.cy) {
      cySlider.value = values.cy;
      this.updateVignetteValueDisplay("vignette_cy", values.cy);
    }

    // Update the factor sliders if they exist
    if (f1Slider && values.factors && values.factors[0] !== undefined) {
      f1Slider.value = values.factors[0];
      this.updateVignetteValueDisplay("vignette_f1", values.factors[0]);
    }

    if (f2Slider && values.factors && values.factors[1] !== undefined) {
      f2Slider.value = values.factors[1];
      this.updateVignetteValueDisplay("vignette_f2", values.factors[1]);
    }

    if (f3Slider && values.factors && values.factors[2] !== undefined) {
      f3Slider.value = values.factors[2];
      this.updateVignetteValueDisplay("vignette_f3", values.factors[2]);
    }

    if (f4Slider && values.factors && values.factors[3] !== undefined) {
      f4Slider.value = values.factors[3];
      this.updateVignetteValueDisplay("vignette_f4", values.factors[3]);
    }

    // Also update the main vignetting slider if it exists
    const mainSlider = this.el.querySelector('input[phx-value-param="vignetting"]');
    if (mainSlider && values.factors && values.factors[2] !== undefined) {
      // Convert the factor[2] value back to a 0-1 range for the slider
      const sliderValue = values.factors[2] / 0.00005;
      mainSlider.value = sliderValue;

      const valueDisplay = this.el.querySelector(".vignetting-value");
      if (valueDisplay) {
        valueDisplay.textContent = sliderValue.toFixed(2);
      }
    }
  },

  applyPreset(event, presetAttr, presets, params, valueSelector, formatValue, updateCallback) {
    const presetName = event.target.getAttribute(presetAttr);
    const preset = presets[presetName];

    if (!preset) {
      if (typeof updateCallback === "function") {
        updateCallback.call(this, "None");
      }
      return;
    }

    // Update our local state
    Object.assign(params, preset);

    // Update UI displays without triggering events
    this.updateUIDisplaysOnly(preset, valueSelector, formatValue);

    // Set slider values without triggering events
    this.updateSlidersWithoutEvents(preset);

    // Send the update only once
    if (typeof updateCallback === "function") {
      updateCallback.call(this);
    }
  },

  applyHomographyPreset(event) {
    this.applyPreset(
      event,
      "data-homography-preset",
      this.homographyPresets,
      this.homographyParams,
      ".homography-value",
      (_, value) => value.toFixed(4),
      this.sendHomographyUpdate,
    );
  },

  applyFisheyePreset(event) {
    this.applyPreset(
      event,
      "data-fisheye-preset",
      this.fisheyePresets,
      this.fisheyeParams,
      ".fisheye-value",
      (key, value) => {
        if (key === "fx" || key === "fy" || key === "cx" || key === "cy") {
          return Math.round(value);
        } else {
          return value.toFixed(4);
        }
      },
      this.sendFisheyeUpdate,
    );
  },

  // ==========================================
  // Remote Control Communication Methods
  // ==========================================
  sendRemoteControlMessage(data) {
    if (!this.remoteControl || this.remoteControl.readyState !== "open") {
      return false;
    }

    this.remoteControl.send(
      JSON.stringify({
        type: "update_pipeline",
        ...data,
      }),
    );

    return true;
  },

  sendHomographyUpdate(preset) {
    if (preset === "None") {
      return this.sendRemoteControlMessage({ homography: "none" });
    }

    const homography = {
      matrix: {
        h00: this.homographyParams.h00 || 1.0,
        h01: this.homographyParams.h01 || 0.0,
        h02: this.homographyParams.h02 || 0.0,
        h10: this.homographyParams.h10 || 0.0,
        h11: this.homographyParams.h11 || 1.0,
        h12: this.homographyParams.h12 || 0.0,
        h20: this.homographyParams.h20 || 0.0,
        h21: this.homographyParams.h21 || 0.0,
        h22: this.homographyParams.h22 || 1.0,
      },
    };

    return this.sendRemoteControlMessage({ homography });
  },

  sendFisheyeUpdate(preset) {
    if (preset === "None") {
      return this.sendRemoteControlMessage({ distortion_model: "none" });
    }

    const distortionModel = {
      matrix: {
        cx: this.fisheyeParams.cx || 960,
        cy: this.fisheyeParams.cy || 540,
        fx: this.fisheyeParams.fx || 1050,
        fy: this.fisheyeParams.fy || 1050,
      },
      distortion: {
        k1: this.fisheyeParams.k1 || 0.0,
        k2: this.fisheyeParams.k2 || 0.0,
        k3: this.fisheyeParams.k3 || 0.0,
        k4: this.fisheyeParams.k4 || 0.0,
      },
      lens_model: this.fisheyeParams.lens_model || "equidistant",
    };

    return this.sendRemoteControlMessage({ distortion_model: distortionModel });
  },

  sendInterpolationUpdate(method) {
    return this.sendRemoteControlMessage({ interpolation_method: method });
  },

  sendVignettingUpdate(correction) {
    // If it's a string "none", handle it directly
    if (correction === "none") {
      return this.sendRemoteControlMessage({ vignetting_correction: "none" });
    }

    // If it's an object with vignetting parameters, use it directly
    if (typeof correction === "object" && correction !== null) {
      return this.sendRemoteControlMessage({ vignetting_correction: correction });
    }

    // If it's a number (from the slider), convert it to vignetting parameters
    if (typeof correction === "number") {
      return this.sendRemoteControlMessage({
        vignetting_correction: {
          cx: this.vignetteParams?.cx || 960,
          cy: this.vignetteParams?.cy || 540,
          factors: [1.0, 0.0, parseFloat(correction) * 0.00005, 0.0],
        },
      });
    }

    // If it's a preset name, use the preset values
    const preset = this.vignettePresets[correction];
    if (preset) {
      return this.sendRemoteControlMessage({ vignetting_correction: preset });
    }

    // Default fallback
    return this.sendRemoteControlMessage({
      vignetting_correction: {
        cx: 960,
        cy: 540,
        factors: [1.0, 0.0, 0.0, 0.0],
      },
    });
  },

  sendGridUpdate(grid) {
    return this.sendRemoteControlMessage({ grid });
  },

  // ==========================================
  // UI Update Methods
  // ==========================================
  updateUIFromParams(params, valueSelector, formatValue) {
    Object.entries(params).forEach(([key, value]) => {
      if (value === null || value === undefined) return;

      // Find the slider within this hook's element
      const slider = this.el.querySelector(`input[phx-value-param="${key}"]`);
      const valueDisplay = this.el.querySelector(`${valueSelector}-${key}`);

      if (slider) {
        slider.value = value;
        // Trigger an input event to update any listeners
        const event = new Event("input", { bubbles: true });
        slider.dispatchEvent(event);
      }

      if (valueDisplay) {
        valueDisplay.textContent = formatValue(key, value);
      }
    });
  },

  updateSlidersWithoutEvents(params) {
    Object.entries(params).forEach(([key, value]) => {
      if (value === null || value === undefined) return;

      const slider = this.el.querySelector(`input[phx-value-param="${key}"]`);
      if (slider) {
        // Update value without dispatching an event
        slider.value = value;
      }
    });
  },

  // Add handlers for each vignetting parameter
  handleVignetteCxChange(event) {
    const value = parseFloat(event.target.value);
    this.updateVignetteValueDisplay("vignette_cx", value);
    this.vignetteParams.cx = value;
    this.sendVignettingUpdate(this.vignetteParams);
  },

  handleVignetteCyChange(event) {
    const value = parseFloat(event.target.value);
    this.updateVignetteValueDisplay("vignette_cy", value);
    this.vignetteParams.cy = value;
    this.sendVignettingUpdate(this.vignetteParams);
  },

  handleVignetteF1Change(event) {
    const value = parseFloat(event.target.value);
    this.updateVignetteValueDisplay("vignette_f1", value);
    this.vignetteParams.factors[0] = value;
    this.sendVignettingUpdate(this.vignetteParams);
  },

  handleVignetteF2Change(event) {
    const value = parseFloat(event.target.value);
    this.updateVignetteValueDisplay("vignette_f2", value);
    this.vignetteParams.factors[1] = value;
    this.sendVignettingUpdate(this.vignetteParams);
  },

  handleVignetteF3Change(event) {
    const value = parseFloat(event.target.value);
    this.updateVignetteValueDisplay("vignette_f3", value);
    this.vignetteParams.factors[2] = value;
    this.sendVignettingUpdate(this.vignetteParams);
  },

  handleVignetteF4Change(event) {
    const value = parseFloat(event.target.value);
    this.updateVignetteValueDisplay("vignette_f4", value);
    this.vignetteParams.factors[3] = value;
    this.sendVignettingUpdate(this.vignetteParams);
  },

  updateVignetteValueDisplay(param, value) {
    const valueDisplay = this.el.querySelector(`.vignetting-value-${param}`);
    if (valueDisplay) {
      valueDisplay.textContent = value.toFixed(param.includes("f3") ? 6 : 2);
    }
  },

  // New method to update displays without triggering events
  updateUIDisplaysOnly(params, valueSelector, formatValue) {
    Object.entries(params).forEach(([key, value]) => {
      if (value === null || value === undefined) return;

      const valueDisplay = this.el.querySelector(`${valueSelector}-${key}`);
      if (valueDisplay) {
        valueDisplay.textContent = formatValue(key, value);
      }
    });
  },

  // ==========================================
  // Presets Configuration
  // ==========================================
  homographyPresets: {
    None: null,
    Identity: {
      h00: 1.0,
      h01: 0.0,
      h02: 0.0,
      h10: 0.0,
      h11: 1.0,
      h12: 0.0,
      h20: 0.0,
      h21: 0.0,
      h22: 1.0,
    },
    ZoomOut: {
      h00: 0.5,
      h01: 0.0,
      h02: 0.25,
      h10: 0.0,
      h11: 0.5,
      h12: 0.25,
      h20: 0.0,
      h21: 0.0,
      h22: 1.0,
    },
    ZoomIn: {
      h00: 1.5,
      h01: 0.0,
      h02: -0.25,
      h10: 0.0,
      h11: 1.5,
      h12: -0.25,
      h20: 0.0,
      h21: 0.0,
      h22: 1.0,
    },
    Rotate90: {
      h00: 0.0,
      h01: -1.0,
      h02: 960,
      h10: 1.0,
      h11: 0.0,
      h12: -960,
      h20: 0.0,
      h21: 0.0,
      h22: 1.0,
    },
    Rotate45: {
      h00: 0.7071,
      h01: -0.7071,
      h02: 960,
      h10: 0.7071,
      h11: 0.7071,
      h12: -960,
      h20: 0.0,
      h21: 0.0,
      h22: 1.0,
    },
    Skew: {
      h00: 1.0,
      h01: 0.3,
      h02: 0.0,
      h10: 0.3,
      h11: 1.0,
      h12: 0.0,
      h20: 0.0001,
      h21: 0.0001,
      h22: 1.0,
    },
    Perspective: {
      h00: 1.0,
      h01: 0.0,
      h02: 0.0,
      h10: 0.0,
      h11: 1.0,
      h12: 0.0,
      h20: 0.0005,
      h21: 0.0002,
      h22: 1.0,
    },
    Legacy: {
      h00: 1.3032250027964913,
      h01: -0.09462283211737542,
      h02: 833.9679493644677,
      h10: 0.0011345106742600556,
      h11: 1.2724003162329587,
      h12: 471.3314891049412,
      h20: -7.791504983134255e-6,
      h21: -3.7729095080353e-5,
      h22: 0.9808513446745678,
    },
  },

  fisheyePresets: {
    None: null,
    Equidistant: {
      fx: 1050,
      fy: 1050,
      cx: 960,
      cy: 540,
      k1: 0.0,
      k2: 0.0,
      k3: 0.0,
      k4: 0.0,
      lens_model: "equidistant",
    },
    Perspective: {
      fx: 1050,
      fy: 1050,
      cx: 960,
      cy: 540,
      k1: -0.3,
      k2: 0.0,
      k3: 0.0,
      k4: 0.0,
      lens_model: "perspective",
    },
    Orthographic: {
      fx: 1050,
      fy: 1050,
      cx: 960,
      cy: 540,
      k1: -0.3,
      k2: 0.0,
      k3: 0.0,
      k4: 0.0,
      lens_model: "orthographic",
    },
    Stereographic: {
      fx: 1050,
      fy: 1050,
      cx: 960,
      cy: 540,
      k1: 0.3,
      k2: 0.0,
      k3: 0.0,
      k4: 0.0,
      lens_model: "stereographic",
    },
    Equisolid: {
      fx: 1050,
      fy: 1050,
      cx: 960,
      cy: 540,
      k1: 0.3,
      k2: 0.0,
      k3: 0.0,
      k4: 0.0,
      lens_model: "equisolid",
    },
    "e-CON": {
      fx: 640.0244116242908,
      fy: 640.6073224855631,
      cx: 964.6754588353931,
      cy: 594.6080592724254,
      k1: 0.23634005663119811,
      k2: 0.488101067186476,
      k3: -0.4784404598448789,
      k4: 0.180376880890512,
      lens_model: "equidistant",
    },
    "Rokinon FE75MFT": {
      cx: 948.2805634981071,
      cy: 547.3677679389784,
      fx: 806.9338359737485,
      fy: 806.262431744419,
      k1: -0.011471216865893835,
      k2: -0.007111452987309034,
      k3: 0.010281834316738276,
      k4: -0.005961512364417588,
      lens_model: "equidistant",
    },
    "Example 1": {
      cx: 960,
      cy: 540,
      fx: 1000,
      fy: 1000,
      k1: -0.011471216865893835,
      k2: -0.007111452987309034,
      k3: 0.010281834316738276,
      k4: -0.005961512364417588,
    },
    "Example 2": {
      cx: 960,
      cy: 540,
      fx: 1000,
      fy: 1000,
      k1: 1.0,
      k2: 0.0,
      k3: 0.0,
      k4: 0.0,
    },
    "Example 3": {
      cx: 960,
      cy: 540,
      fx: 1050,
      fy: 1050,
      k1: 0.8,
      k2: 0.2,
      k3: 0.0,
      k4: 0.0,
    },
  },

  vignettePresets: {
    None: null,
    Mild: {
      cx: 960,
      cy: 540,
      factors: [1.0, 0.0, 0.000005, 0.0],
    },
    Medium: {
      cx: 960,
      cy: 540,
      factors: [1.0, 0.0, 0.00001, 0.0],
    },
    Strong: {
      cx: 960,
      cy: 540,
      factors: [1.0, 0.0, 0.00005, 0.0],
    },
  },
};
