<script>
  import RemoteControlTrack from "./TimelinePanelTracks/RemoteControlTrack.svelte";
  import UIStateTrack from "./TimelinePanelTracks/UIStateTrack.svelte";
  import WorkflowTrack from "./TimelinePanelTracks/WorkflowTrack.svelte";
  import NetworkTrafficTrack from "./TimelinePanelTracks/NetworkTrafficTrack.svelte";
  import AudioLevelTrack from "./TimelinePanelTracks/AudioLevelTrack.svelte";

  export let track;

  const trackEventsLeft = 26;

  $: trackHeaderSize = () => {
    const el = document.querySelector("[data-el-tracker-header]");
    if (!el) return 0;
    return el.getBoundingClientRect().width;
  };

  const components = {
    key: RemoteControlTrack,
    state: UIStateTrack,
    workflow: WorkflowTrack,
    network: NetworkTrafficTrack,
    audio: AudioLevelTrack,
  };

  const component = components[track.id];
</script>

{#if component}
  <svelte:component
    this={component}
    {track}
    {trackEventsLeft}
    trackHeaderSize={trackHeaderSize()}
  />
{/if}
