export const WindowPrompt = {
  mounted() {
    const id = this.el.id;
    const message = this.el.getAttribute("data-message") || "Enter value";

    this.el.addEventListener("click", () => {
      const input = window.prompt(message);
      if (input !== null) {
        this.pushEvent("window_prompt_input", { id, value: input });
      }
    });
  },
};
