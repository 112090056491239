<script>
  import { Icon, Folder, XMark, RectangleGroup } from "svelte-hero-icons";
  import { createEventDispatcher, onMount } from "svelte";

  export let value = null;
  export let isReadonly = false;
  export let label = "";
  export let store;
  export let optional = false;

  if (!store) {
    console.error("No screen store provided.");
  }

  const screens = store;
  let selectedScreen = null;
  let selectedRegion = null;
  let matchers = null;

  const dispatch = createEventDispatcher();

  $: matchers = selectedScreen ? selectedScreen.matchers : null;
  $: updateInternalState(value, $screens);

  function updateInternalState(value, screens) {
    selectedScreen = null;
    selectedRegion = null;

    if (value && value.screen) {
      selectedScreen = screens.find((s) => s._id === value.screen) || null;
      if (selectedScreen && value.region) {
        matchers = selectedScreen.matchers;
        selectedRegion = $matchers.find((r) => r._id === value.region) || null;
      }
    }
  }

  function selectScreen(screen) {
    if (isReadonly) return;
    selectedScreen = screen;
    selectedRegion = null;
    dispatch("input", { screen: selectedScreen._id, region: null });
  }

  function selectRegion(region) {
    if (isReadonly) return;
    selectedRegion = region;
    dispatch("input", { screen: selectedScreen._id, region: region._id });
  }

  function clearRegion() {
    if (isReadonly) return;
    selectedRegion = null;
    dispatch("input", { screen: selectedScreen._id, region: null });
  }

  function clearScreen() {
    if (isReadonly) return;
    selectedRegion = null;
    selectedScreen = null;
    dispatch("input", null);
  }

  onMount(() => {
    updateInternalState(value, $screens);
  });
</script>

<!-- svelte-ignore a11y-label-has-associated-control -->
{#if label}<label class="block text-gray-500 text-xs mb-2">{label}</label>{/if}

<div class="border rounded-lg p-2 max-w-2xl flex" class:opacity-50={isReadonly}>
  <div class="w-1/2 pr-2 border-r">
    <h3 class="font-semibold text-xs mb-1">Screens</h3>
    <ul class="space-y-0.5 max-h-48 overflow-y-auto">
      {#each $screens as screen (screen._id)}
        <li
          class="flex items-center hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
          class:bg-gray-200={selectedScreen === screen}
        >
          <button
            class="flex-grow text-left flex items-center px-1.5 py-0.5 rounded-sm text-xs overflow-hidden"
            on:click={() => selectScreen(screen)}
            disabled={isReadonly}
          >
            <Icon src={Folder} class="w-3 h-3 text-gray-600 mr-1 flex-shrink-0" />
            <span class="truncate">{screen.name || "Unnamed Screen"}</span>
          </button>
          {#if optional && selectedScreen === screen}
            <button
              class="flex-shrink-0 text-xs text-gray-500 hover:text-gray-700 focus:outline-none"
              on:click={clearScreen}
            >
              <Icon src={XMark} class="w-4 h-4" />
            </button>
          {/if}
        </li>
      {/each}
    </ul>
  </div>

  <div class="w-1/2 pl-2">
    <div class="flex items-center justify-between">
      <h3 class="font-semibold text-xs mb-1">Regions</h3>
      <slot name="dynamic" />
    </div>
    {#if matchers}
      <ul class="space-y-0.5 max-h-48 overflow-y-auto">
        {#each $matchers as region (region._id)}
          <li
            class="flex items-center hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
            class:bg-gray-200={selectedRegion === region}
          >
            <button
              class="flex-grow text-left flex items-center px-1.5 py-0.5 rounded-sm text-xs overflow-hidden"
              on:click={() => selectRegion(region)}
              disabled={isReadonly}
            >
              <Icon src={RectangleGroup} class="w-3 h-3 text-gray-600 mr-1 flex-shrink-0" />
              <span class="truncate">{region.name || "Unnamed Region"}</span>
            </button>
            {#if optional && selectedRegion === region}
              <button
                class="flex-shrink-0 text-xs text-gray-500 hover:text-gray-700 focus:outline-none"
                on:click={clearRegion}
              >
                <Icon src={XMark} class="w-4 h-4" />
              </button>
            {/if}
          </li>
        {/each}
      </ul>
    {:else}
      <p class="text-xs text-gray-500">Please select a screen</p>
    {/if}
  </div>

  <slot name="docs" />
</div>
