<script>
  import { ClipboardDocumentList } from "svelte-hero-icons";
  import Collapsible from "./Collapsible.svelte";
  import MetricsRecord from "./MetricsRecord.svelte";

  export let metrics;
</script>

{#each $metrics as metric}
  <Collapsible icon={ClipboardDocumentList} isOpen={false} variant="inline">
    <div slot="title" class="flex items-center justify-between w-full text-xs pr-2">
      <code>{metric.name}</code>
      <code>{metric.value}</code>
    </div>
    <MetricsRecord {metric} />
  </Collapsible>
{/each}
