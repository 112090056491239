<script>
  import { Icon, Folder, XMark, RectangleGroup } from "svelte-hero-icons";
  import { createEventDispatcher, onMount } from "svelte";
  import { get } from "$lib/utils/shim";

  export let value = null;
  export let isReadonly = false;
  export let label = "";
  export let store;
  export let optional = false;
  export let multipleRegion = false;
  export let multipleScreen = false;

  if (!store) {
    console.error("No screen store provided.");
  }

  const screens = store;
  let currentViewingScreen = null;
  let selectedScreen = new Set();
  let selectedRegion = new Set();
  let selected = new Set();
  let matchers = null;
  let canCancelSelection = optional || multipleRegion;

  const dispatch = createEventDispatcher();

  $: matchers = currentViewingScreen ? currentViewingScreen.matchers : null;
  $: updateInternalState(value, $screens);

  function updateInternalState(value, screens) {
    selectedScreen = new Set();
    selectedRegion = new Set();
    selected = new Set();

    // ensure old values are wrapped in an array
    if (value instanceof Object && !(value instanceof Array)) {
      value = Array(value);
    }

    if (value) {
      for (const v of value) {
        const currentScreen = screens.find((s) => s._id === v.screen) || null;
        if (currentScreen && v.region) {
          const currentMatchers = get(currentScreen.matchers);
          const region = currentMatchers.find((r) => r._id === v.region);
          if (region) {
            if (
              multipleScreen === false &&
              selectedScreen.size === 1 &&
              !selectedScreen.has(currentScreen._id)
            ) {
              continue;
            }
            selected.add(`${v.screen}|${v.region}`);
            selectedScreen.add(currentScreen._id);
            selectedRegion.add(region._id);
            if (!currentViewingScreen) {
              currentViewingScreen = currentScreen;
              matchers = currentScreen.matchers;
            }

            if (multipleRegion === false && selected.size === 1) {
              break;
            }
          }
        }
      }
    }
  }

  function selectScreen(screen) {
    if (isReadonly) return;
    currentViewingScreen = screen;
    matchers = currentViewingScreen ? currentViewingScreen.matchers : null;
  }

  function selectRegion(region) {
    if (isReadonly) return;

    if (multipleRegion === false) {
      selected = new Set([`${currentViewingScreen._id}|${region._id}`]);
      selectedScreen = new Set([currentViewingScreen._id]);
      selectedRegion = new Set([region._id]);
    } else {
      if (multipleScreen === false) {
        if (
          selectedScreen.size === 0 ||
          (selectedScreen.size === 1 && selectedScreen.has(currentViewingScreen._id))
        ) {
          selected.add(`${currentViewingScreen._id}|${region._id}`);
          selectedScreen.add(currentViewingScreen._id);
          selectedRegion.add(region._id);
        } else {
          selected = new Set([`${currentViewingScreen._id}|${region._id}`]);
          selectedScreen = new Set([currentViewingScreen._id]);
          selectedRegion = new Set([region._id]);
        }
      } else {
        selected.add(`${currentViewingScreen._id}|${region._id}`);
        selectedScreen.add(currentViewingScreen._id);
        selectedRegion.add(region._id);
      }
    }

    transformAndDispatch();
  }

  function clearRegion(region) {
    if (isReadonly) return;

    if (currentViewingScreen) {
      selected.delete(`${currentViewingScreen._id}|${region._id}`);
      selectedRegion.delete(region._id);
      if (selectedRegion.size === 0) selectedScreen.delete(currentViewingScreen._id);
      transformAndDispatch();
    }
  }

  function clearScreen(screen) {
    if (isReadonly) return;

    const currentScreen = $screens.find((s) => s._id === screen._id) || null;
    if (currentScreen) {
      const currentMatchers = get(currentScreen.matchers);
      for (const r of currentMatchers) {
        selected.delete(`${screen._id}|${r._id}`);
        selectedRegion.delete(r._id);
      }
    }
    selectedScreen.delete(screen._id);

    transformAndDispatch();
  }

  function transformAndDispatch() {
    let s = Array();
    let dispatchScreens = new Set();
    for (const element of selected) {
      const parts = element.split("|");
      if (parts.length === 2) {
        if (s.length === 1 && multipleRegion === false) {
          break;
        } else if (
          multipleScreen === false &&
          dispatchScreens.size === 1 &&
          !dispatchScreens.has(parts[0])
        ) {
          break;
        } else {
          s.push({ screen: parts[0], region: parts[1] });
          dispatchScreens.add(parts[0]);
        }
      } else {
        console.error("Invalid selected region:", element);
      }
    }

    // this makes it easier for all NoCode blocks that does not support
    // multiple regions
    if (multipleRegion === false) {
      if (s.length === 1) {
        dispatch("input", s[0]);
      } else {
        dispatch("input", null);
      }
    } else {
      if (s.length > 0) {
        dispatch("input", s);
      } else {
        dispatch("input", null);
      }
    }
  }

  onMount(() => {
    updateInternalState(value, $screens);
  });
</script>

<!-- svelte-ignore a11y-label-has-associated-control -->
{#if label}<label class="block text-gray-500 text-xs mb-2">{label}</label>{/if}

<div class="border rounded-lg p-2 max-w-2xl flex" class:opacity-50={isReadonly}>
  <div class="w-1/2 pr-2 border-r">
    <h3 class="font-semibold text-xs mb-1">Screens</h3>
    <ul class="space-y-0.5 max-h-48 overflow-y-auto">
      {#each $screens as screen (screen._id)}
        <li
          class="flex items-center hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
          class:bg-gray-200={selectedScreen.has(screen._id)}
          class:bg-blue-100={currentViewingScreen === screen}
        >
          <button
            class="flex-grow text-left flex items-center px-1.5 py-0.5 rounded-sm text-xs overflow-hidden"
            on:click={() => selectScreen(screen)}
            disabled={isReadonly}
          >
            <Icon src={Folder} class="w-3 h-3 text-gray-600 mr-1 flex-shrink-0" />
            <span class="truncate">{screen.name || "Unnamed Screen"}</span>
          </button>
          {#if canCancelSelection && (selectedScreen.has(screen._id) || currentViewingScreen === screen)}
            <button
              class="flex-shrink-0 text-xs text-gray-500 hover:text-gray-700 focus:outline-none"
              on:click={clearScreen(screen)}
            >
              <Icon src={XMark} class="w-4 h-4" />
            </button>
          {/if}
        </li>
      {/each}
    </ul>
  </div>

  <div class="w-1/2 pl-2">
    <div class="flex items-center justify-between">
      <h3 class="font-semibold text-xs mb-1">Regions</h3>
      <slot name="dynamic" />
    </div>
    {#if matchers}
      <ul class="space-y-0.5 max-h-48 overflow-y-auto">
        {#each $matchers as region (region._id)}
          <li
            class="flex items-center hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
            class:bg-gray-200={selectedRegion.has(region._id)}
          >
            <button
              class="flex-grow text-left flex items-center px-1.5 py-0.5 rounded-sm text-xs overflow-hidden"
              on:click={() => selectRegion(region)}
              disabled={isReadonly}
            >
              <Icon src={RectangleGroup} class="w-3 h-3 text-gray-600 mr-1 flex-shrink-0" />
              <span class="truncate">{region.name || "Unnamed Region"}</span>
            </button>
            {#if canCancelSelection && selectedRegion.has(region._id)}
              <button
                class="flex-shrink-0 text-xs text-gray-500 hover:text-gray-700 focus:outline-none"
                on:click={clearRegion(region)}
              >
                <Icon src={XMark} class="w-4 h-4" />
              </button>
            {/if}
          </li>
        {/each}
      </ul>
    {:else}
      <p class="text-xs text-gray-500">Please select a screen</p>
    {/if}
  </div>

  <slot name="docs" />
</div>
