<script>
  import { ChevronDown, Icon } from "svelte-hero-icons";
  import { clickOutside } from "$utils/clickOutside";

  export let buttonText = "";
  export let buttonClass = "";
  export let onOpen = () => {}; // Dropdown about to open (can be delayed)
  export let onClick = () => {}; // Click on "main button"
  export let disabled = false;
  export let hasSeperateDropdown = false;

  let showDropdown = false;

  async function toggleDropdown() {
    if (!showDropdown) {
      await onOpen();
    }
    showDropdown = !showDropdown;
  }

  function closeDropdown() {
    showDropdown = false;
  }
</script>

<div class="relative inline-block" use:clickOutside on:clickoutside={closeDropdown}>
  <div
    class="flex items-stretch text-sm bg-gray-100 text-gray-800 border border-gray-300 rounded-md"
    class:hover:bg-gray-200={!hasSeperateDropdown}
  >
    <button
      class="flex px-2 py-0.5 items-center border-gray-300 rounded-md-l hover:bg-gray-200 disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-gray-100 {buttonClass}"
      class:border-r={hasSeperateDropdown}
      {disabled}
      on:click={(e) => {
        if (hasSeperateDropdown) {
          onClick(e, { toggleDropdown });
        } else {
          toggleDropdown();
        }
      }}
    >
      <slot name="prefix" {disabled} />
      {#if buttonText}
        <span class="truncate max-w-32">{buttonText}</span>
      {/if}
    </button>

    {#if hasSeperateDropdown}
      <button
        class="flex px-1 py-0.5 items-center cursor-pointer rounded-md-r hover:bg-gray-200"
        on:click={toggleDropdown}
      >
        <Icon src={ChevronDown} class="w-4 h-4" />
      </button>
    {/if}
  </div>

  {#if showDropdown}
    <div class="absolute z-50 right-0 top-6 mt-2 p-4 min-w-80 border bg-white rounded-md shadow">
      <slot name="content" close={closeDropdown} />
    </div>
  {/if}
</div>
