<script>
  import { filetree } from "../Automator/store";
  import { createEventDispatcher } from "svelte";
  import { Icon, DocumentText, XMark } from "svelte-hero-icons";

  export let value = null;
  export let isReadonly = false;
  export let label = "";
  export let optional = false;
  export let includeMainFiles = false;

  $: items = filetree.itemsAsFlatList;

  const dispatch = createEventDispatcher();

  function handleSelect(workflow) {
    if (isReadonly) return;
    dispatch("input", workflow.id);
  }

  function clearSelection() {
    if (isReadonly) return;
    dispatch("input", null);
  }
</script>

<!-- Replace the label with proper form control -->
{#if label}
  <label for="workflow-group" class="block text-gray-500 text-xs mb-2">{label}</label>
{/if}

<div class="border rounded-lg p-2 max-w-md" class:opacity-50={isReadonly}>
  <div class="flex justify-between items-center mb-1">
    <h3 class="font-semibold text-xs">Workflows</h3>
    <div class="flex items-center">
      {#if optional && value}
        <button
          class="text-xs text-gray-500 hover:text-gray-700 focus:outline-none"
          on:click={clearSelection}
          disabled={isReadonly}
        >
          <Icon src={XMark} class="w-4 h-4" />
        </button>
      {/if}
      <slot name="dynamic" />
    </div>
  </div>
  <ul class="space-y-0.5 max-h-48 overflow-y-auto" id="workflow-group" role="radiogroup">
    {#each $items as workflow (workflow.id)}
      {#if includeMainFiles || (workflow.meta?.type === "library" && !workflow.is_folder)}
        <li>
          <label
            class="w-full text-left flex items-center px-1.5 py-0.5 rounded-sm hover:bg-gray-100 focus:outline-none focus:bg-gray-100 text-xs cursor-pointer"
          >
            <input
              type="radio"
              name="workflow-selection"
              checked={value === workflow.id}
              class="mr-1.5 h-3 w-3"
              on:change={() => handleSelect(workflow)}
              disabled={isReadonly}
            />
            <Icon src={DocumentText} class="w-3 h-3 text-gray-600 mr-1 flex-shrink-0" />
            <span class="truncate">{workflow.name}</span>
          </label>
        </li>
      {/if}
    {/each}
  </ul>
</div>
