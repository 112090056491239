export function msToHumanReadableTime(timeMs) {
  const timeSec = timeMs / 1000;

  const formatTime = (value, unit) => {
    return `${value.toFixed(2).replace(/\.00$/, "")} ${unit}`;
  };

  if (timeMs < 1000) {
    return formatTime(timeMs, "ms");
  } else if (timeSec < 60) {
    return formatTime(timeSec, "s");
  } else if (timeSec < 3600) {
    return formatTime(timeSec / 60, "m");
  } else {
    return formatTime(timeSec / 3600, "h");
  }
}

export function formatSeconds(seconds) {
  const mins = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${mins}:${secs.toString().padStart(2, "0")}`;
}
