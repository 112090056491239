// eslint-disable-next-line no-unused-vars
import uPlot from "uplot";

import { turboColormap } from "../../utils/gradients";

function setDeviceColorCSSVar(device_id, color) {
  const variable = `--device-color-${device_id}`;
  document.documentElement.style.setProperty(variable, color);
}

function setGlobalColors(labels) {
  let color;
  Object.keys(labels).forEach((device_id, idx) => {
    color = colorByIdx(labels, idx);
    setDeviceColorCSSVar(device_id, color);
  });
}

export function colorByIdx(labels, idx) {
  return turboColormap(idx / Object.values(labels).length);
}

export function globalColor({ labels }) {
  function init(_u) {
    setGlobalColors(labels);
  }

  return {
    hooks: {
      init,
    },
  };
}
