import { writable, derived, get } from "$utils/shim";
import { ImageManager } from "$utils/imageManager";

const HIDDEN_EVENTS = [
  "workflow_set_progress",
  "workflow_audio_level",
  "workflow_network_metrics",
  "audio_level",
  "network_metrics",
  "screen_captured",
  "accumulated_motion",
  "motion",
  "workflow_capture",
];

export function createWorkflowExecutionStore(liveStore) {
  const imageManager = ImageManager.getInstance();
  const stepStates = writable({});
  const eventLog = writable([]);
  const currentStep = writable(null);
  const activeTab = writable("tree");
  const badges = writable(0); // Used for updating badges
  const mode = writable("idle");
  const isExecuting = writable(false);
  const persistLogs = writable(false);

  function updateBadges() {
    badges.update((v) => v + 1);
  }

  function updateStepState(stepId, updates) {
    stepStates.update((states) => ({
      ...states,
      [stepId]: {
        ...(states[stepId] || {
          status: null,
          isValid: true,
          validations: {},
          duration: 0,
          errors: [],
          progress: 0,
          tags: {},
        }),
        ...updates,
        inputs: {
          ...(states[stepId]?.inputs || {}),
          ...(updates.inputs || {}),
        },
        outputs: {
          ...(states[stepId]?.outputs || {}),
          ...(updates.outputs || {}),
        },
      },
    }));

    if ("status" in updates || "isValid" in updates) {
      updateBadges();
    }
  }

  function clearAllStatuses() {
    stepStates.update((states) =>
      Object.fromEntries(
        Object.entries(states).map(([stepId, state]) => [stepId, { ...state, status: null }]),
      ),
    );
    updateBadges();
  }

  function clearAllValidations() {
    stepStates.update((states) =>
      Object.fromEntries(
        Object.entries(states).map(([stepId, state]) => [
          stepId,
          { ...state, isValid: true, validations: {} },
        ]),
      ),
    );
    updateBadges();
  }

  function setExecutionMode() {
    isExecuting.set(true);
  }

  function setEditMode() {
    isExecuting.set(false);
  }

  function failPendingSteps() {
    // Any steps that are stuck in the "in_progress" state once
    // the Automation is completed should be marked as failed
    const steps = get(stepStates);
    Object.keys(steps)
      .filter((stepId) => steps[stepId].status === "in_progress")
      .forEach((stepId) => updateStepState(stepId, { status: "failed" }));
  }

  function workflowFailedToStart(reason) {
    switch (reason) {
      case "already_running":
        break;
      case "empty_workflow":
      case "no_workflow_selected":
      case "no_workflow_found":
        activeTab.set("tree");
        setEditMode();
        break;
      case "failed_to_run":
        setEditMode();
        activeTab.set("events");
        break;
      default:
        console.warn("Unknown reason for workflow failure:", reason);
        setEditMode();
        break;
    }
  }

  async function processEvent(eventWithImages) {
    const event = await imageManager.processEvent(eventWithImages);

    if (!HIDDEN_EVENTS.includes(event.type)) {
      eventLog.update((events) => [...events, event]);
    }

    switch (event.type) {
      case "automation_started":
        setExecutionMode();
        break;
      case "automation_ended":
        failPendingSteps();
        setEditMode();
        break;
      case "clear_validation":
        clearAllValidations();
        break;
    }

    const { step_id: stepId } = event.data;
    if (!stepId) return; // Ignore events without stepId

    switch (event.type) {
      case "workflow_begin_step":
        updateStepState(stepId, {
          status: "in_progress",
        });
        break;
      case "workflow_capture": {
        if (event.data.capture_type === "start") {
          updateStepState(stepId, {
            status: "in_progress",
            outputs: { captureStart: { image: event.data.image } },
          });
        } else {
          updateStepState(stepId, {
            status: "in_progress",
            outputs: { captureEnd: { image: event.data.image } },
          });
        }
        break;
      }
      case "workflow_end_step":
        updateStepState(stepId, {
          status: "succeeded",
          outputs: { ...event.data.outputs },
          result: event.data.result,
        });
        break;
      case "workflow_fail_step":
        updateStepState(stepId, {
          status: "failed",
          errors: [event.data.reason],
          outputs: { ...event.data.outputs },
        });
        break;
      case "workflow_log_input":
        updateStepState(stepId, {
          inputs: { [event.data.key]: event.data.value },
        });
        break;
      case "workflow_log_output":
        updateStepState(stepId, {
          outputs: { [event.data.key]: event.data.value },
        });
        break;
      case "workflow_log_error":
        updateStepState(stepId, {
          status: "failed",
          errors: [event.data.message],
        });
        break;
      case "workflow_set_progress":
        updateStepState(stepId, {
          progress: event.data.progress,
        });
        break;
      case "validation":
        updateStepState(stepId, {
          isValid: event.data.isValid,
          invalidInputs: event.data.invalidInputs,
        });
        break;
    }
  }

  return {
    initialize() {},
    mode: mode,
    subscribe: stepStates.subscribe,
    badges: { subscribe: badges.subscribe },
    eventLog: { subscribe: eventLog.subscribe },
    currentStep: { subscribe: currentStep.subscribe },
    activeTab: { subscribe: activeTab.subscribe },
    isExecuting,
    stepState: (stepId) => derived(stepStates, ($stepStates) => $stepStates[stepId]),
    processEvent: (event) => processEvent(event),
    workflowFailedToStart: (reason) => workflowFailedToStart(reason),
    stepValidator: (stepId) => {
      const stepState = get(stepStates)[stepId] || { isValid: true };
      return stepState.isValid;
    },
    stepStatus: (stepId) => {
      const stepState = get(stepStates)[stepId] || { status: null };
      return stepState.status;
    },
    setStatus: (stepId, status) => updateStepState(stepId, { status }),
    setValidity: (stepId, isValid) => updateStepState(stepId, { isValid }),
    setCurrentStep(stepIdOrNull) {
      currentStep.set(stepIdOrNull);
    },
    clearStatus: (stepId) => updateStepState(stepId, { status: null }),
    clearAllStatuses: () => clearAllStatuses(),
    clearAllValidations: () => clearAllValidations(),
    clearLogs() {
      eventLog.set([]);
      stepStates.set({});
    },
    clearStepStates() {
      stepStates.set({});
    },
    clearBeforeExecution() {
      this.clearAllStatuses();
      this.clearStepStates();
      if (!get(persistLogs)) this.clearLogs();
    },
    triggerExecuteAll(opts = {}) {
      const { soak, godMode, userTag, deviceFilter, requestTimeout } = opts;
      clearAllStatuses();
      const live = get(liveStore);
      live.pushEvent("execute_all", { soak, godMode, userTag, deviceFilter, requestTimeout });
    },
    triggerDebug(app_id, device_id) {
      const live = get(liveStore);
      this.clearBeforeExecution();
      live.pushEvent("debug_run", { type: "debug", app_id, device_id });
    },
    triggerTrace(app_id, device_id) {
      const live = get(liveStore);
      this.clearBeforeExecution();
      live.pushEvent("debug_run", { type: "trace", app_id, device_id });
      activeTab.set("events");
    },
    deleteTestBatch(batch_id) {
      const live = get(liveStore);
      live.pushEvent("delete_batch", { batch_id });
    },
    deleteTestJob(batch_id, job_id) {
      const live = get(liveStore);
      live.pushEvent("delete_job", { batch_id, job_id });
    },
    cancelJob(job_id) {
      const live = get(liveStore);
      live.pushEvent("cancel_job", { job_id });
    },
    setPersistLogs(value) {
      persistLogs.set(value);
    },
    setActiveTab(tab) {
      activeTab.set(tab);
    },
    reset() {
      activeTab.set("tree");
      stepStates.set({});
      eventLog.set([]);
      currentStep.set(null);
      isExecuting.set(false);
      badges.set(0);
      mode.set("idle");
    },
  };
}
