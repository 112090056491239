export const AlignBottomIcon = {
  default: {
    a: {
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
    },
    path: [
      {
        d: "M5 20.25L19 20.25",
        stroke: "#0F172A",
        strokeWidth: "1.5",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      },
      {
        d: "M7 17.75L7 3.75L11 3.75L11 17.75H7Z",
        fill: "#0F172A",
      },
      {
        d: "M13 17.75L13 11.75L17 11.75L17 17.75H13Z",
        fill: "#0F172A",
      },
    ],
  },
};
