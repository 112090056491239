<script>
  import { Icon, Variable } from "svelte-hero-icons";
  import { definitions } from "../Automator/store";
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  export let selectedStep = null;
  export let selectedOutput = null;
  export let inputType = null;

  $: stepDefinition =
    selectedStep && selectedStep.type ? definitions.getDefinition(selectedStep.type) : null;

  function compatibleOutputs(outputs) {
    if (!inputType) return outputs;
    const o = Object.entries(outputs).filter(([_, value]) => value.type === inputType);
    if (o.length == 0) return outputs;
    return Object.fromEntries(o);
  }

  function handleSelect(outputName) {
    dispatch("select", outputName);
  }
</script>

<div class="w-1/2 pl-2">
  <h3 class="font-semibold mb-1">
    <span>Variables</span>
  </h3>
  {#if stepDefinition && stepDefinition.outputs}
    <ul class="space-y-0.5 max-h-48 overflow-y-auto">
      {#each Object.keys(compatibleOutputs(stepDefinition.outputs)) as outputName}
        <li>
          <button
            class="w-full text-left flex items-center px-1.5 py-0.5 rounded-sm hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
            class:bg-gray-200={selectedOutput === outputName}
            on:click={() => handleSelect(outputName)}
          >
            <Icon src={Variable} class="w-3 h-3 text-gray-600 mr-1 flex-shrink-0" />
            <span class="truncate">{outputName}</span>
          </button>
        </li>
      {/each}
    </ul>
  {:else}
    <p class="text-gray-500 italic">Select a step to view outputs</p>
  {/if}
</div>
