<script>
  import Comment from "./Comment.svelte";
  import { createEventDispatcher } from "svelte";
  import { user } from "../store";

  export let comments = [];
  export let isReadonly = false;

  let currentUserNickname = $user.nickname;

  const dispatch = createEventDispatcher();
  let showTextarea = false;
  let newCommentText = "";

  function addComment() {
    if (newCommentText.trim()) {
      dispatch("addComment", {
        text: newCommentText.trim(),
        author: currentUserNickname,
        resolved: false,
      });
      newCommentText = "";
      showTextarea = false;
    }
  }

  export function toggleTextInput() {
    if (!isReadonly) {
      showTextarea = !showTextarea;
    }
  }
</script>

<div class="mb-2 mt-2">
  {#if comments.length > 0}
    {#each comments as comment, i}
      <Comment
        {...comment}
        index={i}
        {isReadonly}
        on:deleteComment={(e) => dispatch("deleteComment", e.detail)}
        on:resolveComment={(e) => dispatch("resolveComment", e.detail)}
      />
    {/each}
    <!-- svelte-ignore a11y-invalid-attribute -->
    <a
      href="#"
      on:click|preventDefault={toggleTextInput}
      class="text-xs text-blue-600 cursor-pointer hover:underline"
    >
      Reply
    </a>
  {/if}

  {#if showTextarea}
    <textarea
      bind:value={newCommentText}
      rows={2}
      placeholder="Add a comment..."
      class="text-xs w-full p-1 border border-gray-300 rounded-sm mt-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
    />
    <button on:click={addComment} class="mb-2 text-xs text-blue-600 cursor-pointer hover:underline">
      Add Comment
    </button>
  {/if}
</div>
