export const HLSVideo = {
  videoURL() {
    return this.el.dataset.url;
  },

  mounted() {
    this.initPlayer();
  },

  updated() {
    console.log(`Updated HLS video: ${this.videoURL()}`);
    this.player.src = this.videoURL();
  },

  async initPlayer() {
    await import("@mux/mux-player");

    const url = this.videoURL();
    console.log(`Initializing Mux video: ${url}`);

    this.player = this.el.querySelector("mux-player");

    // TODO: Enable for live streams
    // this.player.setAttribute("stream-type", "live");

    this.player.muted = true;
    this.player.src = url;
  },
};
