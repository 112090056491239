<script>
  import DataGrid from "../DataGrid/DataGrid.svelte";

  function findMotionAtTime(accumulatedMotion, time_ms) {
    if (!accumulatedMotion) {
      return { current: null, previous: null };
    }

    let previous = null;
    for (const motionIndex of Object.keys(accumulatedMotion)) {
      const motion = accumulatedMotion[motionIndex];
      if (motion.pts <= time_ms && time_ms <= motion.pts + motion.duration) {
        return { current: motion, previous: previous || 0.0 };
      }
      previous = motion;
    }

    return { current: null, previous: null };
  }

  export let step;
  export let time;

  $: ({ current: curMotion, previous: prevMotion } = findMotionAtTime(
    step?.accumulatedMotion,
    time * 1000,
  ));

  $: deltaHamming = curMotion && prevMotion ? curMotion.hamming - prevMotion.hamming : null;
  $: deltaEuclidean = curMotion && prevMotion ? curMotion.euclidean - prevMotion.euclidean : null;
</script>

<DataGrid
  data={[
    {
      id: "accumulated_title",
      label: "Accumulated",
      value: "",
      type: "text",
    },
    {
      id: "hamming",
      label: "Hamming",
      value: curMotion?.hamming ?? null,
      type: "numeric",
      props: { precision: 0 },
    },
    {
      id: "euclidean",
      label: "Euclidean",
      value: curMotion?.euclidean ?? null,
      type: "numeric",
      props: { precision: 2 },
    },
    {
      id: "delta_title",
      label: "Single",
      value: curMotion?.single_frame_distance ?? null,
      type: "numeric",
      props: { precision: 2 },
    },
    {
      id: "delta_hamming",
      label: "Δ Hamming",
      value: deltaHamming,
      type: "numeric",
      props: { precision: 0 },
    },
    {
      id: "delta_euclidean",
      label: "Δ Euclidean",
      value: deltaEuclidean,
      type: "numeric",
      props: { precision: 2 },
    },
  ]}
/>
