export function createBlockInclusivityExtension() {
  return {
    id: "blockInclusivity",
    createForStep(_parentElement, view, stepContext, _componentContext) {
      return {
        view: null,
        update(_previusBlockResult) {
          const isSkipped = Boolean(stepContext.step?.metadata?.["skip"]);

          if (isSkipped) {
            view.g.classList.add("sqd-step-hidden");
          } else {
            view.g.classList.remove("sqd-step-hidden");
          }

          return null;
        },
        resolveClick(_click) {
          return null;
        },
      };
    },

    createStartValue() {
      return null;
    },
  };
}
