<script>
  import { fade } from "svelte/transition";
  import { cubicOut } from "svelte/easing";
  import { XMark, Icon } from "svelte-hero-icons";
  import { clickOutside } from "$utils/clickOutside";
  import Portal from "$lib/Elements/Portal.svelte";
  import { createEventDispatcher } from "svelte";

  export let open = false;
  export let title = "";
  export let showClose = true;
  export let maxWidth = "64rem";

  const dispatch = createEventDispatcher();

  function handleClose() {
    dispatch("close");
  }

  function handleKeydown(e) {
    if (open && e.key === "Escape") handleClose();
  }
</script>

<svelte:window on:keydown={handleKeydown} />

{#if open}
  <Portal target="body">
    <div
      transition:fade={{ duration: 400, easing: cubicOut }}
      class="z-50 fixed inset-0 bg-zinc-500/75 flex justify-center items-center"
    >
      <div
        use:clickOutside
        on:clickoutside={handleClose}
        class={$$props.class || "bg-white p-6 rounded-lg w-full"}
        style="max-width: {maxWidth}"
      >
        {#if title || showClose}
          <div class="flex justify-between items-center mb-4">
            {#if title}
              <h2 class="text-lg font-semibold text-zinc-800">{title}</h2>
            {/if}
            {#if showClose}
              <button class="opacity-40 hover:opacity-60" on:click={handleClose}>
                <Icon src={XMark} class="w-6 h-6" />
              </button>
            {/if}
          </div>
        {/if}
        <slot />
      </div>
    </div>
  </Portal>
{/if}
