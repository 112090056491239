<script>
  import { onMount, onDestroy } from "svelte";
  import { imageManager } from "$lib/shared_stores";

  export let message;
  export let isPreview = false;
  export let preferredPosition = "top"; // or bottom

  let tooltipElement;
  let tooltipContent;
  let visible = false;
  let tooltipX = 0;
  let tooltipY = 0;

  $: messageLines = message.split("\n");

  function showTooltip(e) {
    visible = true;
    updatePosition(e);
  }

  function hideTooltip() {
    visible = false;
  }

  function updatePosition(e) {
    const touch = e.touches ? e.touches[0] : e;
    const viewport = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    tooltipX = touch.clientX + 10;
    if (preferredPosition === "top") {
      tooltipY = touch.clientY - 30;
    } else {
      tooltipY = touch.clientY + 10;
    }

    if (visible && tooltipContent) {
      const tooltipRect = tooltipContent.getBoundingClientRect();

      if (tooltipX + tooltipRect.width > viewport.width) {
        tooltipX = touch.clientX - tooltipRect.width - 5;
      }
      if (preferredPosition === "top" || tooltipY + tooltipRect.height > viewport.height) {
        tooltipY = touch.clientY - tooltipRect.height - 5;
      }

      tooltipX = Math.max(0, tooltipX);
      tooltipY = Math.max(0, tooltipY);
    }
  }

  onMount(() => {
    if (tooltipElement) {
      tooltipElement.addEventListener("mousemove", updatePosition);
      tooltipElement.addEventListener("touchmove", updatePosition);
    }
  });

  onDestroy(() => {
    if (tooltipElement) {
      tooltipElement.removeEventListener("mousemove", updatePosition);
      tooltipElement.removeEventListener("touchmove", updatePosition);
    }
  });
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<div
  class="flex items-center {$$props.class || ''}"
  style={$$props.style || ""}
  bind:this={tooltipElement}
  on:mouseenter={showTooltip}
  on:mouseleave={hideTooltip}
  on:touchstart|preventDefault={showTooltip}
  on:touchend|preventDefault={hideTooltip}
>
  {#if visible}
    <div
      bind:this={tooltipContent}
      class="fixed bg-gray-800 text-white px-2.5 py-1.5 rounded text-sm z-[1100] pointer-events-none"
      style="left: {tooltipX}px; top: {tooltipY}px;"
    >
      {#if isPreview}
        <img crossorigin="anonymous" src={imageManager.src(message)} alt="Screenshot" />
      {:else}
        <div class="text-xs">
          {#each messageLines as line, i}
            {line}{#if i < messageLines.length - 1}<br />{/if}
          {/each}
        </div>
      {/if}
    </div>
  {/if}
  <slot></slot>
</div>
