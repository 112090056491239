<script>
  import { createEventDispatcher } from "svelte";
  import { Icon, Tv } from "svelte-hero-icons";

  const dispatch = createEventDispatcher();

  let selectedAspectRatio = "16:9";

  function handleChange() {
    dispatch("change", { aspectRatio: selectedAspectRatio });
  }
</script>

<div class="flex items-center justify-end space-x-2 py-2">
  <Icon src={Tv} class="w-4 h-4 text-gray-600" />
  <select
    bind:value={selectedAspectRatio}
    on:change={handleChange}
    class="bg-white border border-gray-300 rounded pr-6 py-1 text-xs font-medium text-gray-700 appearance-none cursor-pointer focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
  >
    <option value="16:9">16:9</option>
    <option value="16:10">16:10</option>
  </select>
</div>

<style>
  select {
    /* Dropdown icon */
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%236B7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.5rem center;
    background-size: 1.5em 1.5em;
  }
</style>
