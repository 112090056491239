/**
 * Creates a non-reactive variable wrapper for use in Svelte components.
 *
 * By default, all variables within a Svelte component are reactive, meaning
 * changes to them will trigger component updates. This utility creates a
 * wrapper object that holds a value which can be modified without triggering
 * Svelte's reactivity system.
 *
 * @param {any} initialValue - The initial value to store
 * @returns {Object} An object with a getter for 'value' and a 'set' method
 * @example
 * const mounted = nonReactiveVariable(false);
 * mounted.value; // false
 * mounted.set(true); // Updates value without triggering reactivity
 */

export function nonReactiveVariable(initialValue) {
  let value = initialValue;

  return {
    get value() {
      return value;
    },
    set(v) {
      // Can't be a setter because it will trigger a reactive update
      value = v;
    },
  };
}
