export const EnterKeyIcon = {
  default: {
    a: {
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
      strokeWidth: "2",
    },
    path: [{ d: "M19 2V12C19 14.7614 16.7614 17 14 17H4M4 17L9 12M4 17L9 22" }],
  },
};
