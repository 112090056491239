<script>
  export let region;

  function labelText({ name, required, tagName, match, lower, upper, color, tolerance }) {
    let label = `${name}${required ? " | required" : ""}`;

    if (tagName === "Text") {
      label += ` | /${match}/`;
    } else if (tagName === "Color") {
      if (color) {
        label += ` | (C:${color}) | T:${tolerance}`;
      } else {
        label += ` | (U:${upper}) | (L:${lower})`;
      }
    }
    return label;
  }
</script>

<div
  class="pointer-events-none select-none text bg-white px-1 m-0 absolute top-0 left-0 -translate-y-full whitespace-nowrap border border-white"
>
  {labelText(region)}
  {#if region.tagName === "Color"}
    <div class="inline-flex mt-1">
      <div class="w-2 h-2" style="background-color: rgb({region.upper});" />
      <div class="w-2 h-2" style="background-color: rgb({region.lower});" />
      <div class="w-2 h-2" style="background-color: rgb({region.color});" />
    </div>
  {/if}
</div>
