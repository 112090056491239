import { writable } from "$utils/shim";

export function createJobsStore() {
  const currentJob = writable(null);

  function selectJob(job) {
    if (!job) {
      console.warn("Can not select job, job is not set");
      return;
    }

    currentJob.set(job);
  }

  return {
    initialize(job) {
      selectJob(job);
    },

    selectJob,

    subscribe: currentJob.subscribe,
    currentJob,

    reset() {
      currentJob.set(null);
    },
  };
}
