<!--
  Debug Component
  ==============

  A utility component that displays props and store values in JSON format.

  Usage:
  ```svelte
  <Debug
    {...someStore}             // All substore will be subscribed to and updated
    normalProp={someValue}     // Regular props will be displayed directly
    storeProp={$someStore}     // Store values will be subscribed to and updated
  />
  ```
-->

<script>
  import { onDestroy } from "svelte";

  let debugObject = {};
  let unsubscribers = [];

  $: {
    unsubscribers.forEach((unsub) => unsub());
    unsubscribers = [];

    Object.entries($$props).map(([key, value]) => {
      if (value && typeof value.subscribe === "function") {
        unsubscribers.push(
          value.subscribe((v) => {
            debugObject[key] = v;
          }),
        );
      } else {
        debugObject[key] = value;
      }
    });
  }

  onDestroy(() => {
    unsubscribers.forEach((unsub) => unsub());
  });

  $: jsonString = JSON.stringify(debugObject, null, 2);
</script>

<div class="max-h-80 m-2 p-2 border border-dotted border-gray-300 rounded overflow-y-auto">
  <pre class="text-[0.6rem] font-mono bg-white text-gray-800 overflow-x-auto">{jsonString}</pre>
</div>
