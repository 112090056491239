export const AlignHorizontalCenterIcon = {
  default: {
    a: {
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
    },
    path: [
      {
        d: "M13 5V19",
        stroke: "#0F172A",
        strokeWidth: "1.5",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      },
      {
        d: "M6 7H20V11H6V7Z",
        fill: "#0F172A",
      },
      {
        d: "M10 13H16V17H10V13Z",
        fill: "#0F172A",
      },
    ],
  },
};
