import { debounce } from "$utils/debounce";
import { persisted } from "$lib/utils/persisted-stores";
import { writable, derived, get } from "$utils/shim";
import { generateUUID } from "$utils/uuid";
import { instantiateFromDefinition } from "./definition";

const CONNECTION_ISSUE_TIMEOUT = 2000;
const SAVE_DEBOUNCE_TIME = 500;

function clone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function createWorkflowStore(liveStore, definitionStore) {
  const workflow = writable(null);
  const persistedWorkflow = writable(null);
  const serverWorkflow = writable(null);
  const hasConnectionIssue = writable(false);
  const isValid = writable(null);
  const autoSaveEnabled = persisted("auto_save_enabled", true);

  let save_timeout;
  let current_workflow_id = null;

  function initialize(data) {
    if (current_workflow_id !== null) {
      console.warn("filetree: initialize should not be called more than once");
      return;
    }
    if (data) {
      updateWorkflow(data);
    } else {
      console.warn("workflow: initialize called with no data");
    }
  }

  function internalUpdate({ definition: new_workflow, isValid: valid }) {
    // Called from Sequential Workflow Designer
    workflow.set(new_workflow);
    isValid.set(valid);
  }

  function update(workflow_id, server_workflow) {
    // Called when server sends an update
    if (workflow_id !== current_workflow_id) {
      console.log("workflow: ⚠︎ Detected workflow switch, updating local workflow.");
      workflow.set(server_workflow);
      persistedWorkflow.set(clone(server_workflow));
      current_workflow_id = workflow_id;
    } else {
      // Server updates are ignored
      // We could verify here that the recevied workflow is the same as locally
      // ...
      console.log("workflow: Ignoring server update");
      serverWorkflow.set(server_workflow);
    }
  }

  function saveWorkflow() {
    const new_workflow = get(workflow);

    if (!new_workflow) {
      console.warn("workflow: saveWorkflow called with no workflow");
      return;
    }

    console.log("workflow: saving 💾");

    clearTimeout(save_timeout);
    save_timeout = setTimeout(() => hasConnectionIssue.set(true), CONNECTION_ISSUE_TIMEOUT);

    const live = get(liveStore);

    live.pushEvent("automator_changed", { workflow: new_workflow }, () => {
      persistedWorkflow.set(clone(new_workflow));
      hasConnectionIssue.set(false);
      clearTimeout(save_timeout);
    });
  }

  const saveDebounced = debounce(saveWorkflow, SAVE_DEBOUNCE_TIME);

  // Track workflow changes and trigger auto-saves when dirty
  const isDirty = derived([workflow, persistedWorkflow], ([$workflow, $persistedWorkflow]) => {
    return JSON.stringify($workflow) !== JSON.stringify($persistedWorkflow);
  });

  isDirty.subscribe((value) => {
    if (value && get(autoSaveEnabled)) {
      saveDebounced();
    }
  });

  function updateType(block) {
    const type = definitionStore.resolveAlias(block.type);

    if (!definitionStore.exists(type)) {
      console.warn(`The block '${type}' is not defined. You might be running an old version.`);
    }

    return type;
  }

  function updateProperties(block) {
    if (!definitionStore.exists(block.type)) {
      return { ...block.properties };
    }

    const definition = definitionStore.getDefinition(block.type).inputs;
    const updatedProperties = { ...block.properties };

    // Add new fields with default values
    Object.entries(definition).forEach(([key, value]) => {
      if (!(key in updatedProperties)) {
        updatedProperties[key] = value.default;
      }
    });

    // Remove deleted fields
    Object.keys(updatedProperties).forEach((key) => {
      if (!(key in definition)) {
        delete updatedProperties[key];
      }
    });

    return updatedProperties;
  }

  function updateBlock(block) {
    const updatedBlock = {
      ...block,
      type: updateType(block),
      properties: updateProperties(block),
    };

    if (block.branches) {
      updatedBlock.branches = Object.fromEntries(
        Object.entries(block.branches).map(([key, sequence]) => [key, updateSequence(sequence)]),
      );
    }

    if (block.sequence) {
      updatedBlock.sequence = updateSequence(block.sequence);
    }

    return updatedBlock;
  }

  function updateSequence(sequence) {
    return sequence.map(updateBlock);
  }

  function updateWorkflow(newWorkflow) {
    const updatedWorkflow = {
      ...newWorkflow,
      properties: definitionStore.updateObjectRootDefaults(newWorkflow.properties),
      sequence: updateSequence(newWorkflow.sequence),
    };

    workflow.set(updatedWorkflow);
  }

  function importWorkflow(workflowData) {
    const parsedWorkflow = JSON.parse(workflowData);
    updateWorkflow(parsedWorkflow);
  }

  function instantiateBlock(blockType) {
    const blockDefinition = definitionStore.getDefinition(blockType);

    if (!blockDefinition) {
      console.error(`Block "${blockType}" not found in definitions`);
      return null;
    }

    return {
      id: generateUUID("w"),
      componentType: "task", // needs to be adjusted
      type: blockType,
      name: blockDefinition.name,
      properties: instantiateFromDefinition(blockDefinition.inputs),
    };
  }

  function insertBlockIntoSequence(newBlock, afterBlockId) {
    function insert(sequence, newBlock, afterBlockId) {
      if (!afterBlockId) {
        return [...sequence, newBlock];
      }

      const index = sequence.findIndex((block) => block.id === afterBlockId);
      if (index === -1) {
        return [...sequence, newBlock];
      }

      return [...sequence.slice(0, index + 1), newBlock, ...sequence.slice(index + 1)];
    }

    workflow.update((currentWorkflow) => {
      const sequence = currentWorkflow.sequence || [];
      const updatedSequence = insert(sequence, newBlock, afterBlockId);

      return {
        ...currentWorkflow,
        sequence: updatedSequence,
      };
    });
  }

  function addBlock(blockType, afterBlockId = null) {
    const newBlock = instantiateBlock(blockType);
    insertBlockIntoSequence(newBlock, afterBlockId);
  }

  async function switchWorkflow(id) {
    const live = get(liveStore);
    if (!live) return;

    live.pushEvent("switch_workflow", { id });
  }

  function toggleAutoSaveEnabled() {
    autoSaveEnabled.set(!get(autoSaveEnabled));
  }

  function stepHasComments(step) {
    return step.comments && step.comments.length > 0;
  }

  return {
    initialize,
    update,
    internalUpdate,
    subscribe: workflow.subscribe,
    hasConnectionIssue,
    autoSaveEnabled,
    isDirty,
    persistedWorkflow,
    serverWorkflow,
    saveWorkflow,
    isValid,
    addBlock,
    instantiateBlock,
    importWorkflow,
    switchWorkflow,
    toggleAutoSaveEnabled,
    stepHasComments,
    reset() {
      workflow.set(null);
      persistedWorkflow.set(null);
      serverWorkflow.set(null);
      isValid.set(null);
      hasConnectionIssue.set(false);
      current_workflow_id = null;
      clearTimeout(save_timeout);
    },
  };
}
