<script>
  import { onMount, onDestroy, createEventDispatcher } from "svelte";
  import Label from "./Label.svelte";
  import { createPositionTracker } from "./positionTracker";
  import { tagColor } from "../Designer/elements";

  export let refElementId;
  export let annotations = [];
  export let baseWidth = 1920;
  export let baseHeight = 1080;
  export let enableDebug = false;

  let overlayElement;
  let { subscribe, destroy } = createPositionTracker(refElementId);

  onMount(() => {
    subscribe(updateOverlayPosition);
  });

  onDestroy(() => {
    destroy();
  });

  function updateOverlayPosition({ top, left, width }) {
    const scaleX = width / baseWidth;

    if (overlayElement) {
      overlayElement.style.top = `${top}px`;
      overlayElement.style.left = `${left}px`;
      overlayElement.style.width = `${baseWidth}px`;
      overlayElement.style.height = `${baseHeight}px`;
      overlayElement.style.transformOrigin = "0 0";
      overlayElement.style.transform = `scale(${scaleX})`;
    }
  }

  function setStyle(node, region) {
    function recalculateStyles() {
      if (region) {
        node.style.top = 0;
        node.style.left = 0;
        node.style.width = region.width + "px";
        node.style.height = region.height + "px";
        node.style.transform = `translate(${region.left}px, ${region.top}px)`;
      }
    }

    recalculateStyles();
  }

  const dispatch = createEventDispatcher();

  function handleAnnotationClick(annotation) {
    dispatch("select", annotation);
  }

  function handleBackgroundClick() {
    dispatch("select", null);
  }

  function handleKeyDown(event, annotation) {
    if (event.key === "Enter" || event.key === " ") {
      handleAnnotationClick(annotation);
    }
  }
</script>

<div
  bind:this={overlayElement}
  class="absolute z-10"
  class:border-2={enableDebug}
  role="button"
  tabindex={0}
  on:click={() => handleBackgroundClick()}
  on:keydown={(event) => handleKeyDown(event, null)}
  class:border-red-500={enableDebug}
>
  {#each annotations as annotation (annotation.ui_id)}
    <div
      use:setStyle={annotation.region}
      class={`absolute top-0 left-0 border-2 bg-opacity-25 pointer-events-auto ${tagColor(annotation.ui_type)} focus:ring-6 focus:ring-blue-500`}
      data-id={annotation.ui_id}
      role="button"
      tabindex={0}
      on:click|stopPropagation={() => handleAnnotationClick(annotation)}
      on:keydown={(event) => handleKeyDown(event, annotation)}
    >
      <Label {annotation} />
    </div>
  {/each}
</div>

{#if false}
  <safelist class="border-2 border-red-500" />
{/if}
