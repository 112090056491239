<script>
  import DataGrid from "../DataGrid/DataGrid.svelte";

  export let event;

  const lower = event.state.lower;
  const upper = event.state.upper;

  let data = [
    {
      id: "status",
      label: "Status",
      value: event.is_active,
      type: "status",
    },
    {
      id: "event_time",
      label: "Event Time",
      value: event.start,
      type: "time",
    },
    {
      id: "region",
      label: "Region",
      value: event.region,
      type: "region",
    },
    {
      id: "lower",
      label: "Lower",
      value: lower,
      type: "color",
    },
    {
      id: "upper",
      label: "Upper",
      value: upper,
      type: "color",
    },
    {
      id: "detected",
      label: "Detected",
      value: event.state.detected,
      type: "list",
      subtype: "region",
    },
  ];

  if (event.state.original) {
    data.push({
      id: "image",
      label: "Original",
      value: event.state.original,
      type: "image",
      props: {
        alt: `Original image`,
      },
    });
  }
  if (event.state.masked) {
    data.push({
      id: "masked",
      label: "Masked",
      value: event.state.masked,
      type: "image",
      props: {
        alt: `Detected area are masked`,
      },
    });
  }
  if (event.state.percentage !== undefined) {
    data.push({
      id: "percentage",
      label: "Percentage",
      value: event.state.percentage * 100,
      props: {
        isPercentage: true,
      },
      type: "numeric",
    });
  }
</script>

<DataGrid {data} />
