import { expandIceServers } from "./webrtc/util";

export const WebrtcTester = {
  async mounted() {
    this.handleEvent("test_webrtc", this.testWebrtc.bind(this));
  },

  async testWebrtc({ iceServers }) {
    console.log("Creating PeerConnection with iceServers", iceServers);
    this.peer = new RTCPeerConnection({
      iceServers: expandIceServers(iceServers),
      bundlePolicy: "max-bundle",
    });

    this.peer.addEventListener("icecandidate", ({ candidate }) => {
      const end = performance.now();
      candidate.latency = end - start;
      console.log("received ice candidate", candidate);

      this.pushEvent(
        "candidate",
        extractKeys(candidate, [
          "candidate",
          "type",
          "url",
          "candidate",
          "protocol",
          "address",
          "port",
          "latency",
        ]),
      );
    });

    this.peer.addEventListener("icecandidateerror", (event) => {
      const end = performance.now();
      event.latency = end - start;
      console.error("received ice candidate error", event);
      const keys = ["errorCode", "errorText", "url", "port", "hostCandidate", "latency"];

      this.pushEvent("candidate_error", extractKeys(event, keys));
    });

    // Create a fake data channel to trigger ice candidates
    this.peer.createDataChannel("");

    console.log("Creating offer...");
    const start = performance.now();
    const offer = await this.peer.createOffer();

    console.log("Offer created, awaiting ice candidates...", offer);
    await this.peer.setLocalDescription(offer);
  },
};

function extractKeys(obj, keys) {
  return keys.reduce((data, key) => {
    return { ...data, [key]: obj[key] };
  }, {});
}
