export let testRuns = [
  {
    id: 1,
    name: "Disney+ Test Cases",
    runs: [
      { id: 1, name: "Boot Sequence", status: "success", duration: "00:02:15" },
      { id: 2, name: "Menu Navigation", status: "failure", duration: "00:01:30" },
      { id: 3, name: "App Launch", status: "success", duration: "00:00:45" },
      { id: 4, name: "Video Playback", status: "running", duration: "00:05:00" },
    ],
    isOpen: true,
  },
  {
    id: 2,
    name: "Netflix Test Cases",
    runs: [],
    isOpen: false,
  },
  {
    id: 3,
    name: "HBO Test Cases",
    runs: [],
    isOpen: false,
  },
];

export let events = [
  { type: "key", track: 1, label: "home", start: 0, duration: 0 },
  { type: "key", track: 1, label: "ok", start: 1.5, duration: 0 },
  { type: "key", track: 1, label: "ok", start: 7, duration: 0 },
  { type: "key", track: 1, label: "left", start: 21, duration: 0 },
  { type: "key", track: 1, label: "up", start: 25, duration: 0 },
  { type: "key", track: 1, label: "ok", start: 26.5, duration: 0 },
  { type: "key", track: 1, label: "left", start: 30, duration: 0 },
  { type: "key", track: 1, label: "right", start: 30.2, duration: 0 },
  { type: "key", track: 1, label: "down", start: 30.4, duration: 0 },
  { type: "key", track: 1, label: "right", start: 30.6, duration: 0 },
  { type: "key", track: 1, label: "up", start: 30.8, duration: 0 },
  { type: "key", track: 1, label: "left", start: 31, duration: 0 },
  { type: "key", track: 1, label: "ok", start: 31.2, duration: 0 },
  { type: "key", track: 1, label: "down", start: 31.4, duration: 0 },
  { type: "key", track: 1, label: "right", start: 31.6, duration: 0 },
  { type: "key", track: 1, label: "up", start: 31.8, duration: 0 },
  { type: "key", track: 1, label: "left", start: 32, duration: 0 },
  { type: "key", track: 1, label: "ok", start: 32.2, duration: 0 },
  { type: "key", track: 1, label: "right", start: 37.5, duration: 0 },
  { type: "key", track: 1, label: "right", start: 38.5, duration: 0 },
  { type: "key", track: 1, label: "ok", start: 39.5, duration: 0 },
  { type: "key", track: 1, label: "home", start: 50, duration: 0 },
  { type: "key", track: 1, label: "right", start: 66, duration: 0 },
  {
    id: "annotation-1",
    ui_type: "text",
    ui_id: "r.text.1",
    type: "state",
    track: 2,
    label: "Loading",
    start: 0,
    duration: 4.75,
    executionTime: 23,
    top: 400,
    left: 600,
    width: 730,
    height: 270,
    state: {
      detectedText: "max",
      matching: true,
    },
  },
  {
    id: "annotation-2",
    ui_type: "text",
    ui_id: "r.text.2",
    type: "state",
    track: 2,
    label: "Profile",
    start: 5,
    duration: 4.75,
    executionTime: 24,
    top: 710,
    left: 710,
    width: 170,
    height: 60,
    state: {
      detectedText: "Stephen",
      matching: true,
    },
  },
  {
    id: "annotation-3",
    ui_type: "color",
    ui_id: "r.color.1",
    type: "state",
    track: 2,
    label: "Home",
    start: 10,
    duration: 9.5,
    executionTime: 25,
    top: 500,
    left: 20,
    width: 100,
    height: 100,
    state: {
      detectedColor: "#F2A314",
      matching: true,
    },
  },
  {
    id: "annotation-4",
    ui_type: "color",
    ui_id: "r.color.2",
    type: "state",
    track: 2,
    label: "Loading",
    start: 20,
    duration: 3.5,
    executionTime: 26,
    top: 500,
    left: 20,
    width: 100,
    height: 100,
    state: {
      detectedColor: "#F2A314",
      matching: false,
    },
  },
  {
    id: "annotation-5",
    ui_type: "color",
    ui_id: "r.region.1",
    type: "state",
    track: 2,
    label: "Search",
    start: 24,
    duration: 15.5,
    executionTime: 26,
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    state: {
      matching: false,
    },
  },
  {
    id: "annotation-6",
    ui_type: "color",
    ui_id: "r.region.2",
    type: "state",
    track: 2,
    label: "Loading",
    start: 40,
    duration: 3.5,
    executionTime: 27,
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    state: {
      matching: false,
    },
  },
  {
    id: "annotation-7",
    ui_type: "color",
    ui_id: "r.region.3",
    type: "state",
    track: "state",
    label: "Home",
    start: 44,
    duration: 100,
    executionTime: 30,
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    state: {
      matching: false,
    },
  },
  { type: "network", track: "network", label: "GET /api/menu", start: 0.5, duration: 1 },
  {
    type: "network",
    track: "network",
    label: "PUT /api/settings",
    start: 7,
    duration: 1.2,
  },
  {
    type: "network",
    track: "network",
    label: "POST /api/analytics",
    start: 19,
    duration: 0.5,
  },
  {
    type: "network",
    track: "network",
    label: "GET /api/stream",
    start: 32,
    duration: 4,
  },
  { type: "audio", track: 4 },
  ...generateWorkflowEvents(),
];

function generateWorkflowEvents() {
  const steps = [
    "OpenApp",
    "Type",
    "WaitFor",
    "Type",
    "WaitFor",
    "WaitForScreen",
    "WaitFor",
    "Type",
    "Capture",
    "Metric",
  ];
  let time = 0;
  return Array.from({ length: 20 }, (_, i) => i % steps.length).map((i) => {
    let duration = 5;
    if (steps[i] == "Type") duration = 3;
    if (steps[i] == "Capture") duration = 2;
    time += duration + 0.25;
    return {
      id: `workflow-${i}`,
      type: "workflow",
      track: "workflow",
      label: steps[i],
      start: time - 5,
      duration,
    };
  });
}

export let fakeStoreData = {
  run_results: testRuns,
  events: events,
};
