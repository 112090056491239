<script>
  import { createEventDispatcher } from "svelte";

  export let value = 1;
  export let min = 1;
  export let max = 10;
  export let step = 1;
  export let label = "";
  export let variant = "default";
  export let id = `slider-${Math.random().toString(36).substring(2, 9)}`;
  export let isReadonly = false;

  const dispatch = createEventDispatcher();

  function handleInput(event) {
    if (!isReadonly) {
      value = parseInt(event.target.value, 10);
      dispatch("input", value);
    }
  }
</script>

<div
  class="relative flex flex-col rounded p-2 w-full bg-gray-100 {$$props.class || ''}"
  class:bg-gray-100={variant == "embedded"}
  class:border-0={variant == "embedded"}
  class:border-gray-200={variant == "default"}
  class:border={variant == "default"}
  class:opacity-60={isReadonly}
>
  <div class="flex items-center mb-1">
    <label
      for={id}
      class="block text-xs font-medium text-gray-500 {isReadonly ? 'cursor-not-allowed' : ''}"
      >{label}</label
    >
    <span class="ml-auto text-xs text-gray-700">
      <slot {value}></slot>
    </span>
  </div>
  <div class="relative flex items-center">
    <input
      {id}
      type="range"
      {min}
      {max}
      {step}
      {value}
      on:input={handleInput}
      class="w-full h-4 appearance-none {isReadonly ? 'cursor-not-allowed' : 'cursor-pointer'}"
      class:embedded={variant == "embedded"}
      disabled={isReadonly}
    />
  </div>
</div>

<style>
  input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    background: transparent;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background: rgb(59 130 246); /* TODO: Get from tailwind */
    cursor: pointer;
    margin-top: -6px;
  }

  input[type="range"]::-moz-range-thumb {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background: rgb(59 130 246); /* TODO: Get from tailwind */
    cursor: pointer;
    border-color: rgb(59 130 246);
  }

  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 3px;
    background: rgb(229 231 235);
    border-radius: 3px;
  }

  input[type="range"]::-moz-range-track {
    width: 100%;
    height: 3px;
    background: rgb(229 231 235);
    border-radius: 3px;
  }

  input.embedded[type="range"]::-webkit-slider-runnable-track,
  input.embedded[type="range"]::-moz-range-track {
    background: rgb(229 231 235);
  }

  input[type="range"]:disabled::-webkit-slider-thumb,
  input[type="range"]:disabled::-moz-range-thumb {
    background: rgb(156 163 175); /* A grayer blue for disabled state */
  }

  input[type="range"]:disabled::-webkit-slider-runnable-track,
  input[type="range"]:disabled::-moz-range-track {
    background: rgb(209 213 219); /* A lighter gray for disabled state */
  }
</style>
