import { get, writable } from "$utils/shim";
import { generateUUID } from "$utils/uuid";

const MINIMUM_REGION_AREA = 100;

export function createRegionStore(id, initialValue = []) {
  const { subscribe, set, update } = writable(initialValue);

  return {
    _id: id,

    subscribe,

    update,

    add(rect) {
      const area = rect.width * rect.height;

      if (area < MINIMUM_REGION_AREA) {
        console.warn(`Region not added because area ${area} is less than ${MINIMUM_REGION_AREA}`);
        return null;
      }

      const id = generateUUID();
      update((regions) => {
        const newRegions = {
          _id: id,
          name: "New Region",
          required: true,
          tagName: "text",
          match: "",
          engine: "recognize",
          upper: null,
          lower: null,
          color: null,
          ...rect,
        };
        return [...regions, newRegions];
      });
      return id;
    },

    delete(id) {
      update((regions) => regions.filter((a) => a._id !== id));
    },

    deleteMany(ids) {
      update((regions) => regions.filter((a) => !ids.includes(a._id)));
    },

    updatePosition(id, rect) {
      update((regions) => {
        const index = regions.findIndex((a) => a._id === id);
        if (index >= 0) {
          regions[index] = { ...regions[index], ...rect };
        }
        return regions;
      });
    },

    updateProp(id, key, value) {
      update((regions) => {
        const index = regions.findIndex((a) => a._id === id);
        if (index >= 0) {
          regions[index] = { ...regions[index], [key]: value };
        }
        return regions;
      });
    },

    getById(id) {
      return get({ subscribe }).find((a) => a._id === id);
    },

    reset() {
      set([]);
    },
  };
}
