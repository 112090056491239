<script>
  import { createEventDispatcher } from "svelte";
  import { Icon, EllipsisHorizontalCircle } from "svelte-hero-icons";
  import { getInputComponent } from "./inputs";
  import DynamicInputSelector from "./DynamicInputSelector.svelte";
  import ValidationErrorIcon from "../Workflow/Badges/ValidationErrorIcon.svelte";
  import { tooltip } from "$lib/utils/tooltip";

  const dispatch = createEventDispatcher();

  export let propertyType;
  export let property;
  export let properties;
  export let step;
  export let isReadonly = false;
  export let showAdditionalHelp = true;
  export let validationWarnings = null;

  let dynamicMode = false;
  let showOutputSelector = false;

  const [component, props] = getInputComponent(property, propertyType, step);
  const { dynamic, docs, ...additionalProps } = props;
  let stepRef = "$prev";

  function updateStepRefAndDynamicMode() {
    if (dynamic) {
      stepRef = properties[property];
      dynamicMode = !(stepRef instanceof Object);
    }
  }

  updateStepRefAndDynamicMode();

  function onPropertyUpdated(value) {
    dispatch("change", value);
    if (value instanceof Object) {
      updateStepRefAndDynamicMode();
    }
  }

  function toOrdinal(number) {
    const suffixes = ["th", "st", "nd", "rd"];
    const mod100 = number % 100;
    const suffix = suffixes[(mod100 - 20) % 10] || suffixes[mod100] || suffixes[0];
    return number + suffix;
  }

  function formatStepRef(stepRef) {
    if (stepRef instanceof Object) {
      if (propertyType instanceof Object && propertyType["type"] === "image") {
        if (propertyType["multiple"] === true) {
          const { assets } = stepRef;
          return assets.length > 0 ? `${assets.length} images` : "No images";
        } else {
          const { assets, selected } = stepRef;
          return assets.length > 0 ? `${toOrdinal(selected + 1)} image selected` : "No images";
        }
      } else {
        return "Non-block output selected";
      }
    }
    return stepRef;
  }

  function onDynamicInputSelector(value) {
    if (value === null) {
      stepRef = "$prev";
      dynamicMode = false;
      showOutputSelector = false;
    } else {
      stepRef = value;
      dispatch("change", value);
      showOutputSelector = false;
    }
  }

  function onToggleDynamicMode() {
    dynamicMode = !dynamicMode;
    showOutputSelector = false;
  }

  function onTogglOutputSelector() {
    showOutputSelector = !showOutputSelector;
  }
</script>

<div class={"relative mb-2"}>
  {#if dynamicMode}
    <div class="flex items-start justify-between">
      <div class=" flex flex-col flex-start rounded p-2 w-full bg-gray-100 border-0">
        <span class="mt-0.5 text-gray-500 text-xs">{additionalProps.label}</span>

        <div class="mt-2">
          <button
            on:click={onTogglOutputSelector}
            class="inline-flex max-w-48 items-center rounded-md bg-green-50 px-2 py-1 text-[11px] font-mono font-medium text-green-700 ring-1 ring-inset ring-green-600/20 hover:bg-green-100 hover:ring-green-600/30 cursor-pointer"
          >
            <span class="truncate hover:text-clip">
              {formatStepRef(stepRef)}
            </span>
          </button>
        </div>
      </div>

      <button
        class="mt-2 ml-2 mr-1 text-xs text-gray-500 hover:text-gray-700 focus:outline-none"
        on:click={onToggleDynamicMode}
      >
        <Icon src={EllipsisHorizontalCircle} class="w-4 h-4" />
      </button>
    </div>
  {:else}
    <svelte:component
      this={component}
      value={properties[property]}
      on:input={(e) => onPropertyUpdated(e.detail)}
      {isReadonly}
      {...additionalProps}
    >
      <div slot="dynamic" class="flex items-center">
        {#if dynamic}
          <button
            class="ml-2 text-xs text-gray-500 hover:text-gray-700 focus:outline-none"
            on:click={onToggleDynamicMode}
          >
            <Icon src={EllipsisHorizontalCircle} class="w-4 h-4" />
          </button>
        {/if}
        {#if validationWarnings && validationWarnings.length > 0}
          <svg
            use:tooltip
            aria-label={validationWarnings.join("\n")}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            class="w-4 h-4 block ml-2"
          >
            <ValidationErrorIcon />
          </svg>
        {/if}
      </div>
      <!-- TODO: use docs slot -->
    </svelte:component>
  {/if}

  {#if showOutputSelector}
    <DynamicInputSelector
      currentStepId={step.id}
      inputType={propertyType instanceof Object ? propertyType.type : null}
      on:change={(e) => onDynamicInputSelector(e.detail)}
    />
  {/if}

  {#if docs && showAdditionalHelp}
    <div class="p-2 text-xs text-gray-400">
      {docs}
    </div>
  {/if}
</div>
