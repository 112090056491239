<script>
  import { Icon, BugAnt, QuestionMarkCircle, ChevronRight, Eye, EyeSlash } from "svelte-hero-icons";
  import { toolbar, features } from "./store";
  import { tooltip } from "$lib/utils/tooltip";
  import { uiStateConfig } from "$utils/ui-state-config";
  import { assignStoresToConsole } from "$lib/shared_stores";
  import HelpModal from "$lib/Elements/HelpModal.svelte";

  $: developerMode = features.developerMode;

  let activeTab = "Remote";

  const toggleDebug = () => (($toolbar.show_debug = !$toolbar.show_debug), assignStoresToConsole());
  const toggleInspectorPane = () => ($toolbar.show_inspector_pane = !$toolbar.show_inspector_pane);
  const toggleRunsPane = () => ($toolbar.show_runs_pane = !$toolbar.show_runs_pane);
  const toggleTimelinePane = () => ($toolbar.show_timeline_pane = !$toolbar.show_timeline_pane);
  const toggleOverlay = () => ($toolbar.show_overlay = !$toolbar.show_overlay);

  let isHelpOpen = false;
  const toggleHelp = () => (isHelpOpen = !isHelpOpen);
  const keyboardShortcuts = {
    "Video Control": {
      "Play/Pause": "Space",
      "Step Forward (10 frame)": "Right Arrow (+ Shift)",
      "Step Backward (10 frame)": "Left Arrow (+ Shift)",
      "Previous Step Time Point": "Right Arrow + Ctrl/Cmd",
      "Next Step Time Point": "Right Arrow + Ctrl/Cmd",
      "Seek to beginning": "Home / 0",
      "Seek to 10% to 90%": "1 - 9",
      "Seek to end": "End",
      "Toggle Mute": "M",
      "Volume Up (+10)": "Up Arrow (+ Shift)",
      "Volume Down (-10)": "Down Arrow (+ Shift)",
      "Zoom In": "Ctrl/Cmd + Plus",
      "Zoom Out": "Ctrl/Cmd + Minus",
      "Reset Zoom": "Ctrl/Cmd + 0",
      "Toggle Live Mode": "L",
    },
  };
</script>

<div class="flex items-center py-1 px-4 bg-white border-b border-gray-200">
  <div class="flex-grow" />

  <div class="flex space-x-4">
    <button
      class="p-1 text-black hover:bg-gray-300 rounded-md flex items-center justify-center w-8 h-8 disabled:opacity-20"
      on:click={toggleOverlay}
      use:tooltip={{ placement: "bottom" }}
      aria-label="Toggle overlay"
      disabled={$toolbar.show_code}
    >
      <Icon src={$toolbar.show_overlay ? Eye : EyeSlash} class="w-5 h-5" />
    </button>

    <button
      class="p-1 text-black hover:bg-gray-300 rounded-md flex items-center justify-center w-8 h-8 disabled:opacity-20"
      on:click={toggleRunsPane}
      use:tooltip={{ placement: "bottom" }}
      aria-label="Toggle runs pane"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 104 108" class="w-5 h-5">
        <rect
          x="0"
          y="0"
          width="50"
          height="100"
          fill={$toolbar.show_runs_pane ? "black" : "none"}
          stroke="black"
          stroke-width="8"
        />
        <rect x="50" y="0" width="50" height="100" fill="none" stroke="black" stroke-width="8" />
      </svg>
    </button>

    <button
      class="p-1 text-black hover:bg-gray-300 rounded-md flex items-center justify-center w-8 h-8 disabled:opacity-20"
      on:click={toggleInspectorPane}
      use:tooltip={{ placement: "bottom" }}
      aria-label="Toggle properties pane"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 104 108" class="w-5 h-5">
        <rect
          x="50"
          y="0"
          width="50"
          height="100"
          fill={$toolbar.show_inspector_pane ? "black" : "none"}
          stroke="black"
          stroke-width="8"
        />
        <rect x="0" y="0" width="50" height="100" fill="none" stroke="black" stroke-width="8" />
      </svg>
    </button>

    <button
      class="p-1 text-black hover:bg-gray-300 rounded-md flex items-center justify-center w-8 h-8 disabled:opacity-20"
      on:click={toggleTimelinePane}
      use:tooltip={{ placement: "bottom" }}
      aria-label="Toggle timeline"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 104 108" class="w-5 h-5">
        <rect
          x="0"
          y="50"
          width="100"
          height="50"
          fill={$toolbar.show_timeline_pane ? "black" : "none"}
          stroke="black"
          stroke-width="8"
        />
        <rect x="0" y="0" width="100" height="50" fill="none" stroke="black" stroke-width="8" />
      </svg>
    </button>

    {#if $developerMode}
      <button
        class="p-1 text-black hover:bg-gray-300 rounded-md flex items-center justify-center w-8 h-8"
        on:click={toggleDebug}
        use:tooltip={{ placement: "bottom" }}
        aria-label="Toggle debug view"
      >
        <Icon src={BugAnt} class="w-5 h-5" />
      </button>
    {/if}

    <button
      on:click={toggleHelp}
      class="p-1 text-black hover:bg-gray-300 rounded-md disabled:opacity-20"
      use:tooltip={{ placement: "bottom" }}
      aria-label="Show Help"
    >
      <Icon src={QuestionMarkCircle} class="w-5 h-5" />
    </button>

    <HelpModal bind:isOpen={isHelpOpen} {keyboardShortcuts} maxWidth="32rem">
      <div class="flex flex-col h-full">
        <h2 class="font-bold mb-4">Timeline</h2>
        <p class="mb-8 text-xs leading-4">
          The timeline provides a comprehensive view of events during execution, organized into 5
          distinct tracks. Use the tabs below to explore different aspects of your test run.
        </p>

        <!-- Tab Navigation -->
        <div class="flex border-b border-gray-200 mb-4">
          {#each ["Remote", "UI State", "Workflow Step", "Network", "Audio Level"] as tab}
            <button
              class="px-4 py-2 text-xs font-medium {activeTab === tab
                ? 'border-b-2 border-blue-500 text-blue-600'
                : 'text-gray-500 hover:text-gray-700 hover:border-gray-300'}"
              on:click={() => (activeTab = tab)}
            >
              {tab}
            </button>
          {/each}
        </div>

        <!-- Tab Content -->
        <div class="text-xs mb-8 mx-4 leading-4">
          {#if activeTab === "Remote"}
            <section>
              <p class="mb-2">
                The Remote Control track displays all interactions with the device, including key
                presses and commands sent to your TV or Set-Top Box. This helps you track user input
                throughout the test.
              </p>
            </section>
          {:else if activeTab === "UI State"}
            <section>
              <p class="mb-2">
                The UI State track monitors interface-related events, such as region matches and
                screen detections. Expand subtracks by clicking the <Icon
                  src={ChevronRight}
                  class="w-3 h-3 inline px-0 mb-[1px]"
                /> arrow.
              </p>
              <p class="mb-4">
                Navigate through your test by clicking any UI state event to jump to its starting
                point. For quick access to the Designer, hold <kbd class="rounded bg-blue-100 px-1"
                  >shift</kbd
                > while clicking to open the corresponding workflow step.
              </p>

              {#if uiStateConfig}
                {@const uiStateEntries = Object.entries(uiStateConfig)}
                <p class="mb-2 font-semibold">UI State Event Types:</p>
                <div class="grid grid-cols-2 gap-2">
                  {#each uiStateEntries as [name, config]}
                    <div class="flex items-center">
                      <button
                        class="w-5 h-5 rounded border {config.color} font-semibold flex items-center px-1 shadow-sm whitespace-nowrap pointer-events-none"
                      >
                        <Icon src={config.icon} class="w-4 h-4 inline px-0" />
                      </button>
                      <span class="ml-2 capitalize">{name}</span>
                    </div>
                  {/each}
                </div>
              {/if}
            </section>
          {:else if activeTab === "Workflow Step"}
            <section>
              <p class="mb-2">
                The Workflow Step track visualizes the sequence of steps executed during your job.
                Expand detailed information using the <Icon
                  src={ChevronRight}
                  class="w-3 h-3 inline px-0 mb-[1px]"
                /> arrow.
              </p>
              <p>
                Click any step to jump to its beginning, or hold <kbd
                  class="rounded bg-blue-100 px-1">shift</kbd
                >
                while clicking to open that step directly in the Automate interface.
              </p>
            </section>
          {:else if activeTab === "Network"}
            <section>
              <p>
                The Network track visualizes upload and download speeds throughout your test
                execution. Hover over any point in the track to view precise speed measurements in
                real-time.
              </p>
            </section>
          {:else if activeTab === "Audio Level"}
            <section>
              <p>
                The Audio Level track monitors sound output levels during playback. Hover over the
                track to see precise volume measurements in decibels (dB) at any point in time.
              </p>
            </section>
          {/if}
        </div>
      </div>
    </HelpModal>
  </div>

  <div class="flex-grow" />
</div>
