export const ScrollBottom = {
  mounted() {
    this.scrollToBottom();
  },
  updated() {
    this.scrollToBottom();
  },
  scrollToBottom() {
    let element = this.el;
    element.scrollTop = element.scrollHeight;
  },
};
