<script>
  import { playback } from "../store";
  import { tooltip } from "$lib/utils/tooltip";
  import { Icon, VideoCamera, VideoCameraSlash } from "svelte-hero-icons";

  $: isLive = playback.isLive;
</script>

<button
  use:tooltip={{ placement: "top" }}
  aria-label={$isLive ? "Disable live-mode" : "Enable live-mode"}
  on:click={() => playback.toggleLiveMode()}
  class="p-3 hover:bg-gray-200 rounded-full"
>
  {#if $isLive}
    <Icon src={VideoCamera} solid class="w-4 h-4 text-red-600" />
  {:else}
    <Icon src={VideoCameraSlash} solid class="w-4 h-4 text-gray-500" />
  {/if}
</button>
