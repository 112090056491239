<script>
  import { fade } from "svelte/transition";
  import { cubicOut } from "svelte/easing";
  import { Check, ArrowPath, Icon } from "svelte-hero-icons";
  import { createEventDispatcher, onMount } from "svelte";
  import { debounce } from "$utils/debounce";
  import Button from "$lib/Elements/Button.svelte";
  import Modal from "$lib/Elements/Modal.svelte";
  import { liveStore } from "../store";

  let images = [];
  let files = [];
  let selectedImages = [];
  let input;
  let hasNextPage = false;
  let endCursor = null;
  let loading = false;

  onMount(loadImages);

  const dispatch = createEventDispatcher();

  async function loadImages() {
    if (loading) return;

    try {
      loading = true;

      const response = await $liveStore.request("request_screenshots", {
        payload: { end_cursor: endCursor },
      });

      images = [...images, ...response.results];
      hasNextPage = response.has_next_page;
      endCursor = response.end_cursor;
    } finally {
      loading = false;
    }
  }

  function toggleImageSelection(image) {
    if (selectedImages.includes(image.id)) {
      selectedImages = selectedImages.filter((id) => id !== image.id);
    } else {
      selectedImages = [...selectedImages, image.id];
    }
  }

  async function submitSelection() {
    const promises = selectedImages.map(async (imageId) => {
      const image = images.find((img) => img.id === imageId);
      if (!image) return null;

      const parsedUrl = new URL(image.url);

      parsedUrl.searchParams.set("ts", Date.now());

      const download = await fetch(parsedUrl.toString());
      const blob = await download.blob();

      return new File([blob], `file:${image.url}`, { type: blob.type });
    });

    const blobFiles = await Promise.all(promises);

    const dataTransfer = new DataTransfer();
    blobFiles.forEach((blobFile) => dataTransfer.items.add(blobFile));

    files = dataTransfer.files;

    handleUpload();
  }

  function handleClose() {
    dispatch("close");
  }

  function handleUpload() {
    dispatch("upload", files);
    handleClose();
  }

  function handleScroll(e) {
    const atBottom = e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight) < 100;

    if (hasNextPage && atBottom) loadImages();
  }
</script>

<Modal {open} title="Select Screen" on:close={handleClose}>
  <p class="text-sm text-zinc-600 mb-4">
    Select a screen from your recent sessions. Alternatively, upload a new image.
  </p>

  <div
    on:scroll={debounce(handleScroll, 300)}
    class="grid grid-cols-4 gap-2 content-start h-[600px] overflow-y-auto"
  >
    {#if images.length === 0 && loading}
      <div class="text-zinc-600 col-span-4 h-[600px] flex items-center justify-center gap-4">
        <Icon src={ArrowPath} class="w-4 h-4 animate-spin" />
        Loading...
      </div>
    {/if}
    {#if images.length === 0 && !loading}
      <div class="text-zinc-600 col-span-4 h-[600px] flex items-center justify-center">
        No screenshots found. You can start a device session to capture some.
      </div>
    {/if}
    {#each images as image (image.id)}
      <div
        in:fade={{ duration: 200, easing: cubicOut }}
        class="relative w-full h-auto cursor-pointer"
        on:click={() => toggleImageSelection(image)}
        on:keydown={(e) => e.key === "Enter" && toggleImageSelection(image)}
        role="button"
        tabindex="0"
      >
        <img
          crossorigin="anonymous"
          src={image.url}
          alt={image.label}
          class="w-full h-full object-cover rounded"
        />
        {#if selectedImages.includes(image.id)}
          <div
            transition:fade={{ duration: 200, easing: cubicOut }}
            class="w-6 h-6 bg-blue-500 rounded-full absolute top-1 right-1 flex items-center justify-center border border-white"
          >
            <Icon src={Check} class="w-4 h-4 text-white" />
          </div>
        {/if}
      </div>
    {/each}
    {#if hasNextPage}
      <div class="col-span-4 flex flex-col items-center my-2">
        <div class="flex items-center text-zinc-600 gap-4">
          <Icon src={ArrowPath} class="w-4 h-4 animate-spin" />
          Loading...
        </div>
      </div>
    {/if}
  </div>

  <div class="flex items-center gap-2 mt-4">
    <Button variant="primary" disabled={selectedImages.length === 0} on:click={submitSelection}>
      Select{selectedImages.length !== 0 ? ` (${selectedImages.length})` : ""}
    </Button>
    <Button variant="tertiary" on:click={() => input.click()}>Upload New</Button>
  </div>

  <input
    bind:this={input}
    type="file"
    accept="image/*"
    multiple
    bind:files
    on:change={handleUpload}
    class="hidden"
  />
</Modal>
