import { writable as svelteWritable } from "../../node_modules/svelte/src/runtime/store/index.js";

const stores = {
  local: {},
  session: {},
};

function getStorage(type) {
  return type === "local" ? localStorage : sessionStorage;
}

export function persisted(key, initialValue, options) {
  const serializer = options?.serializer ?? JSON;
  const storageType = options?.storage ?? "local";
  const syncTabs = options?.syncTabs ?? true;
  const onWriteError =
    options?.onWriteError ??
    ((e) =>
      console.error(`Error when writing value from persisted store "${key}" to ${storageType}`, e));
  const onParseError =
    options?.onParseError ??
    ((newVal, e) =>
      console.error(
        `Error when parsing ${newVal ? '"' + newVal + '"' : "value"} from persisted store "${key}"`,
        e,
      ));

  const browser = typeof window !== "undefined" && typeof document !== "undefined";
  const storage = browser ? getStorage(storageType) : null;

  function updateStorage(key, value) {
    try {
      storage?.setItem(key, serializer.stringify(value));
    } catch (e) {
      onWriteError(e);
    }
  }

  function maybeLoadInitial() {
    function serialize(json) {
      try {
        return serializer.parse(json);
      } catch (e) {
        onParseError(json, e);
      }
    }
    const json = storage?.getItem(key);
    if (json == null) return initialValue;

    const serialized = serialize(json);
    if (serialized == null) return initialValue;

    return serialized;
  }

  if (!stores[storageType][key]) {
    const initial = maybeLoadInitial();
    const store = svelteWritable(initial, (set) => {
      if (browser && storageType == "local" && syncTabs) {
        const handleStorage = (event) => {
          if (event.key === key && event.newValue) {
            let newVal;
            try {
              newVal = serializer.parse(event.newValue);
            } catch (e) {
              onParseError(event.newValue, e);
              return;
            }

            set(newVal);
          }
        };

        window.addEventListener("storage", handleStorage);

        return () => window.removeEventListener("storage", handleStorage);
      }
    });

    const { subscribe, set } = store;

    stores[storageType][key] = {
      set(value) {
        set(value);
        updateStorage(key, value);
      },
      update(callback) {
        return store.update((last) => {
          const value = callback(last);
          updateStorage(key, value);
          return value;
        });
      },
      reset() {
        this.set(initialValue);
      },
      subscribe,
    };
  }
  return stores[storageType][key];
}
