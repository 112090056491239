/**
 * Determines whether a key event should be ignored based on the target element's properties.
 *
 * This function checks for the following conditions:
 * 1. If the target or its ancestors have the `data-keyboard-capture` attribute
 * 2. If the target or its ancestors have `contenteditable="true"`
 * 3. If the target element is a `TEXTAREA`, `INPUT`, or `SELECT`
 */
export function shouldIgnoreKeyEvent(event) {
  const excludedTags = ["TEXTAREA", "INPUT", "SELECT"];

  let targetElement = event.target;
  while (targetElement != null) {
    if (targetElement.hasAttribute("data-keyboard-capture")) {
      return true;
    }

    if (targetElement.getAttribute("contenteditable") === "true") {
      return true;
    }

    targetElement = targetElement.parentElement;
  }

  if (excludedTags.includes(event.target.tagName.toUpperCase())) {
    return true;
  }

  return false;
}
