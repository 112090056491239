// import { bgGradient } from "../plugins/bgGradient";
import { columnHighlight } from "../plugins/columnHighlight";
import { selected } from "../plugins/selected";
import { globalColor, colorByIdx } from "../plugins/globalColor";

export function createSeriesFromLabels(labels, opts = {}) {
  return Object.values(labels).map((label, idx) => ({
    label: label,
    show: true,
    spanGaps: true,
    stroke: colorByIdx(labels, idx),
    width: 4,
    ...opts,
  }));
}

function getScaleFromUnit(unit) {
  switch (unit) {
    case "percentiles":
      return [0, 100];
    case "seconds":
      return [0, 15_000];
  }
}

export function detail({ labels, unit, onSelectedIndex }) {
  return {
    plugins: [
      globalColor({ labels }),
      // bgGradient({
      //   stops: ["#FFFFFF", "#FFFFFF", "#FFFFFF", "rgba(249,127,127,0.5)"],
      // }),
      columnHighlight({
        style: { backgroundColor: "rgba(204,204,204,0.8)" },
      }),
      selected({ onSelectedIndex }),
    ],
    scales: {
      x: { time: true }, // enable time axis
      y: { auto: false, range: getScaleFromUnit(unit) },
    },
    series: [{}, ...createSeriesFromLabels(labels)],
    legend: {
      show: true,
    },
    hooks: {
      setUnit(uplot, unit) {
        const [min, max] = getScaleFromUnit(unit);
        uplot.setScale("y", { min, max });
      },
      setData(_uplot) {
        // called when data has changed
      },
    },
  };
}
