<script>
  import { onMount, onDestroy } from "svelte";
  import { imageManager } from "$lib/shared_stores";

  export let updateInterval = 1000; // Update stats every second

  let stats = imageManager.getStats();
  let interval;

  onMount(() => {
    interval = setInterval(() => {
      stats = imageManager.getStats();
    }, updateInterval);
  });

  onDestroy(() => {
    clearInterval(interval);
  });
</script>

<div class="p-4 border border-gray-300 rounded-md bg-gray-800 text-white">
  <h3 class="text-sm font-semibold mb-2">Image Manager Stats</h3>
  <div class="text-xs font-semibold flex flex-col gap-2">
    <div class="flex justify-between bg-gray-700 p-2 rounded">
      <span>Total Images:</span>
      <span>{stats.totalImages}</span>
    </div>
    <div class="flex justify-between bg-gray-700 p-2 rounded">
      <span>Memory Used:</span>
      <span>{stats.memoryUsedFormatted}</span>
    </div>
    <div class="flex justify-between bg-gray-700 p-2 rounded">
      <span>Memory Limit:</span>
      <span>{stats.maxMemoryFormatted}</span>
    </div>
    <div class="flex justify-between bg-gray-700 p-2 rounded">
      <span>Utilization:</span>
      <span>{stats.utilizationPercentage}%</span>
    </div>
  </div>

  {#if stats.utilizationPercentage > 80}
    <div class="mt-2 p-2 bg-red-500 text-white rounded">
      High memory usage! Consider clearing old images.
    </div>
  {/if}

  <div class="flex gap-2 mt-4">
    <button
      on:click={() => imageManager.clear()}
      class="w-full p-1 text-xs font-bold bg-blue-600 text-white rounded hover:bg-blue-500"
      >Delete images</button
    >
  </div>
</div>
