export async function S3(entries, onViewError) {
  entries.forEach((entry) => {
    let { presigned_url } = entry.meta;

    let xhr = new XMLHttpRequest();

    onViewError(() => xhr.abort());

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        entry.progress(100);
      } else {
        console.log("xhr", xhr);
        console.log(entry.error());
      }
    };

    xhr.onerror = () => entry.error();

    xhr.upload.addEventListener("progress", (event) => {
      if (event.lengthComputable) {
        let percent = Math.round((event.loaded / event.total) * 100);
        if (percent < 100) {
          entry.progress(percent);
        }
      }
    });

    xhr.open("PUT", presigned_url, true);
    xhr.send(entry.file);
  });
}
