import { WebRTCStats } from "@peermetrics/webrtc-stats";

interface Candidate {
  ip?: string;
  port?: string;
  network_type?: string;
  protocol?: string;
  candidate_type?: string;
}

interface Stats {
  audio: {
    mime_type: string;
  };
  video: {
    mime_type: string;
    jitter_buffer_delay: number;
    jitter: number;
    frames_dropped: number;
    packets_lost: number;
    total_processing_delay: number;
    total_decode_time: number;
    frames_per_second: number;
  };
  bytes_received: number;
  bytes_sent: number;
  total_round_trip_time: number;
  local_candidate: Candidate;
  remote_candidate: Candidate;
}

export class MetricsPoller {
  peer: RTCPeerConnection;
  webrtcStats: any;

  constructor(peer: RTCPeerConnection, callback: (data: Stats) => void) {
    this.webrtcStats = new WebRTCStats({
      // the interval in ms of how often we should get stats
      getStatsInterval: 5000, // Default: 1000

      // if we should include the original RTCStatsReport map when firing the `stats` event
      rawStats: false, // Default: false

      // include an object that resulted from transforming RTCStatsReport into an oject (`report.id` as the key)
      statsObject: false, // Default: false

      // if we should filter out some stats
      filteredStats: false, // Default: false

      // If the data object should contain a remote attribute that will contain stats for the remote peer, from `remote-inbound-rtp`, etc
      remote: true, // Default: true

      // If we should wrap the `geUserMedia` calls so we can gather events when the methods is called or success/error
      wrapGetUserMedia: false, // Default: false

      // If we should log messages
      debug: false, // Default: false

      // What kind of level of logs the lib should display. Values: 'none', 'error', 'warn', 'info', 'debug'
      logLevel: "none", // Default: 'none'
    });

    this.webrtcStats.addConnection({
      pc: peer, // RTCPeerConnection instance
      peerId: "sidecar", // any string that helps you identify this peer,
      // connectionId: '06d54adc-e478-4f95-9144-bbb3562a2aad', // optional, an id that you can use to keep track of this connection
      // remote: false // optional, override the global remote flag
    });

    this.webrtcStats.on("stats", (stats) => {
      callback({
        audio: {
          mime_type: stats.data.audio.inbound[0]?.mimeType,
        },
        video: {
          mime_type: stats.data.video.inbound[0]?.mimeType,
          jitter_buffer_delay: stats.data.video.inbound[0]?.jitterBufferDelay,
          jitter: stats.data.video.inbound[0]?.jitter,
          frames_dropped: stats.data.video.inbound[0]?.framesDropped,
          packets_lost: stats.data.video.inbound[0]?.packetsLost,
          total_processing_delay: stats.data.video.inbound[0]?.totalProcessingDelay,
          total_decode_time: stats.data.video.inbound[0]?.totalDecodeTime,
          frames_per_second: stats.data.video.inbound[0]?.framesPerSecond,
        },
        bytes_received: stats.data.connection.bytesReceived,
        bytes_sent: stats.data.connection.bytesSent,
        total_round_trip_time: stats.data.connection.totalRoundTripTime,
        local_candidate: formatCandidate(stats.data.connection.local),
        remote_candidate: formatCandidate(stats.data.connection.remote),
      });
    });
  }

  destroy() {
    this.webrtcStats.destroy();
    this.webrtcStats = null;
  }
}

function formatCandidate(candidate: Candidate = {}): Candidate {
  return {
    ip: candidate.ip || "",
    port: candidate.port || "",
    network_type: candidate.network_type || "",
    protocol: candidate.protocol || "",
    candidate_type: candidate.candidate_type || "",
  };
}
