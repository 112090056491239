import type { ViewHook } from "phoenix_live_view";

export const Copy = {
  mounted(this: ViewHook) {
    this.el.addEventListener("click", (event: Event) => {
      event.preventDefault();

      let { content } = this.el.dataset;

      navigator.clipboard.writeText(content!).then(() => {
        const beforeHTML = this.el.innerHTML;
        this.el.innerText = "Copied to clipboard!";

        setTimeout(() => {
          this.el.innerHTML = beforeHTML;
        }, 2000);
      });
    });
  },
};
