const DEFAULT_BATCH_SIZE = 500;

export async function processEventBatch(events, callback) {
  if (!events || events.length === 0) return;

  let currentIndex = 0;

  const processNextBatch = async () => {
    const batch = events.slice(currentIndex, currentIndex + DEFAULT_BATCH_SIZE);

    for (let index = 0; index < batch.length; index++) {
      await callback(batch[index]);
    }

    currentIndex += DEFAULT_BATCH_SIZE;

    if (currentIndex < events.length) {
      requestAnimationFrame(processNextBatch);
    }
  };

  await processNextBatch();
}
