<script>
  import { createEventDispatcher } from "svelte";
  import { Icon, BugAnt, Play, ArrowPath } from "svelte-hero-icons";
  import { execution, features, jobs } from "../store";
  import DropdownMenu from "$lib/Elements/DropdownMenu.svelte";
  import Button from "$lib/Elements/Button.svelte";
  import SelectInput from "$lib/Inputs/SelectInput.svelte";
  import { deferred } from "$lib/utils/deferred";

  export let apps;
  export let devices;
  export let selectedApp = null;
  export let selectedDevice = null;

  const dispatch = createEventDispatcher();

  $: executionMode = execution.mode;
  $: developerMode = features.developerMode;
  $: isRunning = $executionMode == "in_progress";
  $: currentJob = jobs.currentJob;

  const isReady = deferred();

  let defaultText = "Trace";
  let textDebugButton = defaultText;
  let lastSelection = null;

  function getDeviceName(id) {
    return devices.find((device) => device.value == id)?.label ?? "n/a";
  }

  function debug() {
    lastSelection = "debug";
    textDebugButton = `Debug on ${getDeviceName(selectedDevice)}`;
    execution.triggerDebug(selectedApp, selectedDevice);
  }

  function trace() {
    lastSelection = "trace";
    textDebugButton = `Trace on ${getDeviceName(selectedDevice)}`;
    execution.triggerTrace(selectedApp, selectedDevice);
  }

  function onButtonClick(_e, { toggleDropdown }) {
    if (isRunning) {
      return execution.cancelJob($currentJob.id);
    }

    if (lastSelection === null) {
      toggleDropdown();
    }

    switch (lastSelection) {
      case "trace":
        return trace();
      case "debug":
        return debug();
    }
  }

  function resetButton() {
    textDebugButton = defaultText;
    lastSelection = null;
  }

  async function onOpen() {
    dispatch("open", {});
    await isReady.promise;
  }

  $: if (apps !== null) isReady.resolve();
  $: isSelectionIncomplete = !selectedApp || !selectedDevice;
  $: isSelectionIncomplete && resetButton();
</script>

<DropdownMenu
  buttonText={isRunning ? "Cancel Job" : textDebugButton}
  buttonClass={isRunning ? "opacity-85" : ""}
  {onOpen}
  onClick={onButtonClick}
  hasSeperateDropdown
>
  <div slot="prefix" class="flex items-center">
    {#if isRunning}
      <Icon src={ArrowPath} class="mr-1 h-4 w-4 animate-spin" />
    {:else}
      <Icon src={lastSelection == "debug" ? BugAnt : Play} class="w-4 h-4 mr-1" />
    {/if}
  </div>

  <div slot="content" let:close>
    <h3 class="text-md font-semibold text-gray-800 mb-1">Debug Automation</h3>
    <p class="text-sm text-gray-600 mb-3">Select an App and Device to debug</p>

    <div class="mb-2">
      <SelectInput
        bind:value={selectedApp}
        optional
        label="Select an App"
        options={apps}
        variant="wide"
      />
    </div>

    <div class="mb-4">
      <SelectInput
        bind:value={selectedDevice}
        optional
        label="Select a Device"
        options={devices}
        variant="wide"
      />
    </div>

    {#if isSelectionIncomplete}
      <p class="text-red-500 text-xs mb-4">
        Please select both an app and a device on the "Settings" page.
      </p>
    {/if}

    {#if isRunning}
      <p class="text-red-500 text-xs mb-4">Run in progress.</p>
    {/if}

    <div class="flex justify-end space-x-2">
      <Button variant="secondary" on:click={close}>Cancel</Button>

      {#if $developerMode}
        <Button
          disabled={isSelectionIncomplete || isRunning}
          icon={BugAnt}
          on:click={() => {
            debug();
            close();
          }}
        >
          Debug
        </Button>
      {/if}

      <Button
        disabled={isSelectionIncomplete || isRunning}
        icon={Play}
        on:click={() => {
          trace();
          close();
        }}
      >
        Trace
      </Button>
    </div>
  </div>
</DropdownMenu>
