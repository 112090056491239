<script>
  import { luaCode, toolbar, definitions, execution, workflow as workflowStore } from "./store";
  import DebugState from "./Workflow/DebugState.svelte";
  import DebugWorkflow from "./Workflow/DebugWorkflow.svelte";
  import EventStream from "./Workflow/EventStream.svelte";
  import FileTree from "./FileTree/FileTree.svelte";
  import CodeInput from "$lib/Inputs/CodeInput.svelte";
  import { Icon, CheckCircle, Clipboard, XCircle } from "svelte-hero-icons";
  import ImageManagerStats from "./Workflow/ImageManagerStats.svelte";

  $: toolbox = definitions.toolbox;
  $: activeTab = execution.activeTab;
  $: workflowJson = JSON.stringify($workflowStore, null, 2);

  let copied = false;
  let errorMessage = "";

  $: tabs = [
    { id: "tree", label: "Test Packs" },
    { id: "events", label: "Events" },
    ...($toolbar.show_debug
      ? [
          { id: "lua", label: "Lua" },
          { id: "workflow", label: "WF" },
          { id: "definition", label: "D" },
          { id: "toolbox", label: "TB" },
          { id: "state", label: "S" },
          { id: "imgstats", label: "I" },
        ]
      : []),
  ];

  function copyToClipboard() {
    navigator.clipboard.writeText(workflowJson).then(() => {
      copied = true;
      setTimeout(() => {
        copied = false;
      }, 3000);
    });
  }

  function handleWorkflowUpdate(updatedJson) {
    try {
      const parsedWorkflow = JSON.parse(updatedJson);
      workflowStore.internalUpdate({ definition: parsedWorkflow, isValid: true });
      errorMessage = "";
    } catch (error) {
      console.error("Invalid workflow JSON:", error);
      errorMessage = `Error: ${error.message}`;
    }
  }
</script>

<div class="w-80 flex-shrink-0 flex flex-col p-1 bg-white border-r">
  <div class="flex border-b -mr-1">
    {#each tabs as tab}
      <button
        class="text-xs py-1 px-2 {$activeTab === tab.id
          ? 'border-b border-blue-500 text-blue-500'
          : 'text-gray-500'}"
        on:click={() => execution.setActiveTab(tab.id)}
      >
        {tab.label}
      </button>
    {/each}
  </div>

  <div class="mt-2 overflow-auto h-full px-1">
    {#if $activeTab === "tree"}
      <FileTree />
    {:else if $activeTab === "events"}
      <EventStream />
    {:else if $activeTab === "lua"}
      <div class="p-2 bg-slate-800 text-white rounded w-full h-full overflow-auto">
        <p class="font-semibold mb-2 text-gray-300 text-xs">Lua Code</p>
        <pre class="font-semibold text-[10px]">{$luaCode}</pre>
      </div>
    {:else if $activeTab === "workflow"}
      <div class="flex flex-col">
        <div class="p-2 bg-slate-800 text-white rounded w-full h-full overflow-auto">
          <div class="flex justify-between items-center mb-2">
            <p class="font-semibold text-gray-300 text-xs">Workflow</p>
            <button
              on:click={copyToClipboard}
              class="text-xs py-1 px-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors flex items-center"
            >
              {#if copied}
                <Icon src={CheckCircle} class="w-4 h-4 mr-1" />
                Copied
              {:else}
                <Icon src={Clipboard} class="w-4 h-4 mr-1" />
                Copy
              {/if}
            </button>
          </div>
          {#if errorMessage}
            <div
              class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-2"
              role="alert"
            >
              <strong class="font-bold mr-1"><Icon src={XCircle} class="w-4 h-4 inline" /></strong>
              <span class="block sm:inline">{errorMessage}</span>
            </div>
          {/if}
          <CodeInput
            value={workflowJson}
            on:input={(e) => handleWorkflowUpdate(e.detail)}
            language="json"
            theme="dark"
          />
        </div>
        {#if $workflowStore}
          <DebugState node={$workflowStore} />
        {/if}
      </div>
    {:else if $activeTab === "toolbox"}
      <div class="p-2 bg-slate-800 text-white rounded w-full h-full overflow-auto">
        <p class="font-semibold mb-2 text-gray-300 text-xs">Toolbox</p>
        <pre class="text-[10px] text-gray-400">{JSON.stringify($toolbox, null, 2)}</pre>
      </div>
    {:else if $activeTab === "definition"}
      <div class="p-2 bg-slate-800 text-white rounded w-full h-full overflow-auto">
        <p class="font-semibold mb-2 text-gray-300 text-xs">Definitions</p>
        <pre class="text-[10px] text-gray-400">{JSON.stringify(
            definitions.getDefinitions(),
            null,
            2,
          )}</pre>
      </div>
    {:else if $activeTab === "imgstats"}
      <ImageManagerStats />
    {:else if $activeTab === "state"}
      <DebugWorkflow />
    {/if}
  </div>
</div>
