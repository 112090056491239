export const AlignTopIcon = {
  default: {
    a: {
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
    },
    path: [
      {
        d: "M5 3.75L19 3.75",
        stroke: "#0F172A",
        strokeWidth: "1.5",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      },
      {
        d: "M7 6.25L7 20.25H11L11 6.25L7 6.25Z",
        fill: "#0F172A",
      },
      {
        d: "M13 6.25L13 12.25H17L17 6.25L13 6.25Z",
        fill: "#0F172A",
      },
    ],
  },
};
