<script>
  import { Icon, ArrowsPointingIn, ArrowsPointingOut, Trash, Cog } from "svelte-hero-icons";
  import { tooltip } from "$utils/tooltip";
  import Checkbox from "$lib/Inputs/CheckboxInput.svelte";

  export let onExpandAll;
  export let onCollapseAll;
  export let onClearLogs;
  export let persistLogs;
  export let hideWorkflowEvents;
  export let hideUIDetectResults;

  let showDropdown = false;

  function toggleDropdown() {
    showDropdown = !showDropdown;
  }

  function handlePersistLogsChange(event) {
    persistLogs = event.detail;
  }

  let toolbarHideWorkflowEvents = $hideWorkflowEvents;
  let toolbarHideUIDetectResults = $hideUIDetectResults;

  function handleHideWorkflowEventsChange(event) {
    hideWorkflowEvents.update((_) => {
      // use `event.detail` to update the store value
      // as that's what user intended to do
      // same for `hideUIDetectResults`
      toolbarHideWorkflowEvents = event.detail;
      return toolbarHideWorkflowEvents;
    });
  }

  function handleHideUIDetectResultsChange(event) {
    hideUIDetectResults.update((_) => {
      toolbarHideUIDetectResults = event.detail;
      return toolbarHideUIDetectResults;
    });
  }
</script>

<div class="flex space-x-1 bg-gray-100 p-1 rounded-md justify-between mb-2">
  <div class="flex space-x-1">
    <button
      use:tooltip
      aria-label="Expand All"
      on:click={onExpandAll}
      class="p-1 hover:bg-gray-200 rounded"
    >
      <Icon src={ArrowsPointingOut} class="w-4 h-4" />
    </button>
    <button
      use:tooltip
      aria-label="Collapse All"
      on:click={onCollapseAll}
      class="p-1 hover:bg-gray-200 rounded"
    >
      <Icon src={ArrowsPointingIn} class="w-4 h-4" />
    </button>
    <div class="w-px bg-gray-300 mx-2"></div>
    <button
      on:click={onClearLogs}
      class="text-xs text-red-500 hover:text-red-700 font-semibold py-1 px-2 rounded flex items-center"
    >
      <Icon src={Trash} class="w-3 h-3 mr-1" />
      Clear
    </button>
  </div>
  <button
    on:click={toggleDropdown}
    class="p-1 hover:bg-gray-200 rounded"
    aria-label="Settings"
    use:tooltip
  >
    <Icon src={Cog} class="w-4 h-4" />
  </button>
</div>

{#if showDropdown}
  <div class="mb-4 p-3 bg-white border border-gray-200 rounded-md shadow-lg text-xs">
    <Checkbox
      bind:value={persistLogs}
      label="Persist Logs"
      on:input={handlePersistLogsChange}
      class="mb-2"
    />
    <Checkbox
      bind:value={toolbarHideWorkflowEvents}
      label="Hide Workflow Events"
      on:input={handleHideWorkflowEventsChange}
      class="mb-2"
    />
    <!-- TODO: maybe it would be better if we can select a subset of events to show/hide? -->
    <Checkbox
      bind:value={toolbarHideUIDetectResults}
      label="Hide UI Detect Results"
      on:input={handleHideUIDetectResultsChange}
    />
  </div>
{/if}
