export const RemoteButton = {
  mounted() {
    this.repeatInterval = null;

    const boundHandleDown = this.handleDown.bind(this);
    const boundHandleUp = this.handleUp.bind(this);
    const boundHandleLeave = this.handleLeave.bind(this);

    this.el.addEventListener("pointerdown", boundHandleDown);
    this.el.addEventListener("pointerup", boundHandleUp);
    this.el.addEventListener("pointercancel", boundHandleUp);
    this.el.addEventListener("pointerleave", boundHandleLeave);

    this.cleanup = () => {
      this.el.removeEventListener("pointerdown", boundHandleDown);
      this.el.removeEventListener("pointerup", boundHandleUp);
      this.el.removeEventListener("pointercancel", boundHandleUp);
      this.el.removeEventListener("pointerleave", boundHandleLeave);
    };
  },

  destroyed() {
    this.cleanup?.();
  },

  handleDown(_event) {
    this.pushDownEvent();
    this.startRepeatEvent();
  },

  handleUp(_event) {
    this.pushUpEvent();
    this.stopRepeatEvent();
  },

  handleLeave(_event) {
    if (this.repeatInterval) {
      this.pushUpEvent();
      this.stopRepeatEvent();
    }
  },

  pushDownEvent() {
    this.pushEventTo(this.el.dataset.dispatchTo, {
      command: this.el.dataset.remoteAction,
      event: "keydown",
      repeat: false,
    });
  },

  pushUpEvent() {
    this.pushEventTo(this.el.dataset.dispatchTo, {
      command: this.el.dataset.remoteAction,
      event: "keyup",
      repeat: false,
    });
  },

  startRepeatEvent() {
    this.repeatInterval = setInterval(() => {
      this.pushEventTo(this.el.dataset.dispatchTo, {
        command: this.el.dataset.remoteAction,
        event: "keydown",
        repeat: true,
      });
    }, 150);
  },

  stopRepeatEvent() {
    if (this.repeatInterval) {
      clearInterval(this.repeatInterval);
      this.repeatInterval = null;
    }
  },

  pushEventTo(id, detail) {
    const targetElement = document.querySelector(id);
    if (targetElement) {
      const customEvent = new CustomEvent("remote_control", { detail });
      targetElement.dispatchEvent(customEvent);
    }
  },
};
