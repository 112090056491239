<script>
  import {
    CpuChip,
    Beaker,
    CursorArrowRays,
    ListBullet,
    ChartBarSquare,
    Sun,
    EyeDropper,
    Newspaper,
    Photo,
    PlayCircle,
  } from "svelte-hero-icons";

  import Collapsible from "./Elements/Collapsible.svelte";
  import ActiveElement from "./Elements/ActiveElement.svelte";
  import MetricsTable from "./Elements/MetricsTable.svelte";
  import SystemStats from "./SystemStats.svelte";
  import GenericEventInspectPanel from "./InspectPanels/GenericEventInspectPanel.svelte";
  import ColorEventInspectPanel from "./InspectPanels/ColorEventInspectPanel.svelte";
  import ImageEventInspectPanel from "./InspectPanels/ImageEventInspectPanel.svelte";
  import TextEventInspectPanel from "./InspectPanels/TextEventInspectPanel.svelte";
  import PlaybackProgressBarInspectPanel from "./InspectPanels/PlaybackProgressBarInspectPanel.svelte";

  import { timeline, currentStep, features, playback } from "./store";
  import DataGrid from "./DataGrid/DataGrid.svelte";

  $: developerMode = features.developerMode;
  $: uniqueMatchers = timeline.uniqueMatchers;
  $: visibleMetricsEvents = timeline.visibleMetricsEvents;
  $: activeTimelineEvents = timeline.activeTimelineEvents;
  $: step = currentStep;
  $: accumulatedMotion = $step?.subtype === "detect_playback" ? $step?.accumulatedMotion : null;
  $: currentTime = playback.currentTime;
  $: currentMotion =
    accumulatedMotion?.find(
      (motion) =>
        motion.pts <= $currentTime * 1000 && $currentTime * 1000 <= motion.pts + motion.duration,
    ) || null;
  $: selectedTimelineItem = timeline.selectedTimelineItem;
  $: selectedEventBasedOnTime = timeline.selectedEventBasedOnTime;

  const matcherMap = {
    brightness: { component: GenericEventInspectPanel, icon: Sun },
    color: { component: ColorEventInspectPanel, icon: EyeDropper },
    text: { component: TextEventInspectPanel, icon: Newspaper },
    image: { component: ImageEventInspectPanel, icon: Photo },
    playback_progress_bar: { component: PlaybackProgressBarInspectPanel, icon: PlayCircle },
  };

  const ignoredEventKeys = ["accumulatedMotion"];

  function handleToggle(event) {
    const { id, active } = event.detail;
    timeline.toggleIsSelected(id, active);
  }
</script>

<div class="text-xs h-full flex flex-col overflow-auto -mt-2">
  {#if $developerMode}
    <Collapsible title="System Stats" icon={CpuChip} isOpen={false}>
      <SystemStats />
    </Collapsible>
  {/if}

  <Collapsible title="Active Matchers" icon={ListBullet} isOpen={false}>
    <div class="p-2 space-y-1">
      {#each $uniqueMatchers as element (element.ui_id)}
        <ActiveElement {element} showInOverlay={element.showInOverlay} on:toggle={handleToggle} />
      {/each}
    </div>
  </Collapsible>

  <Collapsible title="Metrics" icon={ChartBarSquare}>
    <div class="pl-1">
      <MetricsTable metrics={visibleMetricsEvents} />
    </div>
  </Collapsible>

  {#if $currentStep && $currentStep.subtype === "detect_playback"}
    <Collapsible title="Accumulated Motion" icon={ChartBarSquare}>
      <div class="w-full grid grid-cols-6 gap-0 overflow-hidden">
        <div
          class="grid grid-cols-subgrid col-span-6 border-b border-gray-200 odd:bg-white even:bg-gray-50"
        >
          <div class="col-span-2 text-left p-1 font-semibold">Step</div>
          <div class="col-span-4 text-left p-1 font-semibold">{$currentStep.label}</div>
        </div>
        <div
          class="grid grid-cols-subgrid col-span-6 border-b border-gray-200 odd:bg-white even:bg-gray-50"
        >
          <div class="col-span-2 text-left p-1 font-semibold">Hamming</div>
          <div class="col-span-4 text-left p-1 font-semibold">
            {currentMotion ? currentMotion.hamming.toFixed(0) : "Not available yet"}
          </div>
        </div>
        <div
          class="grid grid-cols-subgrid col-span-6 border-b border-gray-200 odd:bg-white even:bg-gray-50"
        >
          <div class="col-span-2 text-left p-1 font-semibold">Euclidean</div>
          <div class="col-span-4 text-left p-1 font-semibold">
            {currentMotion ? currentMotion.euclidean.toFixed(2) : "Not available yet"}
          </div>
        </div>
      </div>
    </Collapsible>
  {/if}

  {#if $selectedTimelineItem}
    <Collapsible title={"Selected Event"} icon={CursorArrowRays}>
      <DataGrid
        data={Object.entries($selectedTimelineItem).reduce((acc, [key, value]) => {
          if (ignoredEventKeys.includes(key)) return acc;

          acc.push({ id: key, label: key, value: value, type: "text" });
          return acc;
        }, [])}
      />
    </Collapsible>
  {/if}

  {#if $selectedEventBasedOnTime}
    {@const inspect = matcherMap[$selectedEventBasedOnTime.ui_type] || {
      component: GenericEventInspectPanel,
      icon: Beaker,
    }}
    <Collapsible title={$selectedEventBasedOnTime.name} icon={inspect.icon}>
      <svelte:component this={inspect.component} event={$selectedEventBasedOnTime} />
    </Collapsible>
  {/if}

  {#each $activeTimelineEvents as matcherEvent}
    {#if !$selectedEventBasedOnTime || $selectedEventBasedOnTime.ui_id !== matcherEvent.ui_id}
      {@const inspect = matcherMap[matcherEvent.ui_type] || {
        component: GenericEventInspectPanel,
        icon: Beaker,
      }}
      <Collapsible title={matcherEvent.name} icon={inspect.icon}>
        <svelte:component this={inspect.component} event={matcherEvent} />
      </Collapsible>
    {/if}
  {/each}
</div>
