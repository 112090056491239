<script>
  import { onMount, onDestroy, createEventDispatcher, tick } from "svelte";
  import { fade } from "svelte/transition";
  import { cubicOut } from "svelte/easing";
  import { CodeEditor } from "../../js/code/CodeEditor";
  import Portal from "$lib/Elements/Portal.svelte";
  import { Icon, XMark, Plus, ArrowsPointingOut } from "svelte-hero-icons";
  import VarNameGenerator from "./VarNameGenerator.svelte";

  export let value = "";
  export let language = "lua";
  export let theme = "dark";
  export let optional = false;
  export let variant = "embedded";
  export let isReadonly = false;
  export let isSynced = false;
  export let label = "Code Editor";

  let content = "";
  let inlineElement;
  let fullscreenElement;
  let editor;
  let isFullscreen = false;
  let isHidden = false;

  const dispatch = createEventDispatcher();

  onMount(async () => {
    content = value;
    await createEditor();
  });

  onDestroy(() => {
    if (editor) {
      editor.destroy();
    }
  });

  $: if (isSynced && editor) {
    content = value;
    editor.setContent(content);
  }

  async function createEditor() {
    await tick(); // Ensure the DOM is updated
    editor = new CodeEditor(inlineElement, {
      initialContent: content,
      language,
      theme,
      disabled: isReadonly,
      onChange: (newContent) => {
        if (!isReadonly) {
          content = newContent;
          dispatch("input", newContent);
        }
      },
    });
    await editor.initialize();
  }

  async function toggleFullscreen() {
    isFullscreen = !isFullscreen;
    await tick(); // Wait for the DOM to update

    if (isFullscreen) {
      fullscreenElement.appendChild(editor.view.dom);
    } else {
      inlineElement.appendChild(editor.view.dom);
    }
  }

  function clearCode() {
    if (confirm("Are you sure you want to clear all code?")) {
      content = "";
      isHidden = true;
      dispatch("input", content);
      if (editor) {
        editor.setContent("");
      }
    }
  }

  async function showEditor() {
    isHidden = false;
    await tick(); // Wait for the DOM to update
    if (!editor) {
      await createEditor();
    } else {
      inlineElement.appendChild(editor.view.dom);
    }
  }

  function addVariable(varName) {
    content = value + `\n${varName}`;
    editor.setContent(content);
  }
</script>

{#if variant === "standalone"}
  <div class="w-full h-full" bind:this={inlineElement}></div>
{:else}
  <div class="border rounded-lg p-3 max-w-2xl {isReadonly ? 'bg-gray-100' : ''}">
    <div class="flex justify-between items-center mb-2">
      <h3 class="font-semibold text-xs">{label}</h3>
      <div class="flex items-center space-x-2">
        {#if optional && !isHidden}
          <button
            type="button"
            class="text-gray-500 hover:text-gray-700 focus:outline-none"
            on:click={clearCode}
          >
            <Icon src={XMark} class="w-4 h-4" />
          </button>
        {/if}
        <VarNameGenerator on:input={(e) => addVariable(e.detail)} />
        {#if !isHidden}
          <button
            on:click={toggleFullscreen}
            class="text-blue-500 hover:text-blue-600 transition-colors duration-200"
          >
            <Icon src={ArrowsPointingOut} class="w-4 h-4" />
          </button>
        {/if}
        <slot name="dynamic" />
      </div>
    </div>

    {#if !isHidden}
      <div class="w-full h-64" bind:this={inlineElement}></div>
    {:else}
      <div class="flex justify-center items-center h-32">
        <button
          on:click={showEditor}
          class="flex items-center text-blue-500 hover:text-blue-600 text-sm font-medium transition-colors duration-200"
        >
          <Icon src={Plus} class="w-5 h-5 mr-1" />
          Create Code
        </button>
      </div>
    {/if}
  </div>
{/if}

{#if isFullscreen}
  <Portal target="body">
    <div
      transition:fade={{ duration: 400, easing: cubicOut }}
      class="z-50 fixed inset-0 bg-zinc-500/75 flex items-center justify-center p-4"
    >
      <div class="bg-white rounded-lg w-full h-full max-w-4xl max-h-[90vh] flex flex-col">
        <div class="flex justify-between items-center p-4 border-b gap-2">
          <h3 class="font-semibold">{label}</h3>
          <div class="flex-grow" />
          <VarNameGenerator on:input={(e) => addVariable(e.detail)} />
          <button on:click={toggleFullscreen} class="text-gray-500 hover:text-gray-700">
            <Icon src={XMark} class="w-6 h-6" />
          </button>
        </div>
        <div class="flex-grow overflow-auto p-4">
          <div class="w-full h-full" bind:this={fullscreenElement}></div>
        </div>
      </div>
    </div>
  </Portal>
{/if}
