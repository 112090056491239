export function msToHumanReadableTime(timeMs) {
  const timeSec = timeMs / 1000;

  const formatTime = (value, unit) => {
    return `${value.toFixed(2).replace(/\.00$/, "")} ${unit}`;
  };

  if (timeMs < 1000) {
    return formatTime(timeMs, "ms");
  } else if (timeSec < 60) {
    return formatTime(timeSec, "s");
  } else if (timeSec < 3600) {
    return formatTime(timeSec / 60, "m");
  } else {
    return formatTime(timeSec / 3600, "h");
  }
}

export function formatSeconds(seconds) {
  const mins = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${mins}:${secs.toString().padStart(2, "0")}`;
}

// show time in relative format
// default limit is 7 days
// if the difference between the uploaded time and current time is beyond the limit
// show the actual time
export function toRelativeTime(time_str, limit = 3600 * 24 * 7) {
  if (!time_str) return null;

  const now = new Date();
  const timepoint = new Date(time_str);

  const diffMs = now - timepoint;
  const diffSecs = Math.floor(diffMs / 1000);

  // Check if beyond the limit (in seconds)
  if (diffSecs > limit) {
    return timepoint.toLocaleString();
  }

  const diffMins = Math.floor(diffSecs / 60);
  const diffHours = Math.floor(diffMins / 60);
  const diffDays = Math.floor(diffHours / 24);
  const diffMonths = Math.floor(diffDays / 30);
  const diffYears = Math.floor(diffDays / 365);

  if (diffSecs < 60) {
    return diffSecs <= 1 ? "just now" : `${diffSecs} seconds ago`;
  } else if (diffMins < 60) {
    return diffMins === 1 ? "1 minute ago" : `${diffMins} minutes ago`;
  } else if (diffHours < 24) {
    return diffHours === 1 ? "1 hour ago" : `${diffHours} hours ago`;
  } else if (diffDays < 30) {
    return diffDays === 1 ? "yesterday" : `${diffDays} days ago`;
  } else if (diffMonths < 12) {
    return diffMonths === 1 ? "1 month ago" : `${diffMonths} months ago`;
  } else {
    return diffYears === 1 ? "1 year ago" : `${diffYears} years ago`;
  }
}
