<script>
  // svelte-ignore unused-export-let
  export let label;
  // svelte-ignore unused-export-let
  export let value = null;
  // svelte-ignore unused-export-let
  export let variant = "default";
  // svelte-ignore unused-export-let
  export let isReadonly = false;
</script>

<slot name="dynamic" />
