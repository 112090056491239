<script>
  import { createEventDispatcher } from "svelte";
  import { Icon, QuestionMarkCircle, XMark, EllipsisHorizontal } from "svelte-hero-icons";
  import { tooltip } from "$utils/tooltip";
  import { keyConfig } from "$utils/keyConfig";

  export let value = [];
  export let maxLength = 100;
  export let isReadonly = false;
  export let label = "Keypress Sequence Input";

  const keyMap = {
    " ": "ok",
    Enter: "ok",
    p: "power",
    h: "home",
    Escape: "back",
    b: "back",
    x: "input",
    i: "info",
    s: "settings",
    "+": "volumeUp",
    "-": "volumeDown",
    m: "mute",
    M: "menu",
    0: "num0",
    1: "num1",
    2: "num2",
    3: "num3",
    4: "num4",
    5: "num5",
    6: "num6",
    7: "num7",
    8: "num8",
    9: "num9",
    ">": "chNext",
    "<": "chPrev",
    ArrowLeft: "left",
    ArrowRight: "right",
    ArrowUp: "up",
    ArrowDown: "down",
  };

  const visibleKeys = ["left", "right", "up", "down", "ok", "back"];
  let showAllKeys = false;

  const dispatch = createEventDispatcher();

  let inputFocused = false;
  let invisibleInputRef;

  function addKey(key) {
    if (value.length < maxLength && !isReadonly) {
      value = [...value, key];
      dispatch("input", value);
    }
  }

  function removeKey(index) {
    if (!isReadonly) {
      value = value.filter((_, i) => i !== index);
      dispatch("input", value);
    }
  }

  function handleKeydown(event) {
    if (isReadonly) return;

    const key = event.key;
    const mappedKey = keyMap[key];

    if (mappedKey) {
      event.preventDefault();
      addKey(mappedKey);
    } else if (key === "Backspace" && value.length > 0) {
      event.preventDefault();
      value = value.slice(0, -1);
      dispatch("input", value);
    }
  }

  function getIconByKey(key) {
    const item = keyConfig.find((k) => k.key === key);
    return item ? item.icon : QuestionMarkCircle;
  }

  function focusInput() {
    if (!isReadonly) {
      invisibleInputRef.focus();
    }
  }

  function toggleShowAllKeys() {
    showAllKeys = !showAllKeys;
  }
</script>

<!--
  We're using an invisible <input> element here to ensure that the NoCode.js
  KeyboardDaemon correctly identifies this component as an input field.
  This prevents the daemon from capturing keypress events, such as
  "Delete block" when this component is focused.

  TODO: Remove this. It's no longer required after setting canHandleKey.
-->
<input
  bind:this={invisibleInputRef}
  type="text"
  class="sr-only"
  on:focus={() => (inputFocused = true)}
  on:blur={() => (inputFocused = false)}
  on:keydown={handleKeydown}
  readonly
  value={value.join(",")}
  aria-label={label}
/>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
<div
  class="flex flex-col items-start gap-2 p-3 border-2 rounded-lg outline-none max-w-md"
  class:border-gray-300={!inputFocused}
  class:border-blue-500={inputFocused}
  on:click={focusInput}
  role="region"
  aria-label={label}
>
  <div class="flex items-center justify-between w-full">
    <span class="text-xs font-semibold text-gray-700 w-full">Input Keys:</span>
    <slot name="dynamic" />
  </div>
  <div class="flex flex-wrap gap-2 mb-2 w-full" class:opacity-60={isReadonly}>
    {#each keyConfig.filter((k) => showAllKeys || visibleKeys.includes(k.key)) as { key, icon, label }}
      <button
        use:tooltip
        aria-label={label}
        class="p-1 rounded-md bg-gray-200 text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400 hover:bg-gray-300"
        on:click={() => addKey(key)}
        disabled={isReadonly}
      >
        {#if typeof icon === "string"}
          <span class="w-4 h-4 flex items-center justify-center text-sm font-normal">{icon}</span>
        {:else}
          <Icon src={icon} class="w-4 h-4" />
        {/if}
      </button>
    {/each}
    <button
      use:tooltip
      aria-label="Additional Keys"
      class="p-1 rounded-md bg-gray-200 text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400 hover:bg-gray-300"
      on:click={toggleShowAllKeys}
    >
      <Icon src={EllipsisHorizontal} class="w-4 h-4" />
    </button>
  </div>

  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div
    class="flex flex-wrap items-center gap-2 bg-gray-100 p-2 rounded-md min-h-[40px] w-full"
    class:cursor-pointer={!isReadonly}
    on:click={focusInput}
    role="button"
    tabindex={isReadonly ? "-1" : "0"}
    aria-label="Focus sequence input"
  >
    <span class="text-xs font-semibold text-gray-700 w-full mb-1">Sequence:</span>
    {#each value as key, index}
      <div class="bg-blue-600 text-white p-1 rounded-md flex items-center group relative">
        {#if typeof getIconByKey(key) === "string"}
          <span class="w-4 h-4 flex items-center justify-center text-sm font-normal"
            >{getIconByKey(key)}</span
          >
        {:else}
          <Icon src={getIconByKey(key)} class="w-4 h-4" />
        {/if}
        {#if !isReadonly}
          <button
            class="absolute inset-0 bg-red-600 opacity-0 group-hover:opacity-100 transition-opacity duration-200 rounded-md flex items-center justify-center"
            on:click|stopPropagation={() => removeKey(index)}
            aria-label={`Remove ${key} key`}
          >
            <Icon src={XMark} class="w-3 h-3 transform rotate-90" />
          </button>
        {/if}
      </div>
    {/each}
    {#if value.length === 0}
      <span class="text-xs text-gray-400 select-none">
        {isReadonly ? "No sequence entered" : "Click to focus, then use the available keys"}
      </span>
    {/if}
  </div>

  <slot name="docs" />
</div>
