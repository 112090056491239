export function makeGradient(scaleStops) {
  let can = document.createElement("canvas");
  let ctx = can.getContext("2d");

  return function scaleGradient(u) {
    let scale = u.scales["y"];

    // we want the stop below or at the scaleMax
    // and the stop below or at the scaleMin, else the stop above scaleMin
    let minStopIdx;
    let maxStopIdx;

    for (let i = 0; i < scaleStops.length; i++) {
      const stopVal = scaleStops[i][0];

      if (stopVal <= scale.min || minStopIdx == null) minStopIdx = i;

      maxStopIdx = i;

      if (stopVal >= scale.max) break;
    }

    if (minStopIdx == maxStopIdx) return scaleStops[minStopIdx][1];

    let minStopVal = scaleStops[minStopIdx][0];
    let maxStopVal = scaleStops[maxStopIdx][0];

    if (minStopVal == -Infinity) minStopVal = scale.min;
    if (maxStopVal == Infinity) maxStopVal = scale.max;

    let minStopPos = u.valToPos(minStopVal, "y", true);
    let maxStopPos = u.valToPos(maxStopVal, "y", true);

    const range = minStopPos - maxStopPos;

    const x0 = 0;
    const x1 = 0;
    const y0 = minStopPos;
    const y1 = maxStopPos;

    const gradient = ctx.createLinearGradient(x0, y0, x1, y1);

    let prevColor;

    for (let i = minStopIdx; i <= maxStopIdx; i++) {
      let s = scaleStops[i];

      let stopPos =
        i == minStopIdx ? minStopPos : i == maxStopIdx ? maxStopPos : u.valToPos(s[0], "y", true);
      let pct = (minStopPos - stopPos) / range;

      prevColor = s[1];
      gradient.addColorStop(pct, prevColor);
    }

    return gradient;
  };
}

// Read more: https://ai.googleblog.com/2019/08/turbo-improved-rainbow-colormap-for.html

// prettier-ignore
const kVec = {
  red:   [0.13572138, 4.6153926, -42.66032258, 132.13108234, -152.94239396, 59.28637943],
  green: [0.09140261, 2.19418839, 4.84296658, -14.18503333, 4.27729857, 2.82956604],
  blue:  [0.1066733, 12.64194608, -60.58204836, 110.36276771, -89.90310912, 27.34824973]
}

export function turboColormap(x) {
  const saturate = (value) => Math.min(Math.max(value, 0), 1);
  const dot = (a, b) => a.reduce((sum, val, idx) => sum + val * b[idx], 0);
  const toCSS = (channel) => Math.round(255 * saturate(channel));

  x = saturate(x);
  const v = [1.0, x, x * x, x * x * x, x * x * x * x, x * x * x * x * x];

  const red = dot(v, kVec.red);
  const green = dot(v, kVec.green);
  const blue = dot(v, kVec.blue);

  return `rgb(${toCSS(red)}, ${toCSS(green)}, ${toCSS(blue)})`;
}
