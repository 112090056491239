<script>
  import { afterUpdate } from "svelte";
  import { shouldIgnoreKeyEvent } from "../../../js/utils/keyboard";
  import Moveable from "svelte-moveable";
  import Selecto from "svelte-selecto";
  import { readable } from "$utils/shim";
  import {
    activeRegions,
    screenStores,
    currentAssets,
    selectedElements,
    selectedScreenRegions,
    selectedScreenId,
    toolbar,
    features,
    moveableRef,
  } from "../store";
  import AnnotationLabel from "./AnnotationLabel.svelte";
  import { tagColor } from "../elements";
  import AspectRatioSelector from "$lib/Elements/AspectRatioSelector.svelte";

  export let hidden;

  let basewidth = 1920;
  let baseheight = 1080;

  $: selectedImage = $currentAssets.selected;

  let targets = [];
  let selectoRef;
  let parentWidth = 0;
  let parentHeight = 0;
  let containerStyles = "";
  let scale = 1.0;
  let enableDynamicCanvasSize = false;
  let selectionIndex = 0;

  // unpack nested store
  const isReadonly = features.readonlyMode;
  let regions = $selectedScreenRegions.matchers;
  $: {
    // WORKAROUND: When deleting selectedScreenRegions
    regions = $selectedScreenRegions ? $selectedScreenRegions.matchers : readable([]);
  }

  $: {
    if (enableDynamicCanvasSize && $selectedImage) {
      scale = parentWidth / $selectedImage.width;
      containerStyles = `transform-origin: 0 0;
        transform: scale(${scale});
        height: calc(${$selectedImage.height}px * ${scale});`;
    } else {
      scale = parentWidth / basewidth;
      containerStyles = `transform-origin: 0 0;
        transform: scale(${scale});
        height: calc(${baseheight}px * ${scale});`;
    }

    // Update selector boxes
    if ($moveableRef && selectoRef) {
      $moveableRef.updateRect();
    }
  }

  $: {
    $selectedElements = targets.map((e) => String(e.dataset.id));
  }

  $: {
    if ($toolbar.draw_mode) {
      onSelect(null);
    }
  }

  function onAspectRatioChange(event) {
    const { aspectRatio } = event.detail;
    if (aspectRatio === "16:9") {
      basewidth = 1920;
      baseheight = 1080;
    } else if (aspectRatio === "16:10") {
      basewidth = 1920;
      baseheight = 1200;
    }
  }

  function onSelect(id, addToSelection = false) {
    if (id) {
      const element = document.querySelector(`[data-id='${id}']`);
      if (element) {
        if (addToSelection) {
          if (!targets.includes(element)) {
            targets = [...targets, element];
          }
        } else {
          targets = [element];
        }
      }
    } else {
      targets = [];
    }
  }

  afterUpdate(() => {
    const currentSelectedIds = $selectedElements;
    const currentTargetIds = targets.map((el) => el.dataset?.id).filter(Boolean);

    if (JSON.stringify(currentSelectedIds) !== JSON.stringify(currentTargetIds)) {
      if (currentSelectedIds.length === 0) {
        onSelect(null);
      } else {
        targets = []; // Clear existing targets
        currentSelectedIds.forEach((id) => onSelect(id, true));
      }
    }
  });

  function onAddElement(rect) {
    if ($isReadonly) {
      return;
    }

    if (!$selectedScreenId) {
      console.warn("Can not draw when no screen is selected");
      return;
    }

    const imageRect = document.querySelector(".elements").getBoundingClientRect();
    if ($toolbar.draw_mode) {
      const id = regions.add({
        top: Math.round(rect.top / scale - imageRect.y / scale),
        left: Math.round(rect.left / scale - imageRect.x / scale),
        width: Math.round(rect.width / scale),
        height: Math.round(rect.height / scale),
      });

      if (!id) {
        return;
      }

      $toolbar.draw_mode = false;

      // Wait a tick for element to be created
      setTimeout(() => onSelect(id), 0);
    }
  }

  function setStyle(node, region_id) {
    function recalculateStyles() {
      // TODO: Optimize
      const region = regions.getById(region_id);

      if (region) {
        node.style.top = 0;
        node.style.left = 0;
        node.style.width = region.width + "px";
        node.style.height = region.height + "px";
        node.style.transform = `translate(${region.left}px, ${region.top}px)`;
      }

      if ($moveableRef && selectoRef) {
        $moveableRef.updateRect();
      }
    }

    recalculateStyles();

    window.addEventListener("repaintRegions", recalculateStyles);
    return {
      destroy() {
        window.removeEventListener("repaintRegions", recalculateStyles);
      },
    };
  }

  function onKeyDown(event) {
    // Prevent global keyboard shortcuts when typing in an input or textarea element
    if (shouldIgnoreKeyEvent(event) || hidden || $isReadonly) {
      return;
    }

    const step = event.shiftKey ? 10 : 1;

    switch (event.key) {
      case "Delete":
      case "Backspace":
        screenStores.deleteSelected();
        break;
      case "ArrowUp":
        moveSelectedItems(0, -step);
        event.preventDefault();
        break;
      case "ArrowDown":
        moveSelectedItems(0, step);
        event.preventDefault();
        break;
      case "ArrowLeft":
        moveSelectedItems(-step, 0);
        event.preventDefault();
        break;
      case "ArrowRight":
        moveSelectedItems(step, 0);
        event.preventDefault();
        break;
      case "d":
        console.log("targets", targets);
        console.log("screenStores", screenStores);
        break;
      case "r":
        $toolbar.draw_mode = "region";
        break;
      case "v":
        $toolbar.draw_mode = false;
        break;
    }
  }

  function moveSelectedItems(deltaX, deltaY) {
    $selectedElements.forEach((id) => {
      const region = regions.getById(id);
      if (region) {
        regions.updatePosition(id, {
          left: region.left + deltaX,
          top: region.top + deltaY,
        });
      }
    });

    // Trigger a repaint of the regions
    window.dispatchEvent(new CustomEvent("repaintRegions"));
  }
</script>

<svelte:window on:keydown={onKeyDown} />

<Moveable
  bind:this={$moveableRef}
  target={targets}
  rootContainer={".elements"}
  draggable={!$isReadonly}
  resizable={!$isReadonly}
  origin={false}
  keepRatio={false}
  snappable={!$isReadonly}
  snapContainer={".elements > div"}
  bounds={{ left: 0, top: 0, right: 0, bottom: 0, position: "css" }}
  edge={[]}
  throttleDrag={1}
  on:drag={({ detail: e }) => {
    const id = e.moveable.getDragElement().dataset.id;
    regions.updatePosition(id, {
      left: Math.round(e.translate[0]),
      top: Math.round(e.translate[1]),
    });
    e.target.style.transform = e.transform;
  }}
  on:resize={({ detail: e }) => {
    const id = e.moveable.getDragElement().dataset.id;
    regions.updatePosition(id, {
      left: e.drag.translate[0],
      top: e.drag.translate[1],
      width: e.drag.width,
      height: e.drag.height,
    });

    e.target.style.width = `${e.width}px`;
    e.target.style.height = `${e.height}px`;
    e.target.style.transform = e.drag.transform;
  }}
  on:clickGroup={({ detail: e }) => {
    selectoRef.clickTarget(e.inputEvent, e.inputTarget);
  }}
  on:dragGroup={({ detail: groupEvent }) => {
    groupEvent.events.forEach((e) => {
      const id = e.moveable.getDragElement().dataset.id;
      regions.updatePosition(id, {
        left: Math.round(e.translate[0]),
        top: Math.round(e.translate[1]),
      });
      e.target.style.transform = e.transform;
    });
  }}
  on:resizeGroup={({ detail: resizeEvent }) => {
    resizeEvent.events.forEach((e) => {
      const id = e.moveable.getDragElement().dataset.id;
      // TODO: Batch update?
      regions.updatePosition(id, {
        left: Math.round(e.drag.translate[0]),
        top: Math.round(e.drag.translate[1]),
        width: Math.round(e.drag.width),
        height: Math.round(e.drag.height),
      });
      e.target.style.width = `${e.width}px`;
      e.target.style.height = `${e.height}px`;
      e.target.style.transform = e.drag.transform;
    });
  }}
  on:render={({ detail: e }) => {
    e.target.style.cssText += e.cssText;
  }}
  on:renderGroup={({ detail: e }) => {
    e.events.forEach((ev) => {
      ev.target.style.cssText += ev.cssText;
    });
  }}
/>

<Selecto
  bind:this={selectoRef}
  dragContainer={".elements"}
  selectableTargets={[".target"]}
  selectByClick={true}
  selectFromInside={true}
  toggleContinueSelect={"shift"}
  hitRate={0}
  on:select={({ detail: e }) => {
    if ($toolbar.draw_mode) {
      return;
    }
    if (e.isDragStartEnd) {
      return;
    }

    targets = e.selected;

    // Keep track of selection order (which selected does not)
    e.added.forEach((m) => {
      selectionIndex = selectionIndex + 1;
      m.dataset.selectionIndex = selectionIndex;
    });
  }}
  on:dragStart={({ detail: e }) => {
    // ??
    const target = e.inputEvent.target;
    if (
      $moveableRef.isMoveableElement(target) ||
      targets.some((t) => t === target || t.contains(target))
    ) {
      e.stop();
    }
  }}
  on:selectEnd={({ detail: e }) => {
    if (e.isDragStartEnd) {
      e.inputEvent.preventDefault();
      $moveableRef.waitToChangeTarget().then(() => {
        $moveableRef.dragStart(e.inputEvent);
      });
    } else {
      onAddElement(e.rect);
    }
    targets = e.selected;
  }}
/>

<div bind:clientWidth={parentWidth} class="bg-black drop-shadow-[0_0_10px_#000000AA]">
  <div class="elements" style={containerStyles}>
    <div
      class="bg-zinc-900 overflow-hidden"
      style={enableDynamicCanvasSize ? "" : `width: ${basewidth}px; height: ${baseheight}px`}
    >
      {#if $selectedImage}
        <img
          src={$selectedImage.url}
          crossorigin="anonymous"
          draggable="false"
          alt="Sample"
          class="select-none"
          class:cursor-crosshair={$toolbar.draw_mode}
          class:cursor-default={!$toolbar.draw_mode}
          style={`width: ${$selectedImage.width}px; height: ${$selectedImage.height}px; max-width: none;`}
        />
      {:else}
        <div class="h-full flex items-center justify-center text-6xl font-semibold text-gray-600">
          No image selected
        </div>
      {/if}
    </div>

    {#if $toolbar.show_overlay}
      {#each $activeRegions as region (region._id)}
        <div
          use:setStyle={region._id}
          data-id={region._id}
          class={`target box-border absolute border-2 ${tagColor(region.tagName)} bg-opacity-25`}
          class:cursor-crosshair={$toolbar.draw_mode}
        >
          {#if $toolbar.show_label}
            <AnnotationLabel {region} />
          {/if}
        </div>
      {/each}
    {/if}
  </div>
</div>

<AspectRatioSelector on:change={onAspectRatioChange} />

{#if $toolbar.show_debug}
  <div class="w-full h-32 overflow-auto border p-2 mt-4">
    {#each $activeRegions as region (region._id)}
      <pre
        class="text-xs mb-1"
        class:bg-gray-300={$selectedElements.includes(region._id)}>- {JSON.stringify(region)}</pre>
    {/each}
  </div>

  <div class="w-full border p-2 mt-4">
    <pre class="text-xs p-1">{JSON.stringify({ parentWidth, parentHeight, scale })}</pre>
  </div>
{/if}
