import uPlot from "uplot";
import { throttle } from "../../utils/throttle";

export function resize(element) {
  const minHeight = element.offsetHeight;

  function getSize() {
    return {
      width: element.offsetWidth,
      height: minHeight || element.offsetHeight,
    };
  }

  function onResize(uplot) {
    uplot.setSize(getSize());
  }

  const throttledOnResize = throttle(onResize, 50);

  function init(uplot) {
    window.addEventListener("resize", () => throttledOnResize(uplot));

    // TODO:
    // WORKAROUND
    // if (preset_id === "samples") {
    //   setTimeout(throttledOnResize, 200);
    // }
    // Workaround
    setTimeout(() => throttledOnResize(uplot), 200);
  }

  function destroy() {
    window.removeEventListener("resize", throttledOnResize);
  }

  return {
    opts: (_u, opts) => {
      uPlot.assign(opts, getSize());
    },
    hooks: {
      init,
      destroy,
    },
  };
}
