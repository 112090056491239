<script>
  import {
    Icon,
    ArrowsPointingIn,
    ArrowsPointingOut,
    Cog,
    ArrowUp,
    ArrowDown,
    Eye,
    EyeSlash,
  } from "svelte-hero-icons";
  import { tooltip } from "$utils/tooltip";
  import { persisted } from "$lib/utils/persisted-stores";
  import Checkbox from "$lib/Inputs/CheckboxInput.svelte";

  export let onExpandAll;
  export let onCollapseAll;
  export let onToggleSortDirection;
  export let onToggleShowDetails;
  export let showDetails = true;
  export let showAutoScheduled = persisted("show_additional_help", false);
  export let sortDirection = "desc";

  let showDropdown = false;

  function toggleDropdown() {
    showDropdown = !showDropdown;
  }

  function handleshowDetailsChange(event) {
    showDetails = event.detail;
    onToggleShowDetails(showDetails);
  }

  function handleshowAutoScheduledChange() {
    showAutoScheduled.update((value) => !value);
  }

  function handleSortDirectionChange() {
    sortDirection = sortDirection === "asc" ? "desc" : "asc";
    onToggleSortDirection(sortDirection);
  }
</script>

<div class="bg-gray-100 px-1 py-1 mb-2 w-full">
  <div class="flex justify-between items-center">
    <div class="flex space-x-1">
      <button
        use:tooltip
        aria-label="Expand All"
        on:click={onExpandAll}
        class="p-1 hover:bg-gray-200 rounded"
      >
        <Icon src={ArrowsPointingOut} class="w-4 h-4" />
      </button>
      <button
        use:tooltip
        aria-label="Collapse All"
        on:click={onCollapseAll}
        class="p-1 hover:bg-gray-200 rounded"
      >
        <Icon src={ArrowsPointingIn} class="w-4 h-4" />
      </button>
      <button
        use:tooltip
        aria-label="Toggle Sort Direction"
        on:click={handleSortDirectionChange}
        class="p-1 hover:bg-gray-200 rounded"
      >
        <Icon src={sortDirection === "desc" ? ArrowDown : ArrowUp} class="w-4 h-4" />
      </button>
      <div class="w-px bg-gray-300 mx-2"></div>
    </div>
    <div class="flex space-x-1">
      <button
        use:tooltip
        aria-label="Toggle Auto Scheduled"
        on:click={handleshowAutoScheduledChange}
        class="p-1 hover:bg-gray-200 rounded"
      >
        <Icon src={$showAutoScheduled ? Eye : EyeSlash} class="w-4 h-4" />
      </button>
      <button
        on:click={toggleDropdown}
        class="p-1 hover:bg-gray-200 rounded"
        aria-label="Settings"
        use:tooltip
      >
        <Icon src={Cog} class="w-4 h-4" />
      </button>
    </div>
  </div>
</div>

{#if showDropdown}
  <div class="mb-4 p-3 mx-2 bg-white border border-gray-200 rounded-md shadow-lg text-xs">
    <Checkbox bind:value={showDetails} label="Show Details" on:input={handleshowDetailsChange} />
  </div>
{/if}
