<script>
  import { createEventDispatcher } from "svelte";
  import { Icon } from "svelte-hero-icons";

  export let variant = "primary";
  export let disabled = false;
  export let type = "button";
  export let icon = null;

  const dispatch = createEventDispatcher();

  function handleClick(event) {
    dispatch("click", event);
  }

  $: variantClasses = (() => {
    switch (variant) {
      case "primary":
        return "bg-blue-600 hover:bg-blue-700 text-white";
      case "secondary":
        return "bg-gray-200 hover:bg-gray-300 text-gray-800";
      case "tertiary":
        return "bg-white hover:bg-gray-50 text-gray-800 border border-gray-300";
      default:
        return "bg-blue-600 hover:bg-blue-700 text-white";
    }
  })();

  $: buttonClasses = `
    px-3 py-1 rounded-md font-medium text-sm
    transition-colors duration-300
    flex items-center justify-center
    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
    ${variantClasses}
    ${disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}
    ${$$props.class || ""}
  `;
</script>

<button {type} class={buttonClasses} on:click={handleClick} {disabled} {...$$restProps}>
  {#if icon}
    <Icon src={icon} class="w-4 h-4 mr-2" />
  {/if}
  <slot></slot>
</button>
