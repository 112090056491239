export function clickOutside(node, excludeElements = []) {
  const handleClick = (event) => {
    const isExcluded =
      Array.isArray(excludeElements) &&
      excludeElements.some((el) => el && el.contains(event.target));

    if (
      node &&
      document.body.contains(node) &&
      !node.contains(event.target) &&
      !isExcluded &&
      !event.defaultPrevented
    ) {
      node.dispatchEvent(new CustomEvent("clickoutside", null));
    }
  };

  document.addEventListener("click", handleClick, true);

  return {
    destroy() {
      document.removeEventListener("click", handleClick, true);
    },
    update(newExcludeElements) {
      excludeElements = newExcludeElements;
    },
  };
}
