import uPlot from "uplot";
import { selected } from "../plugins/selected";

function drawPoints(u, seriesIdx, _idx0, _idx1) {
  const size = 6 * uPlot.pxRatio;

  uPlot.orient(
    u,
    seriesIdx,
    (
      series,
      _dataX,
      _dataY,
      scaleX,
      scaleY,
      valToPosX,
      valToPosY,
      xOff,
      yOff,
      xDim,
      yDim,
      _moveTo,
      _lineTo,
      _rect,
      arc,
    ) => {
      let d = u.data;
      let deg360 = 2 * Math.PI;

      // Check if we have color data (3rd array)
      const hasColorData = d.length > 2 && Array.isArray(d[2]);

      for (let i = 0; i < d[0].length; i++) {
        let xVal = d[0][i];
        let yVal = d[1][i];
        let colorIdx = hasColorData ? d[2][i] : 0;

        u.ctx.fillStyle = series.color(xVal, yVal, colorIdx);
        let p = new Path2D();

        if (xVal >= scaleX.min && xVal <= scaleX.max && yVal >= scaleY.min && yVal <= scaleY.max) {
          let cx = valToPosX(xVal, scaleX, xDim, xOff);
          let cy = valToPosY(yVal, scaleY, yDim, yOff);

          p.moveTo(cx + size / 2, cy);
          arc(p, cx, cy, size / 2, 0, deg360);
        }

        u.ctx.fill(p);
      }
    },
  );

  return null;
}

export function samples({ labels, data, onSelectedIndex, selectedIndex }) {
  const r_min = Math.min(...data[0]) - 0.5;
  const r_max = Math.max(...data[0]) + 0.5;

  const hasColorData = data.length > 2 && Array.isArray(data[2]);

  const colors = [
    "#9d174d", // red
    "#1d4ed8", // blue
    "#15803d", // green
    "#b45309", // amber
    "#7e22ce", // purple
    "#0891b2", // cyan
    "#4338ca", // indigo
    "#a16207", // yellow
    "#be185d", // pink
    "#1e40af", // dark blue
  ];

  const getColor = (idx) => colors[idx % colors.length];

  return {
    mode: 2,
    plugins: [selected({ onSelectedIndex, selectedIndex, eventName: "sample-selected" })],
    scales: {
      x: {
        time: false,
        range: [r_min, r_max],
      },
    },
    series: [
      {
        label: labels.m,
      },
      {
        label: "Individual measurement",
        width: 3,
        stroke: "red",
        color: (_x, _y, colorIdx) => (hasColorData ? getColor(colorIdx) : "#9d174d"),
        paths: drawPoints,
      },
    ],
    legend: {
      show: false,
    },
    axes: [{ label: labels.xlabel }, { size: 60, label: labels.ylabel }],
  };
}
