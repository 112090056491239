<script>
  import Modal from "$lib/Elements/Modal.svelte";
  import ImportableImage from "$lib/Elements/ImportableImage.svelte";

  export let src;
  export let alt;
  export let sessionId = null;

  let open = false;

  function toggleModal() {
    open = !open;
  }
</script>

<button on:click={toggleModal} class="cursor-zoom-in">
  <ImportableImage {src} {alt} class={$$props.class || ""} {sessionId} />
</button>

<Modal {open} showClose={false} on:close={toggleModal} maxWidth="80vw" class="p-0">
  <img {src} {alt} crossorigin="anonymous" class="max-h-dvh max-w-full object-contain" />
</Modal>
