import { writable, get, derived } from "$utils/shim";

export function instantiateFromDefinition(definitions) {
  return Object.fromEntries(
    Object.entries(definitions).map(([key, value]) => [key, value.default]),
  );
}

export function createDefinitionStore() {
  const userRoles = writable([]);
  const definitions = writable({});
  const groupDefinitions = writable({});
  const rootDefinitions = {};
  const aliases = {};

  function isStepAccessibleToUser(step, userRoles) {
    return !step.metadata.restrictions || userRoles.includes(step.metadata.restrictions);
  }

  function makeToolboxStep(step) {
    if (step.componentType === "task") {
      // These are the fields used by the SequentialWorkflowDesigner as "step"
      // These are also the fields persisted in the workflow

      return {
        name: step.name,
        componentType: step.componentType,
        type: step.type,
        properties: instantiateFromDefinition(step.inputs),
      };
    } else {
      return { ...step, inputs: undefined, outputs: undefined, metadata: undefined };
    }
  }

  function iconUrlProvider(_componentType, type) {
    const stepDefinitions = get(definitions)[type];
    return `/icons/${stepDefinitions?.metadata?.icon ?? "default.svg"}`;
  }

  function labelProvider(step) {
    const stepDefinitions = get(definitions)[step.type];
    return stepDefinitions?.metadata?.name ?? step.type;
  }

  const toolbox = derived(
    [definitions, groupDefinitions, userRoles],
    ([$definitions, $groupDefinitions, $userRoles]) => {
      const groups = {};
      Object.keys($definitions)
        .map((key) => ({ ...$definitions[key], type: key }))
        .filter((step) => isStepAccessibleToUser(step, $userRoles))
        .forEach((step) => {
          const groupId = step.metadata.group || "default";
          groups[groupId] = groups[groupId] || { ...$groupDefinitions[groupId] };
          groups[groupId].steps = groups[groupId].steps || [];
          groups[groupId].steps.push(makeToolboxStep(step));
        });

      const groupsAsList = Object.keys(groups)
        .map((key) => groups[key])
        .sort((a, b) => a.position - b.position);

      return {
        groups: groupsAsList,
        labelProvider: labelProvider,
      };
    },
  );

  function updateObjectRootDefaults(properties = {}) {
    const updatedProperties = { ...properties };
    Object.entries(rootDefinitions).forEach(([property, propertyType]) => {
      if (properties[property] === undefined) {
        updatedProperties[property] = propertyType.default;
      }
    });
    return updatedProperties;
  }

  function updateOrCreateStep(stepType, step) {
    definitions.update((state) => {
      return { ...state, [stepType]: step };
    });
  }

  function initialize(stepDefinitions, rootDefinition, roles) {
    userRoles.set(roles);

    const groups = {};

    Object.values(stepDefinitions).forEach((tool) => {
      const groupId = tool.metadata.group || "default";

      if (!groups[groupId]) {
        groups[groupId] = {
          groupId,
          name: tool.metadata.group_name,
          position: tool.metadata.group_position,
        };
      }

      (tool.aliases || []).forEach((alias) => {
        aliases[alias] = tool.type;
      });

      updateOrCreateStep(tool.type, {
        componentType: tool.componentType,
        name: tool.metadata.name,
        metadata: tool.metadata,
        properties: tool.properties || {},
        sequence: tool.sequence || undefined,
        branches: tool.branches || undefined,
        inputs: Object.fromEntries((tool.inputs || []).map((input) => [input.name, input])),
        outputs: Object.fromEntries((tool.outputs || []).map((output) => [output.name, output])),
      });
    });

    groupDefinitions.set(groups);

    rootDefinition.forEach((step) => {
      rootDefinitions[step.name] = step;
    });
  }

  return {
    initialize,
    toolbox,
    exists: (type) => type in get(definitions),
    getDefinitions: () => get(definitions),
    getDefinition: (type) => get(definitions)[type],
    getRootDefinitions: () => rootDefinitions,
    resolveAlias: (alias) => aliases[alias] || alias,
    updateObjectRootDefaults,
    iconUrlProvider,
    reset() {
      userRoles.set([]);
      definitions.set({});
      groupDefinitions.set({});
    },
  };
}
