<script>
  import { Icon, ChevronRight, ChevronDown } from "svelte-hero-icons";
  import { execution, jobs } from "../store";
  import Toolbar from "./Toolbar.svelte";
  import ImportableImage from "$lib/Elements/ImportableImage.svelte";
  import { imageManager } from "$lib/shared_stores";
  import { persisted } from "$lib/utils/persisted-stores";

  let expandedEvents = {};
  let events = execution.eventLog;
  let persistLogs = false;

  const hideWorkflowEvents = persisted("hide_workflow_events", true);
  const hideUIDetectResults = persisted("hide_ui_detect_results", false);

  $: execution.setPersistLogs(persistLogs);

  $: filteredEvents = $events
    .filter((event) => !$hideWorkflowEvents || !event.type.startsWith("workflow"))
    .filter((event) => !$hideUIDetectResults || event.type !== "ui_detect_result");

  function initializeExpandedEvents() {
    expandedEvents = $events.reduce((acc, event) => {
      acc[event.id] = false;
      return acc;
    }, {});
  }

  initializeExpandedEvents();

  function toggleEvent(id) {
    expandedEvents[id] = !expandedEvents[id];
    expandedEvents = expandedEvents;
  }

  function clearLogs() {
    execution.clearLogs();
    expandedEvents = {};
  }

  function expandAll() {
    expandedEvents = $events.reduce((acc, event) => {
      acc[event.id] = true;
      return acc;
    }, {});
  }

  function collapseAll() {
    expandedEvents = $events.reduce((acc, event) => {
      acc[event.id] = false;
      return acc;
    }, {});
  }

  // See: event_components.ex
  function getEventTypeColor(type) {
    switch (type) {
      case "asset_imported":
      case "asset_uploaded":
      case "ui_detect_result":
      case "ui_screen_changed":
      case "visual_comparison":
        return "text-yellow-500";
      case "automation_started":
      case "automation_ended":
        return "text-blue-500";
      case "error":
        return "text-red-500";
      case "log":
      case "log_image":
      case "workflow":
        return "text-gray-500";
      case "metric":
      case "network_metrics":
        return "text-purple-500";
      case "test":
      case "test_cases":
        return "text-green-500";
      default:
        return "text-white";
    }
  }

  function getRelevantInfo(event) {
    if (event.type === "workflow") {
      const { step_id, type } = event.data;
      return `${type} - ${step_id}`;
    }
    return event.type;
  }

  function isImage(value) {
    return (
      value &&
      ((value instanceof Object &&
        value.image &&
        typeof value.image === "string" &&
        (value.image.startsWith("img:") || value.image.startsWith("https://"))) ||
        (typeof value === "string" && value.startsWith("img:")))
    );
  }

  function isImageUrl(value) {
    return value && typeof value === "string" && value.endsWith(".jpg");
  }

  function formatValue(value) {
    // If value is an object and might contain image data
    if (value && typeof value === "object") {
      const processed = {};
      for (const [key, prop] of Object.entries(value)) {
        processed[key] =
          typeof prop === "object" && "image" in prop && prop.image.startsWith("data:")
            ? "<truncated image>"
            : prop;
      }
      return JSON.stringify(processed, null, 2);
    }

    if (typeof value === "string") {
      return value;
    }

    return JSON.stringify(value);
  }
</script>

<Toolbar
  onExpandAll={expandAll}
  onCollapseAll={collapseAll}
  onClearLogs={clearLogs}
  bind:persistLogs
  {hideWorkflowEvents}
  {hideUIDetectResults}
/>

<div class="bg-gray-900 text-white p-2 font-mono text-xs rounded-md overflow-auto">
  {#if filteredEvents.length === 0}
    <div class="flex items-center justify-center h-full text-gray-600 p-4 text-md text-center">
      No events yet. Start a Trace to see some events.
    </div>
  {:else}
    {#each filteredEvents as event, index}
      <div class="border-gray-800 py-1" class:border-b={index !== filteredEvents.length - 1}>
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <!-- svelte-ignore a11y-no-static-element-interactions -->
        <div class="cursor-pointer flex items-center" on:click={() => toggleEvent(event.id)}>
          <Icon
            src={expandedEvents[event.id] ? ChevronDown : ChevronRight}
            class="w-3 h-3 text-gray-500 mr-1 flex-shrink-0"
          />
          <span class="font-bold {getEventTypeColor(event.type)} truncate">
            {getRelevantInfo(event)}
          </span>
        </div>
        {#if expandedEvents[event.id]}
          <div class="mt-1 ml-4">
            {#each Object.entries(event.data) as [key, value]}
              <div class="text-xs">
                <span class="text-purple-400">{key}:</span>
                {#if isImage(value)}
                  <div class="mt-2 mb-4">
                    <ImportableImage
                      src={imageManager.src(value.image || value)}
                      alt={key}
                      sessionId={$jobs.m3u8}
                    />
                  </div>
                {:else if isImageUrl(value)}
                  <a target="_blank" rel="noopener noreferrer" href={value}>
                    <span class="text-gray-300 break-all">{value}</span>
                    <div class="mt-2 mb-4">
                      <ImportableImage src={value} alt={key} sessionId={$jobs.m3u8} />
                    </div>
                  </a>
                {:else}
                  <span class="text-gray-300 whitespace-pre-wrap">{formatValue(value)}</span>
                {/if}
              </div>
            {/each}
          </div>
        {/if}
      </div>
    {/each}
  {/if}
</div>
