<script>
  import { onMount, onDestroy } from "svelte";

  export let number;
  export let live;

  function increase() {
    live.pushEvent("set_number", { number: number + 1 });
  }

  function decrease() {
    live.pushEvent("set_number", { number: number - 1 });
  }

  function handleUpdate(params) {
    if (!params) return;
    const { number: value } = params;
    number = value;
  }

  onMount(() => {
    live.handleEvent("server_set_number", handleUpdate);
  });

  onDestroy(() => {
    live.removeHandleEvent(handleUpdate);
  });
</script>

<div class="max-w-md rounded overflow-hidden shadow-lg m-4">
  <div class="px-6 py-4">
    <div class="font-bold text-xl mb-2">Svelte Component and Tailwind CSS is working</div>

    <p class="text-gray-700 text-base mb-2">
      The number is {number}
    </p>

    <button
      class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded"
      on:click={increase}>+</button
    >
    <button
      class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded"
      on:click={decrease}>-</button
    >
  </div>
</div>
