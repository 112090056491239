import CommentIcon from "./CommentIcon.svelte";
import { renderIcon } from "./utils";

export function createCommentExtension() {
  return {
    id: "commentBadge",
    createForStep(parentElement, _view, stepContext, componentContext) {
      const getHasComments = componentContext.validator.configuration.hasComments || (() => false);

      return {
        view: null,
        update(_previousBlockResult) {
          const hasComments = getHasComments(stepContext.step);
          if (hasComments && !this.view) {
            this.view = renderIcon(CommentIcon, parentElement);
            this.view.width = 20 + 4; // Fixes issue with BADGE_GAP
            this.view.id = "comment";
          } else if (!hasComments && this.view) {
            this.view.destroy();
            this.view = null;
          }

          return null;
        },
        resolveClick(_click) {
          return null;
        },
      };
    },

    createStartValue() {
      return null;
    },
  };
}
