<script>
  import DataGrid from "../DataGrid/DataGrid.svelte";

  export let metric;

  let data = [
    {
      id: "metric_time",
      label: "Time",
      value: metric.start,
      type: "time",
    },
    {
      id: "metric_value",
      label: "Value",
      value: metric.value,
      type: "text",
    },
  ];

  const knownTypes = {
    screenshot: "image",
  };
  const knownNames = {
    screenshot: "Screenshot",
    landingPage: "Landing Page",
    previousPage: "Previous Page",
  };
  for (let [key, value] of Object.entries(metric.tags ?? {})) {
    data.push({
      id: key,
      label: knownNames[key] || `Tag:${key}`,
      value: value,
      type: knownTypes[key] || "text",
    });
  }
</script>

<div
  class="flex items-center justify-between w-full pr-1 pb-2 pl-4 space-y-1 hover:bg-gray-100 rounded text-xs"
>
  <DataGrid {data} split="equal" />
</div>
