<script>
  import Tooltip from "./Tooltip.svelte";

  export let event;
  export let hoverWidth;
  export let width;
  // svelte-ignore unused-export-let
  export let x;

  width = width.toFixed(3);

  const timelineHeight = 35;
  const minValue = -70;
  const maxValue = 0;
  const diff = maxValue - minValue;
  const offset = Math.abs(minValue);
  const height = Math.max(((event.level + offset) / diff) * (timelineHeight - 8), 0).toFixed(3);
</script>

<div class="relative" style="left: 25px;">
  <Tooltip message={`${event.level.toFixed(2)} dB`} style="width: {hoverWidth}px;">
    <svg
      class="relative z-10 pt-1 w-full h-[{timelineHeight}px]"
      style="width: {width}px"
      viewBox="0 0 {width} {timelineHeight}"
    >
      <rect {height} {width} y={timelineHeight - height - 4} x="0" fill="#FFAD60" />
    </svg>
  </Tooltip>
</div>
