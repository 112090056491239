import { diff, rgbaToLab } from "color-diff";

export async function getPixelColor(src, mouseX, mouseY) {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const img = new Image();

    // For this to work we need to make sure that the image in the cache also
    // uses <img crossorigin="anonymous" />
    img.crossOrigin = "anonymous";
    img.onload = function () {
      canvas.width = img.width;
      canvas.height = img.height;
      context.drawImage(img, 0, 0, img.width, img.height);
      const pixelData = context.getImageData(mouseX, mouseY, 1, 1).data;
      const pixelColor = `rgb(${pixelData[0]}, ${pixelData[1]}, ${pixelData[2]})`;
      resolve(pixelColor);
    };
    img.onerror = function (error) {
      reject(error);
    };
    img.src = src;
  });
}

export async function getColorsInRegion(src, x, y, width, height) {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const img = new Image();

    // For this to work we need to make sure that the image in the cache also
    // uses <img crossorigin="anonymous" />
    img.crossOrigin = "anonymous";
    img.onload = function () {
      canvas.width = img.width;
      canvas.height = img.height;
      context.drawImage(img, 0, 0, img.width, img.height);
      const imageData = context.getImageData(x, y, width, height);
      const colors = [];

      for (let i = 0; i < imageData.data.length; i += 4) {
        const r = imageData.data[i];
        const g = imageData.data[i + 1];
        const b = imageData.data[i + 2];
        colors.push([r, g, b]);
      }
      resolve(colors);
    };
    img.onerror = function (error) {
      reject(error);
    };
    img.src = src;
  });
}

export function getColorHistogram(colors, similarityThreshold = 5, format = "rgb") {
  const histogram = new Map();

  // Count the occurrences of each color
  for (const [r, g, b] of colors) {
    const key = `${r},${g},${b}`;
    histogram.set(key, (histogram.get(key) || 0) + 1);
  }

  // Convert the histogram map to an array of color-count pairs
  const histogramArray = Array.from(histogram, ([key, count]) => [
    key.split(",").map(Number),
    count,
  ]);

  // Sort the histogram array in descending order based on count
  histogramArray.sort((a, b) => b[1] - a[1]);

  // Group similar colors together using color-diff
  const groupedColors = [];
  for (const [color, count] of histogramArray) {
    let grouped = false;
    const [r, g, b] = color;
    const rgbColor = { R: r, G: g, B: b };
    const labColor = rgbaToLab(rgbColor);

    for (let i = 0; i < groupedColors.length; i++) {
      const [groupColor, groupCount] = groupedColors[i];
      const groupRGBColor = { R: groupColor[0], G: groupColor[1], B: groupColor[2] };
      const labGroupColor = rgbaToLab(groupRGBColor);
      if (diff(labColor, labGroupColor) <= similarityThreshold) {
        groupedColors[i][0] = [
          Math.round((groupColor[0] * groupCount + r * count) / (groupCount + count)),
          Math.round((groupColor[1] * groupCount + g * count) / (groupCount + count)),
          Math.round((groupColor[2] * groupCount + b * count) / (groupCount + count)),
        ];
        groupedColors[i][1] += count;
        grouped = true;
        break;
      }
    }

    if (!grouped) {
      groupedColors.push([color, count]);
    }
  }

  // Sort the grouped colors array in descending order based on count
  groupedColors.sort((a, b) => b[1] - a[1]);

  // Get the top 10 dominant colors after grouping
  const dominantColors = groupedColors.slice(0, 10);

  return format === "rgb" ? toRGB(dominantColors) : toHex(dominantColors);
}

function toRGB(colors) {
  return colors.map(([[r, g, b], count]) => {
    return [`rgb(${r},${g},${b})`, count];
  });
}

function toHex(colors) {
  const h = (x) => x.toString(16).padStart(2, "0");
  return colors.map(([[r, g, b], count]) => {
    const hex = `#${h(r)}${h(g)}${h(b)}`.toUpperCase();
    return [hex, count];
  });
}
