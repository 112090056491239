import { writable } from "$utils/shim";

export const RunStatus = {
  AVAILABLE: "available",
  EXECUTING: "executing",
  COMPLETED: "completed",
  FAILED: "failed",
  INTERRUPTED: "interrupted",
};

export function createTestRunsStore(liveStore, playbackStore, timelineStore) {
  const testCases = writable([]);
  const selectedTestRunId = writable(null);
  let previousSelectedTestRunId = null;

  function setTestCaseOpenState(run, expand = true) {
    testCases.update((cases) =>
      cases.map((testCase) => {
        if (testCase.runs.some((r) => r.id === run.id)) {
          return { ...testCase, isOpen: expand };
        }
        return testCase;
      }),
    );
  }

  function toggleTestCase(testCaseId) {
    testCases.update((cases) =>
      cases.map((testCase) =>
        testCase.id === testCaseId ? { ...testCase, isOpen: !testCase.isOpen } : testCase,
      ),
    );
  }

  function expandAllTestCase(expand = true) {
    testCases.update((cases) => cases.map((testCase) => ({ ...testCase, isOpen: expand })));
  }

  function selectTestRun(run) {
    if (!run) {
      console.warn("Can not select test run, run is not set");
      return;
    }

    selectedTestRunId.set(run.id);
    setTestCaseOpenState(run, true);

    let isLiveRun = run.status === RunStatus.EXECUTING || run.status === RunStatus.AVAILABLE;
    playbackStore.setSource(run.m3u8, isLiveRun);
    playbackStore.enableLiveMode(isLiveRun);

    if (run.id !== previousSelectedTestRunId) {
      timelineStore.clearTimeline();
      previousSelectedTestRunId = run.id;
    }
  }

  return {
    initialize(testResults, run) {
      testCases.set(testResults);
      selectTestRun(run);
    },

    update(testResults) {
      testCases.set(testResults);
    },

    subscribe: testCases.subscribe,
    selectedTestRunId: { subscribe: selectedTestRunId.subscribe },

    toggleTestCase,
    expandAllTestCase,
    selectTestRun,

    reset() {
      testCases.set([]);
      selectedTestRunId.set(null);
    },
  };
}
