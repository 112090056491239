import uPlot from "uplot";

export function histogram({ labels }) {
  return {
    plugins: [],
    scales: {
      x: {
        time: false,
        distr: 2,
      },
    },
    series: [
      {},
      {
        label: labels.h,
        width: 0,
        lineInterpolation: null,
        stroke: "#1e40af",
        fill: "#1e40af40",
        paths: uPlot.paths.bars({ size: [1.0, 500], align: 1 }),
      },
    ],
    legend: {
      show: false,
    },
    axes: [
      {
        label: labels.xlabel,
        show: true,
        space: 40,
      },
      {
        space: 40,
        show: true,
        label: labels.ylabel || "Frequency",
        size: 50,
      },
    ],
  };
}
