const image = new Image();
image.src = "/images/watermark.svg";

const DEFAULT_CONFIG = {
  paddingX: 15, // Horizontal padding from edges in pixels
  paddingY: 5, // Vertical padding from edges in pixels
  width: 160, // Width of watermark in pixels
  opacity: 0.1, // Opacity of watermark (0-1)
};

const Watermark = {
  id: "chartWatermark",
  beforeDraw: (chart, args, options) => {
    const config = { ...DEFAULT_CONFIG, ...options };

    if (image.complete) {
      const ctx = chart.ctx;
      const { top, right } = chart.chartArea;
      const height = (config.width * image.height) / image.width;
      const x = right - config.width - config.paddingX;
      const y = top + config.paddingY;

      ctx.save();
      ctx.globalAlpha = config.opacity;
      ctx.drawImage(image, x, y, config.width, height);
      ctx.restore();
    } else {
      image.onload = () => chart.draw();
    }
  },
};

export default Watermark;
