import uPlot from "uplot";

import { presets } from "../presets";

function mergeHooks(hook_list_arg, hooks) {
  const hook_list = { ...hook_list_arg };
  Object.keys(hooks).forEach((hook_name) => {
    hook_list[hook_name] = hook_list[hook_name] || [];
    hook_list[hook_name].push(hooks[hook_name]);
  });
  return hook_list;
}

function makeHooksWrapper(hooks_map) {
  const hooks_wrapper = {};
  Object.keys(hooks_map).forEach((hook_name) => {
    hooks_wrapper[hook_name] = (...args) => {
      const fns = hooks_map[hook_name] || [];
      fns.forEach((fn) => fn(...args));
    };
  });
  return hooks_wrapper;
}

function generateHooksMap(plugins) {
  let hooks_map = {};
  plugins
    .map((plugin) => plugin.hooks || {})
    .forEach((hooks) => {
      hooks_map = mergeHooks(hooks_map, hooks);
    });
  return hooks_map;
}

export function preset(id, options) {
  if (!(id in presets)) {
    throw new Error(`Preset not found (${id})`);
  }

  const preset_opts = presets[id](options);
  const plugins = preset_opts.plugins || [];
  const hooks = preset_opts.hooks || {};

  delete preset_opts.plugins;
  delete preset_opts.hooks;

  const hooks_map = mergeHooks(generateHooksMap(plugins), hooks);
  const hooks_wrapper = makeHooksWrapper(hooks_map);

  return uPlot.assign(
    {
      opts: (u, opts) => {
        plugins.forEach((plugin) => {
          plugin.opts && plugin.opts(u, opts);
        });
        uPlot.assign(opts, preset_opts);
      },
    },
    { hooks: hooks_wrapper },
  );
}
