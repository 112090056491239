<script>
  import Tooltip from "./Tooltip.svelte";

  export let event;
  export let networkMaxSpeed;
  export let hoverWidth;
  export let width;
  // svelte-ignore unused-export-let
  export let x;

  function formatSpeed(bps) {
    if (bps < 1024) {
      return `${bps} B/s`;
    } else if (bps < 1024 * 1024) {
      return `${(bps / 1024).toFixed(2)} KB/s`;
    } else {
      return `${(bps / (1024 * 1024)).toFixed(2)} MB/s`;
    }
  }

  const timelineHeight = 35;
  const halfHeight = (timelineHeight - 3) / 2;
  const rxY = halfHeight - 0.3;

  let rxHeight = 0;
  let txHeight = 0;
  let txY = 0;

  networkMaxSpeed.subscribe((value) => {
    if (!value) return;

    rxHeight = (event.rx / value) * halfHeight;
    txHeight = (event.tx / value) * halfHeight;
    txY = halfHeight - txHeight;
  });
</script>

<div class="relative" style="left: 25px;">
  <Tooltip
    message={`D: ${formatSpeed(event.rx || 0)}\nU: ${formatSpeed(event.tx || 0)}`}
    style="width: {hoverWidth}px;"
  >
    <svg
      class="relative z-10 pt-1 w-full h-[{timelineHeight}px]"
      style="width: {hoverWidth}px"
      viewBox="0 0 {hoverWidth} {timelineHeight}"
    >
      <rect height={rxHeight} {width} y={rxY} x="0" fill="#007AFF" />
      <rect height={txHeight} {width} y={txY} x="0" fill="#F7509E" />
    </svg>
  </Tooltip>
</div>
