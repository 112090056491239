<script>
  import StatusCell from "./Cells/StatusCell.svelte";
  import ImageCell from "./Cells/ImageCell.svelte";
  import RegionCell from "./Cells/RegionCell.svelte";
  import NumericCell from "./Cells/NumericCell.svelte";
  import ColorCell from "./Cells/ColorCell.svelte";
  import TextCell from "./Cells/TextCell.svelte";
  import TimeCell from "./Cells/TimeCell.svelte";

  export let item;
  export let isEven = true;
  export let key_span = "col-span-2";
  export let value_span = "col-span-4";

  const cellComponents = {
    status: StatusCell,
    image: ImageCell,
    region: RegionCell,
    numeric: NumericCell,
    color: ColorCell,
    text: TextCell,
    time: TimeCell,
  };
</script>

<div
  class="grid grid-cols-subgrid col-span-6 border-b border-gray-200"
  class:bg-gray-50={!isEven}
  class:bg-white={isEven}
>
  {#if item.type === "list"}
    {#each item.value as subitem}
      <div class="p-1 {key_span} text-gray-600 font-semibold">{item.label}</div>
      <div class="{value_span} {item.noPadding || item.subtype === 'color' ? '' : 'p-1'}">
        <svelte:component this={cellComponents[item.subtype]} value={subitem} {...item.props} />
      </div>
    {/each}
  {:else}
    <div class="p-1 {key_span} text-gray-600 font-semibold">{item.label}</div>
    <div class="{value_span} {item.noPadding || item.type === 'color' ? '' : 'p-1'}">
      <svelte:component this={cellComponents[item.type]} value={item.value} {...item.props} />
    </div>
  {/if}
</div>
