import uPlot from "uplot";
import { selected } from "../plugins/selected";

export function samples({ labels, data, onSelectedIndex }) {
  const r_min = Math.min(...data[0]) - 1;
  const r_max = Math.max(...data[0]) + 1;

  return {
    plugins: [selected({ onSelectedIndex, eventName: "sample-selected" })],
    scales: {
      x: {
        time: false,
        range: [r_min, r_max],
      },
    },
    series: [
      {
        label: labels.m,
      },
      {
        label: "Individual measurement",
        width: 1,
        drawStyle: 2,
        lineInterpolation: null,
        stroke: "#E24D42",
        fill: "#E24D421A",
        paths: uPlot.paths.bars({ size: [0.9, 100] }),
      },
    ],
    legend: {
      show: false,
    },
  };
}
