<script>
  import { createEventDispatcher } from "svelte";
  import { clickOutside } from "$utils/clickOutside";
  import { Icon, ChevronDown, XMark } from "svelte-hero-icons";

  export let value;
  export let label;
  export let options = [];
  export let optional = false;
  export let variant = "default";
  export let isReadonly = false;

  const dispatch = createEventDispatcher();
  let isOpen = false;

  $: isObjectOptions = options.length > 0 && options[0] instanceof Object;
  $: normalizedOptions = isObjectOptions
    ? options
    : options.map((opt) => ({ value: opt, label: opt }));
  $: selectedOption = normalizedOptions.find((option) => option.value === value);

  function handleSelect(option) {
    if (!isReadonly) {
      value = option.value;
      isOpen = false;
      dispatch("change", value);
      dispatch("input", value);
    }
  }

  function clearValue() {
    if (!isReadonly) {
      value = null;
      dispatch("change", value);
      dispatch("input", value);
    }
  }

  function toggleDropdown() {
    isOpen = !isOpen;
  }

  function closeDropdown() {
    isOpen = false;
  }
</script>

<div class="flex flex-col basis-full {isReadonly ? 'opacity-60' : ''}">
  <div
    aria-label="Select"
    class="w-full flex items-center {$$props.class || ''}"
    use:clickOutside
    on:clickoutside={closeDropdown}
  >
    <div class="relative w-full">
      <button
        type="button"
        class="flex items-center w-full h-8 text-left rounded p-2 pr-8 text-xs bg-gray-100 border-0 focus:outline-none {isReadonly
          ? 'cursor-not-allowed'
          : 'focus:ring-2 focus:ring-blue-500'}"
        class:pl-8={variant === "narrow"}
        class:pl-12={variant === "default"}
        class:pl-16={variant === "medium"}
        class:pl-32={variant === "wide"}
        class:pl-40={variant === "ultrawide"}
        on:click={toggleDropdown}
        disabled={isReadonly}
      >
        {#if selectedOption}
          {#if selectedOption.image}
            <img
              src={selectedOption.image}
              alt={selectedOption.label}
              class="w-4 h-4 object-contain mr-2"
            />
          {/if}
          <span class="truncate">{selectedOption.label}</span>
        {/if}
      </button>

      <!-- svelte-ignore a11y-label-has-associated-control -->
      <label
        class="absolute left-2 top-1/2 -translate-y-1/2 text-xs text-gray-500 pointer-events-none transition-all {isReadonly
          ? 'cursor-not-allowed'
          : ''}"
      >
        {label}
      </label>

      <Icon
        src={ChevronDown}
        class="absolute right-2 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-500 pointer-events-none {isReadonly
          ? 'opacity-50'
          : ''}"
      />
    </div>

    {#if optional && !isReadonly}
      <button
        type="button"
        class="ml-2 text-gray-500 hover:text-gray-700 focus:outline-none"
        on:click={clearValue}
      >
        <Icon src={XMark} class="w-4 h-4" />
      </button>
    {/if}

    <slot name="dynamic" />
  </div>

  <div
    class="z-50 mt-1 py-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-40 overflow-auto top-full"
    class:hidden={!isOpen || isReadonly}
    class:mr-6={optional}
  >
    {#each normalizedOptions as option (option.value)}
      <button
        type="button"
        class="flex items-center w-full px-2 py-1 text-left text-xs hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
        on:click={() => handleSelect(option)}
      >
        {#if option.image}
          <img src={option.image} alt={option.label} class="w-4 h-4 object-contain mr-2" />
        {/if}
        <span class="truncate">{option.label}</span>
      </button>
    {/each}
  </div>
</div>
