import { shouldIgnoreKeyEvent } from "../utils/keyboard";
import { throttle } from "../utils/throttle";
import { MetricsPoller } from "./webrtc/stats";
import { expandIceServers } from "./webrtc/util";

const LOOKUP = {
  " ": "ok",
  Enter: "ok",
  ArrowUp: "up",
  ArrowDown: "down",
  ArrowLeft: "left",
  ArrowRight: "right",
  p: "power",
  h: "home",
  Escape: "back",
  b: "back",
  x: "input",
  i: "info",
  s: "settings",
  "+": "volume_up",
  "-": "volume_down",
  M: "mute",
  0: "number-0",
  1: "number-1",
  2: "number-2",
  3: "number-3",
  4: "number-4",
  5: "number-5",
  6: "number-6",
  7: "number-7",
  8: "number-8",
  9: "number-9",
  ">": "ch_next",
  "<": "ch_prev",
};

const FAILED_STATES = ["closed", "failed"];

const sendKeyDownEventThrottled = throttle((webrtc, event, command) => {
  webrtc.sendRemoteControl(command, event.type, event.repeat);
}, 100);

export const Webrtc = {
  async mounted() {
    this.cleanupEventListeners = null;
    this.bufferedIceCandidates = [];
    this.keyPress = {};
    this.longPressThreshold = 300; // ms, how long to wait before sending long press
    this.keyEventSeqNo = 0;
    const iceServers = expandIceServers(JSON.parse(this.el.dataset.iceServers));
    console.log("ice servers", iceServers);

    this.handleEvent("start_webrtc", async (data) => {
      console.log("Starting WebRTC connection:", data);

      this.videoStream = null;
      this.audioStream = null;

      this.peer = new RTCPeerConnection({
        // uncomment to test TURN relay
        // iceTransportPolicy: "relay",
        bundlePolicy: "max-bundle",
        iceServers,
      });

      const $video = this.el.querySelector("#device-stream-video");

      $video.addEventListener("playing", () => {
        console.log("playing content?");

        this.pushEvent("connection:changed", {
          state: "playing",
        });

        // Report resolution once video starts playing and dimensions are available
        if ($video.videoWidth && $video.videoHeight) {
          this.pushEvent("resolution_updated", {
            width: $video.videoWidth,
            height: $video.videoHeight,
          });
        }
      });

      const $audio = this.el.querySelector("#device-stream-audio");

      this.peer.addEventListener("track", (event) => {
        console.log("Received track event", event.track.kind, event);

        if (event.track.kind === "video") {
          this.videoStream = event.streams[0];
        }

        if (event.track.kind === "audio") {
          this.audioStream = event.streams[0];
        }
      });

      this.peer.addEventListener("connectionstatechange", async (event) => {
        console.log("Connection state changed", this.peer.connectionState, event);

        if (this.peer.connectionState === "connected") {
          console.log("Connected. Assigning stream to player");

          $video.srcObject = this.videoStream;
          $audio.srcObject = this.audioStream;

          this.metrics = new MetricsPoller(this.peer, (stats) => this.writeStats(stats));
        }

        /*
         * TODO: This needs more work as it reconnects under relay.
         */
        if (this.peer.connectionState === "disconnected") {
          console.log("restarting ice");

          this.peer.restartIce();
        }

        if (FAILED_STATES.includes(this.peer.connectionState) && this.metrics) {
          this.metrics.destroy();
          this.metrics = null;
        }

        this.pushEvent("connection:changed", {
          state: this.peer.connectionState,
        });
      });

      this.peer.addEventListener("icecandidate", async (event) => {
        if (event.candidate) {
          console.log("Sending ice candidate", event.candidate);
          this.pushEvent("send_ice_candidate", event.candidate);
        }
      });

      this.peer.addEventListener("icecandidateerror", (event) => {
        console.error("Ice candidate error", event);
      });

      this.peer.addEventListener("icegatheringstatechange", (event) => {
        console.log("Ice gathering state change", event);
      });

      this.peer.addEventListener("datachannel", (event) => {
        console.log("Remote has created a data channel", event.channel.label, event);

        this.remoteControl = event.channel;

        // Share the data channel with other hooks that need it
        window.sauronRemoteControl = this.remoteControl;

        // Dispatch an event to notify other components that the channel is ready
        window.dispatchEvent(
          new CustomEvent("remoteControlReady", {
            detail: { remoteControl: this.remoteControl },
          }),
        );

        this.remoteControl.addEventListener("message", (message) =>
          console.log("Received data channel messsage", message),
        );

        this.pushEvent("data_channel", { status: "open" });
      });

      this.handleEvent("sidecar_offer", async (offer) => {
        console.log("Received sidecar offer", offer);

        await this.handleOffer(offer);
      });

      this.handleEvent("station_event", async (json) => {
        window.dispatchEvent(
          new CustomEvent("updateAnnotations", {
            detail: json.data.message,
          }),
        );
        console.log("Received station event", json);
      });

      this.handleEvent("ice_candidate", async (candidate) => {
        try {
          await this.receiveIceCandidate(candidate);
        } catch (e) {
          console.error("Failed to add ice candidate", candidate, e);
        }
      });

      this.handleEvent("capture_screen", () => {
        console.log("Capturing screen");
        this.remoteControl.send(JSON.stringify({ type: "capture_screen" }));
      });

      this.handleEvent("setup_event_listeners", () => {
        this.setupEventListeners();
      });

      this.handleEvent("remove_event_listeners", () => {
        this.removeEventListeners();
      });

      this.handleEvent("execute_snippet", ({ snippet, ui_xml, snippet_id }) => {
        console.log("Executing code snippet", {
          snippet,
          ui_xml,
          snippet_id,
        });
        this.remoteControl.send(
          JSON.stringify({
            type: "execute_snippet",
            ui_xml,
            snippet,
            snippet_id,
          }),
        );
      });

      this.handleEvent("update_ui", ({ ui_xml }) => {
        console.log("Update UI only", {
          ui_xml,
        });
        this.remoteControl.send(
          JSON.stringify({
            type: "update_ui",
            ui_xml,
          }),
        );
      });

      // Events dispatched from SauronWeb.RemoteController.tv_remote_button/1
      this.el.addEventListener("remote_control", (message) => {
        const { command, event, repeat } = message.detail;

        this.sendRemoteControl(command, event, repeat);
      });

      if (data.offer) {
        await this.handleOffer(data.offer);

        data.ice_candidates.forEach((candidate) => this.addIceCandidate(candidate));
      }

      this.handleEvent("update_audio", ({ volume }) => {
        this.updateAudio(volume);
      });

      // Add touch event tracking for swipes
      this.touchStartX = 0;
      this.touchStartY = 0;
      this.touchStartTime = 0;
      this.isSwiping = false;
    });
  },

  setupEventListeners() {
    this.removeEventListeners();

    const $video = this.el.querySelector("#device-stream-video");

    const handleScreenTap = this.handleScreenTap.bind(this);
    const handleKeyPress = this.handleKeyPress.bind(this);
    const handleExecuteSnippet = this.handleExecuteSnippet.bind(this);

    // Use mouse events instead of touch events for swipe
    const handleMouseDown = this.handleMouseDown.bind(this);
    const handleMouseMove = this.handleMouseMove.bind(this);
    const handleMouseUp = this.handleMouseUp.bind(this);

    $video.addEventListener("click", handleScreenTap);
    $video.addEventListener("mousedown", handleMouseDown);
    $video.addEventListener("mousemove", handleMouseMove);
    $video.addEventListener("mouseup", handleMouseUp);
    // Also handle mouse leaving the video element
    $video.addEventListener("mouseleave", handleMouseUp);
    document.addEventListener("keydown", handleKeyPress);
    document.addEventListener("keyup", handleKeyPress);
    document.addEventListener("executedFromCodeEditor", handleExecuteSnippet);

    this._cleanupEventListeners = () => {
      $video.removeEventListener("click", handleScreenTap);
      $video.removeEventListener("mousedown", handleMouseDown);
      $video.removeEventListener("mousemove", handleMouseMove);
      $video.removeEventListener("mouseup", handleMouseUp);
      $video.removeEventListener("mouseleave", handleMouseUp);
      document.removeEventListener("keydown", handleKeyPress);
      document.removeEventListener("keyup", handleKeyPress);
      document.removeEventListener("executedFromCodeEditor", handleExecuteSnippet);
    };
  },

  removeEventListeners() {
    this._cleanupEventListeners?.();
  },

  async handleOffer(offer) {
    console.log("Handling remote offer", offer);
    await this.peer.setRemoteDescription(offer);

    const answer = await this.peer.createAnswer();

    await this.peer.setLocalDescription(answer);

    this.pushEvent("browser_answer", answer);

    this.bufferedIceCandidates.forEach(async (candidate) => {
      await this.addIceCandidate(candidate);
    });

    this.bufferedIceCandidates = [];
  },

  async receiveIceCandidate(candidate) {
    if (!this.peer.currentRemoteDescription) {
      console.log("Buffering ice candidate, no remote offer");
      this.bufferedIceCandidates.push(candidate);
      return;
    }

    await this.addIceCandidate(candidate);
  },

  async addIceCandidate(candidate) {
    try {
      console.debug("Adding ice candidate", candidate);
      await this.peer.addIceCandidate(candidate);
    } catch (e) {
      console.error("Failed to add ice candidate", candidate, e);
    }
  },

  reconnected() {
    this.pushEvent("connection:changed", {
      state: this.peer.connectionState,
    });
  },

  sendRemoteControl(command, event, repeat) {
    if (this.remoteControl.readyState !== "open") {
      // the data channel isn't open, don't try to send or
      // record commands that will not be sent
      return;
    }

    console.log(`Sending remote control event "${command}" (${event}, repeat ${repeat})`);

    this.remoteControl.send(
      JSON.stringify({
        type: "remote_control",
        command,
        event,
        repeat,
        seq: this.keyEventSeqNo++,
      }),
    );

    this.pushEvent("remote_control", {
      command,
      event,
      repeat,
      client_timestamp: performance.now(),
    });
  },

  handleKeyPress(event) {
    // Ignore events that shouldn't be handled (e.g. when typing in input fields)
    if (shouldIgnoreKeyEvent(event)) {
      return;
    }

    event.preventDefault();

    const keyMap = {
      m: "toggle_mute",
      t: "toggle_theater_mode",
      c: "screen_capture",
      k: "toggle_keyboard",
      "~": "toggle_editor",
    };

    if (event.type === "keydown") {
      const eventName = keyMap[event.key];
      const withModifier = event.metaKey || event.ctrlKey || event.altKey;

      if (!withModifier && eventName) {
        this.pushEvent(eventName, {});
        return;
      }
    }

    this.handleRemoteKeyPress(event);
  },

  handleRemoteKeyPress(event) {
    const command = LOOKUP[event.key];

    if (command) {
      const $el = document.querySelector("[data-remote-action=" + command + "]");

      if (event.type === "keydown") {
        sendKeyDownEventThrottled(this, event, command);

        if (event.repeat === false && $el) {
          const activeClassName = $el.dataset["activeClassName"];
          $el.classList.add(activeClassName);
        }
      } else if (event.type === "keyup") {
        this.sendRemoteControl(command, event.type, event.repeat);

        if ($el) {
          const activeClassName = $el.dataset["activeClassName"];
          setTimeout(function () {
            $el.classList.remove(activeClassName);
          }, 100);
        }
      }
    }
  },

  handleExecuteSnippet() {
    console.log("CMD+Enter triggered from code editor");
    this.pushEvent("execute_snippet", {});
  },

  handleScreenTap(event) {
    // If we were swiping, don't register a tap
    if (this.isSwiping) {
      this.isSwiping = false;
      return;
    }

    const videoRect = event.target.getBoundingClientRect();
    const x = event.clientX - videoRect.left;
    const y = event.clientY - videoRect.top;
    const normalizedX = x / videoRect.width;
    const normalizedY = y / videoRect.height;

    console.log("Screen tap:", { x: normalizedX, y: normalizedY });

    if (this.remoteControl.readyState === "open") {
      this.remoteControl.send(
        JSON.stringify({
          type: "remote_control",
          command: "tap",
          event: "click",
          repeat: false,
          coords: { x: normalizedX, y: normalizedY },
        }),
      );

      this.pushEvent("remote_control", {
        command: `tap ${normalizedX} ${normalizedY}`,
        event: "click",
        repeat: false,
        client_timestamp: performance.now(),
      });
    }
  },

  handleMouseDown(event) {
    // Prevent default to avoid text selection during swipe
    event.preventDefault();

    const videoRect = event.target.getBoundingClientRect();

    this.isMouseDown = true;
    this.touchStartX = (event.clientX - videoRect.left) / videoRect.width;
    this.touchStartY = (event.clientY - videoRect.top) / videoRect.height;
    this.touchStartTime = performance.now();
    this.isSwiping = false;
  },

  handleMouseMove(event) {
    if (!this.isMouseDown) return;

    // Mark as swiping if moved more than a small threshold
    const videoRect = event.target.getBoundingClientRect();
    const currentX = (event.clientX - videoRect.left) / videoRect.width;
    const currentY = (event.clientY - videoRect.top) / videoRect.height;

    const deltaX = Math.abs(currentX - this.touchStartX);
    const deltaY = Math.abs(currentY - this.touchStartY);

    if (deltaX > 0.03 || deltaY > 0.03) {
      this.isSwiping = true;
      // Prevent click event from firing when we're swiping
      event.preventDefault();
      event.stopPropagation();
    }
  },

  handleMouseUp(event) {
    if (!this.isMouseDown) return;

    this.isMouseDown = false;

    if (!this.isSwiping) return; // Was a click, not a swipe

    const videoRect = event.target.getBoundingClientRect();

    const endX = (event.clientX - videoRect.left) / videoRect.width;
    const endY = (event.clientY - videoRect.top) / videoRect.height;
    const duration = Math.min(performance.now() - this.touchStartTime, 1000);

    console.log("Swipe:", {
      startX: this.touchStartX,
      startY: this.touchStartY,
      endX,
      endY,
      duration,
    });

    if (this.remoteControl.readyState === "open") {
      this.remoteControl.send(
        JSON.stringify({
          type: "remote_control",
          command: "swipe",
          event: "swipe",
          coords: {
            startX: this.touchStartX,
            startY: this.touchStartY,
            endX,
            endY,
            duration,
          },
        }),
      );

      this.pushEvent("remote_control", {
        command: `swipe ${this.touchStartX} ${this.touchStartY} ${endX} ${endY} ${duration}`,
        event: "swipe",
        repeat: false,
        client_timestamp: performance.now(),
      });
    }

    // Prevent the click event from firing
    event.preventDefault();
    event.stopPropagation();
  },

  writeStats(stats) {
    this.pushEvent("webrtc-stats", { stats });
  },

  destroyed() {
    console.log("Closing RTCPeerConnection");

    if (this.metrics) {
      this.metrics.destroy();
      this.metrics = null;
    }

    if (this.peer) {
      this.peer.close();
    }

    this.removeEventListeners();
  },

  updateAudio(volume) {
    const $audio = this.el.querySelector("#device-stream-audio");

    if ($audio) {
      $audio.muted = volume === 0;
      $audio.volume = volume / 100;
    }
  },
};
