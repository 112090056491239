export const DistributeVerticalIcon = {
  default: {
    a: {
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
    },
    path: [
      {
        d: "M5 18.25L19 18.25",
        stroke: "#0F172A",
        strokeWidth: "1.5",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      },
      {
        d: "M5 4L19 4",
        stroke: "#0F172A",
        strokeWidth: "1.5",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      },
      {
        d: "M7 9H17V13H7V9Z",
        fill: "#0F172A",
      },
    ],
  },
};
