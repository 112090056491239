<script>
  import TimeControls from "./TimeControls.svelte";
  import TimelinePanelTrack from "./TimelinePanelTrack.svelte";
  import CurrentTimeIndicator from "./Elements/CurrentTimeIndicator.svelte";

  import { formatSeconds } from "$lib/utils/time";
  import { timeline, playback } from "./store";

  const { scaledTimelineWidth, uiStateSubtracks, workflowSubtracks, tracks, markerIncrement } =
    timeline;
  const { totalDuration, currentTime } = playback;

  let timelineElement;
  let timelineScrollContainer;

  function handleTimelineClick(event) {
    const offsetX = 8;
    const rect = timelineElement.getBoundingClientRect();
    const clickX = event.clientX - rect.left - offsetX;
    playback.goToTime((clickX / $scaledTimelineWidth) * $totalDuration);
  }

  function handleTimeUpdate(event) {
    playback.goToTime(event.detail);
  }

  $: timelineMarkers = Array.from(
    { length: Math.ceil($scaledTimelineWidth / $markerIncrement) },
    (_, index) => index * $markerIncrement,
  );

  $: trackHeaderSize = () => {
    const el = document.querySelector("[data-el-tracker-header]");
    if (!el) return 0;
    return el.getBoundingClientRect().width;
  };

  $: getSubtracks = (trackId) => {
    if (trackId === "state") return $uiStateSubtracks;
    else if (trackId === "workflow") return $workflowSubtracks;
    return [];
  };

  $: displayingTracks = () => {
    return $tracks.reduce((acc, track) => {
      return track.showSubtrack ? acc + getSubtracks(track.id).size : acc;
    }, $tracks.length);
  };

  const trackEventsLeft = 26;

  function drag(node) {
    let startY;
    let startMaxHeight;
    let startHeight;
    let fontSize;

    function handleMousedown(event) {
      event.preventDefault();
      startY = event.clientY;
      const panel = node.parentNode.querySelector("[data-el-timeline-panel]");

      fontSize = parseFloat(getComputedStyle(panel).fontSize);
      startMaxHeight = parseFloat(panel.style.maxHeight);
      startHeight = panel.getBoundingClientRect().height;

      window.addEventListener("mousemove", handleMousemove);
      window.addEventListener("mouseup", handleMouseup);
    }

    function handleMousemove(event) {
      computeAndSetMaxHeight(event);
    }

    function handleMouseup(event) {
      window.removeEventListener("mousemove", handleMousemove);
      window.removeEventListener("mouseup", handleMouseup);

      computeAndSetMaxHeight(event);
    }

    function computeAndSetMaxHeight(event) {
      const dy = -(event.clientY - startY);
      const dMaxHeight = dy / fontSize;

      let newMaxHeight = Math.max(8, startMaxHeight + dMaxHeight);
      const panel = node.parentNode.querySelector("[data-el-timeline-panel]");
      panel.style.maxHeight = `${newMaxHeight}rem`;
      panel.style.height = `${startHeight + dy}px`;
    }

    node.addEventListener("mousedown", handleMousedown);

    return {
      destroy() {
        node.removeEventListener("mousedown", handleMousedown);
      },
    };
  }
</script>

<div
  use:drag
  class="pt-1 border-b bg-white border-gray-200 cursor-ns-resize"
  data-el-timeline-panel-drag-bar
/>

<TimeControls />

<div
  data-el-timeline-panel
  class="bg-white border-t border-gray-200 text-xs min-h-8 overflow-hidden flex flex-col"
  style="max-height: 8rem; height: 100%;"
>
  <div
    bind:this={timelineScrollContainer}
    class="relative overflow-y-auto overflow-x-auto flex-grow"
  >
    <div
      class="flex h-6 border-b border-gray-200 sticky top-0 z-[100] bg-white"
      style="min-width: 100%; width: {$scaledTimelineWidth +
        trackEventsLeft +
        trackHeaderSize()}px;"
    >
      <!-- Spacer before timestamps -->
      <div
        class="w-36 flex-shrink-0 px-2 flex items-center sticky left-0 z-[100] border-r border-gray-200 bg-white"
      ></div>

      <!-- Timestamps -->
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <!-- svelte-ignore a11y-no-static-element-interactions -->
      <div
        class="relative cursor-col-resize"
        bind:this={timelineElement}
        on:click={handleTimelineClick}
        style="left: {trackEventsLeft}px; width: {$scaledTimelineWidth + trackHeaderSize()}px"
      >
        {#each timelineMarkers as marker, index}
          {#if index % 10 === 0}
            <span
              class="absolute leading-none bottom-2"
              style="left: {marker}px; transform: translateX(-50%);"
            >
              {formatSeconds(index)}
            </span>
          {/if}

          <span
            class="absolute w-[1px] h-[5px] bg-gray-500 bottom-0"
            style="left: {marker}px; transform: translateX(-50%);"
          />
        {/each}
      </div>
    </div>

    {#each $tracks as track}
      <TimelinePanelTrack {track} />
    {/each}

    <div
      class="absolute top-6 left-40 h-full pointer-events-none"
      style="width: {$scaledTimelineWidth}px; height: {displayingTracks() * 32}px"
    >
      <CurrentTimeIndicator
        scrollContainer={timelineScrollContainer}
        currentTime={$currentTime}
        totalDuration={$totalDuration}
        timelineWidth={$scaledTimelineWidth}
        on:timeupdate={handleTimeUpdate}
      />
    </div>
  </div>
</div>
