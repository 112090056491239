const DEFAULT_BATCH_SIZE = 500;

export function processEventBatch(events, callback) {
  if (!events || events.length === 0) return;

  let currentIndex = 0;

  const processNextBatch = () => {
    const batch = events.slice(currentIndex, currentIndex + DEFAULT_BATCH_SIZE);

    batch.forEach((event) => callback(event));

    currentIndex += DEFAULT_BATCH_SIZE;

    if (currentIndex < events.length) {
      requestAnimationFrame(processNextBatch);
    }
  };

  processNextBatch();
}
