<script>
  import { createEventDispatcher } from "svelte";
  import StepSelector from "$lib/Inputs/StepSelector.svelte";
  import OutputSelector from "$lib/Inputs/OutputSelector.svelte";

  const dispatch = createEventDispatcher();

  export let currentStepId = null;
  export let inputType = null;

  let selectedStep = null;
  let selectedOutput = null;

  $: if (!selectedStep) selectedOutput = null;

  function selectOutput(outputName) {
    selectedOutput = outputName;
    const stepId = selectedStep.id === "global_context" ? "context" : selectedStep.id;
    const outputValue = selectedStep.isPreviousStep
      ? outputName === "default"
        ? "$prev"
        : `$prev.${outputName}`
      : `$step["${stepId}"].${outputName}`;
    dispatch("change", outputValue);
  }

  function emitNull() {
    dispatch("change", null);
  }
</script>

<div class="mt-2 border rounded-lg p-2 max-w-2xl flex flex-col text-xs">
  <div class="flex">
    <StepSelector {currentStepId} bind:selectedStep includeGlobalContext={true} />

    <OutputSelector
      {selectedStep}
      {inputType}
      {selectedOutput}
      on:select={(e) => selectOutput(e.detail)}
    />
  </div>

  <button
    class="self-end mt-2 px-2 py-1 bg-gray-100 hover:bg-gray-200 rounded-md text-xs transition-colors"
    on:click={emitNull}
  >
    I don't want to use a block output
  </button>
</div>
