<script>
  import CheckboxInput from "$lib/Inputs/CheckboxInput.svelte";
  import TextInput from "$lib/Inputs/TextInput.svelte";
  import { tagRequireble } from "$lib/Designer/elements";
  import PropHelp from "../PropHelp.svelte";

  export let props;
  export let update;
  export let isReadonly = false;
  export let showAdditionalHelp = true;
</script>

<div class="flex mt-2">
  <TextInput value={props.name} on:input={(e) => update(e, "name")} label="Name" {isReadonly} />
</div>

{#if tagRequireble(props.tagName)}
  <div class="flex flex-col mt-2">
    <CheckboxInput
      value={props.required}
      on:input={(e) => update(e, "required")}
      label="Required"
      {isReadonly}
    />

    <PropHelp {showAdditionalHelp}>
      Required regions must match for the Screen to be considered active.
    </PropHelp>
  </div>
{/if}
