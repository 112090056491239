export class ImageManager {
  static prefix = "img:";

  constructor(options = {}) {
    if (ImageManager.instance) {
      return ImageManager.instance;
    }

    this.images = new Map();
    this.memoryUsage = 0;
    this.maxMemory = options.maxMemory || 100 * 1024 * 1024; // Default 100MB

    ImageManager.instance = this;
  }

  static getInstance(options = {}) {
    if (!ImageManager.instance) {
      new ImageManager(options);
    }
    return ImageManager.instance;
  }

  async hashData(str) {
    // Convert string to array buffer for hashing
    const buffer = new TextEncoder().encode(str);
    // Generate hash
    const hashBuffer = await crypto.subtle.digest("SHA-256", buffer);
    // Convert to hex string
    return (
      ImageManager.prefix +
      Array.from(new Uint8Array(hashBuffer))
        .map((b) => b.toString(16).padStart(2, "0"))
        .join("")
    );
  }

  // Estimate size of base64 string in bytes
  calculateImageSize(base64String) {
    const base64 = base64String.replace(/^data:image\/\w+;base64,/, "");
    return Math.round(base64.length * 0.75);
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  async store(base64Data) {
    if (!base64Data) return null;

    // Generate hash from image data
    const id = await this.hashData(base64Data);

    // If we already have this image, just return the id
    if (this.images.has(id)) {
      return id;
    }

    const imageSize = this.calculateImageSize(base64Data);

    if (this.memoryUsage + imageSize > this.maxMemory) {
      console.warn(`Image storage exceeds memory limit of ${this.formatBytes(this.maxMemory)}`);
    }

    const img = new Image();
    img.src = base64Data;

    this.images.set(id, {
      image: img,
      size: imageSize,
    });

    this.memoryUsage += imageSize;
    return id;
  }

  src(id) {
    return id.startsWith(ImageManager.prefix) ? this.images.get(id)?.image.src : id;
  }

  getStats() {
    return {
      totalImages: this.images.size,
      memoryUsed: this.memoryUsage,
      memoryUsedFormatted: this.formatBytes(this.memoryUsage),
      maxMemory: this.maxMemory,
      maxMemoryFormatted: this.formatBytes(this.maxMemory),
      utilizationPercentage: ((this.memoryUsage / this.maxMemory) * 100).toFixed(1),
    };
  }

  // Process event needs to be async now
  async processEvent(event) {
    if (!event?.data) return event;

    const processValue = async (obj) => {
      if (!obj) return obj;

      if (typeof obj === "object") {
        const newObj = Array.isArray(obj) ? [] : {};

        for (const [key, value] of Object.entries(obj)) {
          newObj[key] = await processValue(value);
        }

        return newObj;
      }

      if (typeof obj === "string" && obj.startsWith("data:image/")) {
        return await this.store(obj);
      }

      return obj;
    };

    return {
      ...event,
      data: await processValue(event.data),
    };
  }

  remove(id) {
    const imageData = this.images.get(id);
    if (imageData) {
      this.memoryUsage -= imageData.size;
      this.images.delete(id);
    }
  }

  clear() {
    this.images.clear();
    this.memoryUsage = 0;
  }
}
