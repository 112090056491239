<script>
  import ImagePreview from "$lib/Elements/ImagePreview.svelte";
  import { imageManager } from "$lib/shared_stores";

  export let value;
  export let alt = "Image";
  export let sessionId = null;
</script>

<ImagePreview
  src={imageManager.src(value)}
  {alt}
  class="max-w-full h-auto rounded-sm"
  {sessionId}
/>
