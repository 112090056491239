// prettier-ignore
const unpackObj = (obj) =>
  Object.keys(obj).map((name) => (obj[name] ? name : null));

// prettier-ignore
const processItem = (arg) =>
  typeof arg === "object" && arg !== null
    ? Array.isArray(arg)
      ? arg
      : unpackObj(arg)
    : arg;

function classnames(...args) {
  return args
    .map(processItem)
    .flat()
    .filter((item) => item)
    .join(" ");
}

/**

A simple JavaScript utility for conditionally joining classNames together.

### Usage:

```
cx('foo', 'bar');                 // => 'foo bar'
cx('foo', { bar: true });         // => 'foo bar'
cx({ 'foo-bar': true });          // => 'foo-bar'
cx({ 'foo-bar': false });         // => ''
cx({ foo: true }, { bar: true }); // => 'foo bar'
cx({ foo: true, bar: true });     // => 'foo bar'
```

Lots of arguments of various types:

```
cx('foo', { bar: true, duck: false }, 'baz', { quux: true });
// => 'foo bar baz quux'
```

other falsy values are just ignored

```
cx(null, false, 'bar', undefined, 0, { baz: null }, ''); // => 'bar'
```
*/
export const cx = classnames;
