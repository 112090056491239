<script>
  import { onMount, onDestroy } from "svelte";
  import {
    toolbar,
    initializeStore,
    exportedState,
    appAttributes,
    selectedElements,
    loadState,
  } from "./Designer/store";
  import { stateToStructuredData } from "./Designer/store/data";
  import { debounce } from "$utils/debounce";

  import RegionCanvas from "./Designer/Canvas/RegionCanvas.svelte";
  import TreeView from "./Designer/TreeView/TreeView.svelte";
  import PropWrapper from "./Designer/PropEditor/PropWrapper.svelte";
  import ImageSelectorBar from "./Designer/SourceSelector/ImageSelectorBar.svelte";
  import Menubar from "./Designer/Menubar/Menubar.svelte";
  import Toolbar from "./Designer/Toolbar/Toolbar.svelte";
  import CodeInput from "./Inputs/CodeInput.svelte";
  import { cx } from "$utils/cx";
  import { nonReactiveVariable } from "./utils/svelte";

  // TODO: We should remove inactive components from the DOM instead
  export let hidden = false;
  export let is_readonly = false;
  export let ui_code;
  export let ui_assets;
  export let live;
  export let mock_env = false;
  export let user_roles = ["user"];
  export let ui_code_as_xml = "";

  // Non-reactive value to track if the component is mounted
  const isMounted = nonReactiveVariable(false);

  const onChangeEventName = "ui_script_changed";

  $: {
    // We could update the store here and merge ui_assets but we already
    // receive the asset information via 'upload_complete'
  }

  $: if (isMounted.value) {
    pushDebounced($exportedState);
  }

  $: if ($toolbar.show_code) {
    selectedElements.set([]);
  }

  const pushDebouncedXML = debounce((code) => {
    live.pushEvent(onChangeEventName, { content: code });
  }, 500);

  const pushDebounced = debounce((ui_code) => {
    // We don't want to send updates back to Elixir when the code editor is enabled
    // otherwise we create an infinite loop of updates.
    if (!$toolbar.show_code) {
      live.pushEvent(onChangeEventName, {
        content: stateToStructuredData(ui_code, $appAttributes),
      });
    }
  }, 500);

  function handleCodeUpdate(ui_code) {
    if (!ui_code) return null;
    loadState(ui_code, ui_assets);
  }

  onMount(() => {
    initializeStore(live, {
      structuredData: ui_code,
      assets: ui_assets,
      user_roles,
      is_readonly,
    });
    live.handleEvent("update_designer_state", handleCodeUpdate);
    isMounted.set(true);
  });

  onDestroy(() => {
    live.removeHandleEvent(handleCodeUpdate);
  });

  function onCodeInput(event) {
    pushDebouncedXML(event.detail);
  }
</script>

{#if !hidden}
  <Menubar />

  <div
    class={cx("relative flex items-start space-x-8 px-4 py-4 automator-height-container", {
      "automator-mock-env": mock_env,
    })}
    id="region-designer-wrapper"
  >
    {#if $toolbar.show_code}
      <div class="absolute top-0 left-0 w-full h-full">
        <CodeInput
          value={ui_code_as_xml}
          language="xml"
          on:input={onCodeInput}
          theme="light"
          variant="standalone"
          isReadonly={is_readonly}
        />
      </div>
    {:else}
      {#if $toolbar.show_treeview_pane}
        <TreeView />
      {/if}
      <div class="flex-grow min-w-0 flex justify-between flex-col space-y-4 z-10">
        <div id="region-canvas-wrapper">
          <RegionCanvas {hidden} />
        </div>
        <div>
          <ImageSelectorBar />
        </div>
      </div>
      {#if $toolbar.show_prop_pane}
        <PropWrapper />
      {/if}
    {/if}
  </div>

  <Toolbar />

  {#if $toolbar.show_debug}
    <div class="rounded overflow-hidden shadow-lg m-4 bg-slate-200">
      <div class="px-6 py-4">
        <div class="flex flex-row w-full">
          <pre
            class="text-gray-700 text-xs mb-2 flex-1 border-r border-gray-300 pr-4">ui_code: {JSON.stringify(
              ui_code,
              null,
              2,
            )}</pre>

          <pre class="text-gray-700 text-xs mb-2 flex-1 pl-4">$exportedState: {JSON.stringify(
              stateToStructuredData($exportedState),
              null,
              2,
            )}</pre>
        </div>
      </div>
    </div>
  {/if}
{/if}
