<script>
  import { slide } from "svelte/transition";
  import {
    Icon,
    ChevronRight,
    ChevronDown,
    FolderOpen,
    Folder,
    Document,
    DocumentCheck,
    BookOpen,
  } from "svelte-hero-icons";
  import InlineEdit from "$lib/Inputs/InlineEdit.svelte";
  import { filetree } from "../store";

  export let item;

  $: selectedNode = filetree.selectedNode;
  $: isSelected = $selectedNode?.id === item.id;
  $: isMoving = filetree.isMoving;
  $: isCopying = filetree.isCopying;
  $: isLibrary = item.meta?.type === "library";
  $: folderIcon = item.is_folder
    ? isLibrary
      ? BookOpen
      : item.expanded
        ? FolderOpen
        : Folder
    : isLibrary
      ? Document
      : DocumentCheck;

  function toggleExpand() {
    if (item.is_folder) {
      item.expanded = !item.expanded;
    }
  }

  function handleLabelChange(event) {
    filetree.updateItemLabel(item.id, event.detail);
  }

  function handleFolderClick() {
    if ($isMoving) {
      filetree.moveItem($selectedNode.id, item.id);
    } else if ($isCopying) {
      filetree.copyItem($selectedNode.id, item.id);
    } else {
      filetree.selectNode(item);
    }
  }
</script>

<!-- svelte-ignore a11y-role-has-required-aria-props -->
<!-- svelte-ignore a11y-interactive-supports-focus -->
<!-- svelte-ignore a11y-click-events-have-key-events -->
<div
  class="flex items-center border border-transparent w-full rounded-md transition-colors duration-100 ease-in-out cursor-pointer"
  class:bg-blue-100={isSelected}
  class:border-blue-200={isSelected}
  on:click={handleFolderClick}
  role="treeitem"
  aria-expanded={item.expanded}
>
  <div class="flex items-center gap-x-1 py-0.5 px-1 w-full">
    <button
      class="{isSelected
        ? 'hover:bg-blue-200 focus:bg-blue-200'
        : 'hover:bg-gray-100 focus:bg-gray-100'} size-6 flex justify-center items-center rounded-md focus:outline-none disabled:opacity-50 disabled:pointer-events-none"
      on:click|stopPropagation={toggleExpand}
      disabled={!item.is_folder}
    >
      {#if item.is_folder}
        <Icon src={item.expanded ? ChevronDown : ChevronRight} class="w-4 h-4" />
      {/if}
    </button>

    <Icon
      src={folderIcon}
      class={`w-4 h-4 flex-shrink-0 ${isLibrary ? "text-blue-500" : "text-gray-500"}`}
    />

    <InlineEdit
      class="flex-grow px-1 text-left rounded text-sm text-gray-600 border-2 border-transparent "
      value={item.name}
      on:change={handleLabelChange}
    />
  </div>
</div>

{#if item.expanded && item.children && item.children.length > 0}
  <div transition:slide={{ duration: 100 }} class="ml-4 pl-2 border-l border-gray-200">
    {#each item.children as child (child.id)}
      <svelte:self item={child} />
    {/each}
  </div>
{/if}

{#if false}
  <safelist class="bg-blue-100 border border-blue-200 hover:bg-blue-300" />
{/if}
