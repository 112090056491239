<script>
  import { execution, toolbar, definitions as definitionStore } from "../store";

  import { Icon, ArrowUturnLeft, ChatBubbleBottomCenterText } from "svelte-hero-icons";
  import OpenDocumentation from "$lib/Elements/OpenDocumentation.svelte";
  import Pane from "$lib/Elements/Pane.svelte";
  import CopyText from "$lib/Elements/CopyText.svelte";
  import Debug from "$lib/Elements/Debug.svelte";
  import CheckboxInput from "$lib/Inputs/CheckboxInput.svelte";
  import InlineEdit from "$lib/Inputs/InlineEdit.svelte";
  import InputWrapper from "./InputWrapper.svelte";
  import StepState from "../Workflow/StepState.svelte";
  import { persisted } from "$lib/utils/persisted-stores";
  import CommentThread from "./CommentThread.svelte";

  let workflow;
  // svelte-ignore unused-export-let
  export { workflow as definition };
  export let context;
  export let step;
  export let isReadonly;

  let toggleTextInput; // callback from CommentThread

  // TODO: Handle definitionStore.exists(step.type) === false
  const stepDefinition = definitionStore.getDefinition(step.type);
  const state = execution.stepState(step.id);
  $: showStepState = $state && $state.status !== null;

  const additionalHelp = persisted("show_additional_help", true);
  $: showAdditionalHelp = $additionalHelp;

  const { doc, link } = stepDefinition.metadata;
  const { properties, componentType, type } = step;
  const metadata = step.metadata || {};

  function handleAddComment(event) {
    const newComment = event.detail;
    step.comments = [...(step.comments || []), newComment];
    context.notifyPropertiesChanged();
  }

  function handleDeleteComment(event) {
    const { index } = event.detail;
    step.comments = step.comments.filter((_, i) => i !== index);
    context.notifyPropertiesChanged();
  }

  function handleResolveComment(event) {
    const { index, resolver } = event.detail;
    step.comments[index].resolved = true;
    step.comments[index].resolver = resolver;
    context.notifyPropertiesChanged();
  }

  function onPropertyChanged(property, value) {
    const branchesById = getBranchesById(properties["branches"], step.branches);

    properties[property] = value;
    context.notifyPropertiesChanged();

    if (stepDefinition.inputs[property].name == "branches") {
      step.branches = updateBranches(branchesById, value);
      context.notifyChildrenChanged();
    }
  }

  function onMetaChanged(property, value) {
    step.metadata = step.metadata || {};
    step.metadata[property] = value;
    context.notifyPropertiesChanged();
  }

  function onNameChange(newName) {
    step.name = newName || stepDefinition.name;
    context.notifyNameChanged();
  }

  function resetName() {
    step.name = stepDefinition.name;
    context.notifyNameChanged();
  }

  function getBranchesById(oldBranchDef, branches) {
    if (!oldBranchDef || !branches) return;

    return Object.entries(branches).reduce((acc, [branch_name, branch]) => {
      const branchDef = oldBranchDef.find((b) => b.name === branch_name);
      if (branchDef && branchDef.id) {
        acc[branchDef.id] = [...branch];
      }
      return acc;
    }, {});
  }

  function updateBranches(branchesById, newSteps) {
    return newSteps.reduce((acc, branch) => {
      acc[branch.name] = [...(branchesById[branch.id] || [])];
      return acc;
    }, {});
  }
</script>

<Pane>
  <div
    class={$toolbar.hide_props && showStepState
      ? "max-h-6 overflow-hidden relative gradient-overlay"
      : ""}
  >
    <div class="border-b border-gray-200 px-3 pb-4 mb-4">
      <h3 class="font-semibold mb-2 text-gray-800 text-base flex items-center">
        <InlineEdit
          value={step.name || stepDefinition.name}
          on:change={(e) => onNameChange(e.detail)}
          class="block w-full text-left hover:outline outline-1 outline-slate-200 rounded-sm -mx-1 px-1"
          doubleClick={false}
        />
        <button
          on:click={resetName}
          class="ml-2 text-xs text-gray-500 hover:text-gray-700 focus:outline-none"
          title="Reset to default name"
        >
          <Icon src={ArrowUturnLeft} class="w-3 h-3" />
        </button>
      </h3>

      <div class="flex items-center space-x-2">
        <CopyText text={type} class="text-xs" />
        {#if link}
          <OpenDocumentation {link} />
        {/if}
        <button
          on:click={toggleTextInput}
          class="bg-transparent border-none cursor-pointer p-0 text-gray-500"
        >
          <Icon src={ChatBubbleBottomCenterText} class="w-4 h-4 -ml-1" />
        </button>
      </div>

      <CommentThread
        bind:toggleTextInput
        {isReadonly}
        comments={step.comments || []}
        on:addComment={handleAddComment}
        on:deleteComment={handleDeleteComment}
        on:resolveComment={handleResolveComment}
      />

      {#if doc && showAdditionalHelp}
        <p class="mt-2 text-xs text-gray-400 prose prose-slate">
          <!-- eslint-disable-next-line -->
          {@html doc}
        </p>
      {/if}

      <div class="mt-3 flex flex-col space-y-1">
        <CheckboxInput
          value={metadata.skip}
          on:input={(e) => onMetaChanged("skip", e.detail)}
          label="Skip block during execution"
        />
        <CheckboxInput
          value={metadata.continueOnError}
          on:input={(e) => onMetaChanged("continueOnError", e.detail)}
          label="Ignore errors, continue execution"
        />
      </div>
    </div>

    <div class="px-3 mb-4">
      <h4 class="font-semibold mb-4">Properties</h4>
      {#if ["task", "switch", "container"].includes(componentType)}
        {#each Object.entries(stepDefinition.inputs) as [property, propertyType]}
          <InputWrapper
            {properties}
            {property}
            {propertyType}
            {isReadonly}
            {showAdditionalHelp}
            {step}
            validationWarnings={$state?.validations[property] || null}
            on:change={(e) => onPropertyChanged(property, e.detail)}
          />
        {/each}
      {:else}
        <pre>{JSON.stringify(step, null, 2)}</pre>
      {/if}
    </div>
  </div>

  {#if showStepState}
    <StepState state={$state} />
  {/if}

  {#if $toolbar.show_debug}
    <Debug state={$state} {step} />
  {/if}
</Pane>

<style>
  .gradient-overlay:before {
    content: "";
    background: linear-gradient(0, white, transparent);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
  }
</style>
