export function deferred() {
  let resolve;
  let reject;
  return {
    promise: new Promise((innerResolve, innerReject) => {
      resolve = innerResolve;
      reject = innerReject;
    }),
    resolve,
    reject,
  };
}
