<script>
  import { createEventDispatcher } from "svelte";
  import { Icon, XMark } from "svelte-hero-icons";

  export let value = "";
  export let label;
  export let placeholder = "";
  export let rows = 5;
  export let language = "plaintext";
  export let optional = false;
  export let isReadonly = false;

  const id = String(Math.random());
  const dispatch = createEventDispatcher();

  function handleInput(event) {
    if (!isReadonly) {
      value = event.target.value;
      dispatch("input", value);
    }
  }

  function clearValue() {
    if (!isReadonly) {
      value = "";
      dispatch("input", value);
    }
  }
</script>

<div
  class="relative w-full flex flex-col items-start rounded bg-gray-100 {$$props.class || ''}"
  class:opacity-60={isReadonly}
>
  <div
    class="flex justify-between items-center w-full text-gray-500 border-b border-gray-200 mb-2 p-2"
  >
    <label for={id} class:cursor-not-allowed={isReadonly}>{label}</label>
    <div class="flex items-center">
      {#if optional && !isReadonly}
        <button
          type="button"
          class="text-gray-500 hover:text-gray-700 focus:outline-none"
          on:click={clearValue}
        >
          <Icon src={XMark} class="w-4 h-4" />
        </button>
      {/if}
      <slot name="dynamic" />
    </div>
  </div>
  <textarea
    {id}
    {rows}
    class="p-2 w-full text-xs border-0 font-mono bg-gray-100"
    class:cursor-not-allowed={isReadonly}
    {value}
    {placeholder}
    on:input={handleInput}
    data-language={language}
    readonly={isReadonly}
  ></textarea>
</div>
