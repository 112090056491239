<script>
  import { createEventDispatcher } from "svelte";
  import { Icon, XMark } from "svelte-hero-icons";

  export let value = 0;
  export let label;
  export let variant = "default";
  export let optional = false;
  export let signed = true;
  export let isReadonly = false;
  export let allowDecimals = true;

  const id = String(Math.random());
  const dispatch = createEventDispatcher();

  function handleInput(event) {
    if (!isReadonly) {
      const inputValue = event.target.value;
      const parseInput = allowDecimals ? parseFloat : parseInt;
      value = inputValue !== "" ? parseInput(inputValue, 10) : null;
      dispatch("input", value);
    }
  }

  function handleKeyPress(event) {
    if (!allowDecimals && (event.charCode < 48 || event.charCode > 57)) {
      event.preventDefault();
    }
  }

  function clearValue() {
    if (!isReadonly) {
      value = null;
      dispatch("input", value);
    }
  }
</script>

<div class="relative flex items-center {$$props.class || ''}">
  <input
    {id}
    step={allowDecimals ? "any" : $$props.step || 1}
    type="number"
    class="rounded p-2 w-full text-xs bg-gray-100 border-0"
    class:pl-8={variant == "narrow"}
    class:pl-12={variant == "default"}
    class:pl-32={variant == "wide"}
    class:pl-40={variant == "ultrawide"}
    class:cursor-not-allowed={isReadonly}
    class:opacity-60={isReadonly}
    value={value !== null ? value : ""}
    min={signed ? null : 0}
    on:input={handleInput}
    on:keypress={handleKeyPress}
    readonly={isReadonly}
  />
  {#if optional && !isReadonly}
    <button
      type="button"
      class="ml-2 text-gray-500 hover:text-gray-700 focus:outline-none"
      on:click={clearValue}
    >
      <Icon src={XMark} class="w-4 h-4" />
    </button>
  {/if}
  <slot name="dynamic" />
  <label for={id} class="absolute left-2 text-gray-500 {isReadonly ? 'cursor-not-allowed' : ''}"
    >{label}</label
  >
</div>
