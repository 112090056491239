import CheckMark from "./CheckMark.svelte";
import Spinner from "./Spinner.svelte";
import ErrorIcon from "./ErrorIcon.svelte";
import { renderIcon } from "./utils";

function renderStatusIcon(status, parentElement) {
  switch (status) {
    case "in_progress":
      return renderIcon(Spinner, parentElement);
    case "succeeded":
      return renderIcon(CheckMark, parentElement);
    case "failed":
      return renderIcon(ErrorIcon, parentElement);
    default:
      return null;
  }
}

export function createStatusBadgesExtension() {
  return {
    id: "execStatusBadge",
    createForStep(parentElement, _view, stepContext, componentContext) {
      // TODO: Find out why this is called excessively
      const getStatus = componentContext.validator.configuration.status || (() => null);

      return {
        view: null,
        update(_previusBlockResult) {
          const status = getStatus(stepContext.step);
          if (status === this.view?.id) {
            return null;
          }

          if (this.view) {
            this.view.destroy();
            this.view = null;
          }

          if (status) {
            this.view = renderStatusIcon(status, parentElement);
            this.view.id = status;
          }

          return null;
        },
        resolveClick(_click) {
          return null;
        },
      };
    },

    createStartValue() {
      return null;
    },
  };
}
