<g transform="translate(-11, 0)">
  <circle cx="11" cy="11" r="11" class="fill-gray-400"> </circle>
  <g class="sqd-icon-badge-rotate">
    <g transform="translate(3, 3)">
      <path
        d="M24 8c7.83 0 14.51 5.67 15.78 13.4a3.057 3.057 0 0 0 2.98 2.6c.88 0 1.72-.39 2.29-1.06.58-.68.82-1.57.67-2.44C44 9.84 34.8 2 24 2 13.2 2 4 9.84 2.28 20.5c-.15.87.1 1.76.67 2.44.57.67 1.41 1.06 2.29 1.06a3.057 3.057 0 0 0 2.98-2.6C9.49 13.67 16.17 8 24 8zm0 0"
        class="fill-white scale-[0.3333333333333333]"
      >
      </path>
    </g>
  </g>
</g>

<style global>
  .sqd-icon-badge-rotate {
    transform-box: fill-box;
    transform-origin: bottom;
    animation: sqd-icon-badge-rotate-animation 700ms infinite linear;
  }

  @keyframes sqd-icon-badge-rotate-animation {
    100% {
      transform: rotate(360deg);
    }
  }
</style>
