<script>
  export let value;
  export let statusLabel = {
    true: "active",
    false: "inactive",
  };

  function getStatusColor(status) {
    return status ? "bg-green-100 text-green-800" : "bg-yellow-100 text-yellow-800";
  }
</script>

<span class="{getStatusColor(value)} text-xs font-medium px-2 py-0.5 rounded">
  {statusLabel[value]}
</span>
