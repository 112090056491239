// It is possible to cross refence a store from another component
export { screenStoresAsList, selectScreen, selectRegion } from "./Designer/store";

export { createAssetStore } from "./Designer/store/assets";

export { execution } from "$lib/Automator/store";

import { ImageManager } from "$utils/imageManager";

export const imageManager = ImageManager.getInstance();
