<script>
  import ImageInput from "$lib/Inputs/ImageInput.svelte";
  import SelectInput from "$lib/Inputs/SelectInput.svelte";
  import NumericInput from "$lib/Inputs/NumericInput.svelte";
  import { assetGroupStore } from "$lib/Automator/store";
  import PropHelp from "../PropHelp.svelte";

  export let props;
  export let update;
  export let isReadonly = false;
  export let showAdditionalHelp = true;

  const modeOptions = [
    { value: "compare", label: "Compare" },
    { value: "template_match", label: "Template Match" },
  ];

  const compareMetrics = [
    { value: "rmse", label: "RMSE" },
    { value: "gray_rmse", label: "Gray RMSE" },
    { value: "perceptual_hamming", label: "Perceptual (Hamming)" },
    { value: "perceptual_euclidean", label: "Perceptual (Euclidean)" },
    { value: "ssim", label: "Structural Similarity" },
    { value: "dssim", label: "Structural Dissimilarity" },
  ];

  const templateMatchMethods = [
    { value: "sqdiff_normed", label: "Squared Difference" },
    { value: "ccorr_normed", label: "Cross Correlation" },
    { value: "ccoeff_normed", label: "Cross Coefficient" },
  ];

  let mode = props.mode;
  let metrics = props.metrics;
  let threshold = props.threshold;
  updateMetricsBasedOnMode();

  if (!threshold || !isFinite(threshold)) {
    threshold = 5;
  }

  let store = assetGroupStore.requestStore(props._id, "assets");
  const thresholdDocs = {
    compare: {
      rmse: "The maximum difference between the pixels of the two images.",
      gray_rmse:
        "The maximum difference between the pixels of the two images after converting them to grayscale.",
      perceptual_hamming:
        "The minimum distance between the two images. The higher the value, the more similar the images are.",
      perceptual_euclidean:
        "The minimum distance between the two images. The higher the value, the more similar the images are.",
      ssim: "The minimum similarity between the two images in the range of 0 to 100. The higher the value, the more similar the images are.",
      dssim:
        "The minimum disimilarity between the two images in the range of 0 to 100. The higher the value, the more different the images are.",
    },
    template_match: {
      sqdiff_normed:
        "The maximum value of the normalized squared difference between the two images. The higher the value, the more similar the images are.",
      ccorr_normed:
        "The minimum value of the normalized cross correlation between the two images. The higher the value, the more similar the images are.",
      ccoeff_normed:
        "The minimum value of the normalized cross coefficient between the two images. The higher the value, the more similar the images are.",
    },
  };

  function updateSelection(e) {
    update({ detail: e.detail }, "assets");
  }

  function updateMode(e) {
    mode = e.detail;
    updateMetricsBasedOnMode();
  }

  function updateMetricsBasedOnMode() {
    if (!mode || !modeOptions.some((option) => option.value === mode)) {
      mode = "compare";
    }

    if (mode === "compare") {
      update({ detail: mode }, "mode");
      if (!metrics || !compareMetrics.some((metric) => metric.value === metrics)) {
        metrics = "rmse";
        update({ detail: metrics }, "metrics");
      }
    } else {
      mode = "template_match";
      update({ detail: mode }, "mode");
      if (!metrics || !templateMatchMethods.some((method) => method.value === metrics)) {
        metrics = "ccoeff_normed";
        update({ detail: metrics }, "metrics");
      }
    }
  }
</script>

<div class="flex flex-col mt-2">
  <ImageInput
    multiple={false}
    label="Target Image"
    value={props.assets}
    {store}
    associatedElement={props._id}
    on:input={(e) => updateSelection(e)}
    {isReadonly}
  />
</div>

<div class="flex flex-col mt-2">
  <SelectInput
    value={mode}
    on:change={(e) => updateMode(e)}
    label="Mode"
    options={modeOptions}
    {isReadonly}
  />

  <PropHelp {showAdditionalHelp}>
    "Compare" mode will compare the image with the selected image. Currently, both images should be
    of the same size.<br /><br />

    "Match Template" mode will match the image with the selected template.
  </PropHelp>
</div>

{#if mode === "compare"}
  <div class="flex flex-col mt-2">
    <SelectInput
      value={metrics}
      on:change={(e) => update(e, "metrics")}
      label="Metric"
      options={compareMetrics}
      variant="medium"
      {isReadonly}
    />

    <PropHelp {showAdditionalHelp}>
      "RMSE" metric stands for Root Mean Squared Error. It is a measure of the average difference
      between the pixels of the two images.<br /><br />

      "Gray RMSE" metric is the same as RMSE but converts the image to grayscale before comparing.
    </PropHelp>
  </div>
{:else}
  <div class="flex flex-col mt-2">
    <SelectInput
      value={metrics}
      on:change={(e) => update(e, "metrics")}
      label="Method"
      options={templateMatchMethods}
      variant="medium"
      {isReadonly}
    />

    <PropHelp {showAdditionalHelp}>
      Result value is normalized to the range of 0 to 1. The higher the value, the more similar the
      images are.
    </PropHelp>
  </div>
{/if}

<div class="flex flex-col mt-2">
  <NumericInput
    value={threshold}
    on:input={(e) => update(e, "threshold")}
    label="Threshold"
    variant="wide"
    allowDecimals={true}
    {isReadonly}
  />

  <PropHelp {showAdditionalHelp}>
    {thresholdDocs[mode][metrics]}
  </PropHelp>
</div>
