<script>
  import NumericInput from "$lib/Inputs/NumericInput.svelte";

  export let props;
  export let update;
  export let isReadonly = false;
</script>

<div class="flex mt-2">
  <NumericInput value={props.row} on:input={(e) => update(e, "row")} label="Row" {isReadonly} />
</div>

<div class="flex mt-2">
  <NumericInput value={props.col} on:input={(e) => update(e, "col")} label="Col" {isReadonly} />
</div>
