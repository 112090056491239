<script>
  import {
    Icon,
    Play,
    Pause,
    ChevronLeft,
    ChevronRight,
    ChevronDoubleLeft,
    ChevronDoubleRight,
    Camera,
    InboxArrowDown,
    ArrowUturnLeft,
    MagnifyingGlassPlus,
    MagnifyingGlassMinus,
    ArrowsPointingOut,
    ArrowsPointingIn,
    SpeakerWave,
    SpeakerXMark,
  } from "svelte-hero-icons";

  import SliderInput from "$lib/Inputs/SliderInput.svelte";
  import { formatSeconds } from "$lib/utils/time";
  import { timeline, playback } from "./store";
  import { FRAMES_PER_SECOND } from "./store/playback";
  import LiveStatusToggle from "./Elements/LiveStatusToggle.svelte";

  const { totalDuration, currentTime, isPlaying, canCaptureCurrent, audioLevel } = playback;
  const { zoom } = timeline;

  $: totalTime = formatSeconds($totalDuration);
  $: currentTimeFormatted = formatSeconds($currentTime);
  $: zoomFormated = $zoom.toFixed(1) + "x";

  let isFullscreen = false;
  let showFrames = false;
  let previousStyle = {};

  function toggleFullscreen() {
    const container = document.querySelector("[data-el-timeline-panel-container]");
    const main = document.querySelector("[data-el-debugger-main]");
    const panel = document.querySelector("[data-el-timeline-panel]");
    if (container !== undefined) {
      isFullscreen = !isFullscreen;

      const drag = document.querySelector("[data-el-timeline-panel-drag-bar]");
      if (isFullscreen) {
        if (drag !== undefined) drag.style.display = "none";
        previousStyle = JSON.parse(JSON.stringify(panel.style));
        container.style = "position: absolute; inset: 0;";
        main.style = "position: relative;";
        panel.style = "position: relative; inset: 0px;";
      } else {
        if (drag !== undefined) drag.style.display = "";
        container.style = "";
        main.style = "";
        panel.style.height = previousStyle.height;
        panel.style.maxHeight = previousStyle.maxHeight;
      }
    }
  }

  function toFrames(time) {
    return Math.trunc(time * FRAMES_PER_SECOND);
  }
</script>

<div class="flex items-center justify-between px-4 py-1 bg-white">
  <div class="flex items-center space-x-4">
    <button
      type="button"
      class="min-w-20 text-sm text-left"
      on:click={() => (showFrames = !showFrames)}
      on:keydown={(e) => e.key === "Enter" && (showFrames = !showFrames)}
    >
      {#if !showFrames}
        <span class="text-gray-700">
          {currentTimeFormatted} <span class="text-gray-400">/ {totalTime}</span>
        </span>
      {:else}
        <span class="text-gray-700">
          {toFrames($currentTime)}F
          <span class="text-gray-400">/ {toFrames($totalDuration)}F</span>
        </span>
      {/if}
    </button>

    <LiveStatusToggle />
  </div>

  <div class="flex absolute left-1/2 -translate-x-1/2 items-center flex-grow space-x-1">
    <button class="p-3 rounded-full hover:bg-gray-200" on:click={() => playback.rewind()}>
      <Icon src={ArrowUturnLeft} class="w-4 h-4" />
    </button>
    <button class="p-3 rounded-full hover:bg-gray-200" on:click={() => playback.stepBackward(10)}>
      <Icon src={ChevronDoubleLeft} class="w-4 h-4" />
    </button>
    <button class="p-3 rounded-full hover:bg-gray-200" on:click={() => playback.stepBackward(1)}>
      <Icon src={ChevronLeft} class="w-4 h-4" />
    </button>
    <button
      class="p-3 bg-blue-500 text-white rounded-full hover:bg-blue-600"
      on:click={playback.togglePlay}
    >
      <Icon src={$isPlaying ? Pause : Play} class="w-5 h-5 {$isPlaying ? '' : 'pl-[1px]'}" />
    </button>
    <button class="p-3 rounded-full hover:bg-gray-200" on:click={() => playback.stepForward(1)}>
      <Icon src={ChevronRight} class="w-4 h-4" />
    </button>
    <button class="p-3 rounded-full hover:bg-gray-200" on:click={() => playback.stepForward(10)}>
      <Icon src={ChevronDoubleRight} class="w-4 h-4" />
    </button>
  </div>

  <div class="flex items-center space-x-1">
    <div class="flex items-center">
      <button class="p-3 rounded-full hover:bg-gray-200" on:click={() => playback.toggleMute()}>
        <Icon
          src={$audioLevel === 0 ? SpeakerXMark : SpeakerWave}
          outline
          class="w-4 h-4"
          stroke="#000"
        />
      </button>
      <SliderInput
        on:input={(e) => playback.setAudioLevel(e.detail)}
        min="0"
        max="100"
        value={$audioLevel}
        variant="embedded"
        class="bg-white mb-1 w-28"
      />
    </div>
    <button
      class="p-3 rounded-full hover:bg-gray-200 {$canCaptureCurrent ? '' : 'pointer-events-none'}"
      on:click={() => timeline.captureCurrentScreen()}
    >
      <Icon src={Camera} outline class="w-4 h-4" stroke={$canCaptureCurrent ? "#000" : "#bbb"} />
    </button>
    <button
      class="p-3 rounded-full hover:bg-gray-200 {$canCaptureCurrent ? '' : 'pointer-events-none'}"
      on:click={() => timeline.downloadCurrentScreen()}
    >
      <Icon
        src={InboxArrowDown}
        outline
        class="w-4 h-4"
        stroke={$canCaptureCurrent ? "#000" : "#bbb"}
      />
    </button>
    <button class="p-3 rounded-full hover:bg-gray-200" on:click={() => timeline.adjustZoom(-0.1)}>
      <Icon src={MagnifyingGlassMinus} mini class="w-4 h-4" />
    </button>
    <button
      class="px-3 py-2 rounded text-center text-sm hover:bg-gray-200"
      on:click={() => timeline.resetZoom()}>{zoomFormated}</button
    >
    <button class="p-3 rounded-full hover:bg-gray-200" on:click={() => timeline.adjustZoom(0.1)}>
      <Icon src={MagnifyingGlassPlus} mini class="w-4 h-4" />
    </button>
    <button class="p-3 rounded-full hover:bg-gray-200" on:click={() => toggleFullscreen()}>
      <Icon src={isFullscreen ? ArrowsPointingIn : ArrowsPointingOut} mini class="w-4 h-4" />
    </button>
  </div>
</div>
