import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";

// Use use:tooltip={{ placement: "top", content: "Tooltip content" }} for dynamic content
// Use use:tooltip aria-label="Tooltip content" for static content
export function tooltip(node, options = {}) {
  const title = node.getAttribute("aria-label");

  if (!title && !options.content) {
    return;
  }

  const tooltip = tippy(node, { content: title, touch: false, allowHTML: true, ...options });

  return {
    update(options) {
      tooltip.setProps({ touch: false, ...options });
    },
    destroy() {
      tooltip.destroy();
    },
  };
}
