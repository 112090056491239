export const VolumeSlider = {
  mounted() {
    this.loadVolumePreference();
    this.setupEventListener();
  },

  updated() {
    const volume = this.el.dataset.volume;
    const muted = this.el.dataset.muted;

    this.saveVolumePreference(volume, muted);
  },

  destroyed() {
    this.cleanup?.();
  },

  setupEventListener() {
    const boundHandleInput = this.handleInput.bind(this);

    this.el.addEventListener("input", boundHandleInput);

    this.cleanup = () => {
      this.el.removeEventListener("input", boundHandleInput);
    };
  },

  handleInput(e) {
    const volume = e.target.value;
    this.pushEvent("update_volume", { volume });
  },

  loadVolumePreference() {
    const { volume, muted } = this.getVolumePreference();

    if (volume !== null && muted !== null) {
      this.pushEvent("initialize_volume", { volume, muted });
    }
  },

  saveVolumePreference(value, muted) {
    localStorage.setItem("tvlabs:access:volume", value);
    localStorage.setItem("tvlabs:access:muted", muted);
  },

  getVolumePreference() {
    return {
      volume: localStorage.getItem("tvlabs:access:volume"),
      muted: localStorage.getItem("tvlabs:access:muted"),
    };
  },
};
