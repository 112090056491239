import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";

export const Tooltip = {
  tippyInstance: null,

  mounted() {
    this.initializeTippy();
  },

  updated() {
    this.updateTippy();
  },

  destroyed() {
    if (this.tippyInstance) {
      this.tippyInstance.destroy();
    }
  },

  initializeTippy() {
    this.tippyInstance = tippy(this.el, {
      content: this.el.getAttribute("data-tippy-content"),
      arrow: this.el.getAttribute("data-tippy-arrow") === "true",
      placement: this.el.getAttribute("data-tippy-placement"),
      trigger:
        this.el.getAttribute("data-tippy-disabled") === "true" ? "manual" : "mouseenter focus",
      allowHTML: true,
      touch: false,
    });
  },

  updateTippy() {
    if (this.tippyInstance) {
      this.tippyInstance.setProps({
        content: this.el.getAttribute("data-tippy-content"),
        arrow: this.el.getAttribute("data-tippy-arrow") === "true",
        placement: this.el.getAttribute("data-tippy-placement"),
        trigger:
          this.el.getAttribute("data-tippy-disabled") === "true" ? "manual" : "mouseenter focus",
        allowHTML: true,
        touch: false,
      });
    }
  },
};
