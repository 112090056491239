<script>
  import { onMount, tick } from "svelte";
  import { Icon, Cog, XMark } from "svelte-hero-icons";
  import { fly } from "svelte/transition";

  export let live;
  export let routes;

  let events = [];
  let uploads = [];
  let props = {};
  let isPanelOpen = false;
  let newPropKey = "";
  let newPropValue = "";
  let selectedRoute = "/";

  onMount(() => {
    const unsubscribeEvents = live.getEvents().subscribe((value) => {
      events = value;
    });

    const unsubscribeUploads = live.getUploads().subscribe((value) => {
      uploads = value;
    });

    const unsubscribeProps = live.props.subscribe((value) => {
      props = value;
    });

    return () => {
      unsubscribeEvents();
      unsubscribeUploads();
      unsubscribeProps();
    };
  });

  function triggerEvent() {
    const event = document.getElementById("eventName").value;
    const payload = JSON.parse(document.getElementById("eventPayload").value);
    live.triggerEvent(event, payload);
  }

  function clearEvents() {
    live.clearEvents();
  }

  function clearUploads() {
    live.clearUploads();
  }

  function updateProp(key, value) {
    try {
      const parsedValue = JSON.parse(value);
      live.updateProps({ [key]: parsedValue });
    } catch {
      live.updateProps({ [key]: value });
    }
  }

  function deleteProp(key) {
    live.updateProps({ [key]: undefined });
  }

  function addNewProp() {
    if (newPropKey && newPropValue) {
      updateProp(newPropKey, newPropValue);
      newPropKey = "";
      newPropValue = "";
    }
  }

  async function togglePanel() {
    isPanelOpen = !isPanelOpen;
    if (isPanelOpen) {
      await tick();
      document.getElementById("mock-live-panel").focus();
    }
  }

  function navigateToRoute() {
    window.location.href = selectedRoute;
  }
</script>

<div class="relative z-40">
  <button
    on:click={togglePanel}
    class="fixed bottom-2 right-2 p-2 bg-gray-700 text-white rounded hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 z-40"
  >
    <Icon src={Cog} class="w-4 h-4" />
  </button>

  {#if isPanelOpen}
    <div
      id="mock-live-panel"
      class="fixed inset-y-0 right-0 w-80 bg-gray-900 text-gray-300 shadow-xl z-50 flex flex-col"
      transition:fly={{ x: 320, opacity: 1, duration: 200 }}
      tabindex="-1"
    >
      <div class="p-2 flex-shrink-0">
        <div class="flex justify-between items-center mb-2">
          <h2 class="text-sm font-bold text-gray-100">Mock LiveView Socket UI</h2>
          <button
            on:click={togglePanel}
            class="text-gray-400 hover:text-gray-200 focus:outline-none"
          >
            <Icon src={XMark} class="w-4 h-4" />
          </button>
        </div>

        <div class="mb-3">
          <h3 class="text-xs font-semibold mb-1 text-gray-400">Components</h3>
          <div class="flex space-x-2">
            <select
              bind:value={selectedRoute}
              class="flex-grow bg-gray-800 text-gray-300 border border-gray-700 rounded px-2 py-1 text-xs"
            >
              {#each Object.keys(routes) as route}
                <option value={route}>{route}</option>
              {/each}
            </select>
            <button
              on:click={navigateToRoute}
              class="bg-blue-600 text-white px-2 py-1 rounded text-xs hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Go
            </button>
          </div>
        </div>

        <div class="mb-3">
          <h3 class="text-xs font-semibold mb-1 text-gray-400">Props</h3>
          <ul class="border border-gray-700 rounded divide-y divide-gray-700 text-xs mb-2">
            {#each Object.entries(props) as [key, value]}
              <li class="p-1 font-mono bg-gray-800 flex items-center">
                <span class="flex-grow ml-1">{key}</span>
                <input
                  class="bg-gray-700 text-gray-300 border border-gray-600 rounded px-1 py-0.5 text-xs w-24 mr-1"
                  value={JSON.stringify(value)}
                  on:change={(e) => updateProp(key, e.target.value)}
                />
                <button
                  on:click={() => deleteProp(key)}
                  class="text-red-400 hover:text-red-300 focus:outline-none px-1"
                >
                  <Icon src={XMark} mini class="w-3 h-3" />
                </button>
              </li>
            {/each}
          </ul>
          <div class="flex mb-2 space-x-2 w-full">
            <input
              bind:value={newPropKey}
              placeholder="New prop key"
              class="w-1/2 min-w-0 bg-gray-800 text-gray-300 border border-gray-700 rounded px-2 py-1 text-xs"
            />
            <input
              bind:value={newPropValue}
              placeholder="New prop value"
              class="w-1/2 min-w-0 bg-gray-800 text-gray-300 border border-gray-700 rounded px-2 py-1 text-xs"
            />
          </div>
          <button
            on:click={addNewProp}
            class="bg-green-600 text-white px-2 py-1 rounded text-xs hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 w-full"
          >
            Add Prop
          </button>
        </div>

        <div class="mb-3">
          <h3 class="text-xs font-semibold mb-1 text-gray-400">Trigger Event</h3>
          <div class="flex flex-col space-y-1">
            <input
              id="eventName"
              class="bg-gray-800 text-gray-300 border border-gray-700 rounded px-2 py-1 text-xs font-mono"
              placeholder="Event name"
            />
            <textarea
              id="eventPayload"
              class="bg-gray-800 text-gray-300 border border-gray-700 rounded px-2 py-1 text-xs font-mono h-20 resize-y"
              placeholder="Event payload (JSON)"
            ></textarea>
            <button
              on:click={triggerEvent}
              class="bg-blue-600 text-white px-2 py-1 rounded text-xs hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Trigger Event
            </button>
          </div>
        </div>
      </div>

      <div class="flex-grow overflow-hidden flex flex-col">
        <div class="flex-grow overflow-auto p-2">
          <div class="mb-2">
            <div class="flex justify-between items-center mb-1">
              <h3 class="text-xs font-semibold text-gray-400">Events</h3>
              <button
                on:click={clearEvents}
                class="text-xs text-red-400 hover:text-red-300 focus:outline-none"
              >
                Clear
              </button>
            </div>
            <ul class="border border-gray-700 rounded divide-y divide-gray-700 text-xs">
              {#each events as event}
                <li class="p-1 test-xs font-mono bg-gray-800">
                  <span class="text-gray-500">{event.timestamp.toISOString()}</span>
                  - <span class="font-semibold">{event.type}:</span>
                  {event.event}
                  {#if event.payload}
                    <br /><span class="text-gray-400">{JSON.stringify(event.payload, null, 2)}</span
                    >
                  {/if}
                </li>
              {/each}
            </ul>
          </div>
        </div>

        <div class="p-2 flex-shrink-0">
          <div class="flex justify-between items-center mb-1">
            <h3 class="text-xs font-semibold text-gray-400">Uploads</h3>
            <button
              on:click={clearUploads}
              class="text-xs text-red-400 hover:text-red-300 focus:outline-none"
            >
              Clear
            </button>
          </div>
          <ul
            class="border border-gray-700 rounded divide-y divide-gray-700 max-h-32 overflow-auto text-xs"
          >
            {#each uploads as upload}
              <li class="p-1 font-mono bg-gray-800">
                <span class="text-gray-500">{upload.timestamp.toISOString()}</span>
                - Channel: {upload.channel}, Files: {JSON.stringify(upload.files)}
              </li>
            {/each}
          </ul>
        </div>
      </div>
    </div>
  {/if}
</div>
