<script>
  import { onMount, onDestroy } from "svelte";
  import { cx } from "$utils/cx";
  import { processEventBatch } from "$utils/events";

  import TestCasePanel from "./Debugger/TestCases/TestCasePanel.svelte";
  import VideoPlayer from "./Debugger/VideoPlayer.svelte";
  import InspectorPanel from "./Debugger/InspectorPanel.svelte";
  import TimelinePanel from "./Debugger/TimelinePanel.svelte";
  import MenubarDebugger from "./Debugger/MenubarDebugger.svelte";
  import AccidentalNavBlocker from "./Elements/AccidentalNavBlocker.svelte";
  import Pane from "./Elements/Pane.svelte";
  import { fakeStoreData } from "./Debugger/store/fakedata";
  import { timeline, testRuns, initializeStore, toolbar, playback } from "./Debugger/store";
  import Instructions from "./Debugger/Elements/Instructions.svelte";
  import { nonReactiveVariable } from "./utils/svelte";
  import { shouldIgnoreKeyEvent } from "../js/utils/keyboard";

  export let job;
  export let project_id;
  export let live;
  export let run_results = [];
  export let hidden = false;
  export let mock_env = false;
  export let user_roles = ["user"];

  // Non-reactive value to track if the component is mounted
  const isMounted = nonReactiveVariable(false);

  $: if (isMounted.value) testRuns.update(run_results);
  $: if (isMounted.value) testRuns.selectTestRun(job);

  function handleWorkflowUpdate(params) {
    if (!params) return null;
    const { events, isRecording } = params;
    if (isRecording) {
      timeline.clearTimeline();
    }
    processEventBatch(events, timeline.processEvent);
  }

  onMount(() => {
    if (mock_env) {
      initializeStore(live, { ...fakeStoreData, user_roles });
      playback.setSource([{ src: "https://dev.tvlabsassets.com/example.mp4", type: "video/mp4" }]);
      processEventBatch(fakeStoreData.events, timeline.processEvent);
    } else {
      initializeStore(live, { run_results, user_roles, job, project_id });
    }
    live.handleEvent("workflow_events", handleWorkflowUpdate);
    isMounted.set(true);
  });

  onDestroy(() => {
    live.removeHandleEvent(handleWorkflowUpdate);
  });

  function handleKeydown(event) {
    if (shouldIgnoreKeyEvent(event) || hidden) return;

    switch (event.key) {
      case "Space":
      case " ":
        playback.togglePlay();
        break;

      case "ArrowLeft":
      case "ArrowRight": {
        const direction = event.key === "ArrowLeft" ? -1 : 1;
        const moveBetweenBlocks = event.metaKey || event.ctrlKey;
        if (moveBetweenBlocks) {
          const jumpTo =
            direction > 0 ? timeline.nextEventTimePoint() : timeline.previousEventTimePoint();
          playback.goToTime(jumpTo);
        } else {
          const stepSize = event.shiftKey ? 10 : 1;
          direction > 0 ? playback.stepForward(stepSize) : playback.stepBackward(stepSize);
        }
        break;
      }

      case "=":
        if (event.ctrlKey || event.metaKey) {
          timeline.adjustZoom(0.1);
        }
        break;

      case "-":
        if (event.ctrlKey || event.metaKey) {
          timeline.adjustZoom(-0.1);
        }
        break;

      case "0":
        if (event.ctrlKey || event.metaKey) {
          timeline.resetZoom();
        } else {
          playback.rewind();
        }
        break;

      case "Home":
        playback.rewind();
        break;

      case "1":
      case "2":
      case "3":
      case "4":
      case "5":
      case "6":
      case "7":
      case "8":
      case "9":
        playback.goToPercentage(Number(event.key) * 10);
        break;

      case "End":
        playback.forward();
        break;

      case "m":
        playback.toggleMute();
        break;

      case "ArrowUp":
        playback.increaseVolume(event.shiftKey ? 10 : 1);
        break;

      case "ArrowDown":
        playback.decreaseVolume(event.shiftKey ? 10 : 1);
        break;

      case "l":
        playback.toggleLiveMode();
        break;

      default:
        return;
    }

    event.preventDefault();
  }
</script>

<svelte:window on:keydown={handleKeydown} />

<AccidentalNavBlocker />

{#if !hidden}
  <MenubarDebugger />

  <main
    data-el-debugger-main
    class={cx("flex flex-col bg-white automator-height-container", {
      "automator-mock-env": mock_env,
    })}
  >
    <div class="flex-grow overflow-auto">
      <div class="h-full flex p-4">
        {#if $toolbar.show_runs_pane}
          <Pane fixedWidth>
            <TestCasePanel />
          </Pane>
        {/if}

        <div class="flex flex-col justify-start flex-grow">
          <VideoPlayer />
          <Instructions />
        </div>

        {#if $toolbar.show_inspector_pane}
          <Pane fixedWidth>
            <InspectorPanel />
          </Pane>
        {/if}
      </div>
    </div>

    <div data-el-timeline-panel-container class="z-30 bg-white">
      {#if $toolbar.show_timeline_pane}
        <TimelinePanel />
      {/if}
    </div>
  </main>
{/if}
