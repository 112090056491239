<script>
  import { Icon, XMark, QuestionMarkCircle } from "svelte-hero-icons";
  import { keyConfig } from "$utils/keyConfig";

  const keys = keyConfig;

  function getIconByKey(key) {
    const item = keys.find((k) => k.key === key);
    if (item) return item.icon;

    if (key.startsWith("tap ")) return getIconByKey("tap");
    if (key.startsWith("swipe ")) return getIconByKey("swipe");
    return QuestionMarkCircle;
  }

  export let key;
  export let isReadonly = false;
  export let onClick = () => {};
</script>

<div
  class="bg-blue-600 text-white p-1 rounded-md flex items-center group relative border border-1 border-white"
>
  <Icon src={getIconByKey(key)} class="w-4 h-4" />
  {#if !isReadonly}
    <button
      class="absolute inset-0 bg-red-600 opacity-0 group-hover:opacity-100 transition-opacity duration-200 rounded-md flex items-center justify-center"
      on:click|stopPropagation={() => onClick()}
      aria-label={`Remove ${key} key`}
    >
      <Icon src={XMark} class="w-3 h-3 transform rotate-90" />
    </button>
  {/if}
</div>
