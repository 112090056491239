// Renders svelte component into a svg icon

export function renderIcon(Component, parentElement, props = {}) {
  const component = new Component({
    target: parentElement,
    props: props,
  });

  return {
    g: parentElement.children.item(0),
    width: 20,
    height: 20,
    destroy: () => component.$destroy(),
  };
}
