import { workflow } from "../store";

let copiedStep = null;

const actionMap = {
  c: { fn: copyBlock, ctrlOrMeta: true },
  v: { fn: pasteBlock, ctrlOrMeta: true },
  d: { fn: duplicateBlock, ctrlOrMeta: true },
  x: { fn: cutBlock, ctrlOrMeta: true },
  "/": { fn: toggleStepSkip, ctrlOrMeta: true },
  Delete: { fn: deleteBlock, ctrlOrMeta: false },
  Backspace: { fn: deleteBlock, ctrlOrMeta: false },
};

function findNeedle(parentNode, needle, update_fn = null) {
  let result = {
    target: null,
    needle: null,
    needleIndex: -1,
    needleSequence: null,
    needleBranch: -1,
    needleParent: null,
    needleParentIndex: -1,
    needleParentSequence: null,
  };

  if (!parentNode) {
    return result;
  }

  let sequence = [];
  if (Object.prototype.hasOwnProperty.call(parentNode, "sequence")) {
    sequence = parentNode.sequence;
  }

  if (!needle) {
    if (sequence.length > 0) {
      result.needle = sequence[0];
      result.needleIndex = 0;
      result.needleSequence = sequence;
      result.target = result.needle;
    }
    return result;
  }

  return findNeedleInSeq(parentNode, sequence, needle, result, update_fn);
}

function findNeedleInSwitch(parentNode, switchNode, needle, result, update_fn) {
  const branches = Object.entries(switchNode.branches);
  for (let i = 0; i < branches.length; i++) {
    const [_, substeps] = branches[i];
    result = findNeedleInSeq(switchNode, substeps, needle, result, update_fn);
    if (result.needle) {
      if (result.needleBranch === -1) {
        result.needleBranch = i;
      }
      break;
    }
  }

  return result;
}

function findNeedleInSeq(parentNode, sequence, needle, result, update_fn) {
  for (let i = 0; i < sequence.length; i++) {
    const current = sequence[i];
    if (current.id === needle) {
      result.needle = current;
      result.needleIndex = i;
      result.needleSequence = sequence;
      if (update_fn) {
        const updated = update_fn(result);
        if (updated) {
          sequence[i] = result.needle = updated.needle;
          switch (updated.op) {
            case "insert_after":
              sequence.splice(i + 1, 0, updated.block);
              break;
            case "delete":
              sequence.splice(i, 1);
              break;
            case "move_up":
              if (i > 0) {
                sequence.splice(i, 1);
                sequence.splice(i - 1, 0, updated.needle);
              }
              break;
            case "move_down":
              if (i < sequence.length - 1) {
                sequence.splice(i, 1);
                sequence.splice(i + 1, 0, updated.needle);
              }
              break;
            case "no_change":
            default:
              break;
          }

          if (updated.focus) {
            result.focus = updated.focus;
          }
        }
      }
      break;
    } else {
      if (current.componentType === "switch") {
        result = findNeedleInSwitch(parentNode, current, needle, result, update_fn);
        if (result.needle) {
          if (!result.needleParent) {
            result.needleParent = current;
            result.needleParentIndex = i;
            result.needleParentSequence = sequence;
          }
          break;
        }
      }
    }
  }

  return result;
}

function navigateUp(parentNode, key, result) {
  if (result.needleIndex == 0) {
    result.target = result.needleParent;
  } else {
    result = enterStep(result.needleSequence[result.needleIndex - 1], result);
  }

  return result;
}

function navigateDown(parentNode, key, result) {
  if (result.needleIndex == result.needleSequence.length - 1) {
    result.target = result.needleParent;
  } else {
    result = enterStep(result.needleSequence[result.needleIndex + 1], result);
  }

  return result;
}

function navigateLeft(parentNode, key, result) {
  // navigate left can only happend when we are in a switch
  if (result.needleParent && result.needleParent.componentType === "switch") {
    let targetBranch = result.needleBranch - 1;
    const branches = Object.entries(result.needleParent.branches);
    for (let i = targetBranch; i >= 0; i--) {
      const [_, substeps] = branches[i];
      if (substeps.length > 0) {
        result.target = substeps[Math.min(substeps.length - 1, result.needleIndex)];
        break;
      }
    }
    if (!result.target) {
      result.target = result.needleParent;
    }
  }

  return result;
}

function navigateRight(parentNode, key, result) {
  // navigate right can only happend when we are in a switch
  if (result.needleParent && result.needleParent.componentType === "switch") {
    let targetBranch = result.needleBranch + 1;
    const branches = Object.entries(result.needleParent.branches);
    for (let i = targetBranch; i < branches.length; i++) {
      const [_, substeps] = branches[i];
      if (substeps.length > 0) {
        result.target = substeps[Math.min(substeps.length - 1, result.needleIndex)];
        break;
      }
    }
    if (!result.target) {
      result.target = result.needleParent;
    }
  }

  return result;
}

function navigateEnter(parentNode, key, result) {
  // navigate enter can only happend when we are in a switch
  return enterStep(result.needle, result);
}

function navigateToTarget(parentNode, key, result) {
  if (!result.needle) return result;
  if (result.target) return result;

  if (key === "ArrowUp") {
    result = navigateUp(parentNode, key, result);
  } else if (key === "ArrowDown") {
    result = navigateDown(parentNode, key, result);
  } else if (key === "ArrowLeft") {
    result = navigateLeft(parentNode, key, result);
  } else if (key === "ArrowRight") {
    result = navigateRight(parentNode, key, result);
  } else if (key === "Enter") {
    result = navigateEnter(parentNode, key, result);
  }
  return result;
}

function navigateDefinition(parentNode, needle, key) {
  let result = findNeedle(parentNode, needle);
  result = navigateToTarget(parentNode, key, result);
  return result;
}

function enterStep(component, result) {
  if (!component) return result;

  if (component.componentType === "switch") {
    const branches = Object.entries(component.branches);
    for (let i = 0; i < branches.length; i++) {
      const [_, substeps] = branches[i];
      if (substeps.length > 0) {
        result.target = substeps[0];
        break;
      }
    }
  }

  if (!result.target) {
    result.target = component;
  }
  return result;
}

function toggleStepSkip(result) {
  if (result.needle) {
    let metadata = result.needle.metadata || {};
    let skip = metadata.skip || false;
    metadata.skip = !skip;
    result.needle.metadata = metadata;
  }
  return {
    op: "no_change",
    needle: result.needle,
  };
}

function copyBlock(result) {
  if (result.needle) {
    copiedStep = JSON.parse(JSON.stringify(result.needle));
  }
  return {
    op: "no_change",
    needle: result.needle,
  };
}

function pasteBlock(result) {
  if (copiedStep.type) {
    let newStep = workflow.instantiateBlock(copiedStep.type);
    newStep.properties = copiedStep.properties;
    return {
      op: "insert_after",
      needle: result.needle,
      block: newStep,
      focus: newStep.id,
    };
  }

  return {
    op: "no_change",
    needle: result.needle,
  };
}

function duplicateBlock(result) {
  if (result.needle) {
    copiedStep = JSON.parse(JSON.stringify(result.needle));
    let newStep = workflow.instantiateBlock(copiedStep.type);
    newStep.properties = copiedStep.properties;
    return {
      op: "insert_after",
      needle: result.needle,
      block: newStep,
      focus: newStep.id,
    };
  }

  return {
    op: "no_change",
    needle: result.needle,
  };
}

function cutBlock(result) {
  copiedStep = JSON.parse(JSON.stringify(result.needle));
  return deleteBlock(result);
}

function deleteBlock(result) {
  let focus = undefined;
  if (result.needleSequence && result.needleSequence.length > 1) {
    if (result.needleIndex === result.needleSequence.length - 1) {
      focus = result.needleSequence[result.needleSequence.length - 2].id;
    } else {
      focus = result.needleSequence[result.needleIndex + 1].id;
    }
  }

  return {
    op: "delete",
    needle: result.needle,
    focus,
  };
}

function moveBlock(result, direction) {
  if (direction === "ArrowUp") {
    return {
      op: "move_up",
      needle: result.needle,
    };
  } else {
    return {
      op: "move_down",
      needle: result.needle,
    };
  }
}

function handleKeydown(event, designer) {
  let preventDefault = true;
  switch (event.key) {
    case "z":
      if (event.metaKey ^ event.ctrlKey) {
        document
          .querySelector(
            `.sqd-control-bar > .sqd-control-bar-button[title=${event.shiftKey ? "Redo" : "Undo"}]`,
          )
          .click();
      }
      break;
    case "+":
      designer.api.controlBar.zoomIn();
      break;
    case "-":
      designer.api.controlBar.zoomOut();
      break;
    case "f":
      document
        .querySelector(".sqd-control-bar > .sqd-control-bar-button[title='Reset view']")
        .click();
      break;
    case "Enter":
    case "ArrowDown":
    case "ArrowUp":
    case "ArrowLeft":
    case "ArrowRight": {
      const defs = designer.getDefinition();
      const stepId = designer.getSelectedStepId();
      let result;
      if (event.metaKey ^ event.ctrlKey && (event.key === "ArrowUp" || event.key === "ArrowDown")) {
        result = findNeedle(defs, stepId, (result) => moveBlock(result, event.key));
        designer.replaceDefinition(JSON.parse(JSON.stringify(defs)));
        if (result.focus) {
          designer.selectStepById(result.focus);
          designer.moveViewportToStep(result.focus);
        }
      } else {
        result = navigateDefinition(defs, stepId, event.key);
      }

      if (result.target) {
        designer.selectStepById(result.target.id);
        designer.moveViewportToStep(result.target.id);
      }
      break;
    }
    default: {
      if (
        Object.prototype.hasOwnProperty.call(actionMap, event.key) &&
        ((actionMap[event.key].ctrlOrMeta && event.metaKey ^ event.ctrlKey) ||
          !actionMap[event.key].ctrlOrMeta)
      ) {
        const defs = designer.getDefinition();
        const stepId = designer.getSelectedStepId();
        const result = findNeedle(defs, stepId, actionMap[event.key].fn);
        if (event.key !== "c") designer.replaceDefinition(JSON.parse(JSON.stringify(defs)));
        if (result.focus) {
          // need to wait a bit for deletions otherwise we might end up deleting two steps
          const timeout = event.key === "Delete" || event.key === "Backspace" ? 200 : 0;
          setTimeout(() => {
            designer.selectStepById(result.focus);
            designer.moveViewportToStep(result.focus);
          }, timeout);
        }
      } else {
        preventDefault = false;
      }
      break;
    }
  }

  if (preventDefault) {
    event.preventDefault();
  }
}

export const shortcuts = {
  findNeedle,
  findNeedleInSwitch,
  findNeedleInSeq,
  navigateUp,
  navigateDown,
  navigateLeft,
  navigateRight,
  navigateEnter,
  navigateToTarget,
  navigateDefinition,
  enterStep,
  toggleStepSkip,
  copyBlock,
  pasteBlock,
  handleKeydown,
};
