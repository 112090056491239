<script>
  import Key from "../Elements/Key.svelte";
  import { timeline } from "../store";

  export let event;
  // svelte-ignore unused-export-let
  export let width;
  export let x;
</script>

<div class="absolute z-10 hover:z-50 h-6 overflow-hidden flex items-center" style="left: {x}px;">
  <button on:click={(e) => timeline.navigate(e, event, "automate")}>
    <Key key={event.label} isReadonly={true} />
  </button>
</div>
