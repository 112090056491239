<script>
  import DataGrid from "../DataGrid/DataGrid.svelte";

  export let event;

  let data = [
    {
      id: "status",
      label: "Status",
      value: event.is_active,
      type: "status",
    },
    {
      id: "event_time",
      label: "Event Time",
      value: event.start,
      type: "time",
    },
    {
      id: "region",
      label: "Region",
      value: event.region,
      type: "region",
    },
    {
      id: "score",
      label: "Score",
      value: event.state.score,
      type: "numeric",
    },
  ];

  if (event.state.expected) {
    data.push({
      id: "expected",
      label: "Expected",
      value: event.state.expected,
      type: "image",
      props: {
        alt: "Target Image",
      },
    });
  }
  if (event.state.got) {
    data.push({
      id: "got",
      label: "Got",
      value: event.state.got,
      type: "image",
      props: {
        alt: "Result Image",
      },
    });
  }
  if (event.state.visualized) {
    data.push({
      id: "visualized",
      label: "Visualized",
      value: event.state.visualized,
      type: "image",
      props: {
        alt: "Visualized Image",
      },
    });
  }
</script>

<DataGrid {data} />
