<script>
  import {
    Icon,
    XCircle,
    PauseCircle,
    FolderOpen,
    ChevronRight,
    ChevronDown,
    CheckCircle,
    Beaker,
    Trash,
    ArrowUpRight,
  } from "svelte-hero-icons";

  import { execution } from "$lib/Automator/store";
  import { persisted } from "$lib/utils/persisted-stores";
  import Collapsible from "../Elements/Collapsible.svelte";
  import Debug from "../../Elements/Debug.svelte";
  import Toolbar from "./Toolbar.svelte";

  import { toolbar, testRuns, timeline, projectId, features } from "../store";
  import { RunStatus } from "../store/runs";

  $: selectedTestRunId = testRuns.selectedTestRunId;
  $: sortedTestRuns = getSortedTestRuns($testRuns, sortDirection);
  $: selectedTimelineItem = timeline.selectedTimelineItem;
  $: developerMode = features.developerMode;

  let showDetails = true;
  let showAutoScheduled = persisted("show_auto_scheduled", false);
  let sortDirection = "desc";

  function getSortedTestRuns(testRuns, direction) {
    return [...testRuns].sort((a, b) => {
      return direction === "asc" ? a.id - b.id : b.id - a.id;
    });
  }

  function onDeleteTestBatch(batch_id) {
    execution.deleteTestBatch(batch_id);
  }

  function onDeleteTestJob(batch_id, job_id) {
    execution.deleteTestJob(batch_id, job_id);
  }

  const toggleTestCase = (id) => testRuns.toggleTestCase(id);
  const handleExpandAll = () => testRuns.expandAllTestCase(true);
  const handleCollapseAll = () => testRuns.expandAllTestCase(false);
  const handleToggleSortDirection = (newDirection) => (sortDirection = newDirection);
  const handleToggleDetails = (show) => (showDetails = show);
</script>

<div class="h-full flex flex-col overflow-auto -mt-2">
  <div class="border-b border-gray-200 px-3 pb-2">
    <h4 class="font-semibold">Job Executions</h4>
  </div>

  <Toolbar
    onExpandAll={handleExpandAll}
    onCollapseAll={handleCollapseAll}
    onToggleSortDirection={handleToggleSortDirection}
    onToggleShowDetails={handleToggleDetails}
    bind:showDetails
    bind:showAutoScheduled
    bind:sortDirection
  />

  {#each sortedTestRuns as testCase}
    {#if !testCase.isAutoScheduled || $showAutoScheduled}
      <div
        class="flex items-center border border-transparent w-full rounded-md cursor-pointer mb-1 group overflow-clip"
      >
        <button
          class="size-6 justify-center rounded-md flex items-center gap-x-1 px-1 w-full"
          on:click={() => toggleTestCase(testCase.id)}
        >
          <Icon src={testCase.isOpen ? ChevronDown : ChevronRight} class="w-4 h-4" />
          <Icon src={FolderOpen} class="w-4 h-4 text-gray-500" />
          <a
            class="flex-grow px-1 text-left text-sm text-gray-600 truncate rounded hover:bg-gray-100 cursor-pointer"
            href={`/app/batch/${testCase.id}/results`}
            target="_blank"
          >
            {testCase.name}
          </a>
          {#if testCase.timeAgoLabel}
            <span
              class="text-gray-500 text-[0.7rem] transition ease-in-out group-hover:-translate-x-0 translate-x-5 whitespace-nowrap"
              >{testCase.timeAgoLabel}</span
            >
          {/if}
        </button>
        <button
          class="transition ease-in-out group-hover:-translate-x-0 translate-x-5 p-1 rounded hover:bg-red-400 collapse group-hover:visible cursor-pointer flex-shrink-0"
          on:click|stopPropagation={() => onDeleteTestBatch(testCase.id)}
        >
          <Icon src={Trash} class="w-5 h-5 text-gray-800" />
        </button>
      </div>
      {#if testCase.isOpen}
        <div class="px-2 ml-4">
          <ul class="mb-2">
            {#each testCase.runs as run}
              <div class="flex group items-start">
                <a
                  href={`/app/projects/${$projectId}/debug/${run.id}`}
                  data-phx-link="patch"
                  data-phx-link-state="push"
                  class="w-full p-1 hover:bg-gray-100 rounded -mr-8 transition-all ease-in-out group-hover:mr-0"
                  class:bg-gray-100={run.id === $selectedTestRunId}
                >
                  <div class="flex items-center justify-between">
                    <div class="flex items-center space-x-2">
                      {#if run.status === RunStatus.AVAILABLE}
                        <Icon src={CheckCircle} mini class="w-4 h-4 text-gray-500" />
                      {:else if run.status === RunStatus.COMPLETED}
                        <Icon src={CheckCircle} mini class="w-4 h-4 text-green-500" />
                      {:else if run.status === RunStatus.FAILED}
                        <Icon src={XCircle} mini class="w-4 h-4 text-red-500" />
                      {:else if run.status === RunStatus.INTERRUPTED}
                        <Icon src={PauseCircle} mini class="w-4 h-4 text-grey-500" />
                      {:else if run.status === RunStatus.EXECUTING}
                        <div
                          class="w-4 h-4 rounded-full border-2 border-blue-500 border-t-transparent animate-spin"
                        ></div>
                      {:else}
                        <Icon src={CheckCircle} mini class="w-4 h-4 text-green-500" />
                      {/if}
                      <span class="flex-grow text-left font-medium truncate">{run.name}</span>
                      {#if run.duration}
                        <span class="text-gray-500 text-[0.7rem]">{run.duration}s</span>
                      {/if}
                    </div>
                  </div>
                  {#if showDetails && run.device}
                    <div class="w-full group-hover:max-w-40 ml-6 text-left text-gray-500 truncate">
                      {#if $developerMode && run.sidecar_serial_number}
                        <a
                          href={`/admin/sidecars/${run.sidecar_serial_number}`}
                          rel="noopener noreferrer"
                          target="_blank"
                          data-phx-link="navigate"
                          class="w-full hover:bg-gray-200 rounded transition-all ease-in-out group-hover:mr-0"
                          on:click|stopPropagation={() => {}}
                        >
                          <Icon
                            src={ArrowUpRight}
                            mini
                            class="w-4 h-4 hover:bg-gray-200 text-gray-500 inline"
                          />
                        </a>
                      {/if}
                      {run.device}
                    </div>
                  {/if}
                </a>
                <button
                  class="w-8 transition ease-in-out group-hover:translate-x-1 translate-x-2 p-1 rounded hover:bg-red-400 collapse group-hover:visible cursor-pointer"
                  on:click|stopPropagation={() => onDeleteTestJob(testCase.id, run.id)}
                >
                  <Icon src={Trash} class="w-5 h-5 text-gray-800" />
                </button>
              </div>
            {/each}
          </ul>
        </div>
      {/if}
    {/if}
  {/each}

  {#if $toolbar.show_debug}
    <Collapsible title="Debug" icon={Beaker}>
      <Debug
        selectedTestRunId={$selectedTestRunId}
        selectedTimelineItem={$selectedTimelineItem}
        testRuns={$testRuns}
      />
    </Collapsible>
  {/if}
</div>
