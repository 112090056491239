<script>
  import {
    Icon,
    Eye,
    EyeSlash,
    Trash,
    CodeBracket,
    Tag,
    BugAnt,
    QuestionMarkCircle,
    QueueList,
  } from "svelte-hero-icons";
  import { screenStores, selectedElements, toolbar, features } from "../store";
  import { tooltip } from "$lib/utils/tooltip";
  import { tagColor } from "$lib/Designer/elements.js";
  import { persisted } from "$lib/utils/persisted-stores";
  import HelpModal from "$lib/Elements/HelpModal.svelte";

  $: developerMode = features.developerMode;

  const additionalHelp = persisted("show_additional_help", true);
  const toggleAdditionalHelp = () => additionalHelp.update((value) => !value);
  const toggleOverlay = () => ($toolbar.show_overlay = !$toolbar.show_overlay);
  const toggleDebug = () => ($toolbar.show_debug = !$toolbar.show_debug);
  const onClickCode = () => ($toolbar.show_code = !$toolbar.show_code);
  const onToggleLabel = () => ($toolbar.show_label = !$toolbar.show_label);

  let isHelpOpen = false;
  const toggleHelp = () => (isHelpOpen = !isHelpOpen);
  const keyboardShortcuts = {
    "Region Operations": {
      "Switch to Cursor mode": "V",
      "Create new region": "R",
      "Delete selected elements": "Delete",
    },
  };

  function togglePropPane() {
    // WORKAROUND: Unselect, so we don't have to re-compute the bounding boxes
    selectedElements.set([]);
    $toolbar.show_prop_pane = !$toolbar.show_prop_pane;
  }

  function toggleTreeViewPane() {
    // WORKAROUND: Unselect, so we don't have to re-compute the bounding boxes
    selectedElements.set([]);
    $toolbar.show_treeview_pane = !$toolbar.show_treeview_pane;
  }

  const elementTypes = {
    "Text Element": "text",
    "Color Element": "color",
    "Image Element": "image",
    "Brightness Element": "brightness",
    "Button Element": "button",
    "Component Element": "component",
    "Form Element": "form",
    "Input Element": "input",
  };
</script>

<div class="flex justify-center space-x-4 py-1 px-4 bg-white border-b border-gray-200">
  {#if $toolbar.show_debug}
    <button
      class="p-1 text-black hover:bg-gray-300 rounded-md flex items-center justify-center w-8 h-8"
      on:click={() => screenStores.deleteSelected()}
    >
      <Icon src={Trash} class="w-5 h-5" />
    </button>

    <div class="border-l border-gray-200 mr-2"></div>
  {/if}

  <button
    class="p-1 text-black hover:bg-gray-300 rounded-md flex items-center justify-center w-8 h-8 disabled:opacity-20"
    on:click={toggleOverlay}
    use:tooltip={{ placement: "bottom" }}
    aria-label="Toggle overlay"
    disabled={$toolbar.show_code}
  >
    <Icon src={$toolbar.show_overlay ? Eye : EyeSlash} class="w-5 h-5" />
  </button>

  <button
    class="p-1 text-black hover:bg-gray-300 rounded-md flex items-center justify-center w-8 h-8 disabled:opacity-20"
    class:bg-gray-300={$toolbar.show_label}
    disabled={$toolbar.show_code}
    on:click={onToggleLabel}
    use:tooltip={{ placement: "bottom" }}
    aria-label="Toggle labels"
  >
    <Icon src={Tag} class="w-5 h-5" />
  </button>

  <button
    class="p-1 text-black hover:bg-gray-300 rounded-md flex items-center justify-center w-8 h-8"
    class:bg-gray-300={$toolbar.show_code}
    on:click={onClickCode}
    use:tooltip={{ placement: "bottom" }}
    aria-label="Toggle code"
  >
    <Icon src={CodeBracket} class="w-5 h-5" />
  </button>

  <button
    class="p-1 text-black hover:bg-gray-300 rounded-md flex items-center justify-center w-8 h-8 disabled:opacity-20"
    on:click={toggleTreeViewPane}
    disabled={$toolbar.show_code}
    use:tooltip={{ placement: "bottom" }}
    aria-label="Toggle tree view"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 104 108" class="w-5 h-5">
      <rect
        x="0"
        y="0"
        width="50"
        height="100"
        fill={$toolbar.show_treeview_pane ? "black" : "none"}
        stroke="black"
        stroke-width="8"
      />
      <rect x="50" y="0" width="50" height="100" fill="none" stroke="black" stroke-width="8" />
    </svg>
  </button>

  <button
    class="p-1 text-black hover:bg-gray-300 rounded-md disabled:opacity-20"
    on:click={togglePropPane}
    disabled={$toolbar.show_code}
    use:tooltip={{ placement: "bottom" }}
    aria-label="Toggle property pane"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 104 108" class="w-5 h-5">
      <rect x="0" y="0" width="50" height="100" fill="none" stroke="black" stroke-width="8" />
      <rect
        x="50"
        y="0"
        width="50"
        height="100"
        fill={$toolbar.show_prop_pane ? "black" : "none"}
        stroke="black"
        stroke-width="8"
      />
    </svg>
  </button>

  {#if $developerMode}
    <button
      class="p-1 text-black hover:bg-gray-300 rounded-md flex items-center justify-center w-8 h-8"
      on:click={toggleDebug}
      use:tooltip={{ placement: "bottom" }}
      aria-label="Toggle debug view"
    >
      <Icon src={BugAnt} class="w-5 h-5" />
    </button>
  {/if}

  <button
    on:click={toggleAdditionalHelp}
    class="p-1 text-black hover:bg-gray-300 rounded-md disabled:opacity-20"
    use:tooltip={{ placement: "bottom" }}
    aria-label="Toggle Additional Help"
  >
    <Icon src={QueueList} class="w-5 h-5" />
  </button>

  <button
    on:click={toggleHelp}
    class="p-1 text-black hover:bg-gray-300 rounded-md disabled:opacity-20"
    use:tooltip={{ placement: "bottom" }}
    aria-label="Show Help"
  >
    <Icon src={QuestionMarkCircle} class="w-5 h-5" />
  </button>
  <HelpModal bind:isOpen={isHelpOpen} maxWidth="32rem" {keyboardShortcuts}>
    <div class="text-sm w-full mb-8">
      <h2 class="font-bold mb-4 mt-4">Element Types</h2>
      <div class="grid grid-cols-2 gap-2">
        {#each Object.entries(elementTypes) as [label, type]}
          <div class="flex items-center space-x-2">
            <div class={`w-4 h-4 rounded border-2 ${tagColor(type)} bg-opacity-25`}></div>
            <span class="text-xs">{label}</span>
          </div>
        {/each}
      </div>
    </div>
  </HelpModal>
</div>
