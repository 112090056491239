import { writable } from "$utils/shim";

export function createFeatureStore() {
  const developerMode = writable(false);
  const readonlyMode = writable(false);

  return {
    initialize(user_roles, is_readonly) {
      developerMode.set(user_roles.includes("root"));
      readonlyMode.set(is_readonly);
    },
    developerMode,
    readonlyMode,
    reset() {
      developerMode.set(false);
      readonlyMode.set(false);
    },
  };
}
