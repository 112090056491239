<script>
  import PropEditor from "./PropEditor.svelte";
  import AlignmentToolbar from "./AlignmentToolbar.svelte";
  import Pane from "$lib/Elements/Pane.svelte";
  import { selectedElements } from "../store";
</script>

<Pane fixedWidth>
  {#if $selectedElements.length == 0}
    <h3 class="font-semibold px-4 text-sm text-gray-400 pb-5">Nothing selected</h3>
  {:else if $selectedElements.length > 1}
    <h3 class="font-semibold px-4 text-sm text-gray-400 pb-4 mb-4 border-b">
      Multiple items selected
    </h3>
    <AlignmentToolbar />
  {:else}
    <h3 class="font-semibold border-b border-gray-200 px-3 pb-4 text-sm mb-4">
      Region<span class="text-gray-400">#{$selectedElements[0]}</span>
    </h3>
    <PropEditor selected={$selectedElements[0]} />
  {/if}
</Pane>
