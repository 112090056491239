<script>
  import { allTagNames } from "../Designer/elements";

  export let baseWidth = 1920;
  export let baseHeight = 1080;
  export let refElementId;
  export let annotationsStore;

  let annotationIds = [];

  function sample(array) {
    return array[Math.floor(Math.random() * array.length)];
  }

  function rand5() {
    return Math.random().toString(36).substring(2, 7);
  }

  function generateRandomAnnotation() {
    const left = Math.round(Math.random() * (baseWidth - 250));
    const top = Math.round(Math.random() * (baseHeight - 250));
    const width = Math.round(Math.random() * 200 + 50);
    const height = Math.round(Math.random() * 200 + 50);

    return {
      _id: `a.${rand5()}`,
      tagName: sample(allTagNames),
      name: rand5(),
      top,
      left,
      width,
      height,
      state: {},
    };
  }

  function emit(detail) {
    console.log("✉️", JSON.stringify(detail));

    window.dispatchEvent(
      new CustomEvent("updateAnnotations", {
        detail,
      }),
    );
  }

  function addRandomAnnotation(hide) {
    const newAnnotation = generateRandomAnnotation();
    if (hide) {
      newAnnotation.hideAfter = Math.random() * 900 + 100; // Between 0.1 and 1 seconds
      setTimeout(() => {
        annotationIds = annotationIds.filter((id) => id !== newAnnotation._id);
      }, newAnnotation.hideAfter);
    }
    emit({ add: [newAnnotation] });
    annotationIds = [newAnnotation._id, ...annotationIds];
  }

  function addFullscreenAnnotation() {
    const newAnnotation = {
      _id: `a.${rand5()}`,
      tagName: "Text",
      name: "Fullscreen",
      top: 0,
      left: 0,
      width: baseWidth,
      height: baseHeight,
      state: {},
    };
    emit({ add: [newAnnotation] });
    annotationIds = [newAnnotation._id, ...annotationIds];
  }

  function removeRandomAnnotation() {
    if (annotationIds.length > 0) {
      const idToRemove = sample(annotationIds);
      annotationIds = annotationIds.filter((id) => id !== idToRemove);
      emit({ del: [{ _id: idToRemove }] });
    }
  }

  function updateRandomAnnotation() {
    if (annotationIds.length > 0) {
      const idToUpdate = sample(annotationIds);
      const updatedAnnotation = {
        ...generateRandomAnnotation(),
        _id: idToUpdate,
        state: { recognizedText: "foobar" },
      };
      emit({ update: [updatedAnnotation] });
    }
  }

  function stressTest(n = 200) {
    const annotations = Array(n)
      .fill()
      .map(() => generateRandomAnnotation());

    // Add annotations
    emit({ add: annotations });
    annotationIds = [...annotations.map((a) => a._id), ...annotationIds];

    // Update annotations
    let updateCount = 0;
    const updateInterval = setInterval(() => {
      const batchSize = 50; // Update 50 annotations at a time
      const updatedAnnotations = annotationIds.slice(0, batchSize).map((id) => ({
        ...generateRandomAnnotation(),
        _id: id,
        state: { updateCount: updateCount++ },
      }));
      emit({ update: updatedAnnotations });
    }, 30); // Update every 30ms

    // Delete annotations after 10 seconds
    setTimeout(() => {
      clearInterval(updateInterval);
      emit({ del: annotationIds.slice(0, n).map((id) => ({ _id: id })) });
      annotationIds = annotationIds.slice(n);
    }, 10000);
  }
</script>

<div class="space-x-2 mt-2">
  <button
    on:click={() => addRandomAnnotation(true)}
    class="bg-blue-500 hover:bg-blue-400 font-bold text-white px-2 py-1 rounded"
  >
    Add expiring annotation
  </button>

  <button
    on:click={() => addRandomAnnotation(false)}
    class="bg-blue-500 hover:bg-blue-400 font-bold text-white px-2 py-1 rounded"
  >
    Add annotation
  </button>

  <button
    on:click={() => addFullscreenAnnotation()}
    class="bg-blue-500 hover:bg-blue-400 font-bold text-white px-2 py-1 rounded"
  >
    Add fullscreen annotation
  </button>

  <button
    on:click={removeRandomAnnotation}
    class="bg-red-500 hover:bg-red-400 font-bold text-white px-2 py-1 rounded"
  >
    Remove annotation
  </button>

  <button
    on:click={updateRandomAnnotation}
    class="bg-green-500 hover:bg-green-400 font-bold text-white px-2 py-1 rounded"
  >
    Update annotation
  </button>

  <button
    on:click={() => stressTest()}
    class="bg-purple-500 hover:bg-purple-400 font-bold text-white px-2 py-1 rounded"
  >
    Stress Test
  </button>
</div>

<div class="grid grid-cols-2 gap-4 w-full h-48 overflow-auto border p-2 mt-4">
  <pre class="text-xs p-1 border-r-2">{JSON.stringify({ refElementId }, null, 2)}</pre>
  <div class="w-full p-1">
    <pre class="text-xs font-bold">annotationsStore</pre>
    {#each annotationsStore as annotation (annotation._id)}
      <pre class="text-xs mb-1">{JSON.stringify(annotation)}</pre>
    {/each}
  </div>
</div>
