export function expandIceServers(servers) {
  if (Array.isArray(servers)) {
    return servers.flatMap(expandIceServers);
  }

  const { urls, credential, username } = servers;

  return urls.map((url) => {
    if (url.startsWith("turn")) {
      return {
        urls: url,
        username,
        credential,
      };
    } else {
      return { urls: url };
    }
  });
}
