<script>
  import { createEventDispatcher } from "svelte";
  import { draggable } from "$lib/utils/draggable";
  import { Icon, Folder, XMark } from "svelte-hero-icons";

  export let value = [];
  export let isReadonly = false;
  export let label = "";
  export let multiple = false;
  export let optional = false;
  export let store;

  if (!store) {
    console.error("No screen store provided.");
  }

  const screens = store;
  const dispatch = createEventDispatcher();

  let orderedScreens = $screens;
  let selectedScreens = [];

  $: selectedScreens = Array.isArray(value) ? value : [value];
  $: orderScreens(selectedScreens);

  function orderScreens(selectedScreens) {
    if (!multiple) return;

    orderedScreens = orderedScreens.sort((a, b) => {
      const indexA = selectedScreens.indexOf(a.name);
      const indexB = selectedScreens.indexOf(b.name);

      return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB);
    });
  }

  function toggleScreen(screen) {
    if (isReadonly) return;

    let newValue;
    /*
    TODO: We should switch to ids here
    if (multiple) {
      newValue = selectedScreens.includes(screen._id)
        ? selectedScreens.filter((id) => id !== screen._id)
        : [...selectedScreens, screen._id];
    } else {
      newValue = screen._id;
    }
    */

    if (multiple) {
      newValue = selectedScreens.includes(screen.name)
        ? selectedScreens.filter((name) => name !== screen.name)
        : [...selectedScreens, screen.name];
    } else {
      newValue = [screen.name];
    }

    dispatch("input", newValue);
  }

  function clearSelection() {
    if (isReadonly) return;
    dispatch("input", null);
  }

  function handleReorder(event) {
    const { from, to } = event.detail;

    const item = orderedScreens[from];
    orderedScreens.splice(from, 1);
    orderedScreens.splice(to, 0, item);
    orderedScreens = orderedScreens;

    const newValue = orderedScreens
      .filter((screen) => selectedScreens.includes(screen.name))
      .map((screen) => screen.name);

    dispatch("input", newValue);
  }

  function isDraggable(screen) {
    return multiple && !isReadonly && selectedScreens.includes(screen.name);
  }
</script>

<!-- svelte-ignore a11y-label-has-associated-control -->
{#if label}<label class="block text-gray-500 text-xs mb-2">{label}</label>{/if}

<div class="border rounded-lg p-2 max-w-md" class:opacity-50={isReadonly}>
  <div class="flex justify-between items-center mb-1">
    <h3 class="font-semibold text-xs">Screens</h3>
    <div class="flex items-center">
      {#if optional && !multiple && selectedScreens.length > 0}
        <button
          class="text-xs text-gray-500 hover:text-gray-700 focus:outline-none"
          on:click={clearSelection}
          disabled={isReadonly}
        >
          <Icon src={XMark} class="w-4 h-4" />
        </button>
      {/if}
      <slot name="dynamic" />
    </div>
  </div>
  <ul use:draggable on:reorder={handleReorder} class="space-y-0.5 max-h-48 overflow-y-auto">
    {#each orderedScreens as screen (screen._id)}
      <li draggable={isDraggable(screen)}>
        <button
          class="w-full text-left flex items-center px-1.5 py-0.5 rounded-sm hover:bg-gray-100 focus:outline-none focus:bg-gray-100 text-xs"
          class:cursor-move={isDraggable(screen)}
          on:click={() => toggleScreen(screen)}
          disabled={isReadonly}
        >
          {#if multiple}
            <!--
            TODO: We should switch to ids here:
            checked={selectedScreens.includes(screen._id)}
            -->
            <input
              type="checkbox"
              checked={selectedScreens.includes(screen.name)}
              class="mr-1.5 h-3 w-3 cursor-pointer"
              on:change={() => {}}
              disabled={isReadonly}
            />
          {:else}
            <!--
            TODO: We should switch to ids here:
            checked={selectedScreens.includes(screen._id)}
            -->
            <input
              type="radio"
              checked={selectedScreens.includes(screen.name)}
              class="mr-1.5 h-3 w-3"
              on:change={() => {}}
              disabled={isReadonly}
            />
          {/if}
          <Icon src={Folder} class="w-3 h-3 text-gray-600 mr-1 flex-shrink-0" />
          <span class="truncate">{screen.name || "Unnamed Screen"}</span>
        </button>
      </li>
    {/each}
  </ul>
</div>
