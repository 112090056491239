<script>
  import { onDestroy, onMount } from "svelte";
  import ProgressBar from "$lib/Elements/ProgressBar.svelte";

  let systemStats = {
    framesProcessed: 5,
    framesTotal: 30,
    gpu: 63,
    cpu: 25,
    memory: 50,
    memoryTotal: 64,
    queue: 5,
    queueMax: 8,
  };

  function updateStats() {
    const updateValue = (current, min, max, delta) =>
      Math.round(Math.min(max, Math.max(min, current + (Math.random() - 0.5) * delta)));

    systemStats = {
      ...systemStats,
      framesProcessed: updateValue(systemStats.framesProcessed, 0, systemStats.framesTotal, 1.5),
      gpu: updateValue(systemStats.gpu, 0, 100, 5),
      cpu: updateValue(systemStats.cpu, 0, 100, 5),
      memory: updateValue(systemStats.memory, 0, systemStats.memoryTotal, 1),
      queue: updateValue(systemStats.queue, 0, systemStats.queueMax, 1),
    };
  }

  let intervalId;

  onMount(() => {
    intervalId = setInterval(updateStats, 1000);
  });

  onDestroy(() => {
    if (intervalId) clearInterval(intervalId);
  });
</script>

<div class="p-2 space-y-1 flex-shrink-0">
  <div class="flex justify-between items-center">
    <span class="font-medium">Processed vs. total frames</span>
    <span>{systemStats.framesProcessed}/{systemStats.framesTotal}</span>
  </div>

  <ProgressBar showValue label="GPU" value={systemStats.gpu} color="bg-red-500" />
  <ProgressBar showValue label="CPU" value={systemStats.cpu} color="bg-red-500" />
  <ProgressBar
    showValue
    label="MEM"
    value={systemStats.memory}
    max={systemStats.memoryTotal}
    unit=" GB"
    fixed={0}
  />
  <ProgressBar
    showValue
    label="QUE"
    value={systemStats.queue}
    max={systemStats.queueMax}
    color="bg-purple-500"
  />
</div>
