import uPlot from "uplot";

function calculateHighlightPosition_Bar(uplot, currentIndex) {
  const dx = uplot.scales.x.max - uplot.scales.x.min;
  const width = uplot.bbox.width / dx / devicePixelRatio;
  const left = uplot.valToPos(currentIndex, "x") - width / 2;
  return [width, left];
}

function calculateHighlightPosition_Timeseries(uplot, currentIndex) {
  const [min_idx, max_idx] = uplot.series[0].idxs;
  const dx = max_idx - min_idx;
  const width = uplot.bbox.width / dx / devicePixelRatio;
  const x_val = uplot.scales.x.distr == 2 ? currentIndex : uplot.data[0][currentIndex];
  const left = uplot.valToPos(x_val, "x") - width / 2;
  return [width, left];
}

export function columnHighlight({
  className,
  style = { backgroundColor: "rgba(204,204,204,0.3)" },
  isBarChart = false,
} = {}) {
  let highlightElement, currentIndex;

  function onOverEnter() {
    highlightElement.style.display = null;
  }

  function onOverLeave() {
    highlightElement.style.display = "none";
  }

  function init(uplot) {
    const underEl = uplot.under;
    const overEl = uplot.over;

    highlightElement = document.createElement("div");

    className && highlightElement.classList.add(className);

    uPlot.assign(highlightElement.style, {
      pointerEvents: "none",
      display: "none",
      position: "absolute",
      left: 0,
      top: 0,
      height: "100%",
      ...style,
    });

    underEl.appendChild(highlightElement);

    // show/hide highlight on enter/exit
    overEl.addEventListener("mouseenter", onOverEnter);
    overEl.addEventListener("mouseleave", onOverLeave);
  }

  function update(uplot, currentIndex) {
    const [width, left] = isBarChart
      ? calculateHighlightPosition_Bar(uplot, currentIndex)
      : calculateHighlightPosition_Timeseries(uplot, currentIndex);

    if (highlightElement) {
      highlightElement.style.transform = "translateX(" + Math.round(left) + "px)";
      highlightElement.style.width = Math.round(width) + "px";
    }
  }

  function setLegend(uplot) {
    if (currentIndex !== uplot.cursor.idx) {
      currentIndex = uplot.cursor.idx;
      update(uplot, currentIndex);
    }
  }

  function destroy(uplot) {
    const overEl = uplot.over;
    overEl.removeEventListener("mouseenter", onOverEnter);
    overEl.removeEventListener("mouseleave", onOverLeave);

    if (highlightElement && highlightElement.parentElement) {
      highlightElement.parentElement.removeChild(highlightElement);
    }
  }

  return {
    opts: (_uplot, opts) => {
      uPlot.assign(opts, {});
    },
    hooks: {
      init,
      setLegend,
      destroy,
    },
  };
}
