<script>
  import { createEventDispatcher } from "svelte";

  export let value = false;
  export let label;
  export let isReadonly = false;

  const id = String(Math.random());
  const dispatch = createEventDispatcher();

  function handleChange(event) {
    if (!isReadonly) {
      value = event.target.checked;
      dispatch("input", value);
    }
  }
</script>

<div class="w-full flex items-center {$$props.class || ''}">
  <input
    {id}
    type="checkbox"
    class="rounded-sm p-1 bg-gray-100 border-2 border-gray-500 {isReadonly
      ? 'cursor-not-allowed opacity-50'
      : 'cursor-pointer'}"
    checked={value}
    on:change={handleChange}
    disabled={isReadonly}
  />
  <label
    for={id}
    class="ml-1 text-gray-500 select-none {isReadonly ? 'cursor-not-allowed' : 'cursor-pointer'}"
    >{label}</label
  >
  <slot name="dynamic" />
</div>
