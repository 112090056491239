<script>
  import { Icon, DocumentArrowDown } from "svelte-hero-icons";
  import Modal from "$lib/Elements/Modal.svelte";
  import CopyText from "$lib/Elements/CopyText.svelte";
  import CheckboxInput from "$lib/Inputs/CheckboxInput.svelte";
  import NumericInput from "$lib/Inputs/NumericInput.svelte";
  import { playback } from "../store";

  let isOpen = false;
  let startTime = null;
  let endTime = null;
  let useReEncoding = false;
  let output = "recording.mp4";

  $: src = playback.sourceUrl;

  $: command = [
    `ffmpeg -i ${$src}`,
    startTime != null && `-ss ${startTime}`,
    endTime != null && `-to ${endTime}`,
    useReEncoding ? `-vf "fps=30,scale=1920:1080" -c:a copy` : `-c copy`,
    output,
  ]
    .filter(Boolean)
    .join(" ");

  function toggle() {
    isOpen = !isOpen;
  }
</script>

<div class="flex">
  <div class="flex flex-grow justify-start">
    <div class="inline-flex text-xs pl-4 text-gray-400">
      Note: Text overlays are delayed by 3 frames.
    </div>
  </div>

  <div class="flex flex-grow justify-end">
    <button
      on:click={toggle}
      class="inline-flex text-xs pr-4 mr-1 text-blue-500 hover:text-blue-600 disabled:text-gray-400 disabled:cursor-not-allowed transition-colors duration-200"
      disabled={!$src}
    >
      <Icon src={DocumentArrowDown} class="w-4 h-4 mr-1" />
      Download Instructions
    </button>
  </div>
</div>

<Modal open={isOpen} title="Download Recording" on:close={toggle}>
  <div class="text-sm">
    <p>To download this recording, follow these steps:</p>
    <ol class="list-decimal list-inside mt-2">
      <li>
        Download and install FFmpeg from the official website:
        <a
          href="https://ffmpeg.org/download.html"
          target="_blank"
          class="text-blue-500 hover:underline"
        >
          https://ffmpeg.org/download.html
        </a>
      </li>
      <li class="mt-2">Open a terminal and run the following command:</li>
    </ol>
    <div class="relative mb-4">
      <pre
        class="font-mono text-xs bg-gray-100 border rounded border-gray-300 p-4 mt-4 pr-6 whitespace-pre-wrap">{command}</pre>
      <div class="absolute top-4 right-4">
        <CopyText text={command} showTooltip={true} />
      </div>
    </div>
    <div class="mt-2 w-full grid grid-cols-3 gap-4">
      <NumericInput
        variant="wide"
        bind:value={startTime}
        label="Start time (sec)"
        optional={true}
      />
      <NumericInput variant="wide" bind:value={endTime} label="End time (sec)" optional={true} />
      <CheckboxInput bind:value={useReEncoding} label="Re-encode to 30 FPS / 1080p" />
    </div>
    <p class="mt-2">The recording will be saved as <code>{output}</code>.</p>
  </div>
</Modal>
