// eslint-disable-next-line no-unused-vars
import uPlot from "uplot";

export function selected({ onSelectedIndex, eventName }) {
  let onClickHandler = null;
  let selectedIdx = null;

  function init(uplot) {
    function onClick() {
      // should only fire when not dragging
      const timestamp = uplot.data[0][selectedIdx];
      onSelectedIndex && onSelectedIndex(selectedIdx, timestamp, eventName);
    }

    onClickHandler = uplot.over.addEventListener("click", onClick);
  }

  function destroy(uplot) {
    if (onClickHandler) {
      uplot.over.removeEventListener("click", onClickHandler);
    }
  }

  function setLegend(uplot) {
    selectedIdx = null;

    if (uplot.cursor.idx !== null && uplot.cursor.idx != selectedIdx) {
      selectedIdx = uplot.cursor.idx;
    }
  }

  return {
    hooks: {
      init,
      destroy,
      setLegend,
    },
  };
}
