import uPlot from "uplot";

export function zoom(enable = true) {
  return {
    opts: (uplot, opts) => {
      uPlot.assign(opts, {
        cursor: enable
          ? {}
          : {
              drag: {
                x: false,
                y: false,
              },
            },
      });
    },
  };
}
