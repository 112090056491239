// TODO: remove this once `:phoenix_live_view` releases 1.0.0 and we upgrade
// see:
// - https://github.com/phoenixframework/phoenix_live_view/blob/93d242460f5222b1d89e54df56624bc96d53d659/CHANGELOG.md?plain=1#L125
// - https://github.com/phoenixframework/phoenix_live_view/blob/93d242460f5222b1d89e54df56624bc96d53d659/assets/js/phoenix_live_view/view_hook.js#L255

// Decorate the live object with the request method.
export function addRequestHandler(live) {
  return Object.assign(live, {
    request(command, options = {}) {
      return request(live, command, options);
    },
  });
}

// Pushes an event to the live socket, and returns a promise that resolves to the reply.
export function request(live, method, options = {}) {
  const { payload = {}, timeout = 10000 } = options;

  const request = new Promise((resolve) => {
    live.pushEvent(method, payload, (reply) => {
      resolve(reply);
    });
  });

  return timeout > 0 ? withTimeout(request, timeout) : request;
}

function withTimeout(promise, ms) {
  const timeout = new Promise((_, reject) =>
    setTimeout(() => reject(new Error(`Request timed out after ${ms}ms`)), ms),
  );

  return Promise.race([promise, timeout]);
}
