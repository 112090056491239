<script>
  import { onMount, afterUpdate, tick } from "svelte";

  let isClamped = true;
  let showButton = false;
  let textElement;

  export let gradientClasses =
    $$props.gradientClasses || "bg-gradient-to-b from-transparent to-gray-900";

  afterUpdate(() => {
    handleUpdate();
  });

  onMount(() => {
    handleUpdate();
  });

  async function handleUpdate() {
    // data from getComputedStyles may not be available until next tick
    await tick();

    const lineHeight = parseInt(getComputedStyle(textElement).lineHeight, 10);
    const maxHeight = lineHeight * 3;

    showButton = textElement.scrollHeight > maxHeight;
  }

  function toggleClamp() {
    isClamped = !isClamped;
  }
</script>

<div
  bind:this={textElement}
  class="relative overflow-hidden"
  class:max-h-[5.5em]={isClamped}
  class:max-h-full={!isClamped}
>
  <slot />
  {#if isClamped && showButton}
    <div
      class="absolute pointer-events-none bottom-0 left-0 w-full h-8 border-0 {gradientClasses}"
    ></div>
  {/if}
</div>

{#if showButton}
  <button
    on:click={toggleClamp}
    class="my-1 px-2 py-0.5 items-center border border-gray-300 text-gray-300 rounded hover:border-white hover:text-white"
  >
    {isClamped ? "Read more" : "Read less"}
  </button>
{/if}
