<script>
  import { Icon, Plus } from "svelte-hero-icons";
  import {
    screenStores,
    screenStoresAsList,
    selectedScreenId,
    appAttributes,
    toolbar,
    features,
  } from "../store";
  import { tooltip } from "$utils/tooltip";

  import TreeViewScreen from "./TreeViewScreen.svelte";
  import InlineEdit from "$lib/Inputs/InlineEdit.svelte";
  import Pane from "$lib/Elements/Pane.svelte";

  let lastAddedScreenId = null;
  const isReadonly = features.readonlyMode;

  function onAddScreen() {
    return screenStores.addScreen({ name: "New Screen" });
  }

  function onChangeAppName({ detail: name }) {
    appAttributes.update((attr) => ({ ...attr, name: name }));
  }

  function isDuplicateName(screen, screens) {
    return screens.filter((a) => a.name === screen.name).length > 1;
  }
</script>

<Pane fixedWidth>
  <div class="mb-2 border-b border-gray-200 pb-4 px-3 flex items-center">
    <h3 class="text-sm grow text-left">App Name:</h3>
    <InlineEdit
      class={`w-100 px-1 font-semibold rounded text-sm text-gray-600 border-2 border-transparent hover:border-gray-200`}
      value={$appAttributes.name || "Unnamed App"}
      on:change={onChangeAppName}
      isReadonly={$isReadonly}
    />
  </div>
  <div class="flex items-center justify-between border-b border-gray-200 px-3 pb-2">
    <h3 class="font-semibold text-sm grow text-left truncate block">Screens</h3>
    <button
      class="p-2 rounded hover:bg-gray-200 focus:outline-none focus:bg-gray-100"
      on:click={() => (lastAddedScreenId = onAddScreen())}
      use:tooltip
      aria-label="Add screen"
      disabled={$isReadonly}
    >
      <Icon src={Plus} class="w-5 h-5 text-gray-800" />
    </button>
  </div>

  <div class="overflow-auto h-full p-2">
    {#each $screenStoresAsList as screen, index (screen._id)}
      <TreeViewScreen
        name={screen.name}
        regions={screen.matchers}
        isFirst={index === 0}
        isLast={index === $screenStoresAsList.length - 1}
        isReadonly={$isReadonly}
        isNewScreen={screen._id === lastAddedScreenId}
        isDuplicateScreenName={isDuplicateName(screen, $screenStoresAsList)}
      />
    {/each}

    {#if $toolbar.show_debug}
      <div class="w-full h-64 overflow-auto border p-2 mt-4">
        <pre class="text-xs font-bold">$screenStoresAsList</pre>
        {#each $screenStoresAsList as screen (screen._id)}
          <pre
            class="text-xs mb-1"
            class:bg-gray-200={screen._id == $selectedScreenId}>{JSON.stringify(screen)}</pre>
        {/each}
      </div>
    {/if}
  </div>
</Pane>
