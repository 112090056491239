import { columnHighlight } from "../plugins/columnHighlight";
import { createSeriesFromLabels } from "./detail";

export function dev({ labels }) {
  return {
    plugins: [columnHighlight()],
    series: [
      {},
      ...createSeriesFromLabels(labels, {
        scale: "%",
        stroke: "black",
        width: 4 / devicePixelRatio,
      }),
    ],
    axes: [
      {},
      {
        scale: "%",
      },
    ],
  };
}
