<script>
  import DataGrid from "../DataGrid/DataGrid.svelte";

  export let event;

  let data = [
    {
      id: "status",
      label: "Status",
      value: event.is_active,
      type: "status",
    },
    {
      id: "event_time",
      label: "Event Time",
      value: event.start,
      type: "time",
    },
    {
      id: "region",
      label: "Region",
      value: event.region,
      type: "region",
    },
  ];

  for (let [key, value] of Object.entries(event.state ?? {})) {
    data.push({
      id: key,
      label: key,
      value: value,
      type: "text",
    });
  }
</script>

<DataGrid {data} />
