import {
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  ArrowDown,
  ArrowUturnLeft,
  PlayPause,
  Bars3,
  Play,
  Pause,
  Home,
  ChevronUp,
  ChevronDown,
  Power,
  Cog6Tooth,
  InformationCircle,
  SpeakerXMark,
  ArrowsRightLeft,
  ChevronDoubleRight,
  ChevronDoubleLeft,
  Forward,
  Backward,
  CursorArrowRays,
  CursorArrowRipple,
} from "svelte-hero-icons";
import { EnterKeyIcon } from "../Elements/Icons/Enter";

export const keyConfig = [
  { key: "left", icon: ArrowLeft, label: "Left" },
  { key: "right", icon: ArrowRight, label: "Right" },
  { key: "up", icon: ArrowUp, label: "Up" },
  { key: "down", icon: ArrowDown, label: "Down" },
  { key: "ok", icon: EnterKeyIcon, label: "OK" },
  { key: "back", icon: ArrowUturnLeft, label: "Back" },
  { key: "home", icon: Home, label: "Home" },
  { key: "power", icon: Power, label: "Power" },
  { key: "playPause", icon: PlayPause, label: "Play/Pause" },
  { key: "play", icon: Play, label: "Play" },
  { key: "pause", icon: Pause, label: "Pause" },
  { key: "fastForward", icon: Forward, label: "Fast Forward" },
  { key: "rewind", icon: Backward, label: "Rewind" },
  { key: "volumeUp", icon: ChevronUp, label: "Volume Up" },
  { key: "volumeDown", icon: ChevronDown, label: "Volume Down" },
  { key: "mute", icon: SpeakerXMark, label: "Mute" },
  { key: "input", icon: ArrowsRightLeft, label: "Input" },
  { key: "info", icon: InformationCircle, label: "Info" },
  { key: "settings", icon: Cog6Tooth, label: "Settings" },
  { key: "chNext", icon: ChevronDoubleRight, label: "Channel Next" },
  { key: "chPrev", icon: ChevronDoubleLeft, label: "Channel Prev" },
  { key: "menu", icon: Bars3, label: "Menu" },
  { key: "num0", icon: "0", label: "0" },
  { key: "num1", icon: "1", label: "1" },
  { key: "num2", icon: "2", label: "2" },
  { key: "num3", icon: "3", label: "3" },
  { key: "num4", icon: "4", label: "4" },
  { key: "num5", icon: "5", label: "5" },
  { key: "num6", icon: "6", label: "6" },
  { key: "num7", icon: "7", label: "7" },
  { key: "num8", icon: "8", label: "8" },
  { key: "num9", icon: "9", label: "9" },
  { key: "tap", icon: CursorArrowRays, label: "Tap" },
  { key: "swipe", icon: CursorArrowRipple, label: "Swipe" },
];
