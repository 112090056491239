export const EqualSizeIcon = {
  default: {
    a: {
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
    },
    path: [
      {
        d: "M4 4H20V20H4V4Z",
        stroke: "#0F172A",
        strokeWidth: "1.5",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      },
      {
        d: "M8 8H16V16H8V8Z",
        stroke: "#0F172A",
        strokeWidth: "1.5",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      },
    ],
  },
};
