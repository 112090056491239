<script>
  import { toolbar, currentAssets, selectedScreenId } from "../store";
  import { Icon, Plus, XMark } from "svelte-hero-icons";
  import ImageSelectorModal from "./ImageSelectorModal.svelte";
  import { tooltip } from "$utils/tooltip";

  let assets = $currentAssets;
  let uploadingFiles = $currentAssets.uploadingFiles;
  let selectedIndex = $currentAssets.selectedIndex;
  let selected = $currentAssets.selected;
  let errors = $currentAssets.errors;
  let imageModalOpen = false;

  $: {
    assets = $currentAssets;
    uploadingFiles = $currentAssets.uploadingFiles;
    selectedIndex = $currentAssets.selectedIndex;
    selected = $currentAssets.selected;
    errors = $currentAssets.errors;
  }

  async function upload(e) {
    const files = e.detail;

    if (!$selectedScreenId) {
      console.warn("Can not draw upload when no screen is selected");
    }

    if (!files) return;
    const metadata = {};
    const selectedBefore = $selectedIndex;
    await assets.upload(files, $selectedScreenId, metadata, function (updatedAssets) {
      // if the user selected another image while uploading
      // then we just keep the selection
      if (selectedBefore == $selectedIndex) {
        // otherwise we select the last uploaded image
        selectedIndex.set(updatedAssets.length - 1);
      }
    });
  }

  function selectImage(index) {
    assets.select(index);
  }

  function removeImage(index) {
    assets.remove(index);
  }

  function handleDragOver(event) {
    event.preventDefault();
  }

  function handleDrop(event) {
    event.preventDefault();
    upload(new CustomEvent("upload", { detail: event.dataTransfer.files }));
  }
</script>

<div
  class="overflow-x-auto border rounded-xl p-4"
  on:dragover={handleDragOver}
  on:drop={handleDrop}
  role="list"
>
  <div role="region" aria-label="Image Gallery" class="flex space-x-2 w-fit">
    {#each $assets as asset, index (asset.id)}
      <div class="relative w-20 h-20 group">
        <button
          disabled={!$selectedScreenId}
          class="relative w-full h-full p-0 border-0 bg-transparent cursor-pointer"
          on:click={() => selectImage(index)}
        >
          <img
            src={asset.url}
            crossorigin="anonymous"
            draggable="false"
            alt="Thumbnail of asset"
            class="w-full h-full object-cover {$selectedIndex === index
              ? 'outline outline-2 outline-offset-1 outline-indigo-400'
              : ''}"
          />
        </button>
        <button
          class="absolute top-1 right-1 bg-red-500 hover:bg-red-600 text-white p-1 text-xs rounded-xs opacity-0 group-hover:opacity-100 transition-opacity duration-100"
          on:click={() => removeImage(index)}
        >
          <Icon src={XMark} mini class="w-3 h-3" />
        </button>
      </div>
    {/each}

    {#each $uploadingFiles as { tempSrc, progress, phxRef } (phxRef)}
      <div class="relative w-20 h-20 group">
        <img
          src={tempSrc}
          crossorigin="anonymous"
          draggable="false"
          alt=""
          class="w-full h-20 object-cover"
        />
        <div class="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div class="text-white font-bold">
            {Math.round(progress)}%
          </div>
        </div>
      </div>
    {/each}

    <button
      use:tooltip
      aria-label="Add Screen"
      class={"w-20 h-20 border-2 border-dashed flex items-center justify-center  transition select-none duration-100 ease-in-out border-gray-300 text-gray-300 " +
        ($selectedScreenId ? "cursor-pointer hover:bg-gray-200 hover:text-gray-400" : "")}
      on:click={() => (imageModalOpen = !imageModalOpen)}
    >
      <Icon src={Plus} mini class="w-8 h-8" />
    </button>
  </div>
</div>

{#if imageModalOpen}
  <ImageSelectorModal on:upload={upload} on:close={() => (imageModalOpen = false)} />
{/if}

{#if Object.values($errors).length > 0}
  <div class="bg-red-500 text-white font-semibold p-1 text-left text-sm mt-4">
    <pre>{JSON.stringify($errors, null, 2)}</pre>
  </div>
{/if}

{#if $toolbar.show_debug}
  <div class="grid grid-cols-4 gap-2 mt-4">
    <div class="text-xs border p-2 h-40">
      <pre>selectedIndex = {JSON.stringify({ $selectedIndex, $selectedScreenId }, null, 2)}</pre>
      {#if $selected}
        <img
          src={$selected.url}
          crossorigin="anonymous"
          alt=""
          class="mt-4 w-12 h-12 border p-2 object-cover"
        />
      {/if}
    </div>

    <div class="col-span-3 w-full h-40 overflow-auto border p-2">
      {#each $assets as asset, index (asset.id)}
        <pre class="text-xs mb-1" class:bg-gray-300={$selectedIndex == index}>- {JSON.stringify(
            asset,
          )}</pre>
      {/each}
      {#each $uploadingFiles as { tempSrc: _tempSrc, ...item } (item.id)}
        <pre class="text-xs mb-1 bg-red-300">- {JSON.stringify(item)}</pre>
      {/each}
    </div>
  </div>
{/if}
