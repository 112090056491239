import { debounce } from "../utils/debounce";
import luaCompletions from "./lua_completions.json";

export class CodeEditor {
  constructor(element, options) {
    this.element = element;
    this.options = options;
    this.EDITOR_DEBOUNCE = 500;
    this.view = null;
    this.debouncedOnChange = null;
  }

  async initialize() {
    const [
      { EditorView, basicSetup },
      { StreamLanguage },
      { lua },
      { xml },
      { dracula },
      { quietlight },
      { autocompletion },
      { keymap },
      { defaultKeymap, historyKeymap },
    ] = await Promise.all([
      import("codemirror"),
      import("@codemirror/language"),
      import("@codemirror/legacy-modes/mode/lua"),
      import("@codemirror/lang-xml"),
      import("@uiw/codemirror-theme-dracula"),
      import("@uiw/codemirror-theme-quietlight"),
      import("@codemirror/autocomplete"),
      import("@codemirror/view"),
      import("@codemirror/commands"),
    ]);

    const languageSupport = {
      lua: () => StreamLanguage.define(lua),
      xml: () => xml(),
    };

    const themes = {
      dark: dracula,
      light: quietlight,
    };

    const createLuaCompletions = (context) => {
      let word = context.matchBefore(/\w*/);
      if (word.from == word.to && !context.explicit) return null;
      return {
        from: word.from,
        options: luaCompletions,
      };
    };

    const languageCompletions = {
      lua: [createLuaCompletions],
      xml: [
        /* provided by @codemirror/lang-xml */
      ],
    };

    const customKeyMap = [
      {
        key: "Mod-Enter",
        run: () => {
          document.dispatchEvent(new CustomEvent("executedFromCodeEditor"));
          return true;
        },
      },
    ];

    const createExtensions = () => {
      const selectedLangSupport = languageSupport[this.options.language] || languageSupport.lua;
      const selectedCompletions =
        languageCompletions[this.options.language] || languageCompletions.lua;

      return [
        keymap.of([...customKeyMap, ...defaultKeymap, ...historyKeymap]),
        EditorView.editable.of(!this.options.disabled),
        themes[this.options.theme] || dracula,
        selectedLangSupport(),
        autocompletion({ override: selectedCompletions }),
        EditorView.updateListener.of((update) => {
          if (update.docChanged) {
            this.debouncedOnChange(this.getContent());
          }
        }),
        basicSetup,
      ];
    };

    this.view = new EditorView({
      doc: this.options.initialContent,
      extensions: createExtensions(),
      parent: this.element,
    });

    this.view.dom.style.border = "none";
    this.view.dom.style.height = "100%";
    this.debouncedOnChange = debounce(this.options.onChange, this.EDITOR_DEBOUNCE);
  }

  getContent() {
    return this.view ? this.view.state.doc.toString() : "";
  }

  setContent(content) {
    if (this.view) {
      this.view.dispatch({
        changes: { from: 0, to: this.view.state.doc.length, insert: content },
      });
    }
  }

  destroy() {
    if (this.view) {
      this.view.destroy();
    }
  }
}
