<script>
  import { Icon } from "svelte-hero-icons";

  export let variant = "info";
  export let icon;
  export let message;

  const variants = {
    info: {
      border: "border-gray-300",
      bg: "bg-gray-50",
      iconColor: "text-gray-400",
      textColor: "text-gray-500",
    },
    warn: {
      border: "border-amber-400",
      bg: "bg-amber-50",
      iconColor: "text-amber-400",
      textColor: "text-amber-500",
    },
    error: {
      border: "border-red-400",
      bg: "bg-red-50",
      iconColor: "text-red-400",
      textColor: "text-red-500",
    },
  };

  $: styles = variants[variant];
</script>

<div
  class="flex items-center justify-center px-2 py-1 border rounded text-xs shadow-sm {styles.border} {styles.bg}"
>
  <Icon src={icon} mini class="w-4 h-4 mr-2 {styles.iconColor}" />
  <p class="text-sm {styles.textColor}">{message}</p>
</div>
