import { CodeEditor } from "../code/CodeEditor";

export const CodeEditorHook = {
  async mounted() {
    const $editor = this.el.querySelector("div");
    const initialContent = $editor.dataset.content || "";
    const language = $editor.dataset.language || "lua";
    const theme = $editor.dataset.theme || "dark";
    const disabled = $editor.dataset.disabled === "true";
    const onChange = $editor.dataset.onChange || "code_updated";

    this.$editor = $editor;
    this.editor = new CodeEditor($editor, {
      initialContent,
      language,
      theme,
      disabled,
      onChange: (content) => this.pushEvent(onChange, { content }),
    });
    await this.editor.initialize();
  },

  updated() {
    // TODO: Currently this Hook does not support updating the content of the
    //       code editor by updating the props (the prop only acts as initial
    //       content). Because of phx-update="ignore" we won't receive an update
    //       here. For now the Svelte version supports prop updating.

    const content = this.$editor.dataset.content || "";
    this.editor.setContent(content);
  },

  destroyed() {
    if (this.editor) {
      this.editor.destroy();
    }
  },
};
