export const DistributeHorizontalIcon = {
  default: {
    a: {
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
    },
    path: [
      {
        d: "M4.875 4.125L4.875 18.125",
        stroke: "#0F172A",
        strokeWidth: "1.5",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      },
      {
        d: "M19.125 4.125L19.125 18.125",
        stroke: "#0F172A",
        strokeWidth: "1.5",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      },
      {
        d: "M14.125 6.125L14.125 16.125H10.125L10.125 6.125L14.125 6.125Z",
        fill: "#0F172A",
      },
    ],
  },
};
