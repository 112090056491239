<script>
  import { createEventDispatcher } from "svelte";
  import { Icon, XMark } from "svelte-hero-icons";

  export let label;
  export let optional = false;
  export let variant = "default";
  export let value = optional ? undefined : "#000000";
  export let isReadonly = false;

  const id = String(Math.random());
  const dispatch = createEventDispatcher();

  function handleInput(event) {
    if (!isReadonly) {
      value = event.target.value;
      dispatch("input", value);
    }
  }

  function clearValue() {
    if (!isReadonly) {
      value = undefined;
      dispatch("input", value);
    }
  }
</script>

<div class="relative w-full flex items-center {$$props.class || ''}">
  <input
    {id}
    type="color"
    class="border-gray-300 pr-8 rounded w-full text-xs bg-gray-100 border-0"
    class:opacity-10={!value}
    class:pl-8={variant == "narrow"}
    class:pl-12={variant == "default"}
    class:pl-32={variant == "wide"}
    class:pl-40={variant == "ultrawide"}
    class:cursor-not-allowed={isReadonly}
    value={value || "#000000"}
    on:input={handleInput}
    disabled={isReadonly}
  />
  <label for={id} class="absolute left-2 text-gray-500 {isReadonly ? 'cursor-not-allowed' : ''}"
    >{label}</label
  >
  {#if optional && !isReadonly}
    <button
      type="button"
      class="text-gray-500 hover:text-gray-700 focus:outline-none"
      class:invisible={!value}
      on:click={clearValue}
    >
      <Icon src={XMark} class="w-4 h-4" />
    </button>
  {/if}
  <slot name="dynamic" />
</div>
