<script>
  import DataGrid from "../DataGrid/DataGrid.svelte";

  export let event;

  let data = [
    {
      id: "status",
      label: "Status",
      value: event.is_active,
      type: "status",
    },
    {
      id: "event_time",
      label: "Event Time",
      value: event.start,
      type: "time",
    },
    {
      id: "region",
      label: "Region",
      value: event.region,
      type: "region",
    },
    {
      id: "progress",
      label: "Progress",
      value: event.state.progress,
      type: "numeric",
    },
  ];

  if (event.state.progress_bar) {
    data.push({
      id: "location",
      label: "Location",
      value: event.state.progress_bar,
      type: "region",
    });
  }
  if (event.state.visualizations.progress_bar) {
    data.push({
      id: "visualizations.progress_bar",
      label: "Progress Bar",
      value: event.state.visualizations.progress_bar,
      type: "image",
      props: {
        alt: "Progress Bar",
      },
    });
  }
  if (event.state.control_component) {
    data.push({
      id: "control_component",
      label: "Control",
      value: event.state.control_component,
      type: "region",
    });
  }
  if (event.state.visualizations.control_component) {
    data.push({
      id: "visualizations.control_component",
      label: "Control Component",
      value: event.state.visualizations.control_component,
      type: "image",
      props: {
        alt: "Control Component",
      },
    });
  }

  if (event.state.indicators.length === 0) {
    data.push({
      id: "indicators",
      label: "Progress Indicator",
      value: "Not available",
      type: "text",
    });
  } else {
    for (let index = 0; index < event.state.indicators.length; index++) {
      const indicator = event.state.indicators[index];
      data.push({
        id: `indicators_${index}`,
        label: "Indicator Location",
        value: indicator,
        type: "region",
      });
      data.push({
        id: `visualizations.indicators_${index}`,
        label: "Preview",
        value: event.state.visualizations.indicators[index],
        type: "image",
        props: {
          alt: `Preview of indicator #${index + 1}`,
        },
      });
    }
  }
</script>

<DataGrid {data} />
