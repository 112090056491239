<script>
  import { Icon } from "svelte-hero-icons";
  import { tooltip } from "$utils/tooltip";
  export let icon;
  export let description;
  export let selected = false;

  export let onClick = () => {};
</script>

<button
  use:tooltip
  aria-label={description}
  class="flex items-center justify-center hover:bg-blue-300 cursor-pointer p-2 rounded"
  class:bg-blue-600={selected}
  class:text-white={selected}
  on:click={onClick}
>
  <Icon src={icon} class="w-5 h-5" />
</button>
