<script>
  import { createEventDispatcher } from "svelte";

  export let currentTime;
  export let totalDuration;
  export let timelineWidth;
  export let scrollContainer;

  const dispatch = createEventDispatcher();

  $: currentTimePosition = (currentTime / totalDuration) * timelineWidth;

  function autohide(node, { scrollContainer, offsetX }) {
    let isVisible = true;

    function handleScroll() {
      const scrollLeft = scrollContainer.scrollLeft;
      const scrollTop = scrollContainer.scrollTop;
      const indicatorPosition = (currentTime / totalDuration) * timelineWidth + offsetX;
      isVisible = indicatorPosition > scrollLeft;
      node.style.display = isVisible ? "block" : "none";
      node.style.top = `${scrollTop}px`;
    }

    scrollContainer.addEventListener("scroll", handleScroll);

    return {
      update({ scrollContainer: newScrollContainer }) {
        if (newScrollContainer !== scrollContainer) {
          scrollContainer.removeEventListener("scroll", handleScroll);
          scrollContainer = newScrollContainer;
          scrollContainer.addEventListener("scroll", handleScroll);
        }
      },
      destroy() {
        scrollContainer.removeEventListener("scroll", handleScroll);
      },
    };
  }

  function drag(node) {
    let startX;
    let startLeft;

    function handleMousedown(event) {
      event.preventDefault();
      startX = event.clientX;
      startLeft = parseFloat(node.style.left);

      window.addEventListener("mousemove", handleMousemove);
      window.addEventListener("mouseup", handleMouseup);
    }

    function handleMousemove(event) {
      const dx = event.clientX - startX;
      let newLeft = startLeft + dx;

      // Constrain the indicator within the timeline
      newLeft = Math.max(0, Math.min(newLeft, timelineWidth));

      node.style.left = `${newLeft}px`;
      dispatch("timeupdate", (newLeft / timelineWidth) * totalDuration);
    }

    function handleMouseup() {
      window.removeEventListener("mousemove", handleMousemove);
      window.removeEventListener("mouseup", handleMouseup);

      // Dispatch a synthetic scroll event, so indicator is hidden
      if (scrollContainer) {
        scrollContainer.dispatchEvent(new Event("scroll"));
      }
    }

    node.addEventListener("mousedown", handleMousedown);

    return {
      destroy() {
        node.removeEventListener("mousedown", handleMousedown);
      },
    };
  }
</script>

{#if scrollContainer}
  <div
    use:drag
    use:autohide={{ scrollContainer, offsetX: 10 }}
    class="absolute top-0 bottom-0 w-4 z-[1000] cursor-ew-resize select-none pointer-events-auto"
    style="left: {currentTimePosition}px;"
  >
    <div class="absolute left-1/2 -top-6 bottom-0 w-0.5 bg-red-500"></div>
    <div
      class="absolute left-[9px] -top-6 w-[10px] h-[10px] bg-red-500 rounded-full -translate-x-1/2"
    ></div>
  </div>
{/if}
