export const AlignRightIcon = {
  default: {
    a: {
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
    },
    path: [
      {
        d: "M22 5V19",
        stroke: "#0F172A",
        strokeWidth: "1.5",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      },
      {
        d: "M19.5 7H5.5V11H19.5V7Z",
        fill: "#0F172A",
      },
      {
        d: "M19.5 13H13.5V17H19.5V13Z",
        fill: "#0F172A",
      },
    ],
  },
};
