import { writable, derived, get } from "$utils/shim";
import { execution, selectScreen, selectRegion, imageManager } from "$lib/shared_stores";

const defaultScale = 25; // in pixel/second
const LOG_IGNORE_EVENTS = [
  "workflow_set_progress",
  "workflow_audio_level",
  "workflow_network_metrics",
  "audio_level",
  "network_metrics",
  "ui_detect_result",
  "ui_screen_changed",
  "workflow_update_state",
];

export function createTimelineStore(playback) {
  const zoom = writable(1);
  const remoteEvents = writable([]);
  const uiStateEvents = writable([]);
  const registeredMatchers = writable(new Map());
  const currentScreen = writable({ screen_id: null, screen_name: null, id: null, start: 0 });
  const workflowEvents = writable([]);
  const currentWorkflowStep = writable(null);
  const audioEvents = writable([]);
  const networkEvents = writable([]);
  const networkMaxSpeed = writable(1);
  const metrics = writable([]);
  const tracks = writable([]);
  const selectedTimelineItem = writable(null);

  const timelineWidth = derived(
    playback.totalDuration,
    ($totalDuration) => $totalDuration * defaultScale,
  );

  const scaledTimelineWidth = derived(
    [playback.totalDuration, zoom],
    ([$totalDuration, $zoom]) => $totalDuration * $zoom * defaultScale,
  );

  const markerIncrement = derived(zoom, ($zoom) => $zoom * defaultScale);

  function displayUpToMapFilter(events, displayUpTo) {
    if (events.length === 0) return [];
    for (let i = events.length - 1; i >= 0; i--) {
      if (events[i].start < displayUpTo) {
        let sliced = events.slice(0, i);
        let lastEvent = { ...events[i] };
        if (
          lastEvent.duration === undefined ||
          lastEvent.start + lastEvent.duration > displayUpTo
        ) {
          lastEvent.duration = displayUpTo - lastEvent.start;
        }
        sliced.push(lastEvent);
        return sliced;
      }
    }
    return [];
  }

  // ================== Derived stores ==================
  // ===== Visible Remote events =====
  const visibleRemoteElements = derived(
    [playback.isLive, playback.maxPlayedTime, remoteEvents],
    ([$isLive, $maxPlayedTime, $remoteEvents]) =>
      $isLive ? displayUpToMapFilter($remoteEvents, $maxPlayedTime) : $remoteEvents,
  );

  // ===== Visible Workflow Steps events =====
  const visibleWorkflowElements = derived(
    [playback.isLive, playback.maxPlayedTime, workflowEvents],
    ([$isLive, $maxPlayedTime, $workflowEvents]) =>
      $isLive ? displayUpToMapFilter($workflowEvents, $maxPlayedTime) : $workflowEvents,
  );

  // Reduce workflow events into subtracks
  const workflowSubtracks = derived(visibleWorkflowElements, ($subEvents) => {
    let index = 0;
    return $subEvents.reduce((acc, event) => {
      if (event.subtype !== undefined) {
        // TODO: after we support customizing the block names, we should set name to the block name here
        const key = JSON.stringify({ type: event.subtype, id: event.id });
        if (!acc.has(key)) acc.set(key, []);
        acc.get(key).push({ ...event, index });
      }
      index++;
      return acc;
    }, new Map());
  });

  // ===== Visible UI State events =====
  const visibleUIStateElements = derived(
    [playback.isLive, playback.maxPlayedTime, uiStateEvents],
    ([$isLive, $maxPlayedTime, $uiStateEvents]) =>
      $isLive ? displayUpToMapFilter($uiStateEvents, $maxPlayedTime) : $uiStateEvents,
  );

  // Reduce UI State events into subtracks
  const uiStateSubtracks = derived(visibleUIStateElements, ($subEvents) => {
    return $subEvents.reduce((acc, event) => {
      if (event.subtype !== undefined) {
        const name = `${event.tagName} (${event.label})`;
        const key = JSON.stringify({ type: event.subtype, id: event.ui_id, name: name });
        if (!acc.has(key)) acc.set(key, []);
        acc.get(key).push(event);
      }
      return acc;
    }, new Map());
  });

  // Map visible UI State events to their respective active state
  // based on the current playback time
  const uiStateEventsShowInOverlay = derived(
    [visibleUIStateElements, playback.currentTime],
    ([$visibleUIStateEvents, $currentTime]) =>
      $visibleUIStateEvents.map((visibleUIStateEvent) => ({
        ...visibleUIStateEvent,
        showInOverlay:
          visibleUIStateEvent.start <= $currentTime &&
          (visibleUIStateEvent.duration === undefined ||
            $currentTime < visibleUIStateEvent.start + visibleUIStateEvent.duration),
      })),
  );

  // Reduce visible UI State events into unique matchers
  const uniqueMatchers = derived(uiStateEventsShowInOverlay, ($activeUIStateEvents) => {
    const results = $activeUIStateEvents
      .filter((activeUIStateEvent) => activeUIStateEvent.ui_id)
      .reduce(
        ({ acc, ids }, e) => {
          if (!ids.includes(e.ui_id)) {
            ids.push(e.ui_id);
          }
          acc[e.ui_id] = e;
          return { acc, ids };
        },
        { acc: {}, ids: [] },
      );
    const matchers = get(registeredMatchers);
    for (const ui_id in matchers) {
      if (!results.ids.includes(ui_id)) {
        results.acc[ui_id] = matchers[ui_id];
        results.ids.push(ui_id);
      }
    }
    return results.ids.map((id) => results.acc[id]);
  });

  // Filter out only active UI State events based on the current playback time
  // These events will be drawn as overlay on the player
  const activeTimelineEvents = derived(
    [uiStateEventsShowInOverlay, playback.currentTime],
    ([$uiStateEvents, $currentTime]) =>
      $uiStateEvents.filter(
        (event) =>
          $currentTime >= event.start &&
          (event.duration === undefined || $currentTime < event.start + event.duration),
      ),
  );

  // ===== Visible Network Traffic events =====
  const visibleNetworkElements = derived(
    [playback.isLive, playback.maxPlayedTime, networkEvents],
    ([$isLive, $maxPlayedTime, $networkEvents]) =>
      $isLive ? displayUpToMapFilter($networkEvents, $maxPlayedTime) : $networkEvents,
  );

  // ===== Visible Audio Level events =====
  const visibleAudioElements = derived(
    [playback.isLive, playback.maxPlayedTime, audioEvents],
    ([$isLive, $maxPlayedTime, $audioEvents]) =>
      $isLive ? displayUpToMapFilter($audioEvents, $maxPlayedTime) : $audioEvents,
  );

  // ===== Visible Metrics events =====
  const visibleMetricsEvents = derived(
    [playback.isLive, playback.maxPlayedTime, metrics],
    ([$isLive, $maxPlayedTime, $metrics]) =>
      $isLive ? displayUpToMapFilter($metrics, $maxPlayedTime) : $metrics,
  );

  // ===== Selected Event based on time =====
  const selectedEventBasedOnTime = derived(
    [playback.currentTime, uiStateEvents],
    ([$currentTime, $uiStateEvents]) => {
      if ($uiStateEvents.length === 0) return null;

      for (let i = $uiStateEvents.length - 1; i >= 0; i--) {
        if ($uiStateEvents[i].start <= $currentTime) {
          return $uiStateEvents[i];
        }
      }

      return null;
    },
  );

  function uiEventTagName(ui_type) {
    if (ui_type === undefined) return "Unknown";

    const tagNameMap = {
      brightness: "Brightness",
      color: "Color",
      text: "Text",
      screen: "Screen",
      image: "Image",
      playback_progress_bar: "Playback Progress Bar",
    };
    return tagNameMap[ui_type] || ui_type.charAt(0).toUpperCase() + ui_type.slice(1);
  }

  function addEvent(event) {
    if (event.type === "key") {
      remoteEvents.update(($events) => {
        $events[$events.length] = { ...event, track: event.type };
        return $events;
      });
    } else if (event.type === "workflow") {
      workflowEvents.update(($events) => {
        $events[$events.length] = { ...event, track: event.type };
        return $events;
      });
    } else if (event.type === "audio") {
      audioEvents.update(($events) => {
        $events[$events.length] = { ...event, track: event.type };
        return $events;
      });
    } else if (event.type === "network") {
      networkEvents.update(($events) => {
        $events[$events.length] = { ...event, track: event.type };
        return $events;
      });
    } else if (event.type === "state") {
      // TODO: send this from the backend
      const tagName = uiEventTagName(event.ui_type);
      const uiStateEvent = {
        ...event,
        tagName,
        name: `${tagName}: ${event.label}`,
        track: event.type,
        showInOverlay: false,
      };

      if (event.first_seen) {
        let matchers = get(registeredMatchers);
        if (!matchers.has(event.ui_id)) {
          matchers[event.ui_id] = uiStateEvent;
          registeredMatchers.set(matchers);
        }
      }
      uiStateEvents.update(($events) => {
        $events[$events.length] = uiStateEvent;
        return $events;
      });
    } else if (event.type === "metric") {
      metrics.update(($events) => {
        $events[$events.length] = { ...event, track: event.type };
        return $events;
      });
    } else {
      console.warn("Unknown event type:", event.type);
    }
  }

  function updateWorkflowEvent(id, updates) {
    workflowEvents.update(($workflowEvents) => {
      const index = $workflowEvents.findIndex((e) => e.id === id);
      if (index !== -1) {
        $workflowEvents[index] = { ...$workflowEvents[index], ...updates };
      }
      return $workflowEvents;
    });
  }

  function updateWorkflowAccumulatedMotion(data) {
    const stepId = data.step_id;
    workflowEvents.update(($workflowEvents) => {
      const index = $workflowEvents.findIndex((e) => e.id === stepId);
      if (index !== -1) {
        const event = $workflowEvents[index];
        if (event.subtype !== "detect_playback") return $workflowEvents;

        let accumulatedMotion = event.accumulatedMotion || [];
        accumulatedMotion.push(data);
        $workflowEvents[index] = { ...event, accumulatedMotion };
      }
      return $workflowEvents;
    });
  }

  function clearTimeline() {
    // TODO: Support loading persisted events
    remoteEvents.set([]);
    uiStateEvents.set([]);
    currentScreen.set({ screen_id: null, screen_name: null, id: null, start: 0 });
    registeredMatchers.set(new Map());
    workflowEvents.set([]);
    currentWorkflowStep.set(null);
    audioEvents.set([]);
    networkEvents.set([]);
    networkMaxSpeed.set(1);
    metrics.set([]);

    addEvent({
      id: "warmup",
      type: "workflow",
      subtype: "warmup",
      start: 0,
      label: "warmup",
    });
  }

  async function processEvent(eventWithImages) {
    const event = await imageManager.processEvent(eventWithImages);

    if (!LOG_IGNORE_EVENTS.includes(event.type)) {
      console.log("💬", event.type, event.data);
    }

    const FRAME_DURATION = 33;

    let eventTime = 0;
    if (Number.isInteger(event.data.pts)) {
      eventTime = event.data.pts / 1000;

      // During a live stream the timeupdate event is triggered continously
      // duration is set to Infinity. We can use the currentTime as duration.
      playback.setTotalDurationFromEvents(eventTime);
    }

    switch (event.type) {
      case "automation_started":
        updateWorkflowEvent("warmup", {
          duration: eventTime,
        });
        break;
      case "automation_ended":
        break;
      case "workflow_begin_step": {
        const current = {
          id: event.data.step_id,
          type: "workflow",
          subtype: event.data.step_type,
          start: eventTime,
          status: "running",
          // TODO: after we support customizing the block names, we should use the block name here
          label: event.data.step_id,
        };
        addEvent(current);
        currentWorkflowStep.set(current);
        break;
      }
      case "workflow_end_step": {
        const execDuration = event.data.outputs.execDuration / 1000;
        updateWorkflowEvent(event.data.step_id, {
          status: "succeeded",
          execDuration,
          duration: execDuration,
        });
        currentWorkflowStep.set(null);
        break;
      }
      case "metric": {
        addEvent({
          type: "metric",
          start: eventTime,
          duration: FRAME_DURATION / 1000,
          name: event.data.name,
          value: event.data.value,
          tags: event.data.tags,
        });
        break;
      }
      case "error": {
        const step = get(currentWorkflowStep);
        if (!step) break;

        const execDuration = get(playback.totalDuration) - step.start;
        updateWorkflowEvent(step.id, {
          status: "failed",
          execDuration,
          duration: execDuration,
        });
        currentWorkflowStep.set(null);
        break;
      }
      case "workflow_fail_step": {
        const execDuration = event.data.outputs.execDuration / 1000;
        updateWorkflowEvent(event.data.step_id, {
          status: "failed",
          execDuration,
          duration: execDuration,
        });
        currentWorkflowStep.set(null);
        break;
      }
      case "workflow_update_state":
        updateWorkflowEvent(event.data.step_id, event.data.state);
        break;
      case "workflow_set_progress":
        updateWorkflowEvent(event.data.step_id, { progress: event.progress });
        break;
      case "workflow_remote_command":
        addEvent({
          id: event.data.step_id,
          type: "key",
          start: eventTime,
          label: event.data.command,
          duration: FRAME_DURATION / 1000,
        });
        break;
      case "network_metrics":
      case "workflow_network_metrics": {
        addEvent({
          type: "network",
          start: eventTime,
          tx: event.data.tx_bytes,
          rx: event.data.rx_bytes,
          duration: FRAME_DURATION / 1000,
        });
        const currentMaxSpeed = get(networkMaxSpeed);
        const eventMaxSpeed = Math.max(event.data.tx_bytes, event.data.rx_bytes);
        if (eventMaxSpeed > currentMaxSpeed) {
          networkMaxSpeed.set(eventMaxSpeed);
        }
        break;
      }
      case "audio_level":
      case "workflow_audio_level": {
        addEvent({
          id: event.data.step_id,
          type: "audio",
          start: eventTime,
          level: event.data.level,
          duration: FRAME_DURATION / 1000,
        });
        break;
      }
      case "accumulated_motion": {
        updateWorkflowAccumulatedMotion(event.data);
        break;
      }
      case "ui_detect_result": {
        addEvent({
          id: `ui.${event.data.ui_id}.${eventTime}`,
          screen_id: event.data.screen_id,
          ui_id: event.data.ui_id,
          ui_type: event.data.ui_type,
          is_active: event.data.is_active,
          label: event.data.name,
          type: "state",
          subtype: event.data.ui_id,
          start: eventTime,
          state: event.data.state,
          first_seen: event.data.first_seen,
          is_assert: event.data.is_assert,
          executionTime: event.data.execution_time,
          // duration is amount of time the state is active
          // i.e., how long the overlay should be displayed
          duration: 0.03,
          region: event.data.region,
        });
        break;
      }
      case "ui_screen_changed": {
        const newScreen = event.data;
        const id = `screen.${newScreen.screen_id}.${eventTime}`;
        currentScreen.set({ ...newScreen, id: id, start: eventTime });

        addEvent({
          id: id,
          ui_id: newScreen.screen_id,
          ui_type: "screen",
          type: "state",
          subtype: "Screen",
          start: eventTime,
          label: event.data.screen_name,
          preview: event.data.preview,
          duration: 0.3,
          region: {
            left: 0,
            top: 0,
            width: 1,
            height: 1,
          },
        });
        break;
      }
      case "workflow_log_input":
      case "workflow_log_output":
      case "workflow_log_error":
      case "asset_uploaded":
      case "log":
        break;
      default:
        console.warn("Unknown event type:", event.type);
    }
  }

  return {
    initialize(initialTrack) {
      remoteEvents.set([]);
      uiStateEvents.set([]);
      workflowEvents.set([]);
      currentWorkflowStep.set(null);
      networkEvents.set([]);
      audioEvents.set([]);
      metrics.set([]);

      tracks.set(initialTrack);
    },

    tracks: { subscribe: tracks.subscribe },
    timelineWidth: { subscribe: timelineWidth.subscribe },
    zoom: { subscribe: zoom.subscribe },
    markerIncrement: { subscribe: markerIncrement.subscribe },
    scaledTimelineWidth: { subscribe: scaledTimelineWidth.subscribe },
    visibleRemoteElements: { subscribe: visibleRemoteElements.subscribe },
    visibleUIStateElements: { subscribe: visibleUIStateElements.subscribe },
    uiStateEventsShowInOverlay: { subscribe: uiStateEventsShowInOverlay.subscribe },
    visibleWorkflowElements: { subscribe: visibleWorkflowElements.subscribe },
    visibleNetworkElements: { subscribe: visibleNetworkElements.subscribe },
    visibleAudioElements: { subscribe: visibleAudioElements.subscribe },
    visibleMetricsEvents: { subscribe: visibleMetricsEvents.subscribe },
    uiStateEvents: { subscribe: uiStateEvents.subscribe },
    uiStateSubtracks: { subscribe: uiStateSubtracks.subscribe },
    workflowSubtracks: { subscribe: workflowSubtracks.subscribe },
    uniqueMatchers: { subscribe: uniqueMatchers.subscribe },
    activeTimelineEvents: { subscribe: activeTimelineEvents.subscribe },
    networkMaxSpeed: { subscribe: networkMaxSpeed.subscribe },
    metrics: { subscribe: metrics.subscribe },
    currentScreen: { subscribe: currentScreen.subscribe },
    selectedTimelineItem: { subscribe: selectedTimelineItem.subscribe },
    selectedEventBasedOnTime: {
      subscribe: selectedEventBasedOnTime.subscribe,
    },

    addEvent,
    updateWorkflowEvent,
    processEvent,

    setZoom: zoom.set,
    clearTimeline,

    captureCurrentScreen() {
      playback.captureCurrentScreen();
    },

    adjustZoom(delta) {
      zoom.update((z) => Math.max(0.5, Math.min(2, z + delta)));
    },

    select(event) {
      selectedTimelineItem.set(event);
    },

    navigate(e, event, target) {
      if (!e.shiftKey) {
        if (event.start === undefined) return;

        playback.pause();
        playback.goToTime(event.start);
        selectedTimelineItem.set(event);
        return;
      }

      switch (target) {
        case "designer": {
          document.querySelector("[data-el-nav='design']").click();
          if (event.ui_type === "screen") {
            selectScreen(event.ui_id);
            setTimeout(() => selectRegion(event.ui_id, null), 100);
          } else {
            selectScreen(event.screen_id);
            setTimeout(() => selectRegion(event.screen_id, event.ui_id), 100);
          }
          break;
        }
        case "automate": {
          document.querySelector("[data-el-nav='automate']").click();
          if (event.id === "warmup") return;
          execution.setCurrentStep(event.id);
          break;
        }
        default: {
          console.log("unknown navigate target:", target);
          break;
        }
      }
    },

    calcPxStartTime(event) {
      return (event.start * get(scaledTimelineWidth)) / get(playback.totalDuration);
    },

    calcPxDuration(event) {
      const minWidth =
        {
          state: 22,
          workflow: 22,
        }[event.type] || 0.033;
      return this.durationToPx(event.duration, minWidth);
    },

    durationToPx(duration, minWidth) {
      const total = Math.max(get(playback.totalDuration), 0.033);
      const result = Math.max((duration * get(scaledTimelineWidth)) / total, minWidth);
      if (!isFinite(result)) return 0;
      return result;
    },

    resetZoom() {
      zoom.set(1.0);
    },

    toggleIsSelected(id, selected) {
      uiStateEvents.update((events) =>
        events.map((element) =>
          element.id === id ? { ...element, isSelected: selected } : element,
        ),
      );
    },

    toggleSubtrack(trackId) {
      tracks.update((tracks) =>
        tracks.map((track) =>
          track.id === trackId ? { ...track, showSubtrack: !track.showSubtrack } : track,
        ),
      );
    },

    reset() {
      zoom.set(1);
      remoteEvents.set([]);
      uiStateEvents.set([]);
      currentScreen.set({ screen_id: null, screen_name: null, id: null, start: 0 });
      registeredMatchers.set(new Map());
      workflowEvents.set([]);
      currentWorkflowStep.set(null);
      networkEvents.set([]);
      networkMaxSpeed.set(1);
      audioEvents.set([]);
      metrics.set([]);
      tracks.set([]);
      selectedTimelineItem.set(null);
    },
  };
}
