<script>
  import {
    Icon,
    Folder,
    FolderOpen,
    RectangleGroup,
    ChevronRight,
    ChevronDown,
    Trash,
    ArrowUp,
    ArrowDown,
    ExclamationTriangle,
  } from "svelte-hero-icons";
  import InlineEdit from "$lib/Inputs/InlineEdit.svelte";
  import { tooltip } from "$utils/tooltip";
  import { selectedElements, screenStores, selectedScreenId, selectRegion } from "../store";

  export let regions;
  export let name;
  export let isFirst = false;
  export let isLast = false;
  export let isNewScreen = false;
  export let isDuplicateScreenName = false;
  export let isReadonly = false;
  let expanded = false;

  function onSelectScreen() {
    const screenId = regions._id;
    screenStores.selectScreen(screenId);
    screenStores.changeSelection(null);
  }

  function onSelectRegion({ _id: id }) {
    selectRegion(regions._id, id);
  }

  function onDeleteScreen(id) {
    screenStores.deleteScreen(id);
  }

  function onDeleteRegion(matcher) {
    onSelectRegion(matcher);
    screenStores.deleteSelected();
  }

  function onChangeScreenName(event) {
    const screenId = regions._id;
    screenStores.updateName(screenId, event.detail);
  }

  function onChangeRegionName(matcher, event) {
    const screenId = regions._id;
    const regionId = matcher._id;
    screenStores.updateRegionName(screenId, regionId, event.detail);
  }

  function moveScreen(direction) {
    screenStores.moveScreen(regions._id, direction);
  }

  function isDuplicateName(matcher, regions) {
    return regions.filter((region) => region.name === matcher.name).length > 1;
  }
</script>

<div>
  <div class="w-full flex items-center gap-x-0.5 mb-1">
    <button
      class="size-7 flex justify-center items-center hover:bg-gray-200 rounded-sm focus:outline-none focus:bg-gray-100"
      on:click={() => (expanded = !expanded)}
    >
      <Icon src={expanded ? ChevronRight : ChevronDown} class="w-4 h-4 text-gray-800" />
    </button>

    <button
      class="w-full pl-2 rounded-sm cursor-pointer hover:bg-gray-200 group"
      class:bg-gray-200={$selectedScreenId == regions._id}
      on:click={() => onSelectScreen()}
    >
      <div class="flex items-center">
        {#if isDuplicateScreenName}
          <div use:tooltip aria-label="Warning: screen name is not unique!" class="flex-none">
            <Icon src={ExclamationTriangle} class="w-5 h-5 text-yellow-500" />
          </div>
        {:else}
          <Icon
            src={($regions || []).length > 0 ? FolderOpen : Folder}
            class="flex-none w-5 h-5 text-gray-800"
          />
        {/if}
        <div class="ml-2 grow text-left truncate block">
          <InlineEdit
            class={`text-sm text-gray-800 ${name ? "" : "italic"}`}
            value={name || "Unnamed"}
            on:change={onChangeScreenName}
            startEditing={isNewScreen}
            {isReadonly}
          />
        </div>
        <button
          class="ml-auto p-1 rounded-sm hover:bg-gray-300 invisible group-hover:visible cursor-pointer flex-shrink-0"
          on:click|stopPropagation={() => moveScreen("up")}
          disabled={isFirst || isReadonly}
        >
          <Icon src={ArrowUp} class="w-5 h-5 text-gray-800" />
        </button>
        <button
          class="p-1 rounded-sm hover:bg-gray-300 invisible group-hover:visible cursor-pointer flex-shrink-0"
          on:click|stopPropagation={() => moveScreen("down")}
          disabled={isReadonly}
        >
          <Icon src={ArrowDown} class="w-5 h-5 text-gray-800" disabled={isLast} />
        </button>
        <button
          class="p-1 rounded-sm hover:bg-red-400 invisible group-hover:visible cursor-pointer flex-shrink-0"
          on:click|stopPropagation={() => onDeleteScreen(regions._id)}
          disabled={isReadonly}
        >
          <Icon src={Trash} class="w-5 h-5 text-gray-800" />
        </button>
      </div>
    </button>
  </div>

  <div class="w-full overflow-hidden transition-[height] duration-300" class:hidden={expanded}>
    <div
      class="ms-3 ps-5 relative before:absolute before:top-0 before:start-0 before:w-0.5 before:-ms-px before:h-full before:bg-gray-100"
    >
      {#each $regions as matcher (regions._id + "+" + matcher._id)}
        <button
          class="w-full pl-2 mb-1 rounded-sm cursor-pointer hover:bg-gray-200 group"
          class:bg-gray-200={$selectedElements.includes(matcher._id)}
          on:click={() => onSelectRegion(matcher)}
        >
          <div class="flex items-center gap-x-2">
            {#if isDuplicateName(matcher, $regions)}
              <div use:tooltip aria-label="Warning: region name is not unique!">
                <Icon src={ExclamationTriangle} class="flex-none w-5 h-5 text-yellow-500" />
              </div>
            {:else}
              <Icon src={RectangleGroup} class="flex-none w-5 h-5 text-gray-800" />
            {/if}
            <div class="grow text-left truncate block">
              <InlineEdit
                class={`text-sm text-gray-800 ${matcher.required ? "font-bold" : ""} ${matcher.name ? "" : "italic"}`}
                value={matcher.name || "Unnamed"}
                on:change={(e) => onChangeRegionName(matcher, e)}
                {isReadonly}
              />
            </div>
            <button
              class="ml-auto p-1 rounded-sm hover:bg-red-400 invisible group-hover:visible cursor-pointer flex-shrink-0"
              on:click|stopPropagation={() => onDeleteRegion(matcher)}
              disabled={isReadonly}
            >
              <Icon src={Trash} class="w-5 h-5 text-gray-800" />
            </button>
          </div>
        </button>
      {/each}
    </div>
  </div>
</div>

{#if false}
  <safelist class="bg-gray-200 hidden" />
{/if}
