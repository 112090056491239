import type { ViewHook } from "phoenix_live_view";

function downloadBlob(blob: Blob, filename: string): void {
  const link = document.createElement("a");
  link.style.display = "none";

  document.body.appendChild(link);

  const objectURL = URL.createObjectURL(blob);

  link.href = objectURL;
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();
}

function blobFromBuffer(buffer: ArrayBuffer): Blob {
  const blob = new Blob([buffer], { type: "application/octet-binary" });

  return blob;
}

function filenameFromResponse(response: Response): string {
  try {
    return response.headers.get("Content-Disposition")!.split("filename=")[1].split('"')[1];
  } catch (error) {
    return "report.pdf";
  }
}

function disableLink(el: HTMLAnchorElement & { beforeHref: string }) {
  const href = el.getAttribute("href")!;
  el.beforeHref = href;
  el.removeAttribute("href");
  el.setAttribute("role", "link");
  el.setAttribute("aria-disabled", "true");
  el.style.cursor = "pointer";
}

function enableLink(el: HTMLAnchorElement & { beforeHref: string }) {
  el.setAttribute("href", el.beforeHref);
  el.removeAttribute("role");
  el.removeAttribute("aria-disabled");
  el.style.cursor = "pointer";
}

type DownloadReportHook = ViewHook & {
  el: HTMLAnchorElement & { beforeHref: string };
};

export const DownloadReport = {
  mounted(this: DownloadReportHook) {
    this.el.addEventListener("click", async (event) => {
      if (!this.el.hasAttribute("aria-disabled")) {
        event.preventDefault();

        const beforeHTML = this.el.innerHTML;
        this.el.innerHTML = "Generating File...";

        const url = this.el.getAttribute("href")!;

        disableLink(this.el);

        const response = await fetch(url);

        if (response.ok && response.status === 200) {
          const buffer = await response.arrayBuffer();
          const filename = filenameFromResponse(response);

          const blob = blobFromBuffer(buffer);

          downloadBlob(blob, filename);

          enableLink(this.el);
          this.el.innerHTML = beforeHTML;
        } else {
          this.el.innerHTML = "Error: Could not generate file.";

          window.setTimeout(() => {
            enableLink(this.el);
            this.el.innerHTML = beforeHTML;
          }, 2000);
        }
      }
    });
  },
};
