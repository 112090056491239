<script>
  import { Icon, Link } from "svelte-hero-icons";
  export let link;
</script>

<div class="inline-flex items-center space-x-2">
  <a
    href={link}
    target="_blank"
    rel="noopener noreferrer"
    class="p-0 hover:opacity-70 focus:outline-none relative"
  >
    <Icon src={Link} class="w-4 h-4 -ml-1 text-gray-500" />
  </a>
</div>
