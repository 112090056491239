<!--
Displays overlays on top of a reference DOM element (refElementId).

This component supports two ways to send annotations:

  1. Via Window Event:

  With this method annotations can be updated from anywhere in the application
  by dispatching a custom event.

  ```javascript
  window.dispatchEvent(new CustomEvent("updateAnnotations", {
    detail: {
      add: [
        {
          _id: "unique_id_1",
          tagName: "Text",
          name: "Annotation 1",
          left: 100,
          top: 100,
          width: 200,
          height: 100,
          state: {} // Optional
        },
        // more annotations to add...
      ],
      update: [
        {
          _id: "existing_id_1",
          // Include only the properties to update
          name: "Updated Annotation",
          state: { updated: true }
        },
        // more annotations to update...
      ],
      del: [
        { _id: "id_to_delete_1" },
        // more annotations to delete...
      ]
    }
  }));

  2. Via Live Updates:

  With this method updates can be sent through the live view channel.

  ```elixir
  def handle_event("my_event", _, socket) do
    {:noreply, push_event(socket, "update_annotations", %{
      add: [
        %{
          _id: "unique_id_2",
          tagName: "Button",
          name: "New Button",
          left: 200,
          top: 200,
          width: 150,
          height: 75,
          state: %{} # Optional
        }
      ],
      update: [
        %{
          _id: "existing_id_2",
          name: "Updated Button"
        }
      ],
      del: [
        %{_id: "id_to_delete_2"}
      ]
    })}
  end
  ```
--->

<script>
  import { onMount, onDestroy } from "svelte";
  import { writable } from "$utils/shim";

  import Overlays from "./Overlay/OverlayLabels.svelte";
  import DebugControls from "./Overlay/DebugControls.svelte";

  export let refElementId;
  export let live = null;
  export let enableDebug = false;
  export let baseWidth = 1920;
  export let baseHeight = 1080;
  let selected = null;

  const annotationsStore = writable([]);

  function handleUpdate(jsonDiff) {
    if (!jsonDiff) return;

    annotationsStore.update((currentAnnotations) => {
      let updatedAnnotations = [...currentAnnotations];

      if (jsonDiff.add) {
        updatedAnnotations = [...updatedAnnotations, ...jsonDiff.add];

        updatedAnnotations.forEach((annotation) => {
          if (annotation.hideAfter) {
            setTimeout(() => {
              annotationsStore.update((current) => current.filter((a) => a._id !== annotation._id));
            }, annotation.hideAfter);
          }
        });
      }

      if (jsonDiff.update) {
        updatedAnnotations = updatedAnnotations.map((annotation) => {
          const update = jsonDiff.update.find((u) => u._id === annotation._id);
          return update ? { ...annotation, ...update } : annotation;
        });
      }

      if (jsonDiff.del) {
        const idsToRemove = new Set(jsonDiff.del.map((a) => a._id));
        updatedAnnotations = updatedAnnotations.filter((a) => !idsToRemove.has(a._id));
      }

      if (selected) {
        const updatedSelected = updatedAnnotations.filter((a) => a._id == selected);
        if (updatedSelected.length === 1) {
          live.pushEvent("select_annotation", { annotation: updatedSelected[0] });
        }
      }

      return updatedAnnotations;
    });
  }

  function handleWindowEvent(event) {
    handleUpdate(event.detail);
  }

  function handleLiveEvent(payload) {
    handleUpdate(payload);
  }

  onMount(() => {
    window.addEventListener("updateAnnotations", handleWindowEvent);
    if (live) {
      live.handleEvent("update_annotations", handleLiveEvent);
    }
  });

  onDestroy(() => {
    window.removeEventListener("updateAnnotations", handleWindowEvent);
    if (live) {
      live.removeHandleEvent(handleLiveEvent);
    }
  });

  function handleAnnotationClick(event) {
    selected = event.detail._id;
    live.pushEvent("select_annotation", { annotation: event.detail });
  }
</script>

<Overlays
  {refElementId}
  {enableDebug}
  {baseWidth}
  {baseHeight}
  annotations={$annotationsStore}
  on:select={handleAnnotationClick}
/>

{#if enableDebug}
  <DebugControls {refElementId} {baseWidth} {baseHeight} annotationsStore={$annotationsStore} />
{/if}
