import { writable, derived } from "$utils/shim";
import { addRequestHandler } from "$utils/request";
import { createAssetStore } from "$lib/shared_stores";
import { createFeatureStore } from "./features";
import { createPlaybackStore } from "./playback";
import { createTestRunsStore } from "./runs";
import { createTimelineStore } from "./timeline";

const initialToolbar = {
  show_runs_pane: true,
  show_inspector_pane: true,
  show_timeline_pane: true,
  show_overlay: true,
  show_debug: false,
};

export const liveStore = writable(null);

export const assetStore = createAssetStore(liveStore);

export const features = createFeatureStore();

export const toolbar = writable(initialToolbar);

export const playback = createPlaybackStore();

export const timeline = createTimelineStore(playback);

export const testRuns = createTestRunsStore(liveStore, playback, timeline);

export const projectId = writable(null);

export const currentStep = derived(
  [playback.currentTime, timeline.visibleWorkflowElements],
  ([$currentTime, $workflowElements]) =>
    $workflowElements?.find(
      (item) => item.start <= $currentTime && $currentTime <= item.start + item.duration,
    ),
);

export function resetStores() {
  // Svelte stores persist throughout live view patch navigation.
  // All stores must be reset to avoid issues when navigating away.
  features.reset();
  timeline.reset();
  playback.reset();
  testRuns.reset();
  liveStore.set(null);
  toolbar.set(initialToolbar);
  projectId.set(null);
}

export function initializeStore(live, { user_roles, run_results, job, project_id }) {
  resetStores();
  liveStore.set(addRequestHandler(live));
  features.initialize(user_roles);
  let tracks = [
    { id: "key", name: "Remote", visible: true, hasSubtrack: false },
    { id: "state", name: "UI State", visible: true, hasSubtrack: true, showSubtrack: false },
    {
      id: "workflow",
      name: "Workflow Step",
      visible: true,
      hasSubtrack: true,
      showSubtrack: false,
    },
    { id: "network", name: "Network", visible: true, hasSubtrack: false },
    { id: "audio", name: "Audio Level", visible: true, hasSubtrack: false },
  ];
  testRuns.initialize(run_results, job);
  timeline.initialize(tracks);
  projectId.set(project_id);
}

export function uploadScreenshot(files, source) {
  assetStore.upload(files, "<screenshot>", { source });
}
